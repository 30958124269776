import { useKeycloak }         from '@react-keycloak/web';
import { useEffect, useState } from 'react';

type a = () => {
    role: string | null
    roles: string[]
    error: boolean
};

const rolesHierarchy = ['ADMIN', 'PRINCIPAL', 'LXD', 'TEACHER'];

const useGetDocenteSoyRoles: a = () => {
    const { initialized, keycloak } = useKeycloak();
    const [roles, setRoles] = useState<string[]>([]);
    const [firstRole, setFirstRole] = useState<string | null>(null);
    const [error, setError] = useState(false);

    useEffect(() => {
        const token = keycloak.tokenParsed;
        if (initialized && keycloak.authenticated && token !== undefined) {
            if (token.resource_access?.hasOwnProperty('docente.soy')) {
                const resources_access = token.resource_access['docente.soy'].roles;
                setRoles(resources_access);
            } else {
                setError(true);
            }
        }
    }, [initialized, keycloak]);

    useEffect(() => {
        if (initialized && roles.length > 0) {
            const sorted = roles.sort((a, b) => rolesHierarchy.indexOf(a) - rolesHierarchy.indexOf(b));

            if (sorted[0] !== undefined) {
                if (sorted[0].toLowerCase() === 'principal') {
                    setFirstRole('director');
                } else {
                    setFirstRole(sorted[0].toLowerCase());
                }

            } else {
                setError(true);
            }
        }

    }, [initialized, roles]);

    return { role: firstRole, roles, error };
};
export default useGetDocenteSoyRoles;
