import Service                                      from '../abstract/Service';
import { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import ISchool                                      from '../../interfaces/entities/ISchool';

class AuthService extends Service {
    constructor(axios: AxiosInstance) {
        super('auth', axios);
    }

    async register(data: object): Promise<AxiosResponse | AxiosError> {
        try {
            return await this.http.post(`${ this.uri }/register`, data);
        } catch (e) {
            return e as AxiosError;
        }
    }

    async approve(id: string): Promise<AxiosResponse | AxiosError> {
        try {
            return await this.http.get(`${ this.uri }/approve/${ id }`);
        } catch (e) {
            return e as AxiosError;
        }
    }

    async reject(id: string): Promise<AxiosResponse | AxiosError> {
        try {
            return await this.http.get(`${ this.uri }/reject/${ id }`);
        } catch (e) {
            return e as AxiosError;
        }
    }

    async delete(id: string): Promise<AxiosResponse | AxiosError> {
        try {
            return await this.http.delete(`${ this.uri }/delete/${ id }`);
        } catch (e) {
            return e as AxiosError;
        }
    }

    async getSchools(): Promise<AxiosResponse<ISchool[]> | AxiosError> {
        try {
            return await this.http.get(`${ this.uri }/schools`);
        } catch (e) {
            return e as AxiosError;
        }
    }

    async validatedUser(params: object): Promise<AxiosResponse | AxiosError> {
        try {
            return await this.http.post(`${ this.uri }/validated/user`, params);
        } catch (e) {
            return e as AxiosError;
        }
    }
}

export default AuthService;
