import React, { useEffect, useRef, useState, Fragment }                       from 'react';
import { useHistory, useParams }                                              from 'react-router-dom';
import { useSelector }                                                        from 'react-redux';
import { IAppState }                                                          from '../../../../interfaces/store/IAppState';
import { ArrayLabels }                                                        from '../../../../components/labels/types';
import { Button, Col, Form, Input, Row, Select, message, notification, Spin } from 'antd';
import { IResourceFile }                                                      from '../components/records/types';
import Labels
                                                                              from '../../../../components/labels';
import { DeleteOutlined, DeleteTwoTone, PlusOutlined }                        from '@ant-design/icons';
import { handleFinishUpdate, initialDisabledValues, initialLoadingValues }    from './helpers';
import { handleAxiosError, isAxiosError }                                     from '../../../../utils/services';
import { AxiosError, AxiosResponse }                                          from 'axios';
import IIndicator
                                                                              from '../../../../interfaces/entities/IIndicator';
import useServiceByRef
                                                                              from '../../../../utils/hooks/useServiceByRef';
import IUnitService
                                                                              from '../../../../api/services/UnitService';
import SubjectService
                                                                              from '../../../../api/services/SubjectService';
import ResourceService
                                                                              from '../../../../api/services/ResourceService';
import IndicatorService
                                                                              from '../../../../api/services/IndicatorService';
import { LoadingFormType, IFormFieldsUpdate, IFormFieldsUpdateInitial }       from './types';
import { useKeycloak }                                                        from '@react-keycloak/web';
import setFormErrorsByAxiosResponse
                                                                              from '../../../../utils/forms/setFormErrorsByAxiosResponse';
import IResourceType
                                                                              from '../../../../interfaces/entities/IResourceType';
import ISubject
                                                                              from '../../../../interfaces/entities/ISubject';
import IUnit
                                                                              from '../../../../interfaces/entities/IUnit';
import IGrade
                                                                              from '../../../../interfaces/entities/IGrade';
import IResource
                                                                              from '../../../../interfaces/entities/IResource';
import FooterResource                                                         from '../../components/footer';
import IResourceEdit
                                                                              from '../../../../interfaces/entities/IResourceEdit';
import { updateRules }                                                        from './rules';
import IRecord
                                                                              from '../../../../interfaces/entities/IRecord';
import RecordsOld                                                             from '../components/records-old';
import RecordsResource                                                        from '../components/records';
import { redirectByRole }                                                     from '../../../../utils/redirectByRole';

const { TextArea } = Input;
const { Item } = Form;

interface RouteParams {
    resource_id: string
}

const EditResource: React.FC = () => {
    //CONF
    const history = useHistory();
    const [form] = Form.useForm();
    const params = useParams<RouteParams>();
    const readyForAuthRequests = useSelector<IAppState, boolean>(({ axios }) => axios.readyForRequests);
    const ref = useRef<ArrayLabels>(null);
    const { keycloak } = useKeycloak();
    const schoolID = useSelector<IAppState, string | null>(({ auth }) => auth.selected_school_id);

    //SERVICES
    const unitService = useServiceByRef(IUnitService);
    const subjectService = useServiceByRef(SubjectService);
    const resourceService = useServiceByRef(ResourceService);
    const indicatorService = useServiceByRef(IndicatorService);

    // STATES loading
    const [processing, setProcessing] = useState<boolean>(false);
    const [loading, setLoading] = useState<LoadingFormType>(initialLoadingValues);
    const [disabled, setDisabled] = useState<LoadingFormType>({ ...initialDisabledValues, form: false });

    const [indicators, setIndicators] = useState<IIndicator[]>([]);
    const [validateIndicator, setValidateIndicator] = useState<boolean>(false);
    const [selectedIndicators, setSelectedIndicators] = useState<IIndicator[]>([]);

    const [resource, setResource] = useState<IResource>();
    const [resourceTypes, setResourceTypes] = useState<IResourceType[]>([]);
    const [grades, setGrades] = useState<IGrade[]>([]);
    const [selectedGrade, setSelectedGrade] = useState<number>(0);
    const [subjects, setSubjects] = useState<ISubject[]>([]);
    const [units, setUnits] = useState<IUnit[]>([]);

    // to upload files
    const [typeSelected, setTypeSelected] = useState<number>(0);
    const [stateResource, setStateResource] = useState<number>(0);
    const [fileData, setFileData] = useState<IResourceFile[]>([]);
    //old resources
    const [oldRecord, setOldRecord] = useState<IRecord[]>([]);
    // layout Form
    const layoutForm = {
        labelCol: { span: 24 },
        wrapperCol: { span: 24 },
    };

    //get resources
    useEffect(() => {
        if (readyForAuthRequests) {
            const getResource = async () => {
                if (resourceService.current) {
                    setProcessing(true);
                    const response = await resourceService.current?.getEdit(params.resource_id, schoolID ?? null);
                    if (isAxiosError(response)) {
                        const err = response as AxiosError;
                        handleAxiosError(err, {
                            403: _ => notification.error({
                                message: 'Ocurrio un error',
                                description: 'No cuenta con los permisos necesarios para editar este recurso'
                            }),
                            404: _ => notification.error({
                                message: '404 error',
                                description: 'El recurso al cual intenta ingresar no existe.'
                            }),
                            409: _ => notification.error({
                                message: 'Recurso no puede ser modificado',
                                description: 'La carga academica del recurso, ya no se encuentra activa'
                            }),
                            500: error => notification.error({
                                message: 'Ocurrio un error',
                                description: error.message
                            }),
                            default: error => notification.error({
                                message: 'Ocurrio un error',
                                description: error.message
                            })
                        });
                        history.goBack();
                    } else {
                        const { data } = response as AxiosResponse<IResourceEdit>;
                        if (data.resource !== null) {
                            setResource(data.resource);
                            setResourceTypes(data.types);
                            setGrades(data.grades);
                            setSubjects(data.subjects);
                            setUnits(data.units);
                            setTypeSelected(data.resource.resource_type_id);
                            setIndicators(data.indicators);
                            if (data.resource.unit?.academic_subject !== undefined) {
                                const grade = parseInt(data.resource.unit?.academic_subject.grade_id);
                                setSelectedGrade(grade);
                            }
                            if (data.resource.records !== undefined) {
                                setOldRecord(data.resource.records);
                            }
                            enableAll();
                        }
                    }
                    setProcessing(false);
                }
            };

            if (keycloak.authenticated && keycloak.hasResourceRole('TEACHER') && schoolID !== null) {
                getResource().finally();
            } else if (keycloak.authenticated && !keycloak.hasResourceRole('TEACHER')) {
                getResource().finally();
            }
        }
    }, [keycloak, readyForAuthRequests, params.resource_id, resourceService, schoolID, history]);

    const enableAll = () => {
        setDisabled(() => ({
            ...initialDisabledValues,
            resource_types: false,
            grade: false,
            subject: false,
            unit: false,
            indicator: false
        }));
    };
    //INITIAL VALUES
    const [initialUpdate] = useState<IFormFieldsUpdateInitial>({
        resource_type_id: 0,
        name: '',
        description: '',
        labels: [],
        grade_id: '',
        subject_id: '',
        unit_id: '',
        topic: '',
        url: '',
        indicators: [],
    });

    useEffect(() => {
        if (resource !== undefined) {
            setProcessing(true);

            const indicatorSetForm = resource.indicators;
            form.setFieldsValue({
                resource_type_id: resource.resource_type_id,
                name: resource.name,
                description: resource.description,
                labels: resource.labels,
                grade_id: resource.unit?.academic_subject?.grade_id,
                subject_id: resource.unit?.academic_subject?.subject_id,
                unit_id: resource.unit?.id,
                topic: resource.topic,
                url: resource.url,
            });

            // set indicators
            if (indicatorSetForm !== undefined) {
                setSelectedIndicators(indicatorSetForm);
                indicatorSetForm.map((value, index) => (
                    form.setFields([
                        {
                            name: ['indicators', index],
                            value: value.id,
                            errors: [],
                        }
                    ])
                ));
            }
            setProcessing(false);
        } else {
            form.setFieldsValue({
                resource_type_id: '',
                name: '',
                description: '',
                labels: '',
                grade_id: '',
                subject_id: '',
                unit_id: '',
                topic: '',
                url: '',
            });
        }
    }, [resource, form]);

    /**
     * Get Subject where grade ID
     * @param id
     */
    const handleGradesChange = async (id: number) => {
        form.setFields([{
            name: 'subject_id',
            value: null
        }, {
            name: 'unit_id',
            value: null
        }, {
            name: 'indicators',
            value: null
        }]);

        setUnits([]);
        setIndicators([]);

        setDisabled(() => ({
            ...initialDisabledValues,
            resource_types: false,
            grade: false,
            subject: true,
            unit: true,
            indicator: true
        }));
        setLoading((s) => ({ ...s, subject: true }));
        if (subjectService.current) {
            const response = keycloak.hasResourceRole('TEACHER') ? await subjectService.current.getByAcademicCharge(id) : await subjectService.current.getByGrade(id);
            if (!isAxiosError(response)) {
                const { data } = response as AxiosResponse<ISubject[]>;
                if (data.length > 0) {
                    setSubjects(data);
                    setSelectedGrade(id);
                    setDisabled((s) => ({ ...s, subject: false }));
                } else {
                    message.error({
                        content: <>No se encontraron materias disponibles en el grado seleccionado</>,
                        icon: 'error',
                        duration: 2
                    });
                }
            }
        }
        setLoading((s) => ({ ...s, subject: false }));
    };

    /**
     * Get Unit where Subject ID & Grade ID
     * @param id
     */
    const handleSubjectChange = async (id: number) => {
        form.setFields([{
            name: 'unit',
            value: null
        }, {
            name: 'indicators',
            value: null
        }]);
        setDisabled(() => ({
            ...initialDisabledValues,
            resource_types: false,
            grade: false,
            subject: false,
            unit: true,
            indicator: true
        }));
        setLoading((s) => ({ ...s, unit: true }));
        if (unitService.current) {
            const response = await unitService.current.getByAcademicSubject({
                subject_id: id,
                grade_id: selectedGrade
            });

            if (!isAxiosError(response)) {
                const { data } = response as AxiosResponse<IUnit[]>;
                if (data.length > 0) {
                    setDisabled((s) => ({ ...s, unit: false }));
                    setUnits(data);
                } else {
                    message.error({
                        content: <>No se encontraron materias disponibles en el grado seleccionado</>,
                        icon: 'error',
                        duration: 2
                    });
                }
            }
        }
        setLoading((s) => ({ ...s, unit: false }));
    };

    /**
     * Get Indicators & topics where Unit ID
     * @param id
     */
    const handleUnitChange = async (id: string) => {
        form.setFields([{
            name: 'indicators',
            value: null
        }]);
        setDisabled(() => ({
            ...initialDisabledValues,
            resource_types: false,
            grade: false,
            subject: false,
            unit: false,
            indicator: true
        }));
        getIndicators(id).finally();
    };

    // INDICATORS FUNCTIONS
    useEffect(() => {
        if (validateIndicator) {
            if (selectedIndicators.length === 0) {
                form.resetFields(['indicators']);
            }
        }
    }, [selectedIndicators, validateIndicator, form]);

    /**
     * Get Indicators where Unit ID
     * @param id
     */
    const getIndicators = async (id: string) => {
        if (indicatorService.current) {
            setDisabled(() => ({
                ...initialDisabledValues,
                resource_types: false,
                grade: false,
                subject: false,
                unit: false,
                indicator: false
            }));
            setLoading((s) => ({ ...s, indicator: true }));
            const response = await indicatorService.current.getByUnit(id);

            if (!isAxiosError(response)) {
                const { data } = response as AxiosResponse<IIndicator[]>;
                if (data.length > 0) {
                    setIndicators(data);
                    setDisabled((s) => ({ ...s, indicator: false }));
                } else {
                    message.error({
                        content: <>No se encontraron indicadores disponibles en la unidad</>,
                        icon: 'error',
                        duration: 2
                    });
                }
            }
        }
        setLoading((s) => ({ ...s, indicator: false }));
    };

    const handleIndicatorChange = async (id: string) => {
        setValidateIndicator(true);
        if (indicators.length > 0) {
            const provIndicators = Object.assign([], selectedIndicators);
            let existIndex = 0;
            let exist = false;

            provIndicators.some((value: IIndicator, index) => {
                if (value.id === id) {
                    existIndex = index;
                    exist = true;
                }
                return value.id === id;
            });

            if (exist) {
                form.setFields([
                    {
                        name: ['indicators', existIndex],
                        errors: ['No puede registrar el mismo indicador']
                    }
                ]);
            } else {
                setSelectedIndicators((s) => ([...s, indicators.find((indi) => indi.id === id) as unknown as IIndicator]));
                form.setFields([
                    {
                        name: ['indicators', existIndex],
                        errors: []
                    }
                ]);
            }

        } else {
            setSelectedIndicators([indicators.find((indi) => indi.id === id) as unknown as IIndicator]);
        }
    };

    const handleRemoveIndicator = (callback: () => void, field: any) => {
        const fieldValue = form.getFieldValue(['indicators', field.name]);

        if (fieldValue !== undefined) {
            const newSelectedIndicators = selectedIndicators.filter((selectedIndicator) => (selectedIndicator.id !== fieldValue));

            setSelectedIndicators(newSelectedIndicators);
            callback();
        } else {
            callback();
        }
    };

    /**
     * disable the submit event with the enter key, restriction necessary when a new label is entered
     * @param event
     */
    const handleOnEnter = (event: React.KeyboardEvent<HTMLFormElement>) => {
        if (event.key === 'Enter') {
            event.preventDefault();
        }
    };

    const onSuccess = () => {
        setProcessing(false);
        notification.success({
            duration: 3.5,
            message: 'Operación finalizada',
            description: `Recurso editado exitosamente`,
        });

        form.resetFields();
        setStateResource(0);
        setFileData([]);
        setOldRecord([]);
        redirectToResource();
    };

    const onError = (err: AxiosError) => {
        setProcessing(false);
        handleAxiosError(err, {
            422: response => setFormErrorsByAxiosResponse(form, response),
            default: error => notification.error({
                message: error.message,
                description: 'Lo sentimos, ocurrio un error al momento de editar el recurso',
            })
        });
    };

    const redirectToResource = () => {
        const urlRole = redirectByRole('');
        history.push(`${ urlRole }`);
    };

    return (
        <div className="content-resource">
            <Form
                { ...layoutForm }
                name="EditResource"
                className="item-resource"
                initialValues={ initialUpdate }
                form={ form }
                onFinish={ v => {
                    if (resourceService.current) {
                        setProcessing(true);
                        const labels = ref.current?.labels;
                        const resource_state_id = stateResource;
                        const files = fileData;
                        const oldRecords = oldRecord;
                        const school_id = schoolID;
                        const data = { ...v, resource_state_id, labels, files, oldRecords, school_id };
                        handleFinishUpdate(data as IFormFieldsUpdate, onSuccess, onError, resourceService.current, params.resource_id);
                    }
                }
                }
                requiredMark={ false }
                onKeyDown={ e => {
                    handleOnEnter(e);
                } }
            >
                <Spin className="spin-create" tip="Procesando..." spinning={ processing } size="large">
                    { resource !== undefined && (
                        <Fragment>
                            <h2 className="title-resource">Editar recurso </h2>
                            <Item
                                label={ <h3 className="title-sub-1">{ 'Tipo de recurso' }</h3> }
                                name="resource_type_id"
                                rules={ updateRules.resource_type_id }
                                hasFeedback
                            >
                                <Select placeholder="Seleccione un tipo de recurso"
                                        className="select-resource"
                                        options={ resourceTypes.map((value) => ({
                                            value: value.id,
                                            label: value.name
                                        })) }
                                        loading={ loading.resource_types }
                                        disabled={ disabled.resource_types }
                                        onChange={ (value: number) => setTypeSelected(value) }
                                />
                            </Item>
                            <Item
                                label={ <h3 className="title-sub-1">{ 'Nombre' }</h3> }
                                name="name"
                                rules={ updateRules.name }
                                hasFeedback
                            >
                                <Input className="input-resource" placeholder="¿Cual es el nombre del recurso?" />
                            </Item>
                            <Item
                                label={ <h3 className="title-sub-1">{ 'Tags/palabras claves' }</h3> }
                                name="labels"
                                hasFeedback
                            >
                                <Labels resource={ resource } ref={ ref } />
                            </Item>
                            <Item
                                label={ <h3 className="title-sub-1">{ 'Descripción' }</h3> }
                                name="description"
                                rules={ updateRules.description }
                                hasFeedback
                            >
                                <TextArea className="input-resource" rows={ 7 }
                                          placeholder="Escribe una descripción del recurso" />
                            </Item>
                            <Item
                                label={ <h3 className="title-sub-1">{ 'Grado' }</h3> }
                                name="grade_id"
                                rules={ updateRules.grade_id }
                                hasFeedback
                            >
                                <Select placeholder="Seleccione un grado"
                                        className="select-resource"
                                        options={ grades.map((value) => ({ value: value.id, label: value.name })) }
                                        loading={ loading.grade }
                                        disabled={ disabled.grade }
                                        onChange={ (value: number) => handleGradesChange(value) }
                                />
                            </Item>
                            <Item
                                label={ <h3 className="title-sub-1">{ 'Materia' }</h3> }
                                name="subject_id"
                                rules={ updateRules.subject_id }
                                hasFeedback
                            >
                                <Select placeholder="Seleccione una materia"
                                        className="select-resource"
                                        loading={ loading.subject }
                                        disabled={ disabled.subject }
                                        options={ subjects.map((value) => ({ value: value.id, label: value.name })) }
                                        onChange={ (value: number) => handleSubjectChange(value) }
                                />
                            </Item>
                            <Item
                                label={ <h3 className="title-sub-1">{ 'Unidad' }</h3> }
                                name="unit_id"
                                rules={ updateRules.unit_id }
                                hasFeedback
                            >
                                <Select placeholder="Seleccione una Unidad"
                                        className="select-resource"
                                        loading={ loading.unit }
                                        disabled={ disabled.unit }
                                        options={ units.map((value) => ({ value: value.id, label: value.name })) }
                                        onChange={ (value: string) => handleUnitChange(value) }
                                />
                            </Item>
                            <Item
                                label={ <h3 className="title-sub-1">{ 'Tema' }</h3> }
                                name="topic"
                                rules={ updateRules.topic }
                                hasFeedback
                            >
                                <Input className="input-resource" placeholder="Ingrese un tema" />
                            </Item>
                            <h3 className="title-sub-1">Indicadores de logro </h3>
                            <Form.List
                                name="indicators"
                            >
                                { (fields, { add, remove }) => (
                                    <div>
                                        { fields.map((value, index) => (
                                            <Row
                                                gutter={ [16, 16] }
                                                key={ index }
                                            >
                                                <Col span={ 22 }>
                                                    <Form.Item
                                                        { ...value }
                                                        key={ index }
                                                        fieldKey={ value.fieldKey + 1 }
                                                        rules={ updateRules.indicators }
                                                        hasFeedback
                                                    >
                                                        <Select placeholder="Selecciona un indicador de logro"
                                                                className="select-resource"
                                                                options={ indicators.map((value) => ({
                                                                    value: value.id,
                                                                    label: value.name
                                                                })) }
                                                                loading={ loading.indicator }
                                                                disabled={ disabled.indicator }
                                                                onChange={ (value: string) => handleIndicatorChange(value) }
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={ 2 }>
                                                    { fields.length > 1 ? (
                                                        <DeleteOutlined
                                                            onClick={ () => handleRemoveIndicator(() => remove(value.name), value) }
                                                        />
                                                    ) : null }
                                                </Col>
                                            </Row>
                                        )) }

                                        <Button block shape="round" className="btn-primary-resources"
                                                onClick={ () => add() }
                                                disabled={ disabled.indicator }
                                        >
                                            <>
                                                { fields.length > 1 ? 'Agregar otro indicador' : 'Agregar indicador' }
                                            </>
                                        </Button>

                                        { fields.length === 0 ? add() : <></> }
                                    </div>
                                ) }
                            </Form.List>
                            <Item
                                label={ <h3 className="title-sub-1">{ 'Url' }</h3> }
                                name="url"
                                hasFeedback
                            >
                                <Input className="input-resource"
                                       placeholder="Ingrese una url adicional  Ej. juego online, link" />
                            </Item>
                            { typeSelected >= 3 && (
                                <Fragment>
                                    <RecordsResource form={ form } typeSelected={ typeSelected }
                                                     setFileData={ setFileData } fileData={ fileData } />
                                    <RecordsOld oldRecord={ oldRecord } setOldRecord={ setOldRecord } />

                                    { typeSelected === 3 && (
                                        <Form.List name="records_external_link">
                                            { (fields, { add, remove }) => (
                                                <>
                                                    { fields.map(({ key, name, fieldKey, ...restField }) => (
                                                        <Row
                                                            gutter={ [16, 16] }
                                                            key={ key }
                                                        >
                                                            <Col span={ 22 }>
                                                                <Form.Item
                                                                    { ...restField }
                                                                    name={ [name, 'link_file'] }
                                                                    fieldKey={ [fieldKey, 'link_file'] }
                                                                    rules={ [{
                                                                        required: true,
                                                                        message: 'El link del video es requerido'
                                                                    },
                                                                        {
                                                                            whitespace: true
                                                                        },
                                                                        {
                                                                            validator: async (_, value) => {
                                                                                const url = value.match('^(http:\\/\\/|https:\\/\\/)(vimeo\\.com|youtu\\.be|www\\.youtube\\.com)\\/([\\w\\/]+)([\\?].*)?$');
                                                                                if (!url) {
                                                                                    return Promise.reject(new Error('La url ingresada no es valida, por favor ingresar una url de Youtube o Vimeo'));
                                                                                }
                                                                            }
                                                                        },
                                                                    ] }

                                                                    hasFeedback
                                                                >
                                                                    <Input className="input-resource" type="url"
                                                                           placeholder="Url video" />
                                                                </Form.Item>
                                                            </Col>
                                                            <Col span={ 2 }>
                                                                <DeleteTwoTone onClick={ () => remove(name) }
                                                                               style={ { fontSize: '24px' } } />
                                                            </Col>
                                                        </Row>
                                                    )) }
                                                    <Form.Item>
                                                        <Button block shape="round" className="btn-primary-resources"
                                                                onClick={ () => add() }
                                                                icon={ <PlusOutlined /> }>
                                                            Agregar videos por medio de link
                                                        </Button>
                                                    </Form.Item>
                                                </>
                                            ) }
                                        </Form.List>
                                    ) }
                                </Fragment>
                            ) }
                            <Row gutter={ [40, 16] } className="btn-action-resource-new">
                                <Col xs={ 24 } sm={ 24 } md={ 6 } lg={ 6 } xl={ 6 }>
                                    <Button shape="round" htmlType="submit" className="btn-eraser-text" block
                                            onClick={ () => setStateResource(2) }
                                    >
                                        Editar y Guardar en borrador
                                    </Button>
                                </Col>
                                <Col xs={ 24 } sm={ 24 } md={ 18 } lg={ 18 } xl={ 18 }>
                                    <Button shape="round" htmlType="submit" className="btn-primary-resources" block
                                            onClick={ () => setStateResource(1) }
                                    >
                                        Editar y publicar
                                    </Button>
                                </Col>
                            </Row>
                            <FooterResource />
                        </Fragment>
                    ) }
                </Spin>
            </Form>
        </div>
    );
};
export default EditResource;

