import React, { Fragment, useEffect, useState } from 'react';
import { Tabs }                                 from 'antd';
import { useKeycloak }                          from '@react-keycloak/web';
import ResourcesList                            from '../../../components/Resources';
import BannerProfile                            from '../../../components/profile/banner';
import ButtonsProfile                           from '../../../components/profile/buttons';
import ActivityGuidesListShow                   from '../../../components/ActivityGuides';

const { TabPane } = Tabs;

const LXDProfile: React.FC = () => {
    const { initialized, keycloak } = useKeycloak();
    const [logged, setLogged] = useState<boolean>(false);

    useEffect(() => {
        if (initialized) {
            if (keycloak.authenticated) {
                setLogged(true);
            }
        }
    }, [keycloak, initialized]);

    return (
        <Fragment>
            <div className={ 'home-content' }>
                { logged && (
                    <Fragment>
                        <BannerProfile logged={ logged } isLxd />
                        <ButtonsProfile logged={ logged } isLxd />
                        <Tabs className="tabs-profile" defaultActiveKey="1" destroyInactiveTabPane={true}>
                            <TabPane tab="Mis recursos" key="1">
                                <Tabs tabPosition={ 'top' } className={ 'sub-my-resources' }>
                                    <TabPane tab="Recursos" key={ 'all_resources' }>
                                        <ResourcesList
                                            paginated
                                            logged={ logged }
                                            profile={ true }
                                            myResources={ true }
                                            draft={ false }
                                            home={ false }
                                        />
                                    </TabPane>
                                    <TabPane tab="Guia de actividades" key={ 'all_activity guide' }>
                                        <ActivityGuidesListShow
                                            paginated
                                            profile
                                            myResources
                                            home={ false }
                                            draft={ false }
                                        />
                                    </TabPane>
                                </Tabs>
                            </TabPane>
                            <TabPane tab="Borradores" key="2">
                                <Tabs tabPosition={ 'top' } className={ 'sub-my-resources' }>
                                    <TabPane tab="Recursos" key={ 'all_resources' }>
                                        <ResourcesList
                                            paginated
                                            logged={ logged }
                                            profile={ true }
                                            draft={ true }
                                            myResources={ false }
                                            pendingApproval={ false }
                                            home={ false }
                                        />
                                    </TabPane>
                                    <TabPane tab="Guia de actividades" key={ 'all_activity guide' }>
                                        <ActivityGuidesListShow
                                            paginated
                                            profile
                                            draft
                                            home={ false }
                                            myResources={ false }
                                        />
                                    </TabPane>
                                </Tabs>
                            </TabPane>
                            <TabPane tab="Pendientes de aprobación del colegio" key="3">
                                <ResourcesList
                                    paginated
                                    logged={ logged }
                                    profile={ true }
                                    pendingApproval={ true }
                                    myResources={ false }
                                    draft={ false }
                                />
                            </TabPane>
                        </Tabs>
                    </Fragment>
                ) }
            </div>
        </Fragment>
    );
};

export default LXDProfile;
