import RestService                                  from '../abstract/RestService';
import { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import IResource                                    from '../../interfaces/entities/IResource';
import IPaginatedData                               from '../../interfaces/IPaginatedData';
import IResourceInformation                         from '../../interfaces/entities/IResourceInformation';
import IResourcePublicShow                          from '../../interfaces/entities/IResourcePublicShow';
import { getParamsService }                         from '../../utils/paramsServices';

class ResourceService extends RestService<IResource> {
    constructor(axios: AxiosInstance) {
        super('resource', axios);
    }

    async all(page: number): Promise<AxiosResponse<IResource[]> | AxiosError> {
        throw new Error('not implemented');
    }

    async allResources(page: number | undefined, school_id: string | null): Promise<AxiosResponse<IResource[]> | AxiosError> {
        try {
            const params: string = getParamsService(page, school_id);
            return await this.http(this.uri + params);
        } catch (e) {
            return e as AxiosError;
        }
    }

    /**
     * get all resource public / profile
     * @param page
     */
    async allPublic(page?: number): Promise<AxiosResponse<IResource[] | IPaginatedData<IResource>> | AxiosError> {
        try {
            const params = page !== undefined ? `?page=${ page }` : '';
            return await this.http.get(`${ this.uri }/get_by/status/approved/public/` + params);
        } catch (e) {
            return e as AxiosError;
        }
    }


    async getProfileMyResources(page: number): Promise<AxiosResponse<IResource[] | IPaginatedData<IResource>> | AxiosError> {
        try {
            const params = page !== undefined ? `?page=${ page }` : '';
            return await this.http.get(`${ this.uri }/profile/my_resources` + params);
        } catch (e) {
            return e as AxiosError;
        }
    }

    /**
     * get PROFILE resource DRAFT
     * @param page
     */
    async getProfileDraft(page?: number): Promise<AxiosResponse<IResource[] | IPaginatedData<IResource>> | AxiosError> {
        try {
            const params = page !== undefined ? `?page=${ page }` : '';
            return await this.http.get(`${ this.uri }/profile/draft` + params);
        } catch (e) {
            return e as AxiosError;
        }
    }

    /**
     * get PROFILE resource Pending Approval
     * @param page
     */
    async getProfilePendingApproval(page?: number): Promise<AxiosResponse<IResource[] | IPaginatedData<IResource>> | AxiosError> {
        try {
            const params = page !== undefined ? `?page=${ page }` : '';
            return await this.http.get(`${ this.uri }/profile/pending_approval` + params);
        } catch (e) {
            return e as AxiosError;
        }
    }

    /**
     * Get One by Id resource
     * @param id
     * @param school_id
     */
    async get(id: string, school_id: string | null): Promise<AxiosResponse<IResourceInformation> | AxiosError> {
        try {
            const params = school_id !== null ? `?school_id=${ school_id }` : '';
            return await this.http.get(`${ this.uri }/${ id }` + params);
        } catch (e) {
            return e as AxiosError;
        }
    }

    /**
     * Get One by Id resource | edit complete
     * @param id
     * @param school_id
     */
    async getEdit(id: string, school_id: string | null): Promise<AxiosResponse | AxiosError> {
        try {
            const params = school_id !== null ? `?school_id=${ school_id }` : '';
            return await this.http.get(`${ this.uri }/edit/${ id }` + params);
        } catch (e) {
            return e as AxiosError;
        }
    }

    /**
     * get favorite favorite
     * @param page
     * @constructor
     */
    async getFavorite(page?: number): Promise<AxiosResponse<IResource[] | IPaginatedData<IResource>> | AxiosError> {
        try {
            const params = page !== undefined ? `?page=${ page }` : '';
            return await this.http.get(`${ this.uri }/favorite` + params);
        } catch (e) {
            return e as AxiosError;
        }
    }

    /**
     * Add resource to favorite
     * @param params
     * @constructor
     */
    async AddFavoriteResource(params: object): Promise<AxiosResponse | AxiosError> {
        try {
            return await this.http.post(`${ this.uri }/add/favorite/resource`, params);
        } catch (e) {
            return e as AxiosError;
        }
    }

    /**
     * Remove favorite resource
     * @constructor
     * @param id
     */
    async RemoveFavoriteResource(id: string): Promise<AxiosResponse | AxiosError> {
        try {
            return await this.http.put(`${ this.uri }/remove/favorite/resource/${ id }`);
        } catch (e) {
            return e as AxiosError;
        }
    }

    async DeniedResourceByUser(page?: number): Promise<AxiosResponse<IResource[] | IPaginatedData<IResource>> | AxiosError> {
        try {
            const params = page !== undefined ? `?page=${ page }` : '';
            return await this.http.get(`${ this.uri }/get_by/denied/user` + params);
        } catch (e) {
            return e as AxiosError;
        }
    }

    /**
     * get denied my_resources by user by resource
     * @constructor
     * @param user
     * @param resource
     */
    async DeniedResourceByUserByResource(user: string, resource: string): Promise<AxiosResponse<IResource[]> | AxiosError> {
        try {
            return await this.http.get(`${ this.uri }/get_by/denied/user/${ user }/resource/${ resource }`);
        } catch (e) {
            return e as AxiosError;
        }
    }

    /**
     * Search Resource
     * @param params
     */
    async search(params: object): Promise<AxiosResponse | AxiosError> {
        try {
            return await this.http.post(`${ this.uri }/search`, params);
        } catch (e) {
            return e as AxiosError;
        }
    }

    /**
     * Search Resource
     * @param params
     */
    async searchAssignable(params: object): Promise<AxiosResponse | AxiosError> {
        try {
            return await this.http.post(`${ this.uri }/search/assignable`, params);
        } catch (e) {
            return e as AxiosError;
        }
    }

    /**
     * Create resource
     * @param params
     * @param school_id
     */
    async store(params: FormData, school_id: string | null): Promise<AxiosResponse | AxiosError> {
        try {
            const params_school = school_id !== null ? `?school_id=${ school_id }` : '';
            return await this.http.post(this.uri + params_school, params, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
        } catch (e) {
            return e as AxiosError;
        }
    }

    /**
     * update resource
     * @param id
     * @param params
     */
    async update(id: string, params: FormData): Promise<AxiosResponse | AxiosError> {
        try {
            return await this.http.post(`${ this.uri }/update`, params, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
        } catch (e) {
            return e as AxiosError;
        }
    }

    /**
     * delete resource
     * @param id
     */
    async delete(id: string): Promise<AxiosResponse | AxiosError> {
        try {
            return await this.http.delete(`${ this.uri }/${ id }`);
        } catch (e) {
            return e as AxiosError;
        }
    }

    /**
     *
     * @param resource
     */
    async isFavorite(resource: string): Promise<AxiosResponse | AxiosError> {
        try {
            return await this.http.get(`${ this.uri }/if_exist_favorite/resource/${ resource }`);
        } catch (e) {
            return e as AxiosError;
        }
    }

    async recommendationsLatest(): Promise<AxiosResponse | AxiosError> {
        try {
            return await this.http.get(`${ this.uri }/recommendations`);
        } catch (e) {
            return e as AxiosError;
        }
    }

    async draftChangeToPublish(resource: string): Promise<AxiosResponse | AxiosError> {
        try {
            return await this.http.put(`${ this.uri }/draft_change_status/resource/${ resource }`);
        } catch (e) {
            return e as AxiosError;
        }
    }

    async approvedResource(resource: string): Promise<AxiosResponse | AxiosError> {
        try {
            return await this.http.put(`${ this.uri }/approved/resource/${ resource }`);
        } catch (e) {
            return e as AxiosError;
        }
    }

    async deniedResource(resource: string, params: object): Promise<AxiosResponse | AxiosError> {
        try {
            return await this.http.put(`${ this.uri }/denied/resource/${ resource }`, params);
        } catch (e) {
            return e as AxiosError;
        }
    }

    async filterResources(data: object, page?: number): Promise<AxiosResponse<IResource[] | IPaginatedData<IResource>> | AxiosError> {
        try {
            const params = page !== undefined ? `?page=${ page }` : '';
            return await this.http.post(`${ this.uri }/filter` + params, data);
        } catch (e) {
            return e as AxiosError;
        }
    }

    async filterClear(page?: number): Promise<AxiosResponse<IResource[] | IPaginatedData<IResource>> | AxiosError> {
        try {
            const params = page !== undefined ? `?page=${ page }` : '';
            return await this.http.get(`${ this.uri }/filter/clear` + params);
        } catch (e) {
            return e as AxiosError;
        }
    }

    async getPublicUrl(id: string): Promise<AxiosResponse<string> | AxiosError> {
        try {
            return await this.http.get(`${ this.uri }/public_url/resource/${ id }`);
        } catch (e) {
            return e as AxiosError;
        }
    }

    async getResourcePublicShow(params: object): Promise<AxiosResponse<IResourcePublicShow> | AxiosError> {
        try {
            return await this.http.post(`${ this.uri }/public_show`, params);
        } catch (e) {
            return e as AxiosError;
        }
    }

    async filterByLabels(labels: { labels: string[] }, school_id: string | null): Promise<AxiosResponse<IResource[]> | AxiosError> {
        try {
            const params = school_id !== null ? `?school_id=${ school_id }` : '';
            return await this.http.post(`${ this.uri }/recommendations/get_by/labels` + params, labels);
        } catch (e) {
            return e as AxiosError;
        }
    }

}

export default ResourceService;
