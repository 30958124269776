import RestService                                  from '../abstract/RestService';
import IActivityGuide                               from '../../interfaces/entities/IActivityGuide';
import { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import IPaginatedData                               from '../../interfaces/IPaginatedData';
import { AssignedResourcesType }                    from '../../views/activity_guides/ActivityGuideForm/types';
import { getParamsService }                         from '../../utils/paramsServices';

export interface StoreActivityGuideParams {
    title: string
    description: string
    accessibility: 'PUBLIC' | 'PRIVATE'
    indicator_id: string[]
    labels: string[]
    label_id: string[]
    resources: AssignedResourcesType,
    school_id?: string,
    is_draft?: boolean,
    draft_id?: string
}

export interface UpdateActivyGuideParams {
    title?: string
    description?: string
    accessibility?: 'PUBLIC' | 'PRIVATE'
    indicator_id?: string[]
    labels?: string[]
    label_id?: string[]
    resources: AssignedResourcesType,
}

class ActivityGuideService extends RestService<IActivityGuide> {
    constructor(axios: AxiosInstance) {
        super('activity_guides', axios);
    }

    async all(page?: number, school_id?: string | null): Promise<AxiosResponse<IActivityGuide[] | IPaginatedData<IActivityGuide>> | AxiosError> {
        throw new Error('not implemented');
    }

    async allActivityGuides(page: number | undefined, school_id: string | null): Promise<AxiosResponse<IActivityGuide[] | IPaginatedData<IActivityGuide>> | AxiosError> {
        try {
            const params: string = getParamsService(page, school_id);
            return await this.http.get(this.uri + params);
        } catch (e) {
            return e as AxiosError;
        }
    }

    async get(code: string, school: { school_id: string } | null): Promise<AxiosResponse<IActivityGuide> | AxiosError> {
        try {
            const params = school !== null ? `?school_id=${ school.school_id }` : '';
            return await this.http.get(`${ this.uri }/${ code + params }`);
        } catch (e) {
            return e as AxiosError;
        }
    }

    async getDraft(draft_id: string, school_id: string | null): Promise<AxiosResponse<IActivityGuide> | AxiosError> {
        try {
            const params = school_id !== null ? `?school_id=${ school_id }` : '';
            return await this.http.get(`${ this.uri }/drafted/${ draft_id + params }`);
        } catch (e) {
            return e as AxiosError;
        }
    }

    async delete(id: string): Promise<AxiosResponse | AxiosError> {
        try {
            return await this.http.delete(`${ this.uri }/${ id }`);
        } catch (e) {
            return e as AxiosError;
        }
    }

    async edit(id: string): Promise<AxiosResponse | AxiosError> {
        try {
            return await this.http.put(`${ this.uri }/${ id }`);
        } catch (e) {
            return e as AxiosError;
        }
    }

    async incrementView(id: string): Promise<AxiosResponse | AxiosError> {
        try {
            return await this.http.get(`${ this.uri }/view/${ id }`);
        } catch (e) {
            return e as AxiosError;
        }
    }

    async favorite(id: string): Promise<AxiosResponse<{ favorite: boolean }> | AxiosError> {
        try {
            return await this.http.post(`${ this.uri }/favorite/${ id }`);
        } catch (e) {
            return e as AxiosError;
        }
    }

    async checkFavorite(id: string): Promise<AxiosResponse<{ is_favorite: boolean }> | AxiosError> {
        try {
            return await this.http.post(`${ this.uri }/check/favorite/${ id }`);
        } catch (e) {
            return e as AxiosError;
        }
    }

    async rating(id: string, rating: { rating: number }): Promise<AxiosResponse | AxiosError> {
        try {
            return await this.http.post(`${ this.uri }/rating/rate/${ id }`, rating);
        } catch (e) {
            return e as AxiosError;
        }
    }

    async getRating(id: string): Promise<AxiosResponse<{ activityGuide: IActivityGuide, rating: number }> | AxiosError> {
        try {
            return await this.http.get(`${ this.uri }/rating/get/${ id }`);
        } catch (e) {
            return e as AxiosError;
        }
    }

    async store(data: StoreActivityGuideParams): Promise<AxiosResponse<IActivityGuide> | AxiosError> {
        try {
            return await this.http.post(this.uri, data);
        } catch (e) {
            return e as AxiosError;
        }
    }

    async update(activity_guide_id: string, data: UpdateActivyGuideParams): Promise<AxiosResponse | AxiosError> {
        try {
            return await this.http.put(`${ this.uri }/${ activity_guide_id }`, data);
        } catch (e) {
            return e as AxiosError;
        }
    }

    async copy(id: string, school_id: { school_id: string } | null): Promise<AxiosResponse | AxiosError> {
        try {
            return await this.http.post(`${ this.uri }/copy/${ id }`, school_id);
        } catch (e) {
            return e as AxiosError;
        }
    }

    async updateDraft(draft_id: string, data: UpdateActivyGuideParams): Promise<AxiosResponse | AxiosError> {
        try {
            return await this.http.put(`${ this.uri }/drafted/${ draft_id }`, data);
        } catch (e) {
            return e as AxiosError;
        }
    }

    async filter(data: object, page?: number): Promise<AxiosResponse | AxiosError> {
        try {
            const params = page !== undefined ? `?page=${ page }` : '';
            return await this.http.post(`${ this.uri }/filter` + params, data);
        } catch (e) {
            return e as AxiosError;
        }
    }

    async getMyGuides(page: number | undefined, school_id: string | null): Promise<AxiosResponse<IActivityGuide[] | IPaginatedData<IActivityGuide>> | AxiosError> {
        try {
            const params: string = getParamsService(page, school_id);
            return await this.http.get(`${ this.uri }/my_guides` + params);
        } catch (e) {
            return e as AxiosError;
        }
    }

    async getMyDraft(page: number | undefined, school_id: string | null): Promise<AxiosResponse<IActivityGuide[] | IPaginatedData<IActivityGuide>> | AxiosError> {
        try {
            const params: string = getParamsService(page, school_id);
            return await this.http.get(`${ this.uri }/draft` + params);
        } catch (e) {
            return e as AxiosError;
        }
    }

    async getFavorite(page: number | undefined, school_id: string | null): Promise<AxiosResponse<IActivityGuide[] | IPaginatedData<IActivityGuide>> | AxiosError> {
        try {
            const params: string = getParamsService(page, school_id);
            return await this.http.get(`${ this.uri }/favorite` + params);
        } catch (e) {
            return e as AxiosError;
        }
    }
}

export default ActivityGuideService;
