import { AssignedResourcesType, FormValues }                 from './types';
import { StoreActivityGuideParams, UpdateActivyGuideParams } from '../../../api/services/ActivityGuideService';
import { DayType }                                           from '../../../types/entities/days';
import { validateWYSIWYGValue }                              from '../../../utils/forms/validateWYSIWYGValue';
import * as _                                                from 'lodash';
import ILabel                                                from '../../../interfaces/entities/ILabel';
import IActivityGuide                                        from '../../../interfaces/entities/IActivityGuide';

type props = {
    values: FormValues,
    labels: ILabel[],
    activityGuide: IActivityGuide | null,
    teacher: boolean,
    selected_school_id: string | null
}

type formatActivityGuideDataType = (mode: 'CREATE' | 'UPDATE', values: props) => StoreActivityGuideParams | UpdateActivyGuideParams;
type handleResourcesType = (mode: 'CREATE' | 'UPDATE', resources: AssignedResourcesType, activityGuide: IActivityGuide | null) => AssignedResourcesType;

const formatActivityGuideData: formatActivityGuideDataType = (mode, props) => {
    const { activityGuide, values, labels, selected_school_id, teacher } = props;
    // Copy the array cuz modifying the original values object affects the selected items in the view ┐(￣ー￣)┌
    const labelsCopy = [...values.labels];

    const label_id = labels.map((l) => {
        const i = labelsCopy.indexOf(l.name);
        if (i !== -1) {
            labelsCopy.splice(i, 1);
            return l.id;
        }

        return null;
    }).filter(l_id => l_id !== null) as string[];

    const data: StoreActivityGuideParams | UpdateActivyGuideParams = {
        title: values.title,
        description: values.description,
        labels: values.labels,
        label_id,
        indicator_id: values.indicator_id,
        accessibility: values.accessibility,
        resources: handleResources(mode, values.resources, activityGuide),
        school_id: teacher && selected_school_id !== null ? selected_school_id : undefined
    };

    return data;
};

const handleResources: handleResourcesType = (mode, resources, activityGuide) => (
    Object.entries(resources).reduce((o, [day, assignation]) => {
        const { indication, id: resource_id } = assignation;

        let currentAssignationResources: string[] = [];
        let flag = true;

        if (mode === 'UPDATE' && activityGuide !== null && activityGuide.assigned_resources !== undefined) {
            currentAssignationResources = (activityGuide.assigned_resources[day as DayType])?.resources?.map(r => r.id) ?? [];
            flag = !_.isEqual(resource_id, currentAssignationResources);
        }

        if ((indication !== undefined && indication !== null) || (resource_id.length > 0)) {
            o = Object.assign(o, {
                [day as DayType]: {
                    indication: null,
                    id: mode === 'UPDATE' ? currentAssignationResources : resource_id
                }
            });
        }

        if (indication !== undefined && indication !== null) {
            if (validateWYSIWYGValue(indication)) {
                o[day as DayType] = Object.assign(o[day as DayType], { indication });
            }
        }

        if (resource_id.length > 0 && flag) {
            if (day in o) {
                o[day as DayType] = Object.assign(o[day as DayType], { id: resource_id });
            } else {
                o = Object.assign(o, {
                    [day as DayType]: { id: resource_id }
                });
            }
        }

        return o;
    }, {} as AssignedResourcesType)
);

export default formatActivityGuideData;
