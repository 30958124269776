import React                         from 'react';
import ReactDOM                      from 'react-dom';
import App                           from './App';
import './styles/main.less';
import store                         from './store';
import { Provider as ReduxProvider } from 'react-redux';
import { ReactKeycloakProvider }     from '@react-keycloak/web';
import keycloak                      from './keycloak.config';

ReactDOM.render(
    <ReactKeycloakProvider
        authClient={ keycloak }
    >
        <ReduxProvider store={ store }>
            <App />
        </ReduxProvider>
    </ReactKeycloakProvider>,
    document.getElementById('root')
);