import RestService                                  from '../abstract/RestService';
import { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import IPaginatedData                               from '../../interfaces/IPaginatedData';
import IUnit                                        from '../../interfaces/entities/IUnit';
import IUnitSabi                                    from '../../interfaces/entities/IUnitSabi';

type SearchParams = {
    academic_subject_id?: string
    with?: ('subject' | 'grade')[]
};

class IUnitService extends RestService<IUnit> {
    constructor(axios: AxiosInstance) {
        super('unit', axios);
    }

    async all(page: number | undefined): Promise<AxiosResponse<IUnit[] | IPaginatedData<IUnit>> | AxiosError> {
        try {
            const params = page !== undefined ? `?page=${ page }` : '';
            return await this.http.get(`${ this.uri }${ params }`);
        } catch (e) {
            return e as AxiosError;
        }
    }

    async allFromSABI(params: { grade_id: string }): Promise<AxiosResponse<IUnit[] | IPaginatedData<IUnit>> | AxiosError> {
        try {
            return await this.http.post(`sabi/${ this.uri }`, params);
        } catch (e) {
            return e as AxiosError;
        }
    }

    async getByAcademicSubjectSABI(params: { academic_subject_id: string }): Promise<AxiosResponse<IUnitSabi[]> | AxiosError> {
        try {
            return await this.http.post(`sabi/${ this.uri }/get_by/academic_subject`, params);
        } catch (e) {
            return e as AxiosError;
        }
    }

    async getByAcademicSubject(params: { grade_id: number, subject_id: number }): Promise<AxiosResponse<IUnit[]> | AxiosError> {
        try {
            return await this.http.post(`${ this.uri }/get_by/academic_subject`, params);
        } catch (e) {
            return e as AxiosError;
        }
    }

    async search(params: SearchParams): Promise<AxiosResponse<IUnit[]> | AxiosError> {
        try {
            return await this.http.post(this.uri + '/search', params);
        } catch (e) {
            return e as AxiosError;
        }
    }
}

export default IUnitService;
