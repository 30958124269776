import { ThunkAction }   from 'redux-thunk';
import { IAppState }     from '../../interfaces/store/IAppState';
import { IAxiosActions } from '../../interfaces/store/actions/IAxiosActions';
import { AxiosInstance } from 'axios';
import * as types        from '../actions/constants/axios';

interface IAxiosAction<R, E = null> extends ThunkAction<R, IAppState, E, IAxiosActions> {
}

export const setAxiosInstance = (value: AxiosInstance): IAxiosAction<void> => (dispatch => {
    dispatch({
        type: types.SET_AXIOS_INSTANCE,
        value
    });

    dispatch({ type: types.SET_READY, value: true });
});

export const setToken = (value: string): IAxiosAction<void> => (dispatch => dispatch({
    type: types.SET_TOKEN,
    value
}));
