import React, { Fragment, useEffect, useState } from 'react';
import { Tabs }                                 from 'antd';
import { useKeycloak }                          from '@react-keycloak/web';
import BannerProfile                            from '../../../components/profile/banner';
import ResourcesList                            from '../../../components/Resources';
import ButtonsProfile                           from '../../../components/profile/buttons';
import ActivityGuidesListShow                   from '../../../components/ActivityGuides';

const { TabPane } = Tabs;

const ProfileAdmin: React.FC = () => {

    const { initialized, keycloak } = useKeycloak();
    const [logged, setLogged] = useState<boolean>(false);

    useEffect(() => {
        if (initialized) {
            if (keycloak.authenticated) {
                setLogged(true);
            }
        }
    }, [keycloak, initialized]);

    return (
        <Fragment>
            <div className={ 'home-content' }>
                { logged && (
                    <Fragment>
                        <BannerProfile logged isAdmin />
                        <ButtonsProfile logged isAdmin />
                        <Tabs className="tabs-profile" defaultActiveKey="1" destroyInactiveTabPane={ true }>
                            <TabPane tab="Mis recursos" key="1">
                                <Tabs tabPosition={ 'top' } className={ 'sub-my-resources' }>
                                    <TabPane tab="Recursos" key={ 'all_resources_admin' }>
                                        <ResourcesList
                                            paginated
                                            logged={ logged }
                                            profile={ true }
                                            myResources={ true }
                                            draft={ false }
                                            home={ false }
                                        />
                                    </TabPane>
                                    <TabPane tab="Guia de actividades" key={ 'all_activity_guide_admin' }>
                                        <ActivityGuidesListShow
                                            paginated
                                            profile
                                            myResources
                                            home={ false }
                                            draft={ false }
                                        />
                                    </TabPane>
                                </Tabs>
                            </TabPane>
                            <TabPane tab="Borradores" key="2">
                                <Tabs tabPosition={ 'top' } className={ 'sub-my-resources' }>
                                    <TabPane tab="Recursos" key={ 'all_resources' }>
                                        <ResourcesList
                                            paginated
                                            logged={ logged }
                                            profile={ true }
                                            draft={ true }
                                            myResources={ false }
                                            pendingApproval={ false }
                                            home={ false }
                                        />
                                    </TabPane>
                                    <TabPane tab="Guia de actividades" key={ 'all_activity guide' }>
                                        <ActivityGuidesListShow
                                            paginated
                                            profile
                                            draft
                                            home={ false }
                                            myResources={ false }
                                        />
                                    </TabPane>
                                </Tabs>
                            </TabPane>
                            <TabPane tab="Recursos pendientes de aprobar" key="3">
                                <ResourcesList
                                    paginated
                                    logged={ logged }
                                    profile
                                    pendingApproval
                                    home={ false }
                                />
                            </TabPane>
                        </Tabs>
                    </Fragment>
                ) }
            </div>
        </Fragment>
    );
};
export default ProfileAdmin;
