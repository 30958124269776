import React      from 'react';
import HeaderAuth from '../components/header';
import FooterAuth from '../components/footer';
import { Layout } from 'antd';
import '../../styles/layouts/Admin.less';

const { Footer, Header, Content } = Layout;

const AdminLayout: React.FC = ({ children }) => {
    return (
        <Layout id="admin-layout">
            <Header className="auth-header">
                <HeaderAuth />
            </Header>

            <Content className="content">
                { children }
            </Content>

            <Footer className="esefoot">
                <FooterAuth />
            </Footer>
        </Layout>
    );
};

export default AdminLayout;