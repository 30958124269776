import React, { Fragment, useEffect, useState }                                                    from 'react';
import { Badge, Button, Col, Image, Menu, message, Modal, notification, Row, Tooltip, Typography } from 'antd';
import { BellOutlined }                                                                            from '@ant-design/icons';
import { useHistory }                                                                              from 'react-router-dom';
import Logo
                                                                                                   from '../images/logo.svg';
import registerModalProps
                                                                                                   from './RegisterUser/registerModalProps';
import './header.less';
import { useKeycloak }                                                                             from '@react-keycloak/web';
import { KeycloakProfile }                                                                         from 'keycloak-js';
import Notifications
                                                                                                   from './notification';
import {
    handleAxiosError,
    isAxiosError
}                                                                                                  from '../../utils/services';
import { AxiosError, AxiosResponse }                                                               from 'axios';
import INotification
                                                                                                   from '../../interfaces/entities/INotification';
import { useSelector }                                                                             from 'react-redux';
import { IAppState }                                                                               from '../../interfaces/store/IAppState';
import NotificationService
                                                                                                   from '../../api/services/NotificationService';
import useServiceByRef
                                                                                                   from '../../utils/hooks/useServiceByRef';

const { SubMenu } = Menu;
const { Text, Link } = Typography;

const HeaderAuth: React.FC = () => {
    const history = useHistory();
    const [modal, context] = Modal.useModal();
    const { initialized, keycloak } = useKeycloak();
    const [user, setUser] = useState<KeycloakProfile>();
    const [totalNotifications, setTotalNotifications] = useState<number>(0);

    const readyForAuthRequests = useSelector<IAppState, boolean>(({ axios }) => axios.readyForRequests);
    const notificationService = useServiceByRef(NotificationService);
    const [loading, setLoading] = useState<boolean>(false);
    const [notifications, setNotifications] = useState<INotification[]>([]);

    const [refresh, setRefresh] = useState<boolean>(false);

    useEffect(() => {
        if (initialized) {
            const getUserProfile = async () => {
                const user = await keycloak.loadUserProfile();
                setUser(user);
                setRefresh(true);
            };

            if (keycloak.authenticated) {
                getUserProfile().finally();
            }
        }
    }, [keycloak, initialized]);

    useEffect(() => {
        if (readyForAuthRequests && keycloak.authenticated && user !== undefined) {
            const getNotifications = async () => {
                if (notificationService.current && refresh) {
                    setLoading(true);
                    const response = await notificationService.current.all();
                    if (isAxiosError(response)) {
                        handleAxiosError(response as AxiosError, {
                            404: () => {
                                const err = response as AxiosError;
                                notification.success({
                                    message: err.message,
                                    description: 'Ocurrio un error al obtener las notificaciones',
                                    duration: 3,
                                });
                            },
                            default: () => {
                                //    TODO: define message by token expiration if necessary
                                setRefresh(true);
                            }
                        });
                    } else {
                        const { data } = response as AxiosResponse<INotification[]>;
                        setNotifications(data);
                        setTotalNotifications(data.length);
                        setRefresh(false);
                    }
                    setLoading(false);
                }
            };

            getNotifications().finally();
        }
    }, [readyForAuthRequests, keycloak.authenticated, notificationService, user, refresh]);

    const handleRegisterClick = () => {
        const mdl = modal.info(registerModalProps(() => mdl.destroy()));
    };

    const redirectByUser = (action: string) => {
        if (keycloak.hasResourceRole('ADMIN')) {
            history.push(`/admin${ action }`);
        } else if (keycloak.hasResourceRole('LXD')) {
            history.push(`/lxd${ action }`);
        } else if (keycloak.hasResourceRole('PRINCIPAL')) {
            history.push(`/director${ action }`);
        } else if (keycloak.hasResourceRole('TEACHER')) {
            history.push(`/teacher${ action }`);
        }
    };

    const deleteNotifications = async () => {
        if (notificationService.current && notifications.length > 0) {
            setLoading(true);
            const response = await notificationService.current.delete();
            if (isAxiosError(response)) {
                const err = response as AxiosError;
                message.error(`Ocurrio un error limpiar las notificaciones: ${ err.message }`);
            } else {
                notification.success({
                    message: 'Operación finalizada',
                    description: 'Tus notificaciones han sido eliminadas',
                    duration: 3,
                });
                setNotifications([]);
                setTotalNotifications(0);
            }
            setLoading(false);
        }
    };

    return (
        <Fragment>
            { context }

            <Row className={ 'row-head' }>
                <Col xl={ 6 } lg={ 6 } md={ 5 } sm={ 7 } xs={ 10 }>
                    <Button type="text" className="logo-menu" onClick={ () => history.push('/') } target="_blank">
                        <Image preview={ false } src={ Logo } alt="" />
                    </Button>
                </Col>
                <Col
                    xs={ { span: 9, offset: 3 } }
                    sm={ { span: 12, offset: 4 } }
                    md={ { span: 13, offset: 6 } }
                    lg={ { span: 17, offset: 1 } }
                    xl={ { span: 17, offset: 1 } }
                >
                    <Menu
                        className={ 'menu-item' }
                        mode={ 'horizontal' }
                    >
                        { keycloak.authenticated ? (
                            <>
                                <>
                                    { !keycloak.hasResourceRole('PRINCIPAL', 'docente.soy') && (
                                        <Fragment>
                                            <SubMenu key="resource" title="Crear recursos">
                                                <Menu.Item key="setting:1" onClick={ () => {
                                                    redirectByUser('/new/resource');
                                                } }>Recurso</Menu.Item>
                                                <Menu.Item key="setting:2" onClick={ () => {
                                                    redirectByUser('/new/didactic_map/');
                                                } }>Carta didactica</Menu.Item>
                                                <Menu.Item key="setting:3" onClick={ () => {
                                                    redirectByUser('/activity_guides/create/');
                                                } }>Guía de actividades</Menu.Item>
                                            </SubMenu>
                                        </Fragment>
                                    ) }
                                </>
                                <>
                                    { !keycloak.hasResourceRole('PRINCIPAL', 'docente.soy') && (
                                        <Fragment>
                                            <Menu.Item key="list-Activities-Guides" className={ 'item-title' }
                                                       onClick={ () => {
                                                           redirectByUser('/activity_guides');
                                                       } }>
                                                Guias de actividades
                                            </Menu.Item>
                                        </Fragment>
                                    ) }
                                </>
                                { !keycloak.hasResourceRole('PRINCIPAL', 'docente.soy') && !keycloak.hasResourceRole('LXD', 'docente.soy') && (
                                    <Menu.Item key="planning" className={ 'item-title' } onClick={ () => {
                                        redirectByUser('/plannings/');
                                    } }>
                                        Planificación
                                    </Menu.Item>
                                ) }
                                { keycloak.hasResourceRole('ADMIN', 'docente.soy') && (
                                    <Fragment>
                                        <Menu.Item
                                            key="approve-users"
                                            className="item-title"
                                            onClick={ () => history.push('/admin/users/approve') }
                                        >
                                            Docentes
                                        </Menu.Item>
                                        <Menu.Item
                                            key="school-maintenance"
                                            className="item-title"
                                            onClick={ () => history.push('/admin/schools') }
                                        >
                                            Colegios
                                        </Menu.Item>
                                    </Fragment>
                                ) }
                                <SubMenu title={ 'Notificaciones' } key="notifications"
                                         icon={
                                             <Badge size="small" count={ totalNotifications } overflowCount={ 10 }
                                                    offset={ [-10, -5] }>
                                                 <BellOutlined />
                                             </Badge> }
                                         className={ 'item-title' }>
                                    <Menu.ItemGroup title={
                                        <Fragment>
                                            <Row gutter={ 24 }>
                                                <Col>
                                                    <h3><Text type="secondary">Notificaciones</Text></h3>
                                                </Col>
                                                <Col>
                                                    { notifications.length > 0 && (
                                                        <Tooltip title="Eliminar notificaciones">
                                                            <h4>
                                                                <Link onClick={ deleteNotifications } target="_blank">
                                                                    Limpiar
                                                                </Link>
                                                            </h4>
                                                        </Tooltip>
                                                    ) }
                                                </Col>
                                            </Row>
                                        </Fragment>
                                    }>
                                        <Notifications redirectByUser={ redirectByUser }
                                                       loading={ loading } notifications={ notifications } />
                                    </Menu.ItemGroup>
                                </SubMenu>
                                <SubMenu key="profile"
                                         title={ user !== undefined ? `${ user.firstName } ${ user.lastName }` : 'Perfil' }>
                                    <Menu.Item key="profile-user" onClick={ () => {
                                        redirectByUser('');
                                    } }>Perfil</Menu.Item>
                                    <>
                                        { !keycloak.hasResourceRole('ADMIN', 'docente.soy') && !keycloak.hasResourceRole('PRINCIPAL', 'docente.soy') && (
                                            <Fragment>
                                                <Menu.Item key="favorite" className={ 'item-title' } onClick={ () => {
                                                    redirectByUser('/favorite/resource');
                                                } }>
                                                    Favoritos
                                                </Menu.Item>
                                            </Fragment>
                                        ) }
                                    </>
                                    <Menu.Item key="logout" onClick={ () => {
                                        keycloak.logout({
                                            redirectUri: window.location.origin
                                        });
                                    } }>Cerrar sesión</Menu.Item>
                                </SubMenu>
                            </>
                        ) : (
                            <>
                                <Menu.Item
                                    key="resources"
                                    onClick={ () =>
                                        keycloak.login({
                                            redirectUri: window.location.origin
                                        })
                                    }>
                                    Recursos
                                </Menu.Item>
                                <Menu.Item key="create-account" onClick={ handleRegisterClick }>
                                    Crear cuenta
                                </Menu.Item>
                                <Menu.Item
                                    key="login"
                                    onClick={ () =>
                                        keycloak.login({
                                            redirectUri: window.location.origin
                                        })
                                    }
                                >
                                    Ingreso
                                </Menu.Item>
                            </>
                        ) }
                    </Menu>
                </Col>
            </Row>
        </Fragment>
    );
};
export default HeaderAuth;
