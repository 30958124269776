import { AxiosError, AxiosResponse }      from 'axios';
import { handleAxiosError, isAxiosError } from '../../utils/services';
import { message, notification, Table }   from 'antd';
import { ImportError }                    from './types';
import React                              from 'react';
import { ColumnsType }                    from 'antd/lib/table/interface';
import { ModalFunctionsType }             from '../../types/components/modals';

type validateFileType = (fileList: FileList | null) => File | null;
type handleImportResponseType = (
    response: AxiosResponse | AxiosError,
    onSuccess: () => void,
    modalFunctions: ModalFunctionsType
) => any;

const importErrorsColumns: ColumnsType<ImportError> = [
    {
        dataIndex: 'row',
        title: 'Columna'
    },
    {
        dataIndex: 'attribute',
        title: 'Attributo'
    },
    {
        title: 'Errores',
        render: (_, record) => <span>{ record.errors.join(', ') }</span>
    }
];

export const validateFile: validateFileType = fileList => {
    if (fileList === null)
        return null;

    const file = fileList.item(0);

    if (file === null)
        return null;

    if (file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
        return file;
    } else {
        return null;
    }
};

export const handleImportResponse: handleImportResponseType = (response, onSuccess, modalFunctions) => {
    if (isAxiosError(response)) {
        handleAxiosError(response as AxiosError, {
            422: res => {
                const { data } = res as AxiosResponse<{ errors: { file: string[] } }>;

                notification.error({
                    message: 'Ha ocurrido un error a la hora de importar el archivo seleccionado',
                    description: data.errors.file.join(', ')
                });
            },
            400: res => {
                const { data } = res as AxiosResponse<ImportError[]>;
                let count = 0;

                modalFunctions.error({
                    maskClosable: true,
                    icon: null,
                    width: '60%',
                    title: <div className="edit-modal-header">Errores</div>,
                    content: (
                        <Table<ImportError>
                            columns={ importErrorsColumns }
                            dataSource={ data }
                            rowKey={ () => count++ }
                        />
                    )
                });
            },
            default: error => notification.error({
                message: 'Ha ocurrido un error a la hora de importar el archivo seleccionado',
                description: error.message
            })
        });
    } else {
        message.success('El archivo ha sido importado éxitosamente');
        onSuccess();
    }
};