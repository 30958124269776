import React, { Dispatch, SetStateAction, useState }      from 'react';
import IResource                                          from '../../../../../../interfaces/entities/IResource';
import useServiceByRef                                    from '../../../../../../utils/hooks/useServiceByRef';
import ResourceService                                    from '../../../../../../api/services/ResourceService';
import { Button, Col, message, Modal, notification, Row } from 'antd';
import { isAxiosError }                                   from '../../../../../../utils/services';
import { AxiosError }                                     from 'axios';
import { useHistory }                                     from 'react-router-dom';

type Props = {
    resource: IResource
    visiblePublish: boolean
    setVisiblePublish: Dispatch<SetStateAction<boolean>>
};

const ModalPublishResource: React.FC<Props> = ({resource,visiblePublish,setVisiblePublish}) => {
    const history = useHistory();
    const resourceService = useServiceByRef(ResourceService);
    const [action, setAction] = useState<boolean>(false);

    const publishResource = async () => {
        if (resourceService.current && resource !== undefined){
            setAction(true);
                const response = await resourceService.current.draftChangeToPublish(resource.id);
            if (isAxiosError(response)) {
                const err = response as AxiosError;
                message.error(`Ocurrio un error al publicar el recurso: ${ err.message }`);
            } else {
                notification.success({
                    duration: 2,
                    message: 'Operación finalizada',
                    description: `El recurso ha sido publicado' `
                });
                history.goBack();
            }
            setAction(false);
        }
    }

    return(
        <Modal
            className={ 'btn-modal-maintenance' }
            visible={ visiblePublish }
            onOk={ publishResource }
            centered={ true }
            destroyOnClose={ true }
            confirmLoading={ action }
            closable={false}
            footer={ [
                <Row gutter={ [16, 16] } key={`${resource.id}-modal`}>
                    <Col span={ 12 } key="button-back">
                        <Button key="back"
                                onClick={ () => {
                                    setVisiblePublish(false);
                                } }
                                shape="round" type="primary"
                                ghost block>
                            Regresar
                        </Button>
                    </Col>
                    <Col span={ 12 } key="button-submit">
                        <Button key="submit"
                                loading={ action } onClick={ publishResource }
                                shape="round" type="primary" className="btn-review-approved"
                                block>
                            Publicar
                        </Button>
                    </Col>
                </Row>
            ] }
        >
            <h4 key={'title-publish'} className="modal-approve-resource modal-text-center">Publicar recurso</h4>
            <p key={'description-publish'} className="modal-text-center">¿Estás seguro de publicar este recurso?</p>
        </Modal>
    );
}
export default ModalPublishResource;