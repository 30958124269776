import React, {
    ChangeEvent,
    forwardRef,
    Fragment,
    ReactElement,
    Ref,
    useEffect,
    useImperativeHandle,
    useState
}                                                     from 'react';
import { ResourcesList }                              from './types';
import IResource                                      from '../../../../interfaces/entities/IResource';
import ResourceService                                from '../../../../api/services/ResourceService';
import { isAxiosError }                               from '../../../../utils/services';
import { AxiosResponse }                              from 'axios';
import { Card, Col, Dropdown, Input, Menu, Row, Tag } from 'antd';
import { DeleteOutlined }                             from '@ant-design/icons';
import useServiceByRef                                from '../../../../utils/hooks/useServiceByRef';
import { useSelector }                                from 'react-redux';
import { IAppState }                                  from '../../../../interfaces/store/IAppState';
import './index.less';

type Props = {
    stored_resources?: IResource[],
    onSelectedResourcesChange?: (resources: IResource[]) => void,
    grade_id?: number,
    subject_id?: number,
};
const ResourceSearcher = (props: Props, ref: Ref<ResourcesList>) => {
    const [resources, setResources] = useState<IResource[]>([]);
    const [relatedResources, setRelatedResources] = useState<IResource[]>([]);
    const [searchText, setSearchText] = useState<string>('');
    const resourceService = useServiceByRef(ResourceService);
    const readyForAuthRequests = useSelector<IAppState, boolean>(({ axios }) => axios.readyForRequests);
    const { stored_resources, onSelectedResourcesChange } = props;

    const selected_school_id = useSelector<IAppState, string | null>(({ auth }) => auth.selected_school_id);

    useImperativeHandle(ref, () => ({ resources: relatedResources }));

    const resourceList = () => {
        return (
            <Menu>
                {
                    resources.map((value, index) => (
                        <Menu.Item key={ index }
                                   onClick={ () => setRelatedResource(value) }
                        > { value.name } - <b>propietario</b>: { value.user?.name + ' ' + value.user?.last_name }
                        </Menu.Item>
                    ))
                }
            </Menu>
        );
    };

    useEffect(() => {
        const getResource = async (param: string) => {
            if (resourceService.current) {
                const response = props.grade_id !== undefined && props.subject_id ?
                    await resourceService.current?.searchAssignable({
                        name: param,
                        grade_id: props.grade_id,
                        subject_id: props.subject_id,
                        school_id: selected_school_id
                    })
                    : await resourceService.current.search({ name: param });

                if (!isAxiosError(response)) {
                    const { data } = response as AxiosResponse<IResource[]>;
                    setResources(data);
                }
            }
        };

        if (readyForAuthRequests)
            getResource(searchText).finally();
    }, [resourceService, searchText, readyForAuthRequests]);

    useEffect(() => {
        if (stored_resources) {
            setRelatedResources(stored_resources);
        }
    }, [stored_resources]);

    useEffect(() => {
        if (onSelectedResourcesChange !== undefined) {
            onSelectedResourcesChange(relatedResources);
        }
    }, [onSelectedResourcesChange, relatedResources]);

    const search = (param: ChangeEvent<HTMLInputElement>) => {
        setSearchText(param.target.value);
    };

    const setRelatedResource = (resource: IResource) => {
        const stored = [...relatedResources, resource];
        const unique: IResource[] = stored.filter((value, index) => stored.findIndex(value1 => value1.name === value.name) === index);
        setRelatedResources(unique);
        setResources([]);
        setSearchText('');
    };

    const handleOnDelete = (resource: IResource) => {
        const filter = relatedResources.filter((value1) => value1.id !== resource.id);
        setRelatedResources(filter);
    };

    return (
        <Fragment>
            <Dropdown overlay={ resourceList }
                      visible={ searchText.length > 0 }
                      overlayClassName="overlay-resources"
                      className="form-default-labels"
                      trigger={ ['click'] }>
                <Input onChange={ (event) => search(event) } value={ searchText } />
            </Dropdown>

            {
                relatedResources.length > 0 &&
                (
                    <Row className="resource-container" gutter={ 16 }
                         style={ { paddingBottom: 0, paddingTop: 0, marginBottom: 0 } }>
                        {
                            relatedResources.map((resource, index) => (
                                <Col key={ index } span={ 6 } className="resources-wrap card-recommended">
                                    <Card title={
                                        <Row style={ {
                                            backgroundColor: resource.unit?.academic_subject?.subject?.color,
                                            color: 'black !important',
                                            paddingLeft: '10px',
                                            paddingRight: '10px'
                                        } }>
                                            <Col span={ 20 } className="resource-title"
                                                 style={ {
                                                     minHeight: '20px',
                                                     borderTopRightRadius: '4px',
                                                     borderTopLeftRadius: '4px'
                                                 } }>
                                                <h2 style={ {
                                                    paddingTop: '10px',
                                                    color: '#FFF'
                                                } }>{ resource.unit?.academic_subject?.subject?.name }</h2>
                                            </Col>
                                            <Col span={ 2 } className="resource-subtitle"
                                                 style={ { fontSize: '20px' } }>
                                                <DeleteOutlined size={ 6 } onClick={ () => handleOnDelete(resource) } />
                                            </Col>
                                        </Row>
                                    }
                                          className="related-resources"
                                    >
                                        <Row className="related-resources-content">
                                            <Col span={ 24 } className="related-resource-main-content"
                                                 style={ { padding: 'opx !important' } }>
                                                <p>{ resource.name }</p>
                                            </Col>
                                            <Col span={ 24 } className="related-resources-labels">
                                                {
                                                    resource.labels?.map((value, index) => (
                                                        <Tag key={ index }
                                                             color="blue"
                                                        >{ value.name.length > 20 ? value.name.substring(0, 20).concat('...') : value.name }</Tag>
                                                    ))
                                                }
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                            ))
                        }
                    </Row>
                )
            }
        </Fragment>
    );
};

export default forwardRef(ResourceSearcher) as (props: Props &
    {
        ref: Ref<ResourcesList>;
    }
) => ReactElement;
