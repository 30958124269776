import React        from 'react';
import { Button, Col, Layout, Row } from 'antd';
import '../styles/style';
import '../styles/error.less';
import Logo                         from '../images/logo.svg';
import { useHistory }               from 'react-router-dom';

const { Header, Content } = Layout;

const ErrorsLayout: React.FC = ({ children }) => {
    const history = useHistory();
    return (
        <Layout className="auth-layout container">
            <Header className={ 'auth-header' }>
                <Row className={ 'row-head' }>
                    <Col span={ 12 }>
                        <Button type="text" className="logo-menu" onClick={ () => history.push('/') } target="_blank">
                            <img src={ Logo } alt="" />
                        </Button>
                    </Col>
                </Row>
            </Header>
            <Content className="errors-content">
                { children }
            </Content>
        </Layout>
    );
};
export default ErrorsLayout;