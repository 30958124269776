import React, { Fragment } from 'react';
import DidacticLetterType  from '../../../../views/images/type-resource/didactic-letter.svg';
type Props = {
    description: string;
};

const DidacticMapResourceType: React.FC<Props> = ({description}) => {
    return(
    <Fragment>
        <p className="description-letter-activity">{ description }...</p>
        <div className="type_resource">
            <img src={ DidacticLetterType }
                 alt="Type_document" />
        </div>
    </Fragment>
);
}
export default DidacticMapResourceType;