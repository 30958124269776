import { RcFile }                                                    from 'antd/lib/upload/interface';
import { message }                                                   from 'antd';
import { FormInstance }                                              from 'antd/lib/form';
import { getBase64FromImage, isAudio, isDocument, isImage, isVideo } from '../files';

type callback = (src: string | null) => any;
type handleFilesType = (form: FormInstance, field: string, file: RcFile, type: number, callback: callback) => boolean;

const handleFiles: handleFilesType = (form, field, file, type, callback: callback) => {
    if (type === 3) {
        if (file !== undefined && file !== null) {
            if (!isVideo(file)) {
                callback(null);
                form.resetFields([field]);
                message.error('Debes ingresar una video valido');
                return false;
            }

            (async () => {
                form.setFieldsValue({ file });
                callback('video');
            })();
        } else {
            callback(null);
            form.resetFields([field]);
            message.error('Ocurrió un error');
        }
    } else if (type === 4) {
        if (file !== undefined && file !== null) {
            if (!isImage(file)) {
                callback(null);
                form.resetFields([field]);
                message.error('Debes ingresar una imagen valida');
                return false;
            }

            (async () => {
                form.setFieldsValue({ file });
                const src = await getBase64FromImage(file);

                if (typeof src === 'string') {
                    callback(src);
                } else {
                    callback(null);
                    form.resetFields([field]);
                    message.error('Ocurrió un error');
                }
            })();
        } else {
            callback(null);
            form.resetFields([field]);
            message.error('Ocurrió un error');
        }
    } else if (type === 5) {
        if (file !== undefined && file !== null) {
            if (!isAudio(file)) {
                callback(null);
                form.resetFields([field]);
                message.error('Debes ingresar una audio valido');
                return false;
            }

            (async () => {
                form.setFieldsValue({ file });
                callback('audio');
            })();
        } else {
            callback(null);
            form.resetFields([field]);
            message.error('Ocurrió un error');
        }
    } else {
        if (file !== undefined && file !== null) {
            if (!isDocument(file)) {
                callback(null);
                form.resetFields([field]);
                message.error('Debes ingresar una documento valido');
                return false;
            }

            (async () => {
                form.setFieldsValue({ file });
                callback('document');
            })();
        } else {
            callback(null);
            form.resetFields([field]);
            message.error('Ocurrió un error');
        }
    }
    return false;
};

export default handleFiles;
