import IAuthState       from '../../interfaces/store/states/IAuthState';
import { IAuthActions } from '../../interfaces/store/actions/IAuthActions';

const initialState: IAuthState = {
    keycloakUserInfo: null,
    schools: [],
    selected_school_id: null
};

const authReducer = (state = initialState, action: IAuthActions) => {
    switch (action.type) {
        case 'SET_KEYCLOAK_USER_INFO':
            return {
                ...state,
                keycloakUserInfo: action.value
            };
        case 'SET_SCHOOLS':
            return {
                ...state,
                schools: action.value
            };
        case 'SET_SELECTED_SCHOOL_ID':
            return {
                ...state,
                selected_school_id: action.value
            };
        default:
            return state;
    }
};

export default authReducer;