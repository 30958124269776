import { ColumnsType } from 'antd/lib/table';
import IUserSchool     from '../../../../interfaces/entities/IUserSchool';

const columns: ColumnsType<IUserSchool> = [
    {
        title: 'Nombre',
        render: (_, u) => `${ u.user?.last_name }, ${ u.user?.name }`
    },
    {
        title: 'Colegio',
        render: (_, u) => u.school?.name
    }
];

export default columns;