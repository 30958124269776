import RestService                                  from '../abstract/RestService';
import { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import { ITeacher }                                 from '../../interfaces/entities/IUser';


class TeacherService extends RestService<ITeacher> {
    constructor(axios: AxiosInstance) {
        super('teachers', axios);
    }

    async all(page?: number): Promise<AxiosResponse<ITeacher[]> | AxiosError> {
        try {
            const params = page !== undefined ? `?_page=${ page }&_limit=10` : '';
            return await this.http.get(`${ this.uri }${ params }`);
        } catch (e) {
            return e as AxiosError;
        }
    }

    //GET TEACHER BY SCHOOL
    async TeacherBySchool(id: string): Promise<AxiosResponse<ITeacher[]> | AxiosError> {
        try {
            return await this.http.get(`${ this.uri }/get_by/school/${ id }`);
        } catch (e) {
            return e as AxiosError;
        }
    }

    async TeacherDisabledBySchool(id: string): Promise<AxiosResponse<ITeacher[]> | AxiosError> {
        try {
            return await this.http.get(`${ this.uri }/get_by/disabled_user/school/${ id }`);
        } catch (e) {
            return e as AxiosError;
        }
    }

    /**
     * Get teacher
     * @param id
     */

    async get(id: string): Promise<AxiosResponse<ITeacher> | AxiosError> {
        try {
            return await this.http.get(`${ this.uri }/${ id }`);
        } catch (e) {
            return e as AxiosError;
        }
    }

    async disabledUser(user: string): Promise<AxiosResponse | AxiosError> {
        try {
            return await this.http.put(`${ this.uri }/change_status/teacher/${ user }`);
        } catch (e) {
            return e as AxiosError;
        }
    }

    async deleteTeacher(user: string): Promise<AxiosResponse | AxiosError> {
        try {
            return await this.http.delete(`${ this.uri }/${ user }`);
        } catch (e) {
            return e as AxiosError;
        }
    }

}

export default TeacherService;
