import React from 'react';
import { Layout } from 'antd';
import '../styles/style';
import HeaderAuth from '../components/header';
import FooterAuth from '../components/footer';

const Maintenance: React.FC = ({children}) => {
    const { Header, Footer, Content } = Layout;
    return(
        <Layout className="auth-layout container">
            <Header className={ 'auth-header' }>
                <HeaderAuth />
            </Header>
            <Content className="content-maintenance">
                { children }
            </Content>
            <Footer className={ 'esefoot' }> <FooterAuth /></Footer>
        </Layout>
    );
}
export default Maintenance;