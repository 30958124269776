import React, {
    Fragment,
    forwardRef,
    Ref,
    ReactElement,
    useState,
    useImperativeHandle,
    ChangeEvent,
    useEffect
}                                     from 'react';
import { ArrayLabels }                from './types';
import LabelService
                                      from '../../api/services/LabelService';
import ILabel
                                      from '../../interfaces/entities/ILabel';
import { Dropdown, Input, Menu, Tag } from 'antd';
import useServiceByRef                from '../../utils/hooks/useServiceByRef';
import { isAxiosError }               from '../../utils/services';
import { AxiosResponse }              from 'axios';
import IDidacticMap                   from '../../interfaces/entities/IDidacticMap';
import { useSelector }                from 'react-redux';
import { IAppState }                  from '../../interfaces/store/IAppState';
import IResource                      from '../../interfaces/entities/IResource';
import './index.less';

type Props = {
    didacticMap?: IDidacticMap
    resource?: IResource;
};

const Labels = (props: Props, ref: Ref<ArrayLabels>) => {
    const [labels, setLabels] = useState<{ name: string }[]>([]);
    const [searchText, setSearchText] = useState<string>('');
    const [storedLabels, setStoredLabels] = useState<ILabel[]>([]);
    const labelService = useServiceByRef(LabelService);
    const readyForAuthRequests = useSelector<IAppState, boolean>(({ axios }) => axios.readyForRequests);
    const { didacticMap, resource } = props;

    useImperativeHandle(ref, () => ({ labels }));

    const search = (param: ChangeEvent<HTMLInputElement>) => {
        setSearchText(param.target.value);
    };

    const labelList = (): ReactElement => {
        return (
            <Menu>
                {
                    storedLabels.map((value) => (
                        <Menu.Item key={ value.id }
                                   onClick={ () => setLabel(value.name) }>{ value.name.length > 50 ? value.name.substring(0, 50).concat('...') : value.name }</Menu.Item>))
                }
            </Menu>
        );
    };

    const setLabel = (name: string) => {
        const stored = [...labels, { name: name }];
        const unique: { name: string }[] = stored.filter((value, index) => stored.findIndex(value1 => value1.name === value.name) === index);
        setLabels(unique);
        setStoredLabels([]);
        setSearchText('');
    };

    useEffect(() => {
        if (didacticMap !== undefined) {
            const { resource } = didacticMap;

            if (resource) {
                const { labels } = resource;

                if (labels) {
                    setLabels(labels);
                }
            }

        }
    }, [didacticMap]);

    useEffect(() => {
        if (resource !== undefined) {
            const { labels } = resource;

            if (labels) {
                setLabels(labels);
            }
        }
    }, [resource]);

    useEffect(() => {

        const searchLabel = async (param: string) => {
            if (labelService.current !== undefined) {
                const response = await labelService.current.search({ name: param });

                if (!isAxiosError(response)) {
                    const { data } = response as AxiosResponse<ILabel[]>;
                    setStoredLabels(data);
                }
            }
        };

        if (readyForAuthRequests) {
            searchLabel(searchText).finally();
        }

    }, [searchText, labelService, readyForAuthRequests]);

    const removeFromLabels = (name: string) => {
        const filter = labels.filter((value1) => value1.name !== name);
        setLabels(filter);
    };

    const handleOnEnter = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            const name = event.currentTarget.value.trim();
            const stored = [...labels, { name: name.charAt(0).toUpperCase() + name.slice(1) }];
            const unique: { name: string }[] = stored.filter((value, index) => stored.findIndex(value1 => value1.name === value.name) === index);
            setLabels(unique);
            setStoredLabels([]);
            setSearchText('');
        }
    };

    return (
        <Fragment>
            <Dropdown overlay={ labelList } visible={ searchText.length > 0 } overlayClassName="overlay-labels" className="form-default-labels">
                <Input onChange={ (event) => search(event) } value={ searchText } onKeyDown={ e => handleOnEnter(e) } />
            </Dropdown>
            {
                labels.map((value, index) => (
                    <Tag key={ index }
                         color="blue"
                         closable
                         onClose={ (e) => {
                             e.preventDefault();
                             removeFromLabels(value.name);
                         } }
                    >{ value.name.length > 50 ? value.name.substring(0, 50).concat('...') : value.name }</Tag>
                ))
            }
        </Fragment>
    );
};

export default forwardRef(Labels) as (props: Props & { ref: Ref<ArrayLabels> }) => ReactElement;
