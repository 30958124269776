import React, { Fragment, useCallback, useEffect, useState }                                        from 'react';
import { Button, Col, Form, message, notification, Row, Select }                                    from 'antd';
import categories, { CategoryType }                                                                 from '../../../types/entities/categories';
import PlanningService
                                                                                                    from '../../../api/services/PlanningService';
import {
    handleAxiosError,
    isAxiosError
}                                                                                                   from '../../../utils/services';
import { AxiosError, AxiosResponse }                                                                from 'axios';
import { AssignmentType }                                                                           from '../../../interfaces/entities/IAnnualPlanning';
import IGrade
                                                                                                    from '../../../interfaces/entities/IGrade';
import GradeService
                                                                                                    from '../../../api/services/GradeService';
import IAcademicSubject
                                                                                                    from '../../../interfaces/entities/IAcademicSubject';
import AcademicSubjectService
                                                                                                    from '../../../api/services/AcademicSubjectService';
import { useKeycloak }                                                                              from '@react-keycloak/web';
import AcademicChargeService
                                                                                                    from '../../../api/services/AcademicChargeService';
import IAcademicCharge
                                                                                                    from '../../../interfaces/entities/IAcademicCharge';
import useServiceByRef
                                                                                                    from '../../../utils/hooks/useServiceByRef';
import { useSelector }                                                                              from 'react-redux';
import { IAppState }                                                                                from '../../../interfaces/store/IAppState';
import { ErrorsType, FormValuesType, SearchAcademicSubject, ViewEntitiesEnum }                      from './types';
import { handleToggleLoading, initialLoadingValues, searchAcademicCharges, searchAcademicSubjects } from './functions';
import PlanningTable
                                                                                                    from '../components/PlanningTable';
import { useHistory }                                                                               from 'react-router-dom';
import setFormErrorsByAxiosResponse
                                                                                                    from '../../../utils/forms/setFormErrorsByAxiosResponse';
import BottomFormFields
                                                                                                    from '../components/BottomFormFields';
import '../Home/style-home.less';
import './styles.less';
import { PlanningTableFunctionsHandler }                                                            from '../components/types';
import { getAssignmentsForAPI }                                                                     from '../components/functions';

const CreatePlanning = () => {
    const [form] = Form.useForm<FormValuesType>();
    const { keycloak } = useKeycloak();
    const readyForAuthRequests = useSelector<IAppState, boolean>(({ axios }) => axios.readyForRequests);
    const history = useHistory();
    const ref = React.createRef<PlanningTableFunctionsHandler>();

    const [data, setData] = useState<CategoryType[]>(categories);

    const [loading, setLoading] = useState(initialLoadingValues);
    const [errors, setErrors] = useState({} as ErrorsType);

    const [grades, setGrades] = useState<IGrade[]>([]);
    const [academicSubjects, setAcademicSubjects] = useState<IAcademicSubject[]>([]);
    const [academicCharges, setAcademicCharges] = useState<IAcademicCharge[]>([]);

    const gradeService = useServiceByRef(GradeService);
    const asService = useServiceByRef(AcademicSubjectService);
    const acService = useServiceByRef(AcademicChargeService);
    const planningService = useServiceByRef(PlanningService);

    const schoolID = useSelector<IAppState, string | null>(({ auth }) => auth.selected_school_id);

    // This forces the component to rerender.
    const rerender = () => setLoading(val => ({ ...val }));

    const getDisabledSelect = ((name: keyof FormValuesType): boolean => {
        const value = form.getFieldValue(name);
        return value === null || value === undefined;
    });

    const handleGradeChange = async (grade_id: number | undefined) => {
        const as_id = form.getFieldValue('academic_subject_id');

        if (as_id !== null) {
            form.resetFields(['academic_subject_id', 'academic_charge_id']);
        }

        handleToggleLoading(setLoading, ViewEntitiesEnum.academic_subjects);
        if (typeof grade_id !== 'undefined') {
            const data: SearchAcademicSubject = { grade_id: grade_id, school_id: schoolID };
            await searchAcademicSubjects(
                asService, data, setAcademicSubjects,
                err => setErrors(prevState => ({
                    ...prevState,
                    academic_subjects: err.message
                }))
            );
        } else {
            form.resetFields(['grade_id', 'academic_subject_id', 'academic_charge_id']);
            rerender();
        }
        handleToggleLoading(setLoading, ViewEntitiesEnum.academic_subjects);
    };

    const handleAcademicSubjectChange = async (as_id: string | undefined) => {
        const ac_id = form.getFieldValue('academic_charge_id');
        if (ac_id !== null) {
            form.resetFields(['academic_charge_id']);
        }

        handleToggleLoading(setLoading, ViewEntitiesEnum.academic_charges);
        if (typeof as_id !== 'undefined') {
            await searchAcademicCharges(
                acService, as_id, keycloak, setAcademicCharges,
                err => setErrors(prevState => ({
                    ...prevState,
                    academic_charges: err.message
                }))
            );
        } else {
            form.resetFields(['academic_subject_id', 'academic_charge_id']);
            rerender();
        }
        handleToggleLoading(setLoading, ViewEntitiesEnum.academic_charges);
    };

    const getGrades = useCallback(async () => {
        handleToggleLoading(setLoading, ViewEntitiesEnum.grades);
        if (gradeService.current) {
            let res;
            if (!keycloak.hasResourceRole('TEACHER')) {
                res = await gradeService.current.all();
            } else {
                res = await gradeService.current.teacherGrade();
            }

            if (isAxiosError(res)) {
                const err = res as AxiosError;
                setErrors(prevState => ({ ...prevState, grades: err.message }));
            } else {
                const { data } = res as AxiosResponse<IGrade[]>;
                setGrades(data);
            }
        }

        handleToggleLoading(setLoading, ViewEntitiesEnum.grades);
    }, [gradeService, keycloak]);

    const loadDefaultData = useCallback(async () => {
        handleToggleLoading(setLoading, ViewEntitiesEnum.default_data);

        if (planningService.current) {
            const res = await planningService.current.getDefaultData();

            if (isAxiosError(res)) {
                const err = res as AxiosError;

                setErrors(prevState => ({ ...prevState, default_data: err.message }));
                notification.error({
                    message: err.message,
                    description: 'No se ha podido obtener los datos por defecto de las planificaciones. Se mostrarán todos los valores a 0'
                });
            } else {
                const { data: assignments } = res as AxiosResponse<AssignmentType>;

                setData(prevState => prevState.map(c => {
                    const assignment = assignments[c.category];

                    return Object.assign(c, {
                        data: assignment,
                        total: Object.entries(assignment).reduce((sum, [, value]) => sum + value, 0)
                    } as CategoryType);
                }));
            }
        }

        handleToggleLoading(setLoading, ViewEntitiesEnum.default_data);
    }, [planningService]);

    useEffect(() => {
        if (readyForAuthRequests) {
            // noinspection JSIgnoredPromiseFromCall
            loadDefaultData();
            // noinspection JSIgnoredPromiseFromCall
            getGrades();
        }
    }, [getGrades, loadDefaultData, readyForAuthRequests]);

    useEffect(() => {
        if (typeof keycloak.userInfo === 'undefined') {
            keycloak.loadUserInfo();
        }
    }, [keycloak]);

    const handleFinish = async (values: FormValuesType) => {
        if (planningService.current === undefined) {
            message.error('Ha ocurrido un error con la conexión al servidor...');
            return;
        }

        handleToggleLoading(setLoading, ViewEntitiesEnum.form);

        const assignmentValues = Object.entries<number>(values).filter(([key]) => key.indexOf('assignments') !== -1);

        const apiData = {
            academic_charge_id: values.academic_charge_id,
            total_hours: values.total_hours,
            assignments: getAssignmentsForAPI(assignmentValues)
        };

        const res = await planningService.current.store(apiData);

        if (isAxiosError(res)) {
            const err = res as AxiosError;

            handleAxiosError(err, {
                422: res => setFormErrorsByAxiosResponse(form, res),
                default: error => notification.error({
                    message: 'Ha ocurrido un error al guardar los datos de la planificación',
                    description: error.message
                })
            });
        } else {
            notification.success({
                message: 'La planificación ha sido guardada éxitosamente!',
            });

            history.push('/teacher/plannings');
        }

        handleToggleLoading(setLoading, ViewEntitiesEnum.form);
    };

    return (
        <Fragment>
            <Row>
                <Col span={ 24 }>
                    <h1 className={ 'title-plannings' }>Agregar planificación</h1>
                </Col>
                <Col span={ 24 }>
                    <Button
                        type="primary"
                        className={ 'btn-plannings' }
                        onClick={ () => history.push('/teacher/plannings') }
                    >
                        Regresar
                    </Button>
                </Col>
            </Row>
            <Row className="row-btn-plannings" id="new-planning-component">
                <Col span={ 24 }>
                    <Form<FormValuesType>
                        form={ form }
                        layout="vertical"
                        hideRequiredMark
                        onFinish={ handleFinish }
                        onKeyDown={ e => e.key === 'Enter' ? e.preventDefault() : '' }
                    >
                        <Row gutter={ 8 }>
                            <Col span={ 8 }>
                                <Form.Item
                                    label="Grado"
                                    name="grade_id"
                                    initialValue={ null }
                                    hasFeedback={ errors.grades !== undefined }
                                    validateStatus={ errors.grades !== undefined ? 'error' : undefined }
                                    help={ errors.grades }
                                    rules={ [{ required: true, message: 'El grado es requerido' }] }
                                >
                                    <Select
                                        allowClear
                                        className="select-plannings"
                                        loading={ loading.grades }
                                        onChange={ (grade_id: number) =>
                                            handleGradeChange(grade_id)
                                        }
                                        options={ grades.map(({ id, name }) => ({ label: name, value: id })) }
                                    />
                                </Form.Item>
                            </Col>

                            <Col span={ 8 }>
                                <Form.Item
                                    label="Materia"
                                    name="academic_subject_id"
                                    initialValue={ null }
                                    hasFeedback={ errors.academic_subjects !== undefined }
                                    validateStatus={ errors.academic_subjects !== undefined ? 'error' : undefined }
                                    help={ errors.academic_subjects }
                                    rules={ [{ required: true, message: 'La materia es requerido' }] }
                                >
                                    <Select
                                        allowClear
                                        className="select-plannings"
                                        loading={ loading.academic_subjects }
                                        disabled={ getDisabledSelect('grade_id') }
                                        onChange={ (as_id: string) => {
                                            handleAcademicSubjectChange(as_id);
                                        } }
                                        options={ academicSubjects.map(({ id, subject }) => ({
                                            label: subject?.name,
                                            value: id
                                        })) }
                                    />
                                </Form.Item>
                            </Col>

                            <Col span={ 8 }>
                                <Form.Item
                                    label="Año"
                                    name="academic_charge_id"
                                    initialValue={ null }
                                    className="select-plannings"
                                    hasFeedback={ errors.academic_charges !== undefined }
                                    validateStatus={ errors.academic_charges !== undefined ? 'error' : undefined }
                                    help={ errors.academic_charges }
                                    rules={ [{ required: true, message: 'El año escolar es requerido' }] }
                                >
                                    <Select
                                        loading={ loading.academic_charges }
                                        disabled={ getDisabledSelect('academic_subject_id') }
                                        options={ academicCharges.map(({ id, school_year }) => ({
                                            label: school_year?.year,
                                            value: id
                                        })) }
                                    />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row>
                            <Col span={ 24 }>
                                <PlanningTable form={ form } loading={ loading } data={ data } ref={ ref } />
                            </Col>
                        </Row>

                        <BottomFormFields planningTableRef={ ref } loading={ loading } errors={ errors } />
                    </Form>
                </Col>
            </Row>
        </Fragment>
    );
};

export default CreatePlanning;
