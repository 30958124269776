import FormRules       from "../../../types/utils/forms/FormRules";
import { IFormFields } from "./types";

export const storeRules: FormRules<IFormFields> = {
    name: [{
        required: true,
        whitespace: false,
        message: 'El nombre es requerido'
    }],
    description: [{
        required: true,
        whitespace: false,
        message: 'La descripción es requerida'
    }],
    date: [{
        required: true,
        message: 'El fecha es requerida'
    }],
    helper_date: [{
        required: true,
        message: 'El fecha es requerida'
    }],
    hours: [{
        required: true,
        message: 'Las horas de duración es requerida'
    }],
    helper_time: [{
        required: true,
        message: 'Selecciona una hora valida'
    }],
    unit_id: [{
        required: true,
        message: 'Selecciona una unidad'
    }],
    duration: [{
        required: true,
        message: 'Selecciona una hora valida'
    }],
    helpers_duration: [{
        required: true,
        message: 'Las horas de duración es requerida'
    }],
    labels: [{
        required: true,
        message: 'Selecciona uno mas temas relacionados'
    }],
    indicators: [{
        required: true,
        message: 'Selecciona uno o mas indicadores'
    }],
    components: [],
    related_resource: [],
    resource_state_id: [],
    template: [{
        required: true,
        message: 'Elija si el recurso podran verlo otras personas'
    }]
};
