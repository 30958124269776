import React, { Fragment, useCallback, useEffect, useState }                         from 'react';
import { Button, Empty, message, Modal, notification, Table, TablePaginationConfig } from 'antd';
import IUserSchool
                                                                                     from '../../../../interfaces/entities/IUserSchool';
import useService
                                                                                     from '../../../../utils/hooks/useService';
import UsersSchoolsService
                                                                                     from '../../../../api/services/UsersSchoolsService';
import { isAxiosError }                                                              from '../../../../utils/services';
import { AxiosError, AxiosResponse }                                                 from 'axios';
import IPaginatedData
                                                                                     from '../../../../interfaces/IPaginatedData';
import { DeleteOutlined }                                                            from '@ant-design/icons';
import AuthService
                                                                                     from '../../../../api/services/AuthService';

type Props = {
    school_id: string[]
}

const RejectedUsersTable: React.FC<Props> = ({ school_id }) => {
    const [data, setData] = useState<IUserSchool[]>([]);
    const [loading, setLoading] = useState(false);
    const usersService = useService(UsersSchoolsService);
    const authService = useService(AuthService);
    const [page, setPage] = useState(1);
    const [mdl, mdlContext] = Modal.useModal();
    const [error, setError] = useState<string | null>(null);

    const [paginationInfo, setPaginationInfo] = useState<TablePaginationConfig>({
        defaultCurrent: 1,
        defaultPageSize: 10,
        total: 0,
        onChange: setPage,
        showTotal: total => `Total: ${ total }`
    });

    const getData = useCallback(async () => {
        if (usersService !== null) {
            setLoading(true);

            let res;

            if (school_id.length > 0) {
                res = await usersService.search(page, { school_id });
            } else {
                res = await usersService.getRejectedUsers(page);
            }

            if (isAxiosError(res)) {
                setError((res as AxiosError).message);
            } else {
                const { data } = res as AxiosResponse<IPaginatedData<IUserSchool>>;
                setData(data.data);

                setPaginationInfo(prevState => ({
                    ...prevState,
                    total: data.total
                }));
            }
        }
        setLoading(false);
    }, [page, school_id, usersService]);

    const handleDeleteUser = ({ user, id }: IUserSchool) => {
        if (authService != null && user !== undefined) {
            mdl.confirm({
                centered: true,
                keyboard: true,
                maskClosable: true,
                title: <p>
                    Estás segur@ que deseas eliminar la solicitud rechazada de <b>{ user.name } { user.last_name }</b>
                </p>,
                okText: 'Confirmar',
                cancelText: 'Cancelar',
                onOk: async () => {
                    const res = await authService.delete(id);

                    if (isAxiosError(res)) {
                        notification.error({
                            message: 'Ha ocurrido un error al intentar aprobar el usuario',
                            description: (res as AxiosError).message
                        });
                    } else {
                        message.success('El usuario ha sido eliminado éxitosamente');
                        // noinspection ES6MissingAwait
                        getData();
                    }
                }
            });
        } else {
            // noinspection JSIgnoredPromiseFromCall
            message.error('Ha ocurrido un error');
        }
    };

    useEffect(() => {
        // noinspection JSIgnoredPromiseFromCall
        getData();
    }, [getData]);

    return (
        <Fragment>
            { mdlContext }

            <Table<IUserSchool>
                rowKey={ u => u.id }
                dataSource={ data }
                loading={ loading }
                pagination={ paginationInfo }
                locale={ {
                    emptyText: error ? (<Empty description={ error } />) : undefined
                } }
                columns={ [
                    {
                        title: 'Nombre',
                        render: (_, u) => `${ u.user?.last_name }, ${ u.user?.name }`
                    },
                    {
                        title: 'Colegio',
                        render: (_, u) => u.school?.name
                    },
                    {
                        title: 'Acciones',
                        render: (_, u) => (
                            <Button.Group>
                                <Button
                                    danger
                                    title="Eliminar usuario"
                                    icon={ <DeleteOutlined /> }
                                    onClick={ () => handleDeleteUser(u) }
                                />
                            </Button.Group>
                        )
                    }
                ] }
            />
        </Fragment>
    );
};

export default RejectedUsersTable;
