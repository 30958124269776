import { RouteProps }      from 'react-router-dom';
import React, { Fragment } from 'react';
import LayoutRoute         from '../LayoutRoute';
import HomeAuth            from '../../../views/layouts/HomeAuth';
import LayoutResource      from '../../../views/layouts/Resource';
import Maintenance         from '../../../views/layouts/Maintenance';

interface Props extends RouteProps {
    resource: boolean
    maintenance: boolean
}

const TeacherRoute: React.FC<Props> = ({ ...rest }) => {
    return (
        <Fragment>
            <LayoutRoute
                { ...rest }
                Layout={ rest.resource ? LayoutResource : rest.maintenance ? Maintenance : HomeAuth }
                keyCloakValidation={ (keycloak) => keycloak.hasResourceRole('TEACHER') }
            />
        </Fragment>
    );
};
export default TeacherRoute;