import React, { Fragment, useEffect, useState } from "react";
import IIndicator                               from "../../../../interfaces/entities/IIndicator";
import IContent                                 from "../../../../interfaces/entities/IContent";
import { Tag }                                  from "antd";

type Props = {
    indicators: IIndicator[]
    content_type: string
    teacher: boolean
};

const Contents: React.FC<Props> = ({ indicators, content_type, teacher }) => {
    const [contents, setContents] = useState<IContent[]>([]);

    useEffect(() => {
        let data: IContent[] = [];

        if (content_type === 'CONCEPTUAL') {
            indicators.forEach((value) => {
                if (value.contents !== undefined) {
                    value.contents.forEach((value) => {
                        if (parseInt(value.content_type_id.toString()) === 1) {
                            if (data.length > 0) {
                                let exists = false;
                                data.some((value1, index) => {
                                    if (value1.id === value.id)
                                        exists = true;
                                    return value1.id === value.id;
                                });

                                if (!exists) {
                                    data.push(value);
                                }
                            } else {
                                data.push(value);
                            }
                        }
                    });
                }
            });
        } else if (content_type === 'PROCEDURAL') {
            indicators.forEach((value) => {
                if (value.contents !== undefined) {
                    value.contents.forEach((value) => {
                        if (parseInt(value.content_type_id.toString()) === 2) {

                            let exists = false;
                            data.some((value1, index) => {
                                if (value1.id === value.id)
                                    exists = true;
                                return value1.id === value.id;
                            });

                            if (!exists) {
                                data.push(value);
                            }
                        }
                    });
                }
            });
        } else if (content_type === 'ATTITUDINAL') {
            indicators.forEach((value) => {
                if (value.contents !== undefined) {
                    value.contents.forEach((value) => {
                        if (parseInt(value.content_type_id.toString()) === 3 || parseInt(value.content_type_id.toString()) === 4) {
                            if (data.length === 0) {
                                data.push(value);
                            } else {
                                let exists = false;
                                data.some((value1, index) => {
                                    if (value1.id === value.id)
                                        exists = true;
                                    return value1.id === value.id;
                                });

                                if (!exists) {
                                    data.push(value);
                                }
                            }
                        }
                    });
                }
            });
        }
        setContents(data);
    }, [teacher, indicators, content_type]);

    return (
        <Fragment>
            {
                contents.map((value) => (
                    <Tag key={ value.id }
                         color="blue">{ value.name.length > 20 ? value.name.substring(0, 20).concat('...') : value.name }</Tag>
                ))
            }
        </Fragment>
    );
};

export default Contents;