import { FormEntitiesEnum, IFormFields, InsertResponse, LoadingFormType } from './types';
import { AxiosError, AxiosResponse }                                      from 'axios';
import ResourceService
                                                                          from '../../../../api/services/ResourceService';
import { isAxiosError }                                                   from '../../../../utils/services';

export const initialLoadingValues = Object.keys(FormEntitiesEnum).reduce((a, b) => Object.assign(a, { [b]: false }), {}) as LoadingFormType;
export const initialDisabledValues = Object.keys(FormEntitiesEnum).reduce((a, b) => Object.assign(a, { [b]: true }), {}) as LoadingFormType;

type handleFinishType = (
    values: IFormFields,
    onSuccess: (res: AxiosResponse<InsertResponse>) => any,
    onError: (err: AxiosError) => any,
    service: ResourceService
) => void;

export const handleFinish: handleFinishType = async (values, onSuccess, onError, service) => {
    const frmData = new FormData();
    const {
        resource_type_id,
        name,
        description,
        labels,
        unit_id,
        topic,
        url,
        indicators,
        files,
        resource_state_id,
        school_id,
        records_external_link,
    } = values;

    frmData.append('resource_type_id', resource_type_id.toString());
    frmData.append('name', name);
    frmData.append('description', description);
    labels.forEach(val => frmData.append('labels[]', val.name));
    frmData.append('unit_id', unit_id);
    frmData.append('topic', topic);
    frmData.append('url', url);
    indicators.forEach(id => frmData.append('indicators[]', id));
    frmData.append('resource_state_id', resource_state_id.toString());

    if (files !== null && files.length > 0) {
        files.forEach(values => frmData.append('records[]', values.file, `${ values.name_file }.${ values.extension }`));
    }
    if (records_external_link !== null && records_external_link !== undefined) {
        records_external_link.forEach(values => frmData.append('records_external_link[]', values.link_file));
    }

    const res = await service.store(frmData, school_id ?? null);

    if (isAxiosError(res)) {
        onError(res as AxiosError);
    } else {
        onSuccess(res as AxiosResponse<InsertResponse>);
    }
};
