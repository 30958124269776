import React                      from 'react';
import IResource                  from '../../../../../interfaces/entities/IResource';
import ReactPlayer                from 'react-player';
import { Button, Divider, Image } from 'antd';
import { DownloadOutlined }       from '@ant-design/icons';
import PreviewImg from '../../../../images/preview.svg';
import PLayPlayer          from '../../../../images/player/play-circle.svg';
import PauseSVG            from '../../../../images/player/pause-circle.svg';
import LeftPlayer          from '../../../../images/player/chevrons-left.svg';
import RightPlayer         from '../../../../images/player/chevrons-right.svg';
import AudioPlayer         from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.less';
import Icon                from '@ant-design/icons';

type Props = {
    resource: IResource
};

const ResourceTypesShow: React.FC<Props> = ({ resource }) => {
    return (
        <div className="media-player-content" key={resource.id}>
            { resource.records?.map((record, index) => (
                <>
                    {record.file_url !== undefined && record.name !== undefined && (
                      <>
                          { resource?.resource_type_id === 3 ?
                              <div className="media-player" key={ index }>
                                  <ReactPlayer
                                      key={ record.id }
                                      url={ record.file_url }
                                      width="100%"
                                      controls={ true }
                                      height="70vh"
                                  />
                                  <Divider />
                              </div>
                              :
                              resource?.resource_type_id === 4 ?
                                  <div className="media-player" key={ index }>
                                      <Image
                                          key={ record.id }
                                          alt={ record.name }
                                          src={ record.file_url }
                                          height={ '60vh' }
                                          preview={{
                                              // eslint-disable-next-line jsx-a11y/img-redundant-alt
                                              mask:<img src={PreviewImg} alt="Preview-image"/>
                                          }}
                                      />
                                      <Divider />
                                  </div>
                                  :
                                  resource?.resource_type_id === 5 ?
                                      <div className="media-player" key={ index }>
                                          <h6>{ record.name }</h6>
                                          <AudioPlayer
                                              key={record.id}
                                              src={ record.file_url }
                                              showFilledVolume={ false }
                                              customIcons={ {
                                                  play: <Icon component={ () => (
                                                      <img src={ PLayPlayer } alt="play" className="icon-play-player" />) } />,
                                                  pause: <Icon component={ () => (
                                                      <img src={ PauseSVG } alt="pause" className="icon-play-player" />) } />,
                                                  rewind: <Icon component={ () => (
                                                      <img src={ LeftPlayer } alt="pause" className="icon-play-player" />) } />,
                                                  forward: <Icon component={ () => (
                                                      <img src={ RightPlayer } alt="pause" className="icon-play-player" />) } />,
                                              } }
                                          />
                                          <Divider />
                                      </div>
                                      :
                                      <div className="media-player" key={ index }>
                                          <Button
                                              key={ record.id }
                                              target="_blank"
                                              type="primary"
                                              href={ record.file_url }
                                              className="record-download"
                                              icon={ <DownloadOutlined /> } block>
                                              { record.name }
                                          </Button>
                                          <Divider />
                                      </div>
                          }
                      </>
                    )}
                </>
            )) }
        </div>
    );
};
export default ResourceTypesShow;
