import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { Col, Form, Row, Select }                                            from 'antd';
import { isAxiosError }                                                      from '../../../utils/services';
import { AxiosError, AxiosResponse }                                         from 'axios';
import IGrade                                                                from '../../../interfaces/entities/IGrade';
import useServiceByRef
                                                                             from '../../../utils/hooks/useServiceByRef';
import GradeService                                                          from '../../../api/services/GradeService';
import { useSelector }                                                       from 'react-redux';
import { IAppState }                                                         from '../../../interfaces/store/IAppState';
import AcademicSubjectService
                                                                             from '../../../api/services/AcademicSubjectService';
import IAcademicSubject
                                                                             from '../../../interfaces/entities/IAcademicSubject';
import { FormInstance }                                                      from 'antd/lib/form';
import UnitService                                                           from '../../../api/services/UnitService';
import IUnit                                                                 from '../../../interfaces/entities/IUnit';
import IIndicator
                                                                             from '../../../interfaces/entities/IIndicator';
import IndicatorService
                                                                             from '../../../api/services/IndicatorService';
import { AssignationEntitiesEnum, FormValues }                               from './types';
import rules                                                                 from './rules';
import { genLoading }                                                        from '../../../utils/views';
import { filterOptionsFromLabel }                                            from '../../../utils/forms/filterOptions';
import keycloak                                                              from '../../../keycloak.config';

const { Item } = Form;

export type IndicatorsAssignedData = {
    grade_id: number
    academic_subject_id: string
    unit_id: string
    indicator_id: string[]
};

type Props = {
    form: FormInstance<FormValues>
    assignedData?: IndicatorsAssignedData
    setGradeId?: Dispatch<SetStateAction<number>>,
    setSubjectId?: Dispatch<SetStateAction<number>>
}

type LoadingType = {
    [k in AssignationEntitiesEnum]: boolean
}

type ErrorsType = {
    [k in AssignationEntitiesEnum]: string | undefined
}

const initialLoading = genLoading<LoadingType>(AssignationEntitiesEnum);

const AssignIndicators: React.FC<Props> = ({ form, assignedData, setGradeId, setSubjectId }) => {
    const readyForRequests = useSelector<IAppState, boolean>(({ axios }) => axios.readyForRequests);
    const schoolID = useSelector<IAppState, string | null>(({ auth }) => auth.selected_school_id);

    const gradeService = useServiceByRef(GradeService);
    const asService = useServiceByRef(AcademicSubjectService);
    const unitService = useServiceByRef(UnitService);
    const indicatorService = useServiceByRef(IndicatorService);

    const [grades, setGrades] = useState<IGrade[]>([]);
    const [academicSubjects, setAcademicSubjects] = useState<IAcademicSubject[]>([]);
    const [units, setUnits] = useState<IUnit[]>([]);
    const [indicators, setIndicators] = useState<IIndicator[]>([]);

    const [loading, setLoading] = useState(initialLoading);
    const [errors, setErrors] = useState({} as ErrorsType);

    const getGrades = useCallback(async () => {
        setLoading(prevState => ({ ...prevState, grades: true }));

        if (readyForRequests && gradeService.current) {
            const res = await gradeService.current.all();

            if (isAxiosError(res)) {
                const err = res as AxiosError;
                setErrors(prevState => ({ ...prevState, grades: err.message }));
            } else {
                const { data } = res as AxiosResponse<IGrade[]>;
                setGrades(data);
            }
        }

        setLoading(prevState => ({ ...prevState, grades: false }));
    }, [gradeService, readyForRequests]);

    const searchAcademicSubjects = async (grade_id: number | undefined) => {
        setLoading(prevState => ({ ...prevState, academic_subjects: true }));
        form.resetFields(['academic_subject_id', 'unit_id', 'indicator_id']);

        if (asService.current && grade_id !== undefined) {
            let res = schoolID !== null && keycloak.hasResourceRole('TEACHER') ? await asService.current.search({
                grade_id,
                school_id: schoolID,
                with: ['subject']
            }) : await asService.current.search({ grade_id, with: ['subject'] });

            if (isAxiosError(res)) {
                const err = res as AxiosError;
                setErrors(prevState => ({ ...prevState, academic_subjects: err.message }));
            } else {
                const { data } = res as AxiosResponse<IAcademicSubject[]>;
                setAcademicSubjects(data);
            }
        }

        setLoading(prevState => ({ ...prevState, academic_subjects: false }));
    };

    const searchUnits = async (academic_subject_id: string | undefined) => {
        setLoading(prevState => ({ ...prevState, units: true }));
        form.resetFields(['unit_id', 'indicator_id']);

        if (unitService.current && academic_subject_id !== undefined) {
            const res = await unitService.current.search({ academic_subject_id });

            if (isAxiosError(res)) {
                const err = res as AxiosError;
                setErrors(prevState => ({ ...prevState, units: err.message }));
            } else {
                const { data } = res as AxiosResponse<IUnit[]>;
                setUnits(data);
            }
        }

        setLoading(prevState => ({ ...prevState, units: false }));
    };

    const searchIndicators = async (unit_id: string | undefined) => {
        setLoading(prevState => ({ ...prevState, indicators: true }));
        form.resetFields(['indicator_id']);

        if (indicatorService.current && unit_id !== undefined) {
            const res = await indicatorService.current.getByUnit(unit_id);

            if (isAxiosError(res)) {
                const err = res as AxiosError;
                setErrors(prevState => ({ ...prevState, indicators: err.message }));
            } else {
                const { data } = res as AxiosResponse<IIndicator[]>;
                setIndicators(data);
            }
        }

        setLoading(prevState => ({ ...prevState, indicators: false }));
    };

    useEffect(() => {
        // noinspection JSIgnoredPromiseFromCall
        getGrades();
    }, [getGrades]);

    useEffect(() => {
        if (schoolID !== null && keycloak.hasResourceRole('TEACHER') && assignedData) {
            // noinspection JSIgnoredPromiseFromCall
            searchAcademicSubjects(assignedData.grade_id);
            // noinspection JSIgnoredPromiseFromCall
            searchUnits(assignedData.academic_subject_id);
            // noinspection JSIgnoredPromiseFromCall
            searchIndicators(assignedData.unit_id);
        }
        if (assignedData && !keycloak.hasResourceRole('TEACHER')) {
            // noinspection JSIgnoredPromiseFromCall
            searchAcademicSubjects(assignedData.grade_id);
            // noinspection JSIgnoredPromiseFromCall
            searchUnits(assignedData.academic_subject_id);
            // noinspection JSIgnoredPromiseFromCall
            searchIndicators(assignedData.unit_id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [assignedData, schoolID]);

    useEffect(() => {
        if (units[0]?.academic_subject?.grade_id !== undefined && units[0]?.academic_subject?.subject_id !== undefined && setGradeId !== undefined && setSubjectId !== undefined) {
            setGradeId(+units[0]?.academic_subject?.grade_id);
            setSubjectId(+units[0]?.academic_subject?.subject_id);
        }
    }, [setGradeId, setSubjectId, units]);

    return (
        <Row gutter={ [20, 0] }>
            <Col span={ 8 }>
                <Item
                    name="grade_id"
                    label={ <h3 className="title-sub-1">{ 'Grado' }</h3> }
                    rules={ rules.grade_id }
                    hasFeedback={ errors.grades !== undefined }
                    validateStatus={ errors.grades !== undefined ? 'error' : undefined }
                    help={ errors.grades }
                >
                    <Select
                        allowClear
                        showSearch
                        loading={ loading.grades }
                        options={ grades.map(({ id, name }) => ({ value: id, label: name })) }
                        onChange={ searchAcademicSubjects }
                        filterOption={ filterOptionsFromLabel }
                        className="select-resource"
                    />
                </Item>
            </Col>

            <Col span={ 8 }>
                <Item
                    name="academic_subject_id"
                    label={ <h3 className="title-sub-1">{ 'Materia' }</h3> }
                    rules={ rules.academic_subject_id }
                    hasFeedback={ errors.academic_subjects !== undefined }
                    validateStatus={ errors.academic_subjects !== undefined ? 'error' : undefined }
                    help={ errors.academic_subjects }
                >
                    <Select
                        allowClear
                        showSearch
                        loading={ loading.academic_subjects }
                        disabled={ form.getFieldValue('grade_id') === undefined }
                        options={ academicSubjects.map(({ id, subject }) => (
                            { value: id, label: subject?.name }
                        )) }
                        onChange={ searchUnits }
                        filterOption={ filterOptionsFromLabel }
                        className="select-resource"
                    />
                </Item>
            </Col>

            <Col span={ 8 }>
                <Item
                    name="unit_id"
                    label={ <h3 className="title-sub-1">{ 'Unidad' }</h3> }
                    rules={ rules.unit_id }
                    hasFeedback={ errors.units !== undefined }
                    validateStatus={ errors.units !== undefined ? 'error' : undefined }
                    help={ errors.units }
                >
                    <Select
                        allowClear
                        showSearch
                        loading={ loading.units }
                        disabled={ form.getFieldValue('academic_subject_id') === undefined }
                        options={ units.map(({ id, name }) => ({ value: id, label: name })) }
                        onChange={ searchIndicators }
                        filterOption={ filterOptionsFromLabel }
                        className="select-resource"
                    />
                </Item>
            </Col>

            <Col span={ 24 }>
                <Item
                    name="indicator_id"
                    label={ <h3 className="title-sub-1">{ 'Indicadores de logro' }</h3> }
                    rules={ rules.indicator_id }
                    hasFeedback={ errors.indicators !== undefined }
                    validateStatus={ errors.indicators !== undefined ? 'error' : undefined }
                    help={ errors.indicators }
                >
                    <Select
                        allowClear
                        loading={ loading.indicators }
                        mode="multiple"
                        placeholder="Selecciona un indicador de logros"
                        disabled={ form.getFieldValue('unit_id') === undefined }
                        options={ indicators.map(({ id, name }) => ({ value: id, label: name })) }
                        className="select-resource"
                    />
                </Item>
            </Col>
        </Row>
    );
};

export default AssignIndicators;
