import {
    Col,
    Form,
    Input,
    Row,
    Select,
    TimePicker,
    DatePicker,
    Button,
    Card,
    message,
    notification,
    Spin,
}                         from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import SuneEditor
                          from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import React, {
    Fragment,
    useEffect,
    useRef,
    useState
}                         from 'react';
import moment, { Moment } from 'moment';
import {
    useHistory,
    useParams
}                         from 'react-router-dom';
import Labels
                          from '../../../../components/labels/';
import { ArrayLabels }    from '../../../../components/labels/types';
import useServiceByRef
                          from '../../../../utils/hooks/useServiceByRef';
import IndicatorService
                          from '../../../../api/services/IndicatorService';
import { useKeycloak }    from '@react-keycloak/web';
import {
    handleAxiosError,
    isAxiosError
}                         from '../../../../utils/services';
import {
    AxiosError,
    AxiosResponse
}                         from 'axios';
import IIndicator
                          from '../../../../interfaces/entities/IIndicator';
import Contents
                          from '../components/contents';
import {
    ComponentFormEntitiesEnum,
    IFormFields,
    InsertResponse,
    LoadingFormType
}                         from '../types';
import {
    getDidacticMap,
    handleFinisUpdate,
    initialDisabledValues,
    initialLoadingValues,
    resourceStates, sunEditorButtonList
}                         from '../helpers';
import ResourcesSearch
                          from '../../components/resourcesSearch/index';
import { ResourcesList }  from '../../components/resourcesSearch/types';
import setFormErrorsByAxiosResponse
                          from '../../../../utils/forms/setFormErrorsByAxiosResponse';
import DidacticMapService
                          from '../../../../api/services/DidacticMapService';
import { storeRules }     from '../rules';
import IDidacticMap
                          from '../../../../interfaces/entities/IDidacticMap';
import { useForm }        from 'antd/es/form/Form';
import { IAppState }      from '../../../../interfaces/store/IAppState';
import { useSelector }    from 'react-redux';
import FooterResource
                          from '../../components/footer';
import { redirectByRole } from '../../../../utils/redirectByRole';

const { TextArea } = Input;


type QueryParams = {
    didactic_map_id: string
}

const EditDidacticMap: React.FC = () => {
        const history = useHistory();
        const { didactic_map_id } = useParams<QueryParams>();
        const [form] = useForm();
        const ref = useRef<ArrayLabels>(null);
        const searcherRef = useRef<ResourcesList>(null);
        const readyForAuthRequests = useSelector<IAppState, boolean>(({ axios }) => axios.readyForRequests);
        const [indicators, setIndicators] = useState<IIndicator[]>([]);
        const [validateIndicator, setValidateIndicator] = useState<boolean>(false);
        const [loading, setLoading] = useState<LoadingFormType>(initialLoadingValues);
        const [disabled, setDisabled] = useState<LoadingFormType>({ ...initialDisabledValues, form: false });
        const [selectedData, setSelectedDate] = useState<Moment>(moment);
        const [selectedIndicators, setSelectedIndicators] = useState<IIndicator[]>([]);
        const [stateId, setStateId] = useState<number>(0);
        const { keycloak } = useKeycloak();
        const indicatorService = useServiceByRef(IndicatorService);
        const service = useServiceByRef(DidacticMapService);
        const [didacticMap, setDidacticMap] = useState<IDidacticMap>();

        const [gradeSelected, setGradeSelected] = useState<number>(0);
        const [subjectSelected, setSubjectSelected] = useState<number>(0);

        const [refresh, setRefresh] = useState<boolean>(true);

        const layoutForm = {
            labelCol: { span: 24 },
            wrapperCol: { span: 24 },
        };

        const onFinish = (values: IFormFields) => {
            if (service.current) {
                const { formComponents } = values;
                values.resource_state_id = stateId;
                if (formComponents) {
                    const components: { key: string, value: string }[] = [];
                    Object.keys(formComponents).forEach((value) => {
                        if (formComponents[value as ComponentFormEntitiesEnum] !== undefined) {
                            components.push({ key: value, value: formComponents[value as ComponentFormEntitiesEnum] });
                        }
                    });

                    values.components = components;
                }

                const labels: string[] = [];

                ref?.current?.labels.forEach((value) => {
                    labels.push(value.name);
                });

                values.labels = labels;

                values.hours = values.helper_time?.format('Y-MM-DD H:mm') as string;

                values.duration = parseInt(values.helpers_duration?.format('HH') as string);

                values.date = values.helper_date?.format('Y-MM-DD') as string;

                const relatedResources: string[] = [];

                if (searcherRef?.current?.resources !== undefined) {
                    if (searcherRef?.current?.resources.length > 0) {
                        searcherRef?.current?.resources.forEach((value) => {
                            relatedResources.push(value.id);
                        });
                    }
                }

                values.related_resource = relatedResources;

                if (values.indicators.length === 0) {
                    values.indicators = [];
                }

                handleFinisUpdate(didactic_map_id, values, onSuccess, onError, loading1 => setLoading((S) => ({
                    ...S,
                    form: loading1
                })), service.current);
            }
        };

        const onError = (err: AxiosError) => {
            handleAxiosError(err, {
                422: response => {
                    setFormErrorsByAxiosResponse(form, response);
                    notification.error({
                        message: 'Ha ocurrido un error al editar la carta didacticas',
                        description: 'Por favor verifica los datos ingresados',
                    });
                },
                500: error => notification.error({
                    message: 'Ha ocurrido un error al editar la carta didacticas',
                    description: error.message,
                })
            });
        };


        const onSuccess = (res: InsertResponse) => {
            form.resetFields();

            const url = redirectByRole('');
            history.push(`${ url }`);

            notification.success({
                    duration: 3.5,
                    message: 'Operación finalizada',
                    description: `Se ha editado la carta didactica${ res.data.resource_state_id === 3 ? ', pasara por revisión antes de ser publicada.' : '.' }`,
                }
            );
        };

        const handleIndicatorChange = async (id: string) => {
            setValidateIndicator(true);
            if (indicators.length > 0) {
                const provIndicators = Object.assign([], selectedIndicators);
                let exist = false;
                let existIndex = 0;

                provIndicators.some((value: IIndicator, index) => {
                    if (value.id === id) {
                        exist = true;
                        existIndex = index;
                    }
                    return value.id === id;
                });

                if (exist) {
                    form.setFields([
                        {
                            name: ['indicators', existIndex],
                            errors: ['No se puede registrar el mismo indicador']
                        }
                    ]);
                } else {
                    setSelectedIndicators((s) => ([...s, indicators.find((indi) => indi.id === id) as unknown as IIndicator]));
                    form.setFields([
                        {
                            name: ['indicators', existIndex],
                            errors: []
                        }
                    ]);
                }

            } else {
                setSelectedIndicators([indicators.find((indi) => indi.id === id) as unknown as IIndicator]);
            }
        };

        const handleRemoveIndicator = (callback: () => void, field: any) => {
            const fieldValue = form.getFieldValue(['indicators', field.name]);

            if (fieldValue !== undefined) {
                const newSelectedIndicators = selectedIndicators.filter((selectedIndicator) => (selectedIndicator.id !== fieldValue));

                setSelectedIndicators(newSelectedIndicators);
                callback();
            } else {
                callback();
            }
        };

        useEffect(() => {
            if (validateIndicator) {
                if (selectedIndicators.length === 0) {
                    form.resetFields(['indicators']);
                }
            }
        }, [selectedIndicators, validateIndicator, form]);

        useEffect(() => {
            if (service.current) {
                if (didactic_map_id !== undefined && refresh) {
                    if (readyForAuthRequests) {
                        setRefresh(true);
                        getDidacticMap(didactic_map_id,
                            d => {
                                setDidacticMap(d);
                            },
                            err => {
                                handleAxiosError(err, {
                                    404: () => {
                                        notification.error({
                                            message: 'Carta didactica no encontrada',
                                            description: 'La carta didactica que desea modificar no existe.',
                                        });
                                        history.goBack();
                                    },
                                    default: () => {
                                        notification.error({
                                            message: 'Ha ocurrido un error al intentar obtener los datos de la cata didactica seleccionada. Inténtelo más tarde'
                                        });
                                        setRefresh(false);
                                    }
                                });
                            },
                            l => {
                                setLoading((prevstate) => ({ ...prevstate, form: l }));
                                setRefresh(false);
                            },
                            service.current
                        );
                    }
                }
            }

        }, [didactic_map_id, history, service, readyForAuthRequests, refresh]);

        useEffect(() => {
            if (didacticMap !== undefined) {
                form.setFields([
                    {
                        name: 'name',
                        value: didacticMap.resource?.name
                    },
                    {
                        name: 'description',
                        value: didacticMap.resource?.description
                    },
                    {
                        name: 'grade',
                        value: didacticMap.resource?.unit?.academic_subject?.grade?.name
                    },
                    {
                        name: 'subject',
                        value: didacticMap.resource?.unit?.academic_subject?.subject?.name
                    },
                    {
                        name: 'unit_id',
                        value: didacticMap.resource?.unit?.name
                    },
                    {
                        name: 'helper_date',
                        value: moment(didacticMap.date)
                    },
                    {
                        name: 'helper_time',
                        value: moment(moment(didacticMap.hours))
                    },
                    {
                        name: 'helper_duration',
                        value: moment(moment(didacticMap.date).set({ hour: didacticMap.duration }))
                    },
                    {
                        name: 'template',
                        value: didacticMap.resource?.template ? 1 : 0
                    }
                ]);


                if (didacticMap.resource?.unit?.academic_subject?.subject_id !== undefined) {
                    const subject_id: number = +didacticMap.resource?.unit?.academic_subject?.subject_id;
                    setSubjectSelected(subject_id);
                }

                if (didacticMap.resource?.unit?.academic_subject?.grade_id !== undefined) {
                    const grade_id: number = +didacticMap.resource?.unit?.academic_subject?.grade_id;
                    setGradeSelected(grade_id);
                }

                setSelectedDate(moment(moment(didacticMap.hours).format('H:mm:ss')));

                const getIndicators = async (id: string) => {
                    setDisabled((s) => ({ ...s, indicator: true }));
                    setLoading((s) => ({ ...s, indicator: true }));
                    if (indicatorService.current) {
                        const response = await indicatorService.current.getByUnit(id);

                        if (!isAxiosError(response)) {
                            const { data } = response as AxiosResponse<IIndicator[]>;
                            if (data.length > 0) {
                                setDisabled((s) => ({ ...s, indicator: false }));
                                setIndicators(data);
                            } else {
                                message.error({
                                    content: <>No se encontraron indicadores</>,
                                    icon: 'error',
                                    duration: 2
                                });
                            }
                        } else {
                            message.error({
                                content: <>No se encontraron indicadores</>,
                                duration: 2
                            });
                        }
                    }
                    setLoading((s) => ({ ...s, indicator: false }));
                };
                getIndicators(didacticMap.resource?.unit_id as string).finally();
            }
        }, [didacticMap, form, indicatorService]);

        useEffect(() => {
            if (didacticMap !== undefined && indicators.length > 0) {
                const currentIndicators: IIndicator[] = [];

                indicators.forEach((value) => {
                    didacticMap.resource?.indicators?.some((indicator) => {
                        if (value.id === indicator.id)
                            currentIndicators.push(value);

                        return value.id === indicator.id;
                    });
                });

                currentIndicators.forEach((value, index) => {
                    form.setFields([{
                        name: ['indicators', index],
                        value: value.id
                    }]);
                });

                setSelectedIndicators(currentIndicators);
            }
        }, [indicators, didacticMap, form]);

        return (
            <div className="content-resource">
                <Form
                    { ...layoutForm }
                    form={ form }
                    name="basic"
                    className="item-resource"
                    onFinish={ (values) => onFinish(values) }
                >
                    {
                        didacticMap !== undefined
                            ?
                            <Fragment>
                                <h2 className="title-resource">Crear recurso </h2>
                                <Form.Item
                                    label={ <h3 className="title-sub-1">{ 'Nombre' }</h3> }
                                    name="name"
                                    rules={ storeRules.name }
                                >
                                    <Input className="input-resource" placeholder="¿Cual es el nombre del recurso?" />
                                </Form.Item>
                                <Form.Item
                                    label={ <h3 className="title-sub-1">{ 'Tags/palabras claves' }</h3> }
                                >
                                    <Labels ref={ ref } didacticMap={ didacticMap } />
                                </Form.Item>
                                <Form.Item
                                    label={ <h3 className="title-sub-1">{ 'Descripción' }</h3> }
                                    name="description"
                                    rules={ storeRules.description }
                                >
                                    <TextArea className="input-resource" rows={ 7 }
                                              placeholder="Escribe una descripción del recurso" />
                                </Form.Item>
                                <Row gutter={ [16, 16] }>
                                    <Col span={ 8 }>
                                        <Form.Item
                                            label={ <h3 className="title-sub-1">{ 'Grado' }</h3> }
                                            name="grade"
                                        >
                                            <Select placeholder="Seleccione un grado"
                                                    className="select-resource"
                                                    disabled={ true }
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={ 8 }>
                                        <Form.Item
                                            label={ <h3 className="title-sub-1">{ 'Materia' }</h3> }
                                            name="subject"
                                        >
                                            <Select placeholder="Seleccione una materia"
                                                    className="select-resource"
                                                    disabled={ true }
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={ 8 }>
                                        <Form.Item
                                            label={ <h3 className="title-sub-1">{ 'Unidad' }</h3> }
                                            name="unit_id"
                                        >
                                            <Select placeholder="Seleccione una Unidad"
                                                    className="select-resource"
                                                    disabled={ true }
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={ [16, 16] }>
                                    <Col span={ 8 }>
                                        <Form.Item
                                            label={ <h3 className="title-sub-1">{ 'Fecha' }</h3> }
                                            name="helper_date"
                                            rules={ storeRules.helper_date }
                                        >
                                            <DatePicker placeholder="Seleccione una fecha" className="select-resource"
                                                        onChange={ (current: Moment | null) => setSelectedDate(current as Moment) } />
                                        </Form.Item>
                                    </Col>
                                    <Col span={ 8 }>
                                        <Form.Item
                                            label={ <h3 className="title-sub-1">{ 'Hora' }</h3> }
                                            name="helper_time"
                                            rules={ storeRules.helper_time }
                                        >
                                            <DatePicker placeholder="Seleccione el tiempo de duración"
                                                        className="select-resource"
                                                        format="H:mm"
                                                        showTime
                                                        defaultOpenValue={ moment(selectedData, 'H:mm') } />
                                        </Form.Item>
                                    </Col>
                                    <Col span={ 8 }>
                                        <Form.Item
                                            label={ <h3 className="title-sub-1">{ 'Tiempo' }</h3> }
                                            name="helpers_duration"
                                            rules={ storeRules.duration }
                                        >
                                            <TimePicker placeholder="Seleccione una hora"
                                                        className="select-resource"
                                                        format={ 'HH' }
                                                        defaultOpenValue={ moment('00', 'HH') } />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <h3 className="title-sub-1">Indicadores de logro </h3>
                                <Form.List
                                    name="indicators"
                                >
                                    { (fields, { add, remove }) => (
                                        <div>
                                            { fields.map((value, index) => (
                                                <Row
                                                    gutter={ [16, 16] }
                                                    key={ index }
                                                >
                                                    <Col span={ 22 }>
                                                        <Form.Item
                                                            { ...value }
                                                            key={ index }
                                                            fieldKey={ value.fieldKey + 1 }
                                                            hasFeedback
                                                        >
                                                            <Select placeholder="Selecciona un indicador de logro"
                                                                    className="select-resource"
                                                                    options={ indicators.map((value) => ({
                                                                        value: value.id,
                                                                        label: value.name
                                                                    })) }
                                                                    loading={ loading.indicator }
                                                                    disabled={ disabled.indicator }
                                                                    onChange={ (value: string) => handleIndicatorChange(value) }
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={ 2 }>
                                                        { fields.length > 1 ? (
                                                            <DeleteOutlined
                                                                onClick={ () => handleRemoveIndicator(() => remove(value.name), value) }
                                                            />
                                                        ) : null }
                                                    </Col>
                                                </Row>
                                            )) }

                                            <Button block shape="round" className="btn-primary-resources"
                                                    onClick={ () => add() }
                                                    disabled={ disabled.indicator }>
                                                Agregar otro indicador
                                            </Button>

                                            { fields.length === 0 ? add() : <></> }
                                        </div>
                                    ) }
                                </Form.List>
                                <br />
                                <Row gutter={ [16, 16] }>
                                    <Col span={ 8 }>
                                        <Form.Item
                                        >
                                            <Card
                                                headStyle={ { backgroundColor: '#0066CC' } }
                                                title={ <h3 className="title-sub-1-pr">{ 'Contenidos Conceptuales' }</h3> }>
                                                <div className="input-extra">
                                                    { selectedIndicators.length > 0 &&
                                                    (<Contents
                                                        indicators={ selectedIndicators }
                                                        content_type={ 'CONCEPTUAL' }
                                                        teacher={ keycloak.hasResourceRole('Teacher', process.env.REACT_APP_KEYCLOACK_CLIENT_ID) }
                                                    />)
                                                    }
                                                </div>
                                            </Card>
                                        </Form.Item>
                                    </Col>
                                    <Col span={ 8 }>
                                        <Form.Item
                                        >
                                            <Card
                                                headStyle={ { backgroundColor: '#0066CC' } }
                                                title={ <h3
                                                    className="title-sub-1-pr">{ 'Contenidos Procedimentales' }</h3> }>
                                                <div className="input-extra">
                                                    { selectedIndicators.length > 0 &&
                                                    (<Contents
                                                        indicators={ selectedIndicators }
                                                        content_type={ 'PROCEDURAL' }
                                                        teacher={ keycloak.hasResourceRole('Teacher', process.env.REACT_APP_KEYCLOACK_CLIENT_ID) }
                                                    />)
                                                    }
                                                </div>
                                            </Card>
                                        </Form.Item>
                                    </Col>
                                    <Col span={ 8 }>
                                        <Form.Item
                                        >
                                            <Card
                                                headStyle={ { backgroundColor: '#0066CC' } }
                                                title={ <h3
                                                    className="title-sub-1-pr">{ 'Contenidos Actitudinales' }</h3> }>
                                                <div className="input-extra">
                                                    { selectedIndicators.length > 0 &&
                                                    (<Contents
                                                        indicators={ selectedIndicators }
                                                        content_type={ 'ATTITUDINAL' }
                                                        teacher={ keycloak.hasResourceRole('Teacher', process.env.REACT_APP_KEYCLOACK_CLIENT_ID) }
                                                    />)
                                                    }
                                                </div>
                                            </Card>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Card
                                    className="card-content-resource"
                                    headStyle={ { backgroundColor: '#0066CC' } }
                                    title={ <h3 className="title-sub-1-pr">{ 'Metodología' } <br /> { 'Inicio' }</h3> }>
                                    <Row>
                                        <Col span={ 24 }>
                                            <Form.Item
                                                label={ <h3
                                                    className="title-sub-1">{ 'Realimentación / Detección de presaberes:' }</h3> }
                                                name={ ['formComponents', 'METHODOLOGY.BEGINNING.FEEDBACK'] }
                                                className="input-methodology"
                                            >
                                                <SuneEditor
                                                    defaultValue={ didacticMap?.components?.find((component) => component.key === 'METHODOLOGY.BEGINNING.FEEDBACK')?.value }
                                                    setOptions={ {
                                                        buttonList: sunEditorButtonList
                                                    } }
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={ [48, 24] }>
                                        <Col span={ 24 }>
                                            <Form.Item
                                                label={ <h3
                                                    className="title-sub-1">{ 'Presentación del tema o indicador de logro:' }</h3> }
                                                name={ ['formComponents', 'METHODOLOGY.BEGINNING.PRESENTATION'] }
                                                className="input-methodology"
                                            >
                                                <SuneEditor
                                                    defaultValue={ didacticMap?.components?.find((component) => component.key === 'METHODOLOGY.BEGINNING.PRESENTATION')?.value }
                                                    setOptions={ {
                                                        buttonList: sunEditorButtonList
                                                    } }
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={ [48, 24] }>
                                        <Col span={ 24 }>
                                            <Form.Item
                                                label={ <h3 className="title-sub-1">{ 'Situación problema:' }</h3> }
                                                name={ ['formComponents', 'METHODOLOGY.BEGINNING.PROBLEM'] }
                                                className="input-methodology"
                                            >
                                                <SuneEditor
                                                    defaultValue={ didacticMap?.components?.find((component) => component.key === 'METHODOLOGY.BEGINNING.PROBLEM')?.value }
                                                    setOptions={ {
                                                        buttonList: sunEditorButtonList
                                                    } }
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Card>
                                <Card
                                    className="card-content-resource"
                                    headStyle={ { backgroundColor: '#0066CC' } }
                                    title={ <h3 className="title-sub-1-pr">{ 'Metodología' } <br /> { 'Desarrollo' }</h3> }>
                                    <Row>
                                        <Col span={ 24 }>
                                            <Form.Item
                                                label={ <h3 className="title-sub-1">{ 'Conocimiento:' }</h3> }
                                                name={ ['formComponents', 'METHODOLOGY.DEVELOPMENT.KNOWLEDGE'] }
                                                className="input-methodology"
                                            >
                                                <SuneEditor
                                                    defaultValue={ didacticMap?.components?.find((component) => component.key === 'METHODOLOGY.DEVELOPMENT.KNOWLEDGE')?.value }
                                                    setOptions={ {
                                                        buttonList: sunEditorButtonList
                                                    } }
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={ 24 }>
                                            <Form.Item
                                                label={ <h3 className="title-sub-1">{ 'Compresión:' }</h3> }
                                                name={ ['formComponents', 'METHODOLOGY.DEVELOPMENT.UNDERSTANDING'] }
                                                className="input-methodology"
                                            >
                                                <SuneEditor
                                                    defaultValue={ didacticMap?.components?.find((component) => component.key === 'METHODOLOGY.DEVELOPMENT.UNDERSTANDING')?.value }
                                                    setOptions={ {
                                                        buttonList: sunEditorButtonList
                                                    } }
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={ 24 }>
                                            <Form.Item
                                                label={ <h3 className="title-sub-1">{ 'Aplicación:' }</h3> }
                                                name={ ['formComponents', 'METHODOLOGY.DEVELOPMENT.APPLICATION'] }
                                                className="input-methodology"
                                            >
                                                <SuneEditor
                                                    defaultValue={ didacticMap?.components?.find((component) => component.key === 'METHODOLOGY.DEVELOPMENT.APPLICATION')?.value }
                                                    setOptions={ {
                                                        buttonList: sunEditorButtonList
                                                    } }
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={ 24 }>
                                            <Form.Item
                                                label={ <h3 className="title-sub-1">{ 'Análisis:' }</h3> }
                                                name={ ['formComponents', 'METHODOLOGY.DEVELOPMENT.ANALYSIS'] }
                                                className="input-methodology"
                                            >
                                                <SuneEditor
                                                    defaultValue={ didacticMap?.components?.find((component) => component.key === 'METHODOLOGY.DEVELOPMENT.ANALYSIS')?.value }
                                                    setOptions={ {
                                                        buttonList: sunEditorButtonList
                                                    } }
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={ 24 }>
                                            <Form.Item
                                                label={ <h3 className="title-sub-1">{ 'Síntesis:' }</h3> }
                                                name={ ['formComponents', 'METHODOLOGY.DEVELOPMENT.SYNTHESIS'] }
                                                className="input-methodology"
                                            >
                                                <SuneEditor
                                                    defaultValue={ didacticMap?.components?.find((component) => component.key === 'METHODOLOGY.DEVELOPMENT.SYNTHESIS')?.value }
                                                    setOptions={ {
                                                        buttonList: sunEditorButtonList
                                                    } }
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={ 24 }>
                                            <Form.Item
                                                label={ <h3 className="title-sub-1">{ 'Valoración:' }</h3> }
                                                name={ ['formComponents', 'METHODOLOGY.DEVELOPMENT.ASSESSMENT'] }
                                                className="input-methodology"
                                            >
                                                <SuneEditor
                                                    defaultValue={ didacticMap?.components?.find((component) => component.key === 'METHODOLOGY.DEVELOPMENT.ASSESSMENT')?.value }
                                                    setOptions={ {
                                                        buttonList: sunEditorButtonList
                                                    } }
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={ 24 }>
                                            <Form.Item
                                                label={ <h3 className="title-sub-1">{ 'Culminación:' }</h3> }
                                                name={ ['formComponents', 'METHODOLOGY.DEVELOPMENT.CULMINATION'] }
                                                className="input-methodology"
                                            >
                                                <SuneEditor
                                                    defaultValue={ didacticMap?.components?.find((component) => component.key === 'METHODOLOGY.DEVELOPMENT.CULMINATION')?.value }
                                                    setOptions={ {
                                                        buttonList: sunEditorButtonList
                                                    } }
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={ 24 }>
                                            <Form.Item
                                                label={ <h3 className="title-sub-1">{ 'Recursos de clase ' }</h3> }
                                                name={ ['formComponents', 'METHODOLOGY.DEVELOPMENT.GRADE_RESOURCE'] }
                                                className="input-methodology"
                                            >
                                                <SuneEditor
                                                    defaultValue={ didacticMap?.components?.find((component) => component.key === 'METHODOLOGY.DEVELOPMENT.GRADE_RESOURCE')?.value }
                                                    setOptions={ {
                                                        buttonList: sunEditorButtonList
                                                    } }
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={ 24 }>
                                            <Card className="suggested-resource">
                                                <Row>
                                                    <Col span={ 24 }>
                                                        <Form.Item
                                                            label={ <h3
                                                                className="title-sub-1">{ 'Recursos sugeridos:' }</h3> }
                                                            className="input-methodology"
                                                        >
                                                            <ResourcesSearch ref={ searcherRef }
                                                                             stored_resources={ didacticMap.related_resource }
                                                                             subject_id={ subjectSelected }
                                                                             grade_id={ gradeSelected }
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                            </Card>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={ 24 }>
                                            <Form.Item
                                                label={ <h3
                                                    className="title-sub-1">{ 'Evaluación de conocimientos:' }</h3> }
                                                name={ ['formComponents', 'METHODOLOGY.DEVELOPMENT.KNOWLEDGE_ASSESSMENT'] }
                                                className="input-methodology"
                                            >
                                                <SuneEditor
                                                    defaultValue={ didacticMap?.components?.find((component) => component.key === 'METHODOLOGY.DEVELOPMENT.KNOWLEDGE_ASSESSMENT')?.value }
                                                    setOptions={ {
                                                        buttonList: sunEditorButtonList
                                                    } }
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={ 24 }>
                                            <Form.Item
                                                label={ <h3 className="title-sub-1">{ 'Resumen:' }</h3> }
                                                name={ ['formComponents', 'METHODOLOGY.DEVELOPMENT.SUMMARY'] }
                                                className="input-methodology"
                                            >
                                                <SuneEditor
                                                    defaultValue={ didacticMap?.components?.find((component) => component.key === 'METHODOLOGY.DEVELOPMENT.SUMMARY')?.value }
                                                    setOptions={ {
                                                        buttonList: sunEditorButtonList
                                                    } }
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Card>
                                <Form.Item
                                    label={ <h3 className="title-sub-1">{ 'Públicar como' }</h3> }
                                    name="template"
                                    rules={ storeRules.template }
                                >
                                    <Select
                                        placeholder="Seleccione un estado de publicación"
                                        options={ resourceStates.map((value) => ({ value: value.id, label: value.name })) }
                                        className="select-resource"
                                    />
                                </Form.Item>
                                <Form.Item>
                                    <Row gutter={ [40, 16] }>
                                        <Col span={ 6 }>
                                            <Button htmlType="submit" shape="round"
                                                    className="btn-action-size btn-eraser-text" block
                                                    loading={ loading.form } onClick={ () => setStateId(2) }>Guardar
                                                                                                             borrador</Button>
                                        </Col>
                                        <Col span={ 18 }>
                                            <Button shape="round"
                                                    htmlType="submit"
                                                    className="btn-primary-resources btn-action-size"
                                                    block
                                                    loading={ loading.form }
                                                    onClick={ () => setStateId(1) }>Publicar </Button>
                                        </Col>
                                    </Row>
                                </Form.Item>
                                <FooterResource />
                            </Fragment>
                            : <Row justify="center" className="row-spinning-didactic-map">
                                <Col><Spin spinning size="large" tip="Cargando..." className="spinning-didactic-map" /></Col>
                            </Row>
                    }
                </Form>

            </div>
        );
    }
;
export default EditDidacticMap;
