import RestService                                  from "../abstract/RestService";
import ILabel                                       from "../../interfaces/entities/ILabel";
import { AxiosError, AxiosInstance, AxiosResponse } from "axios";

class LabelService extends RestService<ILabel> {
    constructor(axios: AxiosInstance) {
        super('label', axios);
    }

    async all(page?: number): Promise<AxiosResponse<ILabel[]> | AxiosError> {
        try {
            return await this.http.get(this.uri);
        } catch ( e ) {
            return e as AxiosError;
        }
    }

    async search(param: { name: string }): Promise<AxiosResponse<ILabel[]> | AxiosError> {
        try {
            return await this.http.post(`${ this.uri }/search `, param);
        } catch ( e ) {
            return e as AxiosError;
        }
    }
}

export default LabelService;