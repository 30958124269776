import React, { useState, Fragment }                   from 'react';
import { message, Space, Modal, notification, Switch } from 'antd';
import {
    ClockCircleOutlined,
    CloseOutlined,
}                                                      from '@ant-design/icons';
import ISchool                                         from '../../../../interfaces/entities/ISchool';
import useServiceByRef                                 from '../../../../utils/hooks/useServiceByRef';
import TeacherService                                  from '../../../../api/services/TeacherService';
import { isAxiosError }                                from '../../../../utils/services';
import { AxiosError }                                  from 'axios';
import { ExclamationCircleOutlined }                   from '@ant-design/icons';
import './index.less';
import ActiveUsersTable                                from './active-users-table';
import DisabledUsersTable                              from './disabled-users-table';

type Props = {
    selectedSchool?: ISchool | undefined;
    searchTeachersBySchool: (id: string) => Promise<void>;
    create: boolean;
};

const { confirm } = Modal;

const UserApprovalTable: React.FC<Props> = ({ selectedSchool, searchTeachersBySchool, create }) => {

    const teacherService = useServiceByRef(TeacherService);

    const [loading, setLoading] = useState<boolean>(false);
    const [showDisabled, setShowDisabled] = useState(false);
    const [action, setAction] = useState<boolean>(false);

    const statusUser = async (id: string, status: number) => {
        setLoading(true);
        if (teacherService.current) {
            const response = await teacherService.current.disabledUser(id);
            if (isAxiosError(response)) {
                const err = response as AxiosError;
                message.error(`Ocurrio un error al actualizar el estado: ${ err.message }`);
            } else {
                setAction(!action);
                notification.success({
                    message: 'Operación finalizada',
                    duration: 2,
                    description: `El usuario ha sido ${ status === 1 ? 'deshabilitado' : 'activado' }`,
                });
                if (selectedSchool !== undefined) {
                    searchTeachersBySchool(selectedSchool.id).finally();
                }
            }
            setLoading(false);
        }
    };


    const actionChangeStatus = (id: string, status: number) => {
        confirm({
            title: `¿Estas segur@ de ${ status === 1 ? 'deshabilitar' : 'activar' } el usuario?`,
            icon: <ExclamationCircleOutlined />,
            content: `El usuario sera ${ status === 1 ? 'deshabilitado' : 'activado' }  y los recursos ${ status === 1 ? 'no podran ser visibles' : 'podran ser visibles' }?.`,
            okType: 'primary',
            okText: 'Si',
            cancelText: 'No',
            okButtonProps: { shape: 'round', style: { background: '#0066CC' } },
            cancelButtonProps: { shape: 'round' },
            onOk() {
                statusUser(id, status).finally();
            },
        });
    };

    return (
        <Fragment>
            { selectedSchool !== undefined && (
                <Space className="switch_show_status">
                    <Switch
                        defaultChecked
                        checkedChildren={ <ClockCircleOutlined title="Peticiones activas" /> }
                        unCheckedChildren={ <CloseOutlined title="Peticiones rechazadas" /> }
                        onChange={ c => setShowDisabled(!c) }

                    />

                    <h3 className="title-switch">Docentes con estado { showDisabled ? 'deshabilitado' : 'Activos' }</h3>
                </Space>
            ) }
            { !showDisabled && teacherService.current ?
                <ActiveUsersTable
                    selectedSchool={ selectedSchool }
                    searchTeachersBySchool={ searchTeachersBySchool }
                    actionChangeStatus={ actionChangeStatus }
                    loading={ loading }
                    setLoading={ setLoading }
                    create={ create }
                    action={ action }
                    setAction={ setAction }
                />
                :
                <DisabledUsersTable
                    selectedSchool={ selectedSchool }
                    searchTeachersBySchool={ searchTeachersBySchool }
                    actionChangeStatus={ actionChangeStatus }
                    loading={ loading }
                    setLoading={ setLoading }
                    create={ create }
                    action={ action }
                />
            }
        </Fragment>
    );
};
export default UserApprovalTable;