import { FormEntitiesEnum, IFormFieldsUpdate, LoadingFormType, UpdateResponse } from './types';
import { AxiosError, AxiosResponse }                                            from 'axios';
import ResourceService
                                                                                from '../../../../api/services/ResourceService';
import { isAxiosError }                                                         from '../../../../utils/services';

export const initialLoadingValues = Object.keys(FormEntitiesEnum).reduce((a, b) => Object.assign(a, { [b]: false }), {}) as LoadingFormType;
export const initialDisabledValues = Object.keys(FormEntitiesEnum).reduce((a, b) => Object.assign(a, { [b]: true }), {}) as LoadingFormType;

type handleUpdateType = (
    values: IFormFieldsUpdate,
    onSuccess: (res: AxiosResponse<UpdateResponse>) => any,
    onError: (err: AxiosError) => any,
    service: ResourceService,
    resource_id: string
) => void;

export const handleFinishUpdate: handleUpdateType = async (values, onSuccess, onError, service, resource_id) => {
    const frmData = new FormData();
    const {
        resource_type_id,
        name,
        description,
        labels,
        unit_id,
        topic,
        indicators,
        url,
        files,
        oldRecords,
        resource_state_id,
        records_external_link
    } = values;

    frmData.append('resource_id', resource_id);
    frmData.append('resource_type_id', resource_type_id.toString());
    frmData.append('name', name);
    frmData.append('description', description);
    labels.forEach(val => frmData.append('labels[]', val.name));
    frmData.append('unit_id', unit_id);
    frmData.append('topic', topic);

    if (url.length > 0) {
        frmData.append('url', url);
    }

    indicators.forEach(id => frmData.append('indicators[]', id));
    frmData.append('resource_state_id', resource_state_id.toString());

    /** validate for new files */
    if (files !== null && files.length > 0) {
        files.forEach(values => frmData.append('records[]', values.file, `${ values.name_file }.${ values.extension }`));
    }

    /** validate if there are previously stored files */
    if (oldRecords !== null && oldRecords.length > 0) {
        // eslint-disable-next-line array-callback-return
        oldRecords.map((record, index) => {
            const name = `oldRecords[${ index }]`;
            frmData.append(`${ name }[id]`, record.id);
            frmData.append(`${ name }[name]`, record.name);
        });
    }

    if (records_external_link !== null && records_external_link !== undefined) {
        records_external_link.forEach(values => frmData.append('records_external_link[]', values.link_file));
    }

    const res = await service.update(resource_id, frmData);

    if (isAxiosError(res)) {
        onError(res as AxiosError);
    } else {
        onSuccess(res as AxiosResponse<UpdateResponse>);
    }
};
