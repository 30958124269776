export enum FormEntitiesEnum {
    resource_types = 'resource_types',
    grades = 'grades',
    subjects = 'subjects'
}

export type LoadingFormType = {
    [k in FormEntitiesEnum]: boolean
}

export type ErrorsType = {
    [k in FormEntitiesEnum]: string | undefined
}

export interface IFormFields{
    search: string | undefined,
    grades: string[] | undefined,
    subjects: string[] | undefined,
    resource_types: string[] | undefined
}