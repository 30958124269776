import { Col, Grid, Image, Row } from 'antd';
import React                     from 'react';
import FooterLogo                from '../images/logo-footer.svg';
import moment                    from 'moment';

const { useBreakpoint } = Grid;

const Footer: React.FC = () => {
    const screens = useBreakpoint();
    const year = moment().year();

    return (
        <div className={ 'footer-wrap' }>
            <Row>
                <Col lg={ 12 } md={ 12 } sm={ 24 } xs={ 24 }>
                    <Row className="footer-text">
                        <Col span={ 24 } className={ (screens.xs || screens.sm) && !screens.md ? '' : 'footer-center' }>
                            <Image
                                preview={ false } src={ FooterLogo } alt="" className={ 'foot-logo' } />
                        </Col>
                        <Col span={ 24 } className="text-item footer-center">Copyright © { year } Ediciones Servicios
                                                                             Educativos
                        </Col>
                        <Col span={ 24 }>
                            <div className={ 'sub-text' }>
                                6ta 10ma Calle Poniente #1713, frente al Tin Marín.
                                Ediciones Servicios Educativos
                            </div>
                        </Col>
                        <Col span={ 24 }>
                            <div className={ 'sub-text' }>
                                PBX: +503 2506-4300
                                Atención al cliente: +503 2506-4337
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    );
};
export default Footer;
