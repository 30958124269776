import React, { Dispatch, SetStateAction, useEffect, useState }            from 'react';
import { Button, Col, message, notification, Popconfirm, Rate, Row, Spin } from 'antd';
import FavoriteOffImg
                                                                           from '../../../../images/favorite_icon_off.svg';
import Icon                                                                from '@ant-design/icons';
import FavoriteOnImg                                                       from '../../../../images/favorite_icon.png';
import IActivityGuide
                                                                           from '../../../../../interfaces/entities/IActivityGuide';
import ActivityGuideService
                                                                           from '../../../../../api/services/ActivityGuideService';
import useServiceByRef
                                                                           from '../../../../../utils/hooks/useServiceByRef';
import { handleAxiosError, isAxiosError }                                  from '../../../../../utils/services';
import { AxiosError, AxiosResponse }                                       from 'axios';
import { useSelector }                                                     from 'react-redux';
import { IAppState }                                                       from '../../../../../interfaces/store/IAppState';
import RateImg                                                             from '../../../../images/rateoff.svg';
import { IKeycloakUserInfo }                                               from '../../../../../interfaces/store/states/IAuthState';
import { useKeycloak }                                                     from '@react-keycloak/web';

type actionsProps = {
    activityGuide: IActivityGuide;
    setRating: Dispatch<SetStateAction<number>>;
    setScore: Dispatch<SetStateAction<number>>;
};

const Actions: React.FC<actionsProps> = ({ activityGuide, setScore, setRating }) => {
    const [favorite, setFavorite] = useState<boolean>();
    const [average, setAverage] = useState<number>(0);
    const [loading, setLoading] = useState<{ fav: boolean, actionUrl: boolean, publicUrl: boolean, rate: boolean, copy: boolean }>({
        fav: false,
        actionUrl: false,
        publicUrl: false,
        rate: false,
        copy: false
    });
    const selected_school_id = useSelector<IAppState, string | null>(({ auth }) => auth.selected_school_id);
    const { keycloak } = useKeycloak();
    const service = useServiceByRef(ActivityGuideService);
    const readyForAuthRequests = useSelector<IAppState, boolean>(({ axios }) => axios.readyForRequests);

    const user = useSelector<IAppState, IKeycloakUserInfo | null>(({ auth }) => auth.keycloakUserInfo);

    const FavoriteOffImgICon = () => <img className="IconExport" src={ FavoriteOffImg } alt="Icon_favorite" />;
    const FavoriteOffIcon = (data: any) => <Icon component={ FavoriteOffImgICon } { ...data } />;

    const FavoriteOnImgIcon = () => <img className="IconExport" src={ FavoriteOnImg } alt="Icon Favorite On" />;
    const FavoriteOnIcon = () => <Icon component={ FavoriteOnImgIcon } />;

    const FavoriteRate = () => <img src={ RateImg } alt="Icon_rate" />;
    const RateIcon = (data: any) => <Icon component={ FavoriteRate } { ...data } />;

    useEffect(() => {
        const checkFavorite = async () => {
            if (service.current) {
                const response = await service.current.checkFavorite(activityGuide.id);

                if (!isAxiosError(response)) {
                    const { data } = response as AxiosResponse<{ is_favorite: boolean }>;
                    setFavorite(data.is_favorite);
                }
            }
        };

        if (readyForAuthRequests)
            checkFavorite().finally();
    }, [activityGuide, service, readyForAuthRequests]);

    useEffect(() => {
        const getResources = async () => {
            if (service.current) {
                const response = await service.current.getRating(activityGuide.id);
                if (!isAxiosError(response)) {
                    const { data } = response as AxiosResponse<{ rating: number }>;
                    setAverage(data.rating);
                }
            }
        };

        if (readyForAuthRequests)
            getResources().finally();
    }, [service, readyForAuthRequests, activityGuide]);

    const changeFavorite = async () => {
        if (service.current) {
            const response = await service.current.favorite(activityGuide.id);
            if (!isAxiosError(response)) {
                const { data } = response as AxiosResponse<{ favorite: boolean }>;
                setFavorite(data.favorite);
            } else {
                const error = response as AxiosError;
                message.error('Ocurrio un error: ' + error.message);
            }
        }
    };

    const copyPrivateUrl = async () => {
        setLoading((s) => ({ ...s, actionUrl: true }));
        const url = window.location.href;
        await navigator.clipboard.writeText(url);
        notification.success({
            duration: 2,
            message: 'Operación finalizada',
            description: `Url copiada`
        });
        setLoading((s) => ({ ...s, actionUrl: false }));
    };

    const getPublicUrl = async () => {
        setLoading((s) => ({ ...s, publicUrl: true }));
        await navigator.clipboard.writeText(`${ window.location.host }/p/activity_guide/${ activityGuide.code }`);
        notification.success({
            duration: 2,
            message: 'Operación finalizada',
            description: `Url publica copiada`
        });
        setLoading((s) => ({ ...s, publicUrl: false }));
    };

    const copyActivityGuide = async () => {
        setLoading((s) => ({ ...s, copy: true }));
        if (service.current) {
            const validation = selected_school_id !== null && keycloak.hasResourceRole('TEACHER', process.env.REACT_APP_KEYCLOACK_CLIENT_ID);
            const response = await service.current.copy(activityGuide.id, validation ? { school_id: selected_school_id as string } : null);

            if (isAxiosError(response)) {
                const err = response as AxiosError;
                handleAxiosError(err, {
                    '500': _ => notification.error({
                        message: 'Ocurrio un error',
                        description: 'No se pudo realizar una copia de la guía de actividades seleccionada',
                        duration: 3.5
                    }),
                    default: _ => notification.error({
                        message: 'Ocurrio un error',
                        description: 'No se pudo realizar una copia de la guía de actividades seleccionada',
                        duration: 3.5
                    })
                });
            } else {
                notification.success({
                    message: 'Operación finalizada',
                    description: 'Se ha realizado una copia de la guía de actividades, se espera por la espera de aprobación.',
                    duration: 3.5
                });
            }
        }
        setLoading((s) => ({ ...s, copy: false }));
    };

    const rateActivityGuide = async (value: number) => {
        setLoading((s) => ({ ...s, rate: true }));
        if (service.current) {
            const response = await service.current.rating(activityGuide.id, { rating: value });
            if (!isAxiosError(response)) {
                getRate().finally();
            }
        }
        setLoading((s) => ({ ...s, rate: false }));
    };

    const getRate = async () => {
        if (service.current) {
            const response = await service.current.getRating(activityGuide.id);
            if (!isAxiosError(response)) {
                const { data } = response as AxiosResponse<{ activityGuide: IActivityGuide, rating: number }>;
                setAverage(data.rating);
                if (data.activityGuide.rating !== undefined && data.activityGuide.scores !== undefined) {
                    setRating(data.activityGuide.rating);
                    setScore(data.activityGuide.scores);
                }
            }
        }
    };

    return (
        <Row gutter={ [8, 24] } className="container-actions">
            <Col xs={ 24 } sm={ 24 } md={ 9 } lg={ 9 } xl={ 9 }>
                <span
                    className={ 'btn-resource-action' }>{ user?.sub === activityGuide.user_id ? activityGuide.views : 0 } visualizaciones</span>
                <p>{ '¿Qué te parecio este recurso?' }</p>
                <Spin spinning={ loading.rate }>
                    <Rate character={ <RateIcon /> } value={ average } onChange={ rateActivityGuide } />
                </Spin>
            </Col>
            <Col xs={ 24 } sm={ 24 } md={ 15 } lg={ 15 } xl={ 15 } className={ 'col-btn-action' }>
                <Row gutter={ [16, 24] }>
                    <Col xs={ 12 } sm={ 4 } md={ 8 } lg={ 6 } xl={ 4 }>
                        <Popconfirm
                            title={ `¿Estas seguro de ${ favorite ? 'remover el recurso de' : 'agregar el recurso a' } favoritos ?` }
                            onConfirm={ changeFavorite }
                            okText="Si"
                            cancelText="No"
                        >
                            <Button
                                loading={ loading?.fav }
                                className={ `btn-size btn-resource-text  ${ favorite ? 'btn-is-favorite' : 'btn-resource-action' }` }
                                icon={ favorite ? <FavoriteOnIcon /> : <FavoriteOffIcon /> }
                                type={ 'text' }
                            > <span style={ { fontSize: '12px' } }>Favorito</span> </Button>
                        </Popconfirm>
                    </Col>
                    <Col xs={ 12 } sm={ 5 } md={ 8 } lg={ 8 } xl={ 5 }>
                        <Button
                            loading={ loading?.actionUrl }
                            onClick={ copyPrivateUrl }
                            className={ 'btn-size btn-pr btn-resource-text btn-resource-action btn-resource-radius' }
                        ><span style={ { fontSize: '14px' } }>Copiar URL</span></Button>
                    </Col>
                    <Col xs={ 12 } sm={ 9 } md={ 12 } lg={ 9 } xl={ 8 }>
                        <Button
                            loading={ loading?.publicUrl }
                            onClick={ getPublicUrl }
                            className={ 'btn-size btn-resource-text btn-resource-radius btn-resource-share' }
                        ><span style={ { fontSize: '14px' } }>Compartir con mi clase</span></Button>
                    </Col>
                    { !keycloak.hasResourceRole('ADMIN') && !keycloak.hasResourceRole('LXD') && (
                        <Col xs={ 12 } sm={ 5 } md={ 5 } lg={ 5 } xl={ 5 }>
                            <Button
                                loading={ loading?.publicUrl }
                                onClick={ copyActivityGuide }
                                className={ 'btn-size btn-resource-text btn-resource-radius btn-resource-copy-guide' }
                            ><span style={ { fontSize: '14px' } }>Copiar guía</span></Button>
                        </Col>
                    ) }
                </Row>
            </Col>
        </Row>
    );
};

export default Actions;
