import IUserSchool               from '../../../../interfaces/entities/IUserSchool';
import { isAxiosError }          from '../../../../utils/services';
import { message, notification } from 'antd';
import { AxiosError }            from 'axios';
import AuthService               from '../../../../api/services/AuthService';
import React                     from 'react';
import { ModalFunctionsType }    from '../../../../types/components/modals';

type approveFuncType = (
    service: AuthService | null,
    u: IUserSchool,
    getData: () => Promise<any>,
    setApproveLoadingType: React.Dispatch<React.SetStateAction<boolean>>
) => void;

type rejectFuncType = (
    service: AuthService | null,
    modal: ModalFunctionsType,
    u: IUserSchool,
    getData: () => Promise<any>
) => void;

export const handleApproveUser: approveFuncType = async (service, u, getData, setApproveLoading) => {
    if (service != null) {
        setApproveLoading(true);
        const res = await service.approve(u.id);

        if (isAxiosError(res)) {
            notification.error({
                message: 'Ha ocurrido un error al intentar aprobar el usuario',
                description: (res as AxiosError).message
            });
        } else {
            message.success('El usuario ha sido aprobado éxitosamente');
            await getData();
        }
    } else {
        message.error('Ha ocurrido un error');
    }

    setApproveLoading(false);
};

export const handleRejectUser: rejectFuncType = (service, modal, u, getData) => {
    const { user, id } = u;

    if (service != null && user !== undefined) {
        modal.confirm({
            centered: true,
            keyboard: true,
            maskClosable: true,
            title: <p>
                Estás segur@ que deseas rechazar la petición de <b>{ user.name } { user.last_name }</b>
            </p>,
            okText: 'Confirmar',
            cancelText: 'Cancelar',
            onOk: async () => {
                const res = await service.reject(id);

                if (isAxiosError(res)) {
                    notification.error({
                        message: 'Ha ocurrido un error al intentar rechazar el usuario',
                        description: (res as AxiosError).message
                    });
                } else {
                    message.success('El usuario ha sido rechazado éxitosamente');
                    await getData();
                }
            }
        });
    } else {
        // noinspection JSIgnoredPromiseFromCall
        message.error('Ha ocurrido un error');
    }
};
