import React, { Fragment, useEffect, useState } from 'react';
import { useKeycloak }                          from '@react-keycloak/web';
import { KeycloakProfile }                      from 'keycloak-js';
import { Button, Col, Row, Tag }                from 'antd';

type Props = {
    isLxd?: boolean;
    isAdmin?: boolean;
    isDirector?: boolean;
}

const BannerUserSSO: React.FC<Props> = ({ isLxd, isAdmin, isDirector }) => {

    const { initialized, keycloak } = useKeycloak();
    const [loading, setLoading] = useState<boolean>(true);

    const [userSSO, setUserSSO] = useState<KeycloakProfile>();
    const [rolSSO, setRolSSO] = useState<string>('');

    useEffect(() => {
        if (initialized) {
            const getUserProfile = async () => {
                const user = await keycloak.loadUserProfile();
                setUserSSO(user);
                setLoading(false);
            };

            const getRolSSO = () => {
                if (keycloak.hasResourceRole('ADMIN')) {
                    setRolSSO('Administrador');
                } else if (keycloak.hasResourceRole('LXD')) {
                    setRolSSO('Diseñador de experiencia de aprendizaje');
                } else if (keycloak.hasResourceRole('PRINCIPAL')) {
                    setRolSSO('Director');
                }
            };

            if (keycloak.authenticated) {
                getUserProfile().finally();
                getRolSSO();
            }
        }
    }, [isAdmin, isLxd, isDirector, keycloak, initialized]);

    return (
        <Fragment>
            { !loading && userSSO !== undefined && (
                <Fragment>
                    <Col span={ 20 }>
                        <Row className="banner-usr-data">
                            <Col span={ 24 }>
                                <h3 className="banner-tittle">
                                    { userSSO.firstName } { userSSO.lastName }
                                </h3>
                            </Col>
                            <Col span={ 24 }>
                                <h4 className="banner-school">

                                </h4>
                            </Col>
                            <Col span={ 24 }>
                                <p className="banner-tag"><Tag
                                    className="tag-card">
                                    { rolSSO }
                                </Tag></p>
                            </Col>
                            { isLxd ?
                                <Col span={ 24 }>
                                    <h2 className="banner-subject">Administrador de contenidos y creador</h2>
                                </Col>
                                : isAdmin ?
                                    <Col span={ 24 }>
                                        <h2 className="banner-subject">Administrador de contenidos</h2>
                                    </Col>
                                    : null
                            }
                        </Row>
                    </Col>
                    <Col span={ 4 } className="banner-session">
                        <Button className="btn-closed-session"
                                onClick={ () => keycloak.logout({ redirectUri: window.location.origin }) } type="link"
                                danger>{ 'Cerrar sesión' }</Button>
                    </Col>
                </Fragment>
            ) }
        </Fragment>
    );
};
export default BannerUserSSO;