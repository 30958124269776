import { Button, Col, Divider, Modal, notification, Rate, Row, Skeleton, Spin, Tag, Typography } from 'antd';
import React, { Fragment, useEffect, useState }                                                  from 'react';
import {
    useHistory,
    useParams
}                                                                                                from 'react-router-dom';

import Recommendation                     from '../components/recomendation';
import ActionView                         from '../components/actionViews';
import ResourceReview                     from '../components/resourceReview';
import IResource                          from '../../../../interfaces/entities/IResource';
import ResourceService                    from '../../../../api/services/ResourceService';
import { handleAxiosError, isAxiosError } from '../../../../utils/services';
import { AxiosError, AxiosResponse }      from 'axios';

import { useKeycloak }      from '@react-keycloak/web';
import moment               from 'moment';
import IResourceInformation from '../../../../interfaces/entities/IResourceInformation';
import { useSelector }      from 'react-redux';
import { IAppState }        from '../../../../interfaces/store/IAppState';
import ResourceTypesShow    from '../components/resource-types';
import RateImg              from '../../../images/rateoff.svg';
import Icon                 from '@ant-design/icons';
import NotFoundError404     from '../../../errors/404';
import useService           from '../../../../utils/hooks/useService';
import Link                 from 'antd/lib/typography/Link';

interface RouteParams {
    resource_id: string
}

const { Paragraph, Text } = Typography;

const ResourceView: React.FC = () => {
        const history = useHistory();
        const { keycloak, initialized } = useKeycloak();
        const readyForAuthRequests = useSelector<IAppState, boolean>(({ axios }) => axios.readyForRequests);
        const params = useParams<RouteParams>();
        const schoolID = useSelector<IAppState, string | null>(({ auth }) => auth.selected_school_id);

        const resourceService = useService(ResourceService);
        const [rendering, setRendering] = useState(true);

        const [resource, setResource] = useState<IResource>();
        const [rating, setRating] = useState<number>(0);
        const [score, setScore] = useState<number>(0);
        const [isFavorite, setIsFavorite] = useState<boolean>(false);

        const [logged, setLogged] = useState<boolean>(false);

        const [error, setError] = useState<boolean>(false);

        const [publicUrl] = useState<string>(window.location.host + `/p/resource/${ params.resource_id }`);

        // ICON IMG RATE
        const FavoriteRate = () => <img src={ RateImg } alt="Icon_rate" />;
        const RateIcon = (data: any) => <Icon component={ FavoriteRate } { ...data } />;

        useEffect(() => {
            if (initialized) {
                if (keycloak.authenticated) {
                    setLogged(true);
                }
            }
        }, [keycloak, initialized]);

        useEffect(() => {
            const url = window.location.href;

            const getResource = async () => {
                if (resourceService !== null && params.resource_id !== '' && resource === undefined) {
                    setRendering(true);

                    const id = params.resource_id;
                    let response: object | undefined;

                    if (keycloak.authenticated && keycloak.hasResourceRole('TEACHER') && schoolID !== null) {
                        response = await resourceService.get(id, schoolID ?? null);
                    } else if (keycloak.authenticated && !keycloak.hasResourceRole('TEACHER')) {
                        response = await resourceService.get(id, schoolID ?? null);
                    } else if (!keycloak.authenticated) {
                        response = await resourceService.get(id, schoolID ?? null);
                    }

                    if (isAxiosError(response)) {
                        handleAxiosError(response as AxiosError, {
                            404: () => {
                                const err = response as AxiosError;
                                notification.success({
                                    message: err.message,
                                    description: 'Ocurrio un error al obtener el recurso',
                                    duration: 3,
                                });
                                setRendering(true);
                                setError(true);
                            },
                            default: () => {
                                //    TODO: define message by token expiration if necessary
                            }
                        });
                    } else {
                        if (response !== undefined) {
                            const { data } = response as AxiosResponse<IResourceInformation>;
                            if (data && data.resource !== undefined) {
                                setResource(data.resource);
                                //Set rating resource
                                if (data.resource.global_rating > 0) {
                                    setRating(data.resource.global_rating);
                                    setScore(data.resource.score);
                                }
                                setIsFavorite(data.isFavorite);
                                setRendering(false);
                            } else {
                                setError(true);
                            }
                        }
                    }
                }
            };

            if (readyForAuthRequests && logged && resourceService !== null) {
                getResource().finally();
            } else if (!logged && resourceService !== null && url.includes('/p/')) {
                getResource().finally();
            }
        }, [keycloak, logged, readyForAuthRequests, history, resourceService, params.resource_id, schoolID, resource]);

        return (
            <Fragment>
                { !error
                    ?
                    <Row gutter={ [48, 8] }>
                        <Modal
                            title={ null }
                            centered
                            visible={ rendering }
                            footer={ null }
                            className="loading_modal"
                            maskClosable={ false }
                            closable={ false }
                        >
                            <Spin size="large" />
                        </Modal>
                        <Col lg={ logged ? 16 : 21 } md={ logged ? 16 : 21 } sm={ 24 } xs={ 24 }
                             className="content-view-resource">
                            <Skeleton className="skeleton-actions-resource" active loading={ rendering } />
                            {
                                resource && !rendering && (
                                    <Fragment key={ 'content-view' }>
                                        <div className="content-view-info">
                                            { logged && (
                                                <ResourceReview resource={ resource } />
                                            ) }
                                            <h2 className="resource-title-single">{ resource.name }</h2>
                                            <Row gutter={ [48, 24] }>
                                                <Col lg={ 12 } md={ 12 } sm={ 24 } xs={ 24 }>
                                                    { resource.labels?.map((label, index) => (
                                                        <Tag className="tag-resource" key={ index }>
                                                            { label.name.length > 20 ? label.name.substring(0, 20) + '...' : label.name }
                                                        </Tag>
                                                    )) }
                                                </Col>
                                                { keycloak.authenticated && (
                                                    <Col lg={ 12 } md={ 12 } sm={ 24 } xs={ 24 }
                                                         style={ { textAlign: 'end' } }>
                                                        <Fragment>
                                                            <Rate character={ <RateIcon /> }
                                                                  value={ rating }
                                                                  disabled={ true } />
                                                            <span
                                                                style={ {
                                                                    color: '#A0A4A8',
                                                                    marginLeft: '1em'
                                                                } }>Calificaciones { score }</span>
                                                        </Fragment>
                                                    </Col>
                                                )
                                                }
                                            </Row>
                                            <Divider />
                                            <h2 className="format-text-info teacher-author">{ resource.user?.name }</h2>
                                            <h3 className="format-text-info created-at-resource">{ moment(resource.created_at).fromNow() }</h3>
                                            <div className="text-content-view-resource">
                                                <p>{ resource.description }</p>
                                                <p className="text-content-title">Indicador de logro:</p>
                                                <Paragraph className="contents-resource-show">
                                                    { resource.indicators?.map((indicator) => (
                                                        `${ indicator.name }, `
                                                    )) }
                                                </Paragraph>
                                                <p className="text-content-title">Plan de estudio:</p>
                                                <Paragraph>
                                                    { resource.unit?.annual_plannings !== undefined && resource.unit.annual_plannings.length > 0 && (
                                                        <Fragment>
                                                            <Paragraph className="contents-resource-show">
                                                                <Text
                                                                    strong>Año
                                                                           escolar:</Text> { resource.unit?.annual_plannings[0].academic_charge?.school_year?.year },
                                                                <Text
                                                                    strong>Tiempo:</Text> { resource.unit?.annual_plannings[0].total_hours } horas
                                                            </Paragraph>
                                                        </Fragment>
                                                    ) }
                                                </Paragraph>
                                                <Paragraph className="contents-resource-show">
                                                    <Text
                                                        strong>Unidad:</Text>{ resource.unit?.name }, &nbsp;
                                                    { resource.topic !== null && (
                                                        <Fragment>
                                                            <Text
                                                                strong>Tema:</Text>{ resource.topic }, &nbsp;
                                                        </Fragment>
                                                    ) }
                                                    <Text
                                                        strong>Grado:</Text> { resource.unit?.academic_subject?.grade?.name }, &nbsp;
                                                    <Text
                                                        strong>Materia:</Text> { resource.unit?.academic_subject?.subject?.name }
                                                </Paragraph>
                                                {
                                                    resource.url !== null && (
                                                        <p>
                                                            <span className="url-resource">URL complemento: </span>
                                                            <Button type="link" className="url-hyperlink"
                                                                    href={ resource.url }>{ resource.url }</Button>
                                                        </p>
                                                    )
                                                }
                                                { resource.records !== undefined && (
                                                    <ResourceTypesShow resource={ resource } />
                                                ) }
                                                { resource.resource_state_id === 1 && (
                                                    <Fragment>
                                                        <div className="url-content">
                                                                <span
                                                                    className="url-resource">URL publica del recurso: </span>
                                                            <Link href={ `/p/resource/${ params.resource_id }` }>
                                                                <Paragraph
                                                                    style={ { color: 'blue' } }
                                                                    className="url-hyperlink"
                                                                    copyable={ { tooltips: 'Copiar url' } }>{ publicUrl }</Paragraph>
                                                            </Link>
                                                        </div>
                                                        <Divider />
                                                    </Fragment>
                                                ) }
                                                {
                                                    logged && resource.resource_state_id === 1 && (
                                                        <ActionView
                                                            resource={ resource }
                                                            isFavorite={ isFavorite }
                                                            publicUrl={ publicUrl }
                                                            setRating={ setRating }
                                                            setScore={ setScore }
                                                        />
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </Fragment>
                                )
                            }
                        </Col>
                        <Col span={ 1 } />
                        { logged && (
                            <Recommendation
                                rendering={ rendering }
                                labels={ resource?.labels }
                            />
                        ) }
                    </Row>
                    :
                    <div className="resource-404">
                        <NotFoundError404 message="El recurso buscado no existe." />
                    </div>
                }
            </Fragment>
        );
    }
;
export default ResourceView;
