export enum FormEntitiesEnum {
    schools = 'schools',
    form = 'form'
}

export type LoadingType = {
    [k in FormEntitiesEnum]: boolean
}

export type ErrorsType = {
    [k in FormEntitiesEnum]: string | undefined
}

export interface IFormFields {
    infrastructure_code: string | undefined
    odoo_code: string | undefined
    name: string | undefined
    status_id: number | undefined
    public_content: boolean | undefined
}
