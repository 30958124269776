import IGrade from '../../../../interfaces/entities/IGrade';
import ISubject from '../../../../interfaces/entities/ISubject';
import { OptionData }                    from 'rc-select/lib/interface';
import { FormEntitiesEnum, LoadingType } from './type';

export const handleGradesOptionsFormat: (subjects: IGrade[]) => OptionData[] = (grades) => {
    return grades.map(s => ({
        label: `${s.name}`,
        value: s.id,
    }));
};

export const handleSubjectsOptionsFormat: (subjects: ISubject[]) => OptionData[] = (subjects) => {
    return subjects.map(s => ({
        label: `${s.name}`,
        value: s.id
    }));
};

export const initialLoadingValues = Object.keys(FormEntitiesEnum).reduce((a, b) => Object.assign(a, { [b]: false }), {}) as LoadingType;