import React, { Fragment }    from 'react';
import ActivityGuidesListShow from '../../../components/ActivityGuides';

const ActivityGuidesHome = () => {
    return (
        <Fragment>
            <ActivityGuidesListShow paginated home profile={ false } filter/>
        </Fragment>
    );
};

export default ActivityGuidesHome;