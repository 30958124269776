import React, { Fragment, useEffect, useState }           from 'react';
import { Col, Divider, List, Modal, Row, Skeleton, Tabs } from 'antd';
import IUnitSabi, {
    IIndicatorSABI, ICompetenceSABI, ISkillSABI, IKnowledgeSABI, IAttitudeSABI
}                                                         from '../../../../../interfaces/entities/IUnitSabi';
import IUnit                                              from '../../../../../interfaces/entities/IUnit';

const { TabPane } = Tabs;

type Props = {
    indicatorsSabi?: IIndicatorSABI[];
    showUnitIndicatorMdl: boolean;
    unit: IUnit;
    onIndicatorMdlClose: () => void;
    unitsSabi: IUnitSabi[];
}

const ModalContent: React.FC<Props> = ({
                                           showUnitIndicatorMdl,
                                           unit,
                                           onIndicatorMdlClose,
                                           unitsSabi
                                       }) => {

        const [indicators, setIndicators] = useState<IIndicatorSABI[]>([]);

        useEffect(() => {
            unitsSabi.find(sabi => {
                if (sabi.id === unit.id) {

                    return setIndicators(sabi.indicators);
                }
            });
        }, [unit.id, unitsSabi]);

        return (
            <Modal
                key={ unit.id }
                centered
                visible={ showUnitIndicatorMdl }
                title={ <h3 className="title-sub-1">{ `${ unit.name }` }</h3> }
                width={ '60%' }
                okButtonProps={ { style: { display: 'none' } } }
                cancelButtonProps={ { style: { display: 'none' } } }
                className={ 'btn-modal-maintenance' }
                onCancel={ () => {
                    onIndicatorMdlClose();
                    Modal.destroyAll();
                } }
            >
                <Fragment>
                    <Row key={ 'row-content-modal-indicators' }>
                        <Col span={ 24 } key={ 'col-content-modal-competences' }>
                            <h3>Competencias</h3>
                            {
                                indicators !== undefined && indicators !== null && indicators.length > 0 ?
                                    indicators.map(indicator => (
                                        <List<ICompetenceSABI>
                                            key={ `list-competences-${ indicator.id }` }
                                            bordered
                                            dataSource={ indicator.competences }
                                            rowKey={ item => item.id }
                                            renderItem={ item => <List.Item>{ item.text }</List.Item> }
                                        />
                                    ))
                                    :
                                    <Fragment>
                                        <Col span={ 24 } key="Skeleton-loading-competences">
                                            <Skeleton active key={ 'skeleton-active-competences' } />
                                        </Col>
                                        <Divider />
                                    </Fragment>
                            }
                        </Col>
                        <Divider />
                        <Col span={ 24 } key={ 'col-content-modal-contents-title' }>
                            <h3>Contenidos</h3><Divider />
                        </Col>
                        { indicators !== undefined && indicators.length > 0
                            ?
                            <Col span={ 24 } key={ 'col-content-modal-contents' }>
                                <Tabs className="tabs-unit-planning" type={ 'card' } defaultActiveKey="1"
                                      key={ 'col-content-modal-contents-tabs' }>
                                    <TabPane tab={ <span>Contenidos Procedimentales</span> } key="1">
                                        {
                                            indicators.map((indicator) => (
                                                indicator.competences.map((competence) => (
                                                    <List<ISkillSABI>
                                                        bordered
                                                        dataSource={ competence.skills }
                                                        renderItem={ skill => (
                                                            <List.Item>
                                                                { skill.name } - { skill.description }
                                                            </List.Item>
                                                        ) }
                                                    />
                                                ))
                                            ))
                                        }
                                    </TabPane>
                                    <TabPane tab={ <span>Contenidos Conceptuales</span> } key="2">
                                        { indicators.map((indicator) => (
                                            indicator.competences.map((competence) => (
                                                <List<IKnowledgeSABI>
                                                    bordered
                                                    dataSource={ competence.knowledge }
                                                    renderItem={ knowledge => (
                                                        <List.Item>
                                                            { knowledge.name } - { knowledge.description }
                                                        </List.Item>
                                                    ) }
                                                />
                                            ))
                                        )) }

                                    </TabPane>
                                    <TabPane tab={ <span>Contenidos Actitudinales</span> } key="3">
                                        { indicators.map((indicator) => (
                                            indicator.competences.map((competence) => (
                                                <List<IAttitudeSABI>
                                                    bordered
                                                    dataSource={ competence.attitudes }
                                                    renderItem={ attitude => (
                                                        <List.Item>
                                                            { attitude.name } - { attitude.description }
                                                        </List.Item>
                                                    ) }
                                                />
                                            ))
                                        )) }

                                    </TabPane>
                                    <TabPane tab={ <span>Indicadores de logros</span> } key="4">
                                        <List<IIndicatorSABI>
                                            bordered
                                            dataSource={ indicators }
                                            renderItem={ indicator => (
                                                <List.Item>
                                                    { indicator.name } - { indicator.description }
                                                </List.Item>
                                            ) }
                                        />

                                    </TabPane>
                                </Tabs>
                            </Col>
                            :
                            <Col span={ 24 } key="Skeleton-loading-contents">
                                <Skeleton active key={ 'skeleton-active-contents' } />
                            </Col>
                        }
                    </Row>
                </Fragment>
            </Modal>
        );
    }
;
export default ModalContent;
