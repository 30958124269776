import IResource         from '../../../../interfaces/entities/IResource';
import { IResourceFile } from '../components/records/types';
import IIndicator        from '../../../../interfaces/entities/IIndicator';
import IRecord           from '../../../../interfaces/entities/IRecord';

export enum FormEntitiesEnum {
    resource_types = 'resource_types',
    grade = 'grade',
    subject = 'subject',
    indicator = 'indicator',
    unit = 'unit',
    form = 'form'
}

export type LoadingFormType = {
    [k in FormEntitiesEnum]: boolean
}

export type ErrorsType = {
    [k in FormEntitiesEnum]: string | undefined
}

export type UpdateResponse = {
    resource: IResource,
    loading: boolean
}

export interface IFormRulesUpdate {
    resource_type_id: number,
    name: string,
    description: string,
    labels: string[],
    grade_id: string,
    subject_id: string,
    unit_id: string,
    topic: string,
    indicators: string[],
}

export interface IFormFieldsUpdate {
    resource_type_id: number,
    name: string,
    description: string,
    labels: IFormLabels[],
    unit_id: string,
    topic: string,
    url: string,
    indicators: string[],
    files: IResourceFile[] | null,
    oldRecords: IRecord[] | null,
    resource_state_id: number,
    records_external_link: IRecordsExternalLink[]
}

export interface IFormFieldsUpdateInitial {
    resource_type_id: number,
    name: string,
    description: string,
    labels: IFormLabels[] | undefined,
    grade_id: string | undefined,
    subject_id: string | undefined,
    unit_id: string | undefined,
    topic: string | null,
    url: string,
    indicators: IIndicator[] | undefined
}

export interface IFormLabels {
    id: string,
    name: string
}

export interface IRecordsExternalLink {
    link_file: string
}

