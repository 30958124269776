import React, { FunctionComponent }                          from 'react';
import { Redirect, Route, RouteComponentProps, RouteProps, } from 'react-router-dom';
import { KeycloakInstance }                                  from 'keycloak-js';
import { useKeycloak }                                       from '@react-keycloak/web';
import { message }                                           from 'antd';

interface IProps extends RouteProps {
    Layout: FunctionComponent;
    keyCloakValidation?: (keycloak: KeycloakInstance) => boolean;
}

const LayoutRoute: React.FC<IProps> = (props) => {
    const {
        Layout,
        component: Component,
        keyCloakValidation,
        ...rest
    } = props;

    const { keycloak } = useKeycloak();

    if (!Component) return null;

    const Render = (props: RouteComponentProps) => {
        if (keyCloakValidation !== undefined) {
            if (!keycloak.authenticated) {
                let redirect = '';
                const { path } = rest;

                if (path !== undefined) {
                    redirect = Array.isArray(path) ? '' : (path as string).split('/').join('-');
                }

                return <Redirect to={ `/unauthenticated/${ redirect }` }/>;
            } else if (!keyCloakValidation(keycloak)) {
                message.error(
                    'No tienes permiso para acceder a la sección especificada'
                );

                return <Redirect to="/unauthenticated/"/>;
            }
        }

        return (
            <Layout>
                <Component { ...props } />
            </Layout>
        );
    };

    return <Route { ...rest } render={ Render }/>;
};

export default LayoutRoute;
