import { AxiosInstance } from 'axios';

export default abstract class Service {
    public http: AxiosInstance;
    protected uri: string;

    protected constructor(uri: string, axios: AxiosInstance) {
        this.http = axios;
        this.uri = uri;
    }
}