import FormRules         from '../../../../../../types/utils/forms/FormRules';
import {IFormFieldsDeny} from './type';

const rules: FormRules<IFormFieldsDeny> = {
    reason: [
        {
            required: true,
            message: 'La razón es requerida'
        },
        {
            whitespace: true,
            message: 'No se permiten solo espacios en blanco, por favor ingrese una razón valida'
        },
        {
            type:'string',
            message: 'Ingrese datos validos'
        },
        {
            max:255,
            message: 'La capacidad maxima para ingresar la razón son 255 caracteres'
        }
    ],
    description: [
        {
            required: true,
            message: 'La descripción es requerida'
        },
        {
            whitespace: true,
            message: 'No se permiten solo espacios en blanco, por favor ingrese una descripción valida'
        },
        {
            type:'string',
            message: 'Ingrese datos validos'
        },
        {
            max:255,
            message: 'La capacidad maxima para ingresar la descripción son 255 caracteres'
        }
    ],
}
export default rules;