import { routeType }        from '../../types/routes';
import NewResource          from '../../views/resource/resource/create';
import Resource             from '../../views/resource/show/resource';
import LXDProfile           from '../../views/lxd/profile';
import FavoriteResources    from '../../views/resource/favorite';
import EditResource         from '../../views/resource/resource/edit';
import EditDidacticMap      from '../../views/resource/didacticMap/edit/index';
import NewDidacticMap       from '../../views/resource/didacticMap';
import DidacticMap          from '../../views/resource/show/didacticLetter/';
import StudentsGuide        from '../../views/resource/show/ActivityGuide';
import React                from 'react';
import ActivityGuidesHome   from '../../views/activity_guides/Home';
import CreateActivityGuides from '../../views/activity_guides/CreateActivityGuides';
import UpdateActivityGuides from '../../views/activity_guides/UpdateActivityGuides';
import AssociatedResources  from '../../views/plannings/components/AssociatedResources';

const routes: routeType[] = [
    {
        path: '',
        component: LXDProfile
    },
    {
        path: 'resource/:resource_id',
        component: Resource
    },
    {
        path: 'new/resource',
        component: NewResource
    },
    {
        path: 'update/resource/:resource_id',
        component: EditResource
    },
    {
        path: 'favorite/resource',
        component: FavoriteResources
    },
    {
        path: 'didactic_map/:didactic_map_id',
        component: DidacticMap
    },
    {
        path: 'update/didactic_map/:didactic_map_id',
        component: EditDidacticMap
    },
    {
        path: 'new/didactic_map',
        component: NewDidacticMap
    },
    {
        path: 'activity_guide/s/:activity_guide_code',
        component: () => <StudentsGuide publicGuide={ false } />
    },
    {
        path: 'activity_guides',
        component: ActivityGuidesHome
    },
    {
        path: 'activity_guides/create/:draft_id?',
        component: CreateActivityGuides
    },
    {
        path: 'activity_guides/edit/:activity_guide_id',
        component: UpdateActivityGuides
    },
    {
        path: 'plannings/associated/resources/:unit_id',
        component: AssociatedResources
    },
];

export default routes;
