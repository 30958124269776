type screensType = {
    xs?: boolean,
    sm?: boolean,
    md?: boolean,
    lg?: boolean,
    xl?: boolean,
    xxl?: boolean,
}

export const FormatTag = (tag: string, screens: screensType) => {
    if (screens.xl || screens.xxl) {
        if (tag.length > 40) {
            return tag.substring(0, 40) + '...';
        } else {
            return tag;
        }
    } else if (!screens.xl && screens.lg) {
        if (tag.length > 25) {
            return tag.substring(0, 25) + '...';
        } else {
            return tag;
        }
    } else {
        if (tag.length > 20) {
            return tag.substring(0, 20) + '...';
        } else {
            return tag;
        }
    }
};
