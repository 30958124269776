import { Empty, Modal, Table, TablePaginationConfig }        from 'antd';
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import useService                                            from '../../../../utils/hooks/useService';
import UsersSchoolsService                                   from '../../../../api/services/UsersSchoolsService';
import { isAxiosError }                                      from '../../../../utils/services';
import { AxiosError, AxiosResponse }                         from 'axios';
import IPaginatedData                                        from '../../../../interfaces/IPaginatedData';
import IUserSchool                                           from '../../../../interfaces/entities/IUserSchool';
import columns                                               from './columns';
import ActionButtons
                                                             from '../../../../components/EntityRestTable/ActionButtons';
import actionInfo                                            from '../../../../components/EntityRestTable/actionsInfo';
import { RestActions }                                       from '../../../../components/EntityRestTable/types';
import tableActions                                          from './tableActions';
import AuthService                                           from '../../../../api/services/AuthService';

type Props = {
    school_id: string[],
    setApproveLoading: React.Dispatch<React.SetStateAction<boolean>>
};

const SearchBySchool: React.FC<Props> = ({ school_id, setApproveLoading }) => {
    const service = useService(UsersSchoolsService);
    const authService = useService(AuthService);
    const [page, setPage] = useState(1);
    const [data, setData] = useState<IUserSchool[]>([]);
    const [loading, setLoading] = useState(false);
    const [modal, modalContext] = Modal.useModal();
    const [error, setError] = useState<string | null>(null);

    const [paginationInfo, setPaginationInfo] = useState<TablePaginationConfig>({
        defaultCurrent: 1,
        defaultPageSize: 10,
        total: 0,
        onChange: setPage,
        showTotal: total => `Total: ${ total }`
    });

    const getData = useCallback(async () => {
        if (service !== null) {
            setLoading(true);
            const res = await service.search(page, { school_id });

            if (isAxiosError(res)) {
                setError((res as AxiosError).message);
            } else {
                const { data } = res as AxiosResponse<IPaginatedData<IUserSchool>>;
                setData(data.data);

                setPaginationInfo(prevState => ({
                    ...prevState,
                    total: data.total
                }));
            }
        }

        setLoading(false);
    }, [service, page, school_id]);

    useEffect(() => {
        // noinspection JSIgnoredPromiseFromCall
        getData();
    }, [getData]);

    return (
        <Fragment>
            { modalContext }

            <Table<IUserSchool>
                rowKey={ u => u.id }
                dataSource={ data }
                loading={ loading }
                pagination={ paginationInfo }
                locale={ {
                    emptyText: error ? (<Empty description={ error }/>) : undefined
                } }
                columns={ [
                    ...columns,
                    {
                        title: 'Acciones',
                        render: (_, record) => (
                            <ActionButtons<IUserSchool>
                                actions={ Object.keys(actionInfo) as RestActions[] }
                                entity={ record }
                                callbacks={ tableActions(authService, modal, setApproveLoading) }
                                getData={ getData }
                            />
                        )
                    }
                ] }
            />
        </Fragment>
    );
};

export default SearchBySchool;