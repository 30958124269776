import React                   from 'react';
import { Breadcrumb, Tooltip } from 'antd';
import { ArrowLeftOutlined }   from '@ant-design/icons';
import { useHistory }          from 'react-router-dom';

type Props = {
    resource?: boolean;
    didacticMap?: boolean;
    activityGuide?: boolean;
    name: string;
}

const BreadCrumbResources: React.FC<Props> = ({ resource, didacticMap, activityGuide, name }) => {

    const history = useHistory();

    return (
        <Breadcrumb className="breadcrumb-resources">
            <Breadcrumb.Item onClick={ history.goBack }>
                <Tooltip title="Regresar">
                    <ArrowLeftOutlined />
                </Tooltip>
            </Breadcrumb.Item>
            <Breadcrumb.Item>{ resource ? 'Recurso: ' : didacticMap ? 'Mapa didactico: ' : activityGuide ? 'Guia de actividades: ' : null }{ name }</Breadcrumb.Item>
        </Breadcrumb>
    );
};

export default BreadCrumbResources;
