import React, { useState, Fragment }                           from 'react';
import { FormEntitiesEnum, IStoreAcademicCharge, LoadingType } from './type';
import GradeService                                            from '../../../../api/services/GradeService';
import SubjectService                                          from '../../../../api/services/SubjectService';
import AcademicChargeService                                   from '../../../../api/services/AcademicChargeService';
import ESchoolAcademicChargeService
                                                               from '../../../../api/services/ESchoolAcademicChargeService';
import { initialLoadingValues }                                from './helpers';
import IAcademicCharge                                         from '../../../../interfaces/entities/IAcademicCharge';
import IGrade                                                  from '../../../../interfaces/entities/IGrade';
import ISubject                                                from '../../../../interfaces/entities/ISubject';
import { handleAxiosError, isAxiosError }                      from '../../../../utils/services';
import { AxiosError, AxiosResponse }                           from 'axios';
import { Button, message }                                     from 'antd';
import ModalAcademicCharge                                     from './Modal';
import useServiceByRef                                         from '../../../../utils/hooks/useServiceByRef';
import IESchoolAcademicCharge
                                                               from '../../../../interfaces/entities/IESchoolAcademicCharge';

type Props = {
    teacher_id: string;
};


const AcademicCharge: React.FC<Props> = ({ teacher_id }) => {
    // STATE CONFIG
    const [loading, setLoading] = useState<LoadingType>(initialLoadingValues);
    const [loadingSyncCharge, setLoadingSyncCharge] = useState<boolean>(false);

    const gradeService = useServiceByRef(GradeService);
    const subjectService = useServiceByRef(SubjectService);
    const academicChargeService = useServiceByRef(AcademicChargeService);
    const academicChargeESchoolService = useServiceByRef(ESchoolAcademicChargeService);

    //STATE
    const [academicCharge, setAcademicCharge] = useState<IAcademicCharge[]>([]);
    const [grades, setGrades] = useState<IGrade[]>([]);
    const [subjects, setSubject] = useState<ISubject[]>([]);
    const [ifUserESchool, setIfUserESchool] = useState<boolean>(true);

    //STATE CREATE TEMPORARY ACADEMIC SUBJECT
    const [newCharge, setNewCharge] = useState<IStoreAcademicCharge[]>([]);

    //STATE - MODAL
    const [showAcademicChargeMdl, setShowAcademicChargeMdlMdl] = useState(false);

    const onAcademicChargeMdlClose = () => {
        setNewCharge([]);
        setShowAcademicChargeMdlMdl(false);
    };
    const toggleAcademicCharge: () => void = () => {
        if (grades !== null && subjects !== null) {
            if (GradeService !== null && SubjectService !== null && teacher_id.trim() !== '') {
                setShowAcademicChargeMdlMdl(true);
                syncAcademicChargeESchool().finally();
            }
        }
    };
    // get Grade
    const getGrades = async () => {
        setLoading(validate => ({ ...validate, [FormEntitiesEnum.grade]: true }));
        if (gradeService.current) {
            const response = await gradeService.current.all();

            if (isAxiosError(response)) {
                const err = response as AxiosError;
                message.error(`Ocurrio un error al obtener los grados: ${ err.message }`);
            } else {
                const { data } = response as AxiosResponse<IGrade[]>;
                setGrades(data);
                setLoading(validate => ({ ...validate, [FormEntitiesEnum.grade]: false }));
            }
        }
    };

    //get subjects
    const getSubjects = async () => {
        setLoading(validate => ({ ...validate, [FormEntitiesEnum.subject]: true }));
        if (subjectService.current) {
            const response = await subjectService.current.all();

            if (isAxiosError(response)) {
                const err = response as AxiosError;
                message.error(`Ocurrio un error al obtener las materias: ${ err.message }`);
            } else {
                const { data } = response as AxiosResponse<ISubject[]>;
                setSubject(data);
                setLoading(validate => ({ ...validate, [FormEntitiesEnum.subject]: false }));
            }
        }
    };

    //get Academics Charge
    const getAcademicCharge = async () => {
        if (academicChargeService.current && teacher_id !== undefined) {
            setLoadingSyncCharge(true);
            const response = await academicChargeService.current.AcademicChargeByTeacher(teacher_id);

            if (isAxiosError(response)) {
                const err = response as AxiosError;
                message.error(`Ocurrio un error al sincronizar la carga acádemica: ${ err.message }`);
            } else {
                const { data } = response as AxiosResponse<IAcademicCharge[]>;
                setAcademicCharge(data);
            }
            setLoadingSyncCharge(false);
        }
    };

    // sync academic charge from e-school
    const syncAcademicChargeESchool = async () => {
        if (academicChargeESchoolService.current && teacher_id !== undefined) {
            setLoadingSyncCharge(true);
            const response = await academicChargeESchoolService.current?.AcademicChargeByTeacher(teacher_id);
            if (isAxiosError(response)) {
                handleAxiosError(response as AxiosError, {
                    404: () => {
                        getSubjects().finally();
                        getGrades().finally();
                        getAcademicCharge().finally();
                        setIfUserESchool(false);
                    },
                    default: () => {
                        //    TODO: define message by token expiration if necessary
                    }
                });
            } else {
                const { data } = response as AxiosResponse<IESchoolAcademicCharge>;
                setIfUserESchool(data.existUserESchool);
                if (data.existUserESchool) {
                    setAcademicCharge(data.academic_charge);
                } else {
                    getSubjects().finally();
                    getGrades().finally();
                    getAcademicCharge().finally();
                }
            }
            setLoadingSyncCharge(false);
        }
    };

    return (
        <Fragment>
            <Button
                onClick={ toggleAcademicCharge }
                type={ 'link' }
                className={ 'btn-charge-academic' }>
                Ver carga académica
            </Button>
            { showAcademicChargeMdl ? (
                <ModalAcademicCharge
                    academicCharge={ academicCharge }
                    grades={ grades }
                    subjects={ subjects }
                    loading={ loading }
                    showAcademicChargeMdl={ showAcademicChargeMdl }
                    onAcademicChargeMdlClose={ onAcademicChargeMdlClose } getAcademicCharge={ getAcademicCharge }
                    teacher_id={ teacher_id }
                    newCharge={ newCharge }
                    setNewCharge={ setNewCharge }
                    ifUserESchool={ ifUserESchool }
                    loadingSyncCharge={ loadingSyncCharge }
                />
            ) : '' }
        </Fragment>
    );
};
export default AcademicCharge;
