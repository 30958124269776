import { IFormFields } from '.';
import FormRules       from '../../../../types/utils/forms/FormRules';

const rules: FormRules<IFormFields> = {
    name: [
        {
            required: true, message: 'El nombre es requerido'
        }
    ],
    last_name: [
        { required: true, message: 'El apellido es requerido' }
    ],
    code: [
        { required: true, message: 'El código de Infraestructura es requerido' }
    ],
    email: [
        {
            required: true,
            type: 'email',
            message: 'El correo electrónico es requerido'
        }
    ],
    gender: [
        { required: true, message: 'El género es requerido' }
    ]
};

export default rules;
