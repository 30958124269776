import React, { useEffect, useState, Fragment }                                                         from 'react';
import {
    useHistory,
    useParams
}                                                                                                       from 'react-router-dom';
import { getDidacticMap }                                                                               from '../../didacticMap/helpers';
import {
    handleAxiosError,
    isAxiosError
}                                                                                                       from '../../../../utils/services';
import { Button, Card, Col, Divider, Form, Input, Modal, notification, Rate, Row, Skeleton, Spin, Tag } from 'antd';
import IDidacticMap
                                                                                                        from '../../../../interfaces/entities/IDidacticMap';
import { useSelector }                                                                                  from 'react-redux';
import { IAppState }                                                                                    from '../../../../interfaces/store/IAppState';
import useServiceByRef
                                                                                                        from '../../../../utils/hooks/useServiceByRef';
import DidacticMapService
                                                                                                        from '../../../../api/services/DidacticMapService';
import moment                                                                                           from 'moment';
import Contents
                                                                                                        from '../../didacticMap/components/contents';
import { useKeycloak }                                                                                  from '@react-keycloak/web';
import IDidacticMapComponent
                                                                                                        from '../../../../interfaces/entities/IDidacticMapComponent';
import { AxiosError, AxiosResponse }                                                                    from 'axios';
import Recommendation
                                                                                                        from '../components/recomendation';
import { IKeycloakUserInfo }                                                                            from '../../../../interfaces/store/states/IAuthState';
import ActionViews
                                                                                                        from '../components/actionViews';
import RateImg
                                                                                                        from '../../../images/rateoff.svg';
import Icon
                                                                                                        from '@ant-design/icons';
import BreadCrumbResources
                                                                                                        from '../../components/BreadCrumbResources';

type QueryParams = {
    didactic_map_id: string
}

const DidacticLetter: React.FC = () => {

    const { didactic_map_id } = useParams<QueryParams>();

    const [didacticMap, setDidacticMap] = useState<IDidacticMap>();
    const [methodologyBeginning, setMethodologyBeginning] = useState<IDidacticMapComponent[]>([]);
    const [methodologyDevelopment, setMethodologyDevelopment] = useState<IDidacticMapComponent[]>([]);
    const [loading, setLoading] = useState<{ print: boolean, copy: boolean, body: boolean }>({
        copy: false,
        print: false,
        body: true
    });

    const readyForAuthRequests = useSelector<IAppState, boolean>(({ axios }) => axios.readyForRequests);
    const userAuth = useSelector<IAppState, IKeycloakUserInfo | null>(({ auth }) => auth.keycloakUserInfo);

    const history = useHistory();
    const service = useServiceByRef(DidacticMapService);
    const { keycloak } = useKeycloak();

    const [rating, setRating] = useState<number>(0);
    const [score, setScore] = useState<number>(0);
    const [favorite, setFavorite] = useState<boolean>(false);

    // ICON IMG RATE
    const FavoriteRate = () => <img src={ RateImg } alt="Icon_rate" />;
    const RateIcon = (data: any) => <Icon component={ FavoriteRate } { ...data } />;

    useEffect(() => {
        return () => {
            Modal.destroyAll();
            setDidacticMap(undefined);
            setMethodologyDevelopment([]);
            setMethodologyBeginning([]);
        };
    }, []);

    useEffect(() => {
        if (didacticMap !== undefined) {
            const { components, resource } = didacticMap;

            if (components) {
                components.forEach((value) => {
                    if (value.component_type?.code.includes('BEGINNING')) {
                        setMethodologyBeginning((prev) => ([...prev, value]));
                    } else {
                        setMethodologyDevelopment((prev) => ([...prev, value]));
                    }
                });
            }
            if (resource) {
                setRating(resource.global_rating);
                setScore(resource.score);
                setFavorite(didacticMap.favorite);
            }
        }
    }, [didacticMap]);

    const copy = async () => {
        setLoading((s) => ({ ...s, copy: true }));
        if (service.current) {
            const response = await service.current.copy(didactic_map_id);

            if (isAxiosError(response)) {
                const err = response as AxiosError;
                handleAxiosError(err, {
                    '500': _ => notification.error({
                        message: 'Ocurrio un error',
                        description: 'No se pudo realizar una copia de la carta didactica seleccionada',
                        duration: 3.5,
                    }),
                    default: _ => notification.error({
                        message: 'Ocurrio un error',
                        description: 'No se pudo realizar una copia de la carta didactica seleccionada',
                        duration: 3.5
                    })
                });
            } else {
                notification.success({
                    message: 'Operación finalizada',
                    description: 'Se ha realizado una copia de la carta didactica, se espera por la espera de aprobación.',
                    duration: 3.5
                });
            }
        }
        setLoading((s) => ({ ...s, copy: false }));
    };

    const print = async () => {
        setLoading((s) => ({ ...s, print: true }));
        if (service.current) {
            const response = await service.current.print(didactic_map_id);

            if (isAxiosError(response)) {
                const err = response as AxiosError;
                handleAxiosError(err, {
                    '500': _ => notification.error({
                        message: 'Ocurrio un error',
                        description: 'No se logro obtener un pdf de la carta didactica',
                        duration: 3.5
                    }),
                    default: _ => notification.error({
                        message: 'Ocurrio un error',
                        description: 'No se logro obtener un pdf de la carta didactica',
                        duration: 3.5
                    })
                });
            } else {

                const { data } = response as AxiosResponse<{ pdf: string }>;

                window.open(data.pdf);

                notification.success({
                    message: 'Operación finalizada',
                    description: 'Se ha realizado la descarga de la carta didactica.',
                    duration: 3.5
                });
            }
        }
        setLoading((s) => ({ ...s, print: false }));
    };

    useEffect(() => {
        if (service.current) {
            if (didactic_map_id !== undefined) {
                if (readyForAuthRequests) {
                    getDidacticMap(didactic_map_id,
                        d => setDidacticMap(d),
                        err => {
                            handleAxiosError(err, {
                                404: () => notification.error({
                                    message: 'Carta didactica no encontrada',
                                    description: 'La carta didactica  no existe.',
                                }),
                                default: () => notification.error({
                                    message: 'Ha ocurrido un error al intentar obtener los datos de la carta didactica seleccionada. Inténtelo más tarde',
                                    onClose: () => history.push('/500')
                                })
                            });
                        },
                        l => setLoading((s) => ({ ...s, body: l })),
                        service.current
                    );
                }
            }
        }
    }, [didactic_map_id, history, service, readyForAuthRequests]);

    const confirmDeleteAction = () => {
        const modal = Modal.info({
            maskClosable: false,
            closable: false,
            okText: <></>,
            icon: <></>,
            className: 'confirm-delete-activity-guide-modal',
            width: '50%',
            content:
                <div style={ { textAlign: 'center' } }>
                    <h2 style={ { color: '#FF5C5C' } }>Eliminar recurso</h2>
                    <p>¿Estás seguro de eliminar la guia de actividades?</p>
                    <Button className={ 'btn-resource-radius btn-confirm btn-resource-copy-guide' }
                            onClick={ () => modal.destroy() }
                    >Regresar</Button>
                    <Button className={ 'btn-resource-radius btn-confirm btn-activity-guide-delete' }
                            onClick={ deleteDidacticMap }
                    >Eliminar</Button>
                </div>

        });
    };

    const deleteDidacticMap = async () => {
        setLoading((s) => ({ ...s, body: true }));
        if (service.current && didacticMap) {
            if (didacticMap.resource?.user?.id === userAuth?.sub) {
                const response = await service.current.delete(didacticMap.id);
                if (isAxiosError(response)) {
                    const err = response as AxiosError;
                    handleAxiosError(err, {
                        403: _ => notification.error({
                            message: 'Ocurrio un error',
                            description: 'No cuenta con los permisos necesarios para eliminar esta guía de actividades'
                        }),
                        409: _ => notification.error({
                            message: 'Ocurrio un error',
                            description: 'La guía de actividades no puede ser eliminada'
                        }),
                        500: error => notification.error({
                            message: 'Ocurrio un error',
                            description: error.message
                        }),
                        default: error => notification.error({
                            message: 'Ocurrio un error',
                            description: error.message
                        })
                    });
                    setLoading((s) => ({ ...s, body: false }));
                } else {
                    notification.success({
                        message: 'Operación finalizada',
                        description: 'La guía seleccionada fue eliminada con éxito. Redirigiendo al inicio.',
                        duration: 1,
                        onClose: () => {
                            setLoading((s) => ({ ...s, body: false }));
                            history.push('/');
                        }
                    });
                }
            } else {
                setLoading((s) => ({ ...s, body: false }));
                notification.error({
                    message: 'Ocurrio un error',
                    description: 'No cuenta con los permisos necesarios para eliminar esta guía de actividades'
                });
            }
        }
    };

    const editDidacticMap = () => {
        setLoading((s) => ({ ...s, body: true }));
        if (didacticMap) {
            if (didacticMap.resource?.user?.id === userAuth?.sub) {
                let prefix = '';
                if (keycloak.hasResourceRole('TEACHER', process.env.REACT_APP_KEYCLOACK_CLIENT_ID)) {
                    prefix = 'teacher';
                } else if (keycloak.hasResourceRole('LXD', process.env.REACT_APP_KEYCLOACK_CLIENT_ID)) {
                    prefix = 'lxd';
                } else if (keycloak.hasResourceRole('ADMIN', process.env.REACT_APP_KEYCLOACK_CLIENT_ID)) {
                    prefix = 'admin';
                }
                setLoading((s) => ({ ...s, body: false }));
                history.push(`/${ prefix }/update/didactic_map/${ didacticMap.id }`);
            } else {
                setLoading((s) => ({ ...s, body: false }));
                notification.error({
                    message: 'Ocurrio un error',
                    description: 'No cuenta con los permisos necesarios para editar esta guía de actividades'
                });
            }
        }
    };

    const goResource = (id: string) => {
        setLoading((s) => ({ ...s, body: true }));
        history.push(`/p/resource/${ id }`);
    };

    return (
        <Row gutter={ [48, 8] }>
            <Col lg={ 16 } md={ 16 } sm={ 24 } xs={ 24 } className="content-view-resource">
                <div className="content-view-info">
                    <Modal
                        title={ null }
                        centered
                        visible={ loading.body }
                        footer={ null }
                        className="loading_modal"
                        maskClosable={ false }
                        closable={ false }
                    >
                        <Spin />
                    </Modal>
                    <BreadCrumbResources didacticMap name={ didacticMap?.resource?.name ?? '' } />
                    <Row gutter={ [24, 24] }>
                        { keycloak.hasResourceRole('ADMIN') || keycloak.hasResourceRole('LXD')
                            ?
                            <Fragment>
                                <Col>
                                    <Button className="btn-review-round" danger
                                            onClick={ confirmDeleteAction }>Eliminar</Button>
                                </Col>
                                { userAuth?.sub === didacticMap?.resource?.user_id && (
                                    <Col>
                                        <Button
                                            className="btn-review-round btn-review-approved"
                                            onClick={ editDidacticMap }>Editar</Button>
                                    </Col>
                                ) }
                            </Fragment>
                            : null
                        }

                        {
                            keycloak.hasResourceRole('TEACHER') && userAuth?.sub === didacticMap?.resource?.user_id
                                ?
                                <Fragment>
                                    <Col>
                                        <Button className="btn-review-round" danger
                                                onClick={ confirmDeleteAction }>Eliminar</Button>
                                    </Col>
                                    <Col>
                                        <Button
                                            className="btn-review-round btn-review-approved"
                                            onClick={ editDidacticMap }>Editar</Button>
                                    </Col>
                                </Fragment>
                                : null
                        }
                    </Row>

                    <h2 className="resource-title">{ didacticMap?.resource?.name }</h2>
                    { didacticMap?.resource?.labels?.map((label, index) => (
                        <Tag className="tag-resource"
                             key={ index }>{ label.name.length > 20 ? label.name.substring(0, 20) + '...' : label.name }
                        </Tag>
                    )) }
                    { keycloak.authenticated && (
                        <Col offset={ 12 } lg={ 12 } md={ 12 } sm={ 24 } xs={ 24 } style={ { textAlign: 'end' } }>
                            <Fragment>
                                <Rate character={ <RateIcon /> }
                                      value={ rating }
                                      disabled={ true } />
                                <span
                                    style={ {
                                        color: '#A0A4A8',
                                        marginLeft: '1em'
                                    } }>Calificaciones { score }</span>
                            </Fragment>
                        </Col>
                    ) }
                    <Divider />
                    <Skeleton active loading={ loading.body } paragraph={ { rows: 10 } } />
                    { !loading.body && (
                        <Fragment>
                            <h3 className="format-text-info teacher-author">{ didacticMap?.resource?.user?.name }</h3>
                            <h3 className="format-text-info created-at-resource"> Creado
                                                                                  el { moment(didacticMap?.created_at).format('D') } de { moment(didacticMap?.created_at).format('MMM') } del { moment(didacticMap?.created_at).format('Y') }</h3>
                            <div className="text-content-view-resource">
                                <h3 className="format-text-info">{ didacticMap?.resource?.description }</h3>
                                <Button type="text"
                                        style={ { color: '#FFF', backgroundColor: '#0066CC', borderRadius: '20px' } }
                                        loading={ loading.print }
                                        onClick={ () => print() }
                                >Imprimir carta</Button>
                                <Button type="text"
                                        style={ {
                                            color: '#00C865',
                                            borderColor: '#00C865',
                                            borderRadius: '20px',
                                            marginLeft: '10px'
                                        } }
                                        onClick={ () => copy() }
                                        loading={ loading.copy }
                                >Copiar carta didactica</Button>
                                <br /><br />
                                <Row gutter={ [48, 8] }>
                                    <Col lg={ 8 } md={ 8 } sm={ 8 } xs={ 8 }>
                                        <Form.Item
                                            label={ <p style={ { fontWeight: 'bold' } }>Grado</p> }
                                            colon={ false }
                                        >
                                            <Input disabled={ true }
                                                   value={ didacticMap?.resource?.unit?.academic_subject?.grade?.name }
                                                   style={ { borderRadius: '10px' } }
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col lg={ 8 } md={ 8 } sm={ 8 } xs={ 8 }>
                                        <Form.Item
                                            label={ <p style={ { fontWeight: 'bold' } }>Materia</p> }
                                            colon={ false }
                                        >
                                            <Input disabled={ true }
                                                   value={ didacticMap?.resource?.unit?.academic_subject?.subject?.name }
                                                   style={ { borderRadius: '10px' } }
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col lg={ 8 } md={ 8 } sm={ 8 } xs={ 8 }>
                                        <Form.Item
                                            label={ <p style={ { fontWeight: 'bold' } }>Unidad</p> }
                                            colon={ false }
                                        >
                                            <Input disabled={ true }
                                                   value={ didacticMap?.resource?.unit?.name }
                                                   style={ { borderRadius: '10px' } }
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col lg={ 8 } md={ 8 } sm={ 8 } xs={ 8 }>
                                        <Form.Item
                                            label={ <p style={ { fontWeight: 'bold' } }>Fecha</p> }
                                            colon={ false }
                                        >
                                            <Input disabled={ true }
                                                   value={ moment(didacticMap?.date).format('Y-MM-D') }
                                                   style={ { borderRadius: '10px' } }
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col lg={ 8 } md={ 8 } sm={ 8 } xs={ 8 }>
                                        <Form.Item
                                            label={ <p style={ { fontWeight: 'bold' } }>Hora</p> }
                                            colon={ false }
                                        >
                                            <Input disabled={ true }
                                                   value={ moment(didacticMap?.hours).format('H:mm') }
                                                   style={ { borderRadius: '10px' } }
                                            />
                                        </Form.Item>
                                    </Col>

                                    <Col lg={ 8 } md={ 8 } sm={ 8 } xs={ 8 }>
                                        <Form.Item
                                            label={ <p style={ { fontWeight: 'bold' } }>Duración</p> }
                                            colon={ false }
                                        >
                                            <Input disabled={ true }
                                                   value={ didacticMap?.duration }
                                                   style={ { borderRadius: '10px' } }
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={ 24 } md={ 24 } sm={ 24 } xs={ 24 } className="resources-wrap">
                                        <Card title={
                                            <Row style={ {
                                                backgroundColor: '#173C7B',
                                                color: 'black !important',
                                                paddingLeft: '10px',
                                                paddingRight: '10px'
                                            } } className="ant-card-head-indicators">
                                                <Col span={ 24 } className="resource-title"
                                                     style={ {
                                                         borderTopRightRadius: '20px',
                                                         borderTopLeftRadius: '20px',
                                                     } }>
                                            <span style={ {
                                                color: '#FFF',
                                                fontSize: '18px',
                                                lineHeight: '24px'
                                            } }>Indicador(es) de logros</span>
                                                </Col>
                                            </Row>
                                        }
                                              className="indicators"
                                        >
                                            {
                                                didacticMap?.resource?.indicators?.map((value) => (
                                                    <p key={ value.id }>
                                                        { value.name }
                                                    </p>
                                                ))
                                            }
                                        </Card>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={ 8 } md={ 8 } sm={ 12 } xs={ 24 }>
                                        <span className="indicator-content-title">Contenido Conceptual</span>
                                        <br />
                                        {
                                            didacticMap?.resource?.indicators !== undefined && (
                                                <Contents
                                                    indicators={ didacticMap?.resource?.indicators }
                                                    content_type={ 'CONCEPTUAL' }
                                                    teacher={ keycloak.hasResourceRole('Teacher', process.env.REACT_APP_KEYCLOACK_CLIENT_ID) }
                                                />
                                            )
                                        }
                                    </Col>
                                    <Col lg={ 8 } md={ 8 } sm={ 12 } xs={ 24 }>
                                        <span className="indicator-content-title">Contenido Procedimental</span>
                                        <br />
                                        {
                                            didacticMap?.resource?.indicators !== undefined && (
                                                <Contents
                                                    indicators={ didacticMap?.resource?.indicators }
                                                    content_type={ 'PROCEDURAL' }
                                                    teacher={ keycloak.hasResourceRole('Teacher', process.env.REACT_APP_KEYCLOACK_CLIENT_ID) }
                                                />
                                            )
                                        }
                                    </Col>
                                    <Col lg={ 8 } md={ 8 } sm={ 12 } xs={ 24 }>
                                        <span className="indicator-content-title">Contenido Actitudinal</span>
                                        <br />
                                        {
                                            didacticMap?.resource?.indicators !== undefined && (
                                                <Contents
                                                    indicators={ didacticMap?.resource?.indicators }
                                                    content_type={ 'ATTITUDINAL' }
                                                    teacher={ keycloak.hasResourceRole('Teacher', process.env.REACT_APP_KEYCLOACK_CLIENT_ID) }
                                                />
                                            )
                                        }
                                    </Col>
                                </Row>
                                <br />
                                <Row>
                                    <Col lg={ 24 } md={ 24 } sm={ 24 } xs={ 24 } className="resources-wrap">
                                        { methodologyBeginning.length > 0 && (
                                            <Card title={
                                                <Row style={ {
                                                    backgroundColor: '#173C7B',
                                                    color: 'black !important',
                                                    paddingLeft: '10px',
                                                    paddingRight: '10px'
                                                } } className="ant-card-head-indicators">
                                                    <Col span={ 24 } className="resource-title"
                                                         style={ {
                                                             borderTopRightRadius: '20px',
                                                             borderTopLeftRadius: '20px',
                                                         } }>
                                            <span style={ {
                                                color: '#FFF',
                                                fontSize: '18px',
                                                lineHeight: '24px'
                                            } }>Metodología <div>Inicio</div> </span>
                                                    </Col>
                                                </Row>
                                            }
                                                  className="methodology"
                                            >
                                                {
                                                    methodologyBeginning?.map((value) => (
                                                        <Card key={ value.id } title={
                                                            <Row style={ {
                                                                color: 'black !important',
                                                                paddingRight: '10px'
                                                            } } className="ant-card-head-component">
                                                                <Col span={ 24 } className="resource-title"
                                                                >
                                            <span style={ {
                                                color: '#000000',
                                                fontSize: '18px',
                                                lineHeight: '24px'
                                            } }>{ value.component_type?.description }</span>
                                                                </Col>
                                                            </Row>
                                                        }
                                                              className="custom-component"
                                                        >
                                                            <div
                                                                dangerouslySetInnerHTML={ { __html: value.value } }
                                                            />
                                                        </Card>
                                                    ))
                                                }
                                            </Card>
                                        ) }
                                    </Col>
                                    <Col lg={ 24 } md={ 24 } sm={ 24 } xs={ 24 } className="resources-wrap">
                                        { methodologyDevelopment.length > 0 && (
                                            <Card title={
                                                <Row style={ {
                                                    backgroundColor: '#173C7B',
                                                    color: 'black !important',
                                                    paddingLeft: '10px',
                                                    paddingRight: '10px'
                                                } } className="ant-card-head-indicators">
                                                    <Col span={ 24 } className="resource-title">
                                                <span style={ {
                                                    color: '#FFF',
                                                    fontSize: '18px',
                                                    lineHeight: '24px'
                                                } }>Metodología <div>Desarrollo</div> </span>
                                                    </Col>
                                                </Row>
                                            }
                                                  className="methodology-development"
                                            >
                                                {
                                                    methodologyDevelopment?.map((value) => (
                                                        <Card key={ value.id } title={
                                                            <Row style={ {
                                                                color: 'black !important',
                                                                paddingRight: '4px'
                                                            } } className="ant-card-head-component">
                                                                <Col span={ 24 } className="resource-title">
                                                            <span style={ {
                                                                color: '#000000',
                                                                fontSize: '18px',
                                                                lineHeight: '24px'
                                                            } }>{ value.component_type?.description }</span>
                                                                </Col>
                                                            </Row>
                                                        }
                                                              className="custom-component"
                                                        >
                                                            <div
                                                                dangerouslySetInnerHTML={ { __html: value.value } }
                                                            />
                                                        </Card>
                                                    ))
                                                }
                                                {
                                                    didacticMap?.related_resource && didacticMap.related_resource.length > 0 && (
                                                        <Fragment>
                                                            <Card title={
                                                                <Row style={ {
                                                                    color: 'black !important',
                                                                    paddingRight: '4px'
                                                                } } className="ant-card-head-component">
                                                                    <Col span={ 24 } className="resource-title">
                                                            <span style={ {
                                                                color: '#000000',
                                                                fontSize: '18px',
                                                                lineHeight: '24px'
                                                            } }>Recursos relacionados</span>
                                                                    </Col>
                                                                </Row>
                                                            }
                                                                  className="custom-component"
                                                            >
                                                                <Row className="resource-container" gutter={ 16 }>
                                                                    {
                                                                        didacticMap.related_resource.map((resource) => (
                                                                            <Col span={ 12 } key={ resource.id }>
                                                                                <Card
                                                                                    title={ resource.unit?.academic_subject?.subject?.name }
                                                                                    className="recommendation-card"
                                                                                    headStyle={ {
                                                                                        backgroundColor: `${ resource.unit?.academic_subject?.subject?.color }`,
                                                                                        borderTopLeftRadius: '0.5em',
                                                                                        borderTopRightRadius: '0.5em'
                                                                                    } }
                                                                                    bordered={ false }
                                                                                    hoverable={ true }
                                                                                    onClick={ () => goResource(resource.id) }
                                                                                >
                                                                                    <div
                                                                                        className={ 'body-card-recommendation' }>
                                                                                        <p className={ 'recommendation-title-card' }>{ resource.name }</p>
                                                                                        <div className="tag-card">
                                                                                            { resource.labels?.map((label, index_) => (
                                                                                                <Tag key={ index_ }
                                                                                                     className="tag-resource">{ label.name.length > 20 ? label.name.substring(0, 20) + '...' : label.name }</Tag>
                                                                                            )) }
                                                                                        </div>
                                                                                    </div>
                                                                                </Card>
                                                                            </Col>
                                                                        ))
                                                                    }
                                                                </Row>
                                                            </Card>
                                                        </Fragment>
                                                    )
                                                }
                                            </Card>
                                        ) }
                                    </Col>
                                </Row>
                            </div>
                        </Fragment>
                    ) }
                    <br />
                    { didacticMap !== undefined && didacticMap.resource !== undefined && (
                        <Col span={ 24 }>
                            {
                                <ActionViews resource={ didacticMap.resource } isFavorite={ favorite }
                                             publicUrl={ '' } setRating={ setRating }
                                             setScore={ setScore } />
                            }
                        </Col>
                    )
                    }
                </div>
            </Col>
            <Col span={ 1 } />
            {
                didacticMap && (
                    <Recommendation
                        rendering={ loading.body }
                        labels={ didacticMap.resource?.labels }
                    />
                )
            }
        </Row>
    );
};
export default DidacticLetter;
