import React                                                                   from 'react';
import { Button, Space, Tooltip }                                              from 'antd';
import actionInfo                                                              from './actionsInfo';
import ButtonGroup                                                             from 'antd/es/button/button-group';
import { ExtraActionCallbacks, RestActionCallbacks, RestActions, TableAction } from './types';
import { DeleteOutlined, EyeOutlined, FormOutlined }                           from '@ant-design/icons';

type Props<E> = {
    callbacks: RestActionCallbacks<E> | ExtraActionCallbacks<E>
    actions: RestActions[]
    entity: E
    getData: () => Promise<any>
}

function ActionButtons<E>(props: Props<E>) {
    const { callbacks, actions, entity, getData } = props;

    return (
        <ButtonGroup className="entity-table-action-button-group">
            <Space>
                { Object.keys(callbacks).filter(k => actions.indexOf(k as RestActions) === -1).map((c, i) => {
                    const extraAction = (callbacks as ExtraActionCallbacks<E>)[c];

                    return (
                        <Button
                            key={ i }
                            type="primary"
                            shape="circle"
                            danger={ extraAction.danger ?? false }
                            title={ extraAction.title }
                            onClick={ () => extraAction.callback(entity, getData) }
                        >
                            { extraAction.content }
                        </Button>
                    );
                }) }

                { actions.map((k, i) => {
                    const action = actionInfo[k], callback = callbacks[k] as TableAction<E>;

                    if (callback !== undefined) {
                        return (
                            <Tooltip title={ action.content }>
                                <Button
                                    key={ i }
                                    size={ 'large' }
                                    shape="circle"
                                    onClick={ () => callback(entity, getData) }
                                    icon={ action.content === 'Detalles' ?
                                        <EyeOutlined style={ { color: '#73DFE7' } } />
                                        : action.content === 'Modificar'
                                            ? <FormOutlined style={ { color: '#3D63E7' } } />
                                            : action.content === 'Eliminar' ?
                                                <DeleteOutlined style={ { color: '#FF5C5C' } } /> : null }
                                />
                            </Tooltip>
                        );
                    }

                    return null;
                }) }
            </Space>
        </ButtonGroup>
    );
}

export default ActionButtons;
