import React, { Dispatch, Fragment, SetStateAction, useEffect, useState } from 'react';
import { Input, Button, Select, Row, Col, message, Form, Grid }           from 'antd';
import IGrade                                                             from '../../../interfaces/entities/IGrade';
import ISubject                                                           from '../../../interfaces/entities/ISubject';
import GradeService                                                       from '../../../api/services/GradeService';
import SubjectService                                                     from '../../../api/services/SubjectService';
import { useSelector }                                                    from 'react-redux';
import { IFormFields, LoadingFormType }                                   from './types';
import { initialLoadingValues, initialDisabledValues }                    from './helpers';
import { handleAxiosError, isAxiosError }                                 from '../../../utils/services';
import { AxiosError, AxiosResponse }                                      from 'axios';
import IResourceType
                                                                          from '../../../interfaces/entities/IResourceType';
import ResourceTypeService
                                                                          from '../../../api/services/ResourceTypeService';
import { IAppState }                                                      from '../../../interfaces/store/IAppState';
import keycloak                                                           from '../../../keycloak.config';
import useService                                                         from '../../../utils/hooks/useService';

const { Search } = Input;
const { useBreakpoint } = Grid;

type Props = {
    home?: boolean;
    resources?: boolean;
    setParamsSearch?: Dispatch<SetStateAction<object | undefined>>;
    setActionResource?: Dispatch<SetStateAction<boolean>>;
};

const Searcher: React.FC<Props> = ({
                                       home, resources, setParamsSearch, setActionResource
                                   }) => {

    const [form] = Form.useForm();
    const screens = useBreakpoint();

    //Services
    const gradeService = useService(GradeService);
    const subjectService = useService(SubjectService);
    const resourcesTypesService = useService(ResourceTypeService);

    const [showFilters, setShowFilters] = useState<boolean>(false);
    const [grades, setGrades] = useState<IGrade[]>([]);
    const [subjects, setSubjects] = useState<ISubject[]>([]);
    const [resourceTypes, setResourceTypes] = useState<IResourceType[]>([]);

    const [loading, setLoading] = useState<LoadingFormType>(initialLoadingValues);
    const [disabled, setDisabled] = useState<LoadingFormType>({ ...initialDisabledValues });

    const [current, setCurrent] = useState<boolean>(false);
    const [flag, setFlag] = useState<boolean>(false);

    const schoolID = useSelector<IAppState, string | null>(({ auth }) => auth.selected_school_id);

    useEffect(() => {
        if (current) {
            const getGrades = async () => {
                if (current && flag && gradeService !== null && grades.length < 1) {
                    setLoading((s) => ({ ...s, grades: true }));
                    const response = await gradeService.all();
                    if (isAxiosError(response)) {
                        handleAxiosError(response as AxiosError, {
                            404: () => {
                                const error = response as AxiosError;
                                message.error(`Ocurrio un error al obtener los grados: ${ error.message }`);
                            },
                            default: () => {
                                //    TODO: define message by token expiration if necessary
                            }
                        });
                    } else {
                        const { data } = response as AxiosResponse<IGrade[]>;
                        setGrades(data);
                        setDisabled(() => ({
                            ...initialDisabledValues,
                            subjects: false,
                        }));
                    }
                    setLoading((s) => ({ ...s, grades: false }));
                } else {
                    setFlag(true);
                }
            };

            const getSubjects = async () => {
                if (current && flag && subjectService !== null && subjects.length < 1) {
                    setLoading((s) => ({ ...s, subjects: true }));
                    const response = await subjectService.all();
                    if (isAxiosError(response)) {
                        handleAxiosError(response as AxiosError, {
                            404: () => {
                                const error = response as AxiosError;
                                message.error(`Ocurrio un error al obtener los Materias: ${ error.message }`);
                            },
                            default: () => {
                                //    TODO: define message by token expiration if necessary
                            }
                        });
                    } else {
                        const { data } = response as AxiosResponse<ISubject[]>;
                        setSubjects(data);
                        setDisabled(() => ({
                            ...initialDisabledValues,
                            grades: false,
                            subjects: false,
                        }));
                    }
                    setLoading((s) => ({ ...s, subjects: false }));
                }
            };

            const getResourcesTypes = async () => {
                if (current && flag && resourcesTypesService !== null && resourceTypes.length < 1) {
                    setLoading((s) => ({ ...s, resource_types: true }));
                    const response = await resourcesTypesService.getAllForHome();
                    if (isAxiosError(response)) {
                        handleAxiosError(response as AxiosError, {
                            404: () => {
                                const error = response as AxiosError;
                                message.error(`Ocurrio un error al obtener los tipos de recursos: ${ error.message }`);
                            },
                            default: () => {
                                //    TODO: define message by token expiration if necessary
                            }
                        });
                    } else {
                        const { data } = response as AxiosResponse<IResourceType[]>;
                        setResourceTypes(data);
                        setDisabled(() => ({
                            ...initialDisabledValues,
                            grades: false,
                            subjects: false,
                            resource_types: false
                        }));
                    }
                    setLoading((s) => ({ ...s, resource_types: false }));
                }
            };

            getGrades().finally();
            getSubjects().finally();

            if (home || resources) {
                getResourcesTypes().finally();
            }

        } else {
            setCurrent(true);
        }
    }, [current, flag, gradeService, subjectService, resourcesTypesService, home, resources, grades, subjects, resourceTypes]);

    const suffix = (
        <Fragment>
            <Button onClick={ () => setShowFilters((val) => !val) } type="link"
                    style={ { padding: 0, height: 'min-content' } }>Filtros</Button>
        </Fragment>
    );

    const onFinish = async (values: IFormFields) => {
        const { search, grades, subjects, resource_types } = values;
        let data: object = {};
        if (search !== undefined && search !== '') {
            data = { ...data, search };
        }
        if (grades !== undefined && grades.length > 0) {
            data = { ...data, grades };
        }
        if (subjects !== undefined && subjects.length > 0) {
            data = { ...data, subjects };
        }
        if (resource_types !== undefined && resource_types.length > 0) {
            data = { ...data, resource_types };
        }
        if (Object.keys(data).length !== 0) {
            if (setParamsSearch) {
                if (keycloak.authenticated && keycloak.hasResourceRole('TEACHER') && schoolID !== null) {
                    data = { ...data, school_id: schoolID };
                }
                setParamsSearch(data);
                if (setActionResource !== undefined) {
                    setActionResource(true);
                }
            }
        } else {
            filterClear().finally();
        }
    };

    const filterClear = async () => {
        if (setParamsSearch) {
            setParamsSearch(undefined);
            if (setActionResource !== undefined) {
                setActionResource(true);
            }
        }
    };

    const sizeInputSearch = () => {
        if ((screens.xs || screens.sm) && !screens.md) {
            return '100%';
        } else {
            return '70%';
        }
    };

    const cleanFilters = () => {
        form.resetFields();
        filterClear().finally();
    };

    return (
        <Fragment>
            <Form
                form={ form }
                className={ 'search-container' }
                onFinish={ onFinish }
            >
                <Form.Item
                    label={ <h1 className="header-search">¿Qué tema estas buscando hoy?</h1> }
                    labelCol={ {
                        span: 24,
                        style: { textAlign: 'center', paddingTop: '3vh', paddingBottom: 50, marginBottom: '1%' }
                    } }
                    wrapperCol={ { span: 24, style: { textAlign: 'center' } } }
                    name={ 'search' }
                    rules={ [
                        {
                            whitespace: true,
                            message: 'No se permiten solo espacios en blanco, por favor ingrese un nombre valido'
                        }
                    ] }
                >
                    <Search
                        placeholder="Busca un tema, recurso , materia o termino que te interece"
                        className="global-search"
                        allowClear
                        enterButton="Buscar"
                        suffix={ suffix }
                        onSearch={ form.submit }
                        style={ { width: sizeInputSearch() } }
                    />
                </Form.Item>
                { showFilters && (
                    <Row gutter={ [32, 24] } className={ showFilters ? 'filter' : 'filter-hidden' }>
                        <Col
                            xs={ 24 } sm={ 24 } md={ { span: 11 } }
                            lg={ { span: resources || home ? 4 : 6, offset: 4 } }
                            xl={ { span: resources || home ? 4 : 6, offset: 4 } }
                        >
                            <Form.Item
                                name={ 'grades' }
                            >
                                <Select
                                    allowClear
                                    showArrow
                                    showSearch
                                    mode="multiple"
                                    style={ { width: '100%' } }
                                    placeholder="Grados"
                                    options={ grades.map((value) => ({ value: value.id, label: value.name })) }
                                    getPopupContainer={ trigger => trigger.parentNode }
                                    optionFilterProp="label"
                                    className="filters"
                                    loading={ loading.grades }
                                    disabled={ disabled.grades }
                                />
                            </Form.Item></Col>
                        <Col
                            xs={ 24 } sm={ 24 } md={ { span: 11 } }
                            lg={ { span: resources || home ? 4 : 6 } }
                            xl={ { span: resources || home ? 4 : 6 } }
                        >
                            <Form.Item
                                name={ 'subjects' }
                            >
                                <Select
                                    allowClear
                                    showArrow
                                    showSearch
                                    mode="multiple"
                                    className="filters"
                                    style={ { width: '100%' } }
                                    placeholder="Materia"
                                    optionLabelProp={ 'label' }
                                    options={ subjects.map((value) => ({ value: value.id, label: value.name })) }
                                    getPopupContainer={ trigger => trigger.parentNode }
                                    optionFilterProp="label"
                                    loading={ loading.subjects }
                                    disabled={ disabled.subjects }
                                />
                            </Form.Item>
                        </Col>
                        { resources || home
                            ?
                            <Col
                                xs={ 24 } sm={ 24 } md={ 11 } lg={ { span: resources || home ? 4 : 6 } }
                                xl={ { span: resources || home ? 4 : 6 } }
                            >
                                <Form.Item
                                    name={ 'resource_types' }
                                >
                                    <Select
                                        allowClear
                                        showArrow
                                        showSearch
                                        mode="multiple"
                                        className="filters"
                                        style={ { width: '100%' } }
                                        placeholder="Tipo de recurso"
                                        optionLabelProp={ 'label' }
                                        options={ resourceTypes.map((value) => ({
                                            value: value.id,
                                            label: value.name
                                        })) }
                                        getPopupContainer={ trigger => trigger.parentNode }
                                        optionFilterProp="label"
                                        loading={ loading.resource_types }
                                        disabled={ disabled.resource_types }
                                    />
                                </Form.Item
                                >
                            </Col>
                            : ''
                        }
                        <Col
                            xs={ 24 } sm={ 24 } md={ { span: 12 } } lg={ { span: 6 } }
                            xl={ { span: 5 } } xxl={ { span: 4 } }
                        >
                            <Row gutter={ [16, 24] }>
                                <Col xs={ 12 } sm={ 12 } md={ { span: 11 } } lg={ { span: 8 } }
                                     xl={ { span: 8 } }>
                                    <Form.Item
                                    >
                                        <Button
                                            shape="round"
                                            type="primary"
                                            htmlType="submit"
                                            block={ !screens.lg }
                                        >
                                            filtrar
                                        </Button>

                                    </Form.Item>
                                </Col>
                                <Col xs={ 12 } sm={ 12 } md={ { span: 11 } } lg={ { span: 8 } }
                                     xl={ { span: 8 } }>
                                    <Button
                                        shape="round"
                                        onClick={ cleanFilters }
                                        block={ !screens.lg }
                                    >
                                        limpiar
                                    </Button>
                                </Col>
                            </Row>

                        </Col>
                        {/*<Col*/ }
                        {/*    xs={ 24 } sm={ 24 } md={ { span: 11 } } lg={ { span: 2 } }*/ }
                        {/*    xl={ { span: 1 } }*/ }
                        {/*>*/ }
                        {/*    <Form.Item*/ }
                        {/*    >*/ }
                        {/*    <Button*/ }
                        {/*        shape="round"*/ }
                        {/*        onClick={ cleanFilters }*/ }
                        {/*    >*/ }
                        {/*        limpiar*/ }
                        {/*    </Button>*/ }
                        {/*    </Form.Item>*/ }
                        {/*</Col>*/ }
                    </Row>
                ) }
            </Form>
        </Fragment>
    );
};
export default Searcher;
