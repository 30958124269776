export enum ViewEntitiesEnum {
    grades = 'grades',
    academic_subjects = 'academic_subjects',
    academic_charges = 'academic_charges',
    form = 'form',
    default_data = 'default_data'
}

export type ErrorsType = {
    [k in ViewEntitiesEnum]: string | undefined
}

type FormFieldsType = {
    grade_id: string
    academic_subject_id: string
    academic_charge_id: string
    total_hours: number
}

type FormAssignmentsType = {
    [k: string]: number
}

export type FormValuesType = FormFieldsType & FormAssignmentsType;

export type SearchAcademicSubject = {
    grade_id: number,
    school_id: string | null
}
