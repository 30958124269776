import React, { Fragment } from 'react';
import LayoutRoute         from '../LayoutRoute';
import PublicLayout        from '../../../views/layouts/Public';
import { RouteProps }      from 'react-router-dom';
import LayoutResource      from '../../../views/layouts/Resource';

interface Props extends RouteProps {
    resource: boolean
}

const PublicRoute: React.FC<Props> = ({ ...rest }) => {
    return (
        <Fragment>
            <LayoutRoute
                { ...rest }
                Layout={ rest.resource ? LayoutResource : PublicLayout }
            />
        </Fragment>
    );
};
export default PublicRoute;