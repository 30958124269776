import { Button, Col, Row, message, notification, Popconfirm } from 'antd';
import React, { Dispatch, Fragment, SetStateAction, useState } from 'react';
import Icon                                                    from '@ant-design/icons';
import FavoriteOffImg                                          from '../../../images/favorite_icon_off.svg';
import FavoriteOnImg                                           from '../../../images/favorite_icon.png';
import { isAxiosError }                                        from '../../../../utils/services';
import { AxiosError }                                          from 'axios';
import useServiceByRef
                                                               from '../../../../utils/hooks/useServiceByRef';
import ResourceService
                                                               from '../../../../api/services/ResourceService';
import RatingAction                                            from './ratingAction';
import IResource                                               from '../../../../interfaces/entities/IResource';
import { useKeycloak }                                         from '@react-keycloak/web';

type Props = {
    resource: IResource;
    isFavorite: boolean;
    publicUrl: string;
    setRating: Dispatch<SetStateAction<number>>;
    setScore: Dispatch<SetStateAction<number>>;
}

const ActionViews: React.FC<Props> = ({ resource, isFavorite, publicUrl, setRating, setScore }) => {

    const resourceService = useServiceByRef(ResourceService);
    const { keycloak } = useKeycloak();
    const [favorite, setFavorite] = useState<boolean>(isFavorite);
    const [actionFavorite, setActionFavorite] = useState<boolean>(false);
    const [actionUrlPublic, setActionUrlPublic] = useState<boolean>(false);
    const [actionUrl, setActionUrl] = useState<boolean>(false);


    const FavoriteOffImgICon = () => <img className="IconExport" src={ FavoriteOffImg } alt="Icon_favorite" />;
    const FavoriteOffIcon = (data: any) => <Icon component={ FavoriteOffImgICon } { ...data } />;

    const FavoriteOnImgIcon = () => <img className="IconExport" src={ FavoriteOnImg } alt="Icon Favorite On" />;
    const FavoriteOnIcon = () => <Icon component={ FavoriteOnImgIcon } />;

    const changeFavorite = async () => {
        if (resourceService.current && resource !== undefined) {
            setActionFavorite(true);
            const params = { resource_id: resource.id };
            const response = favorite ? await resourceService.current?.RemoveFavoriteResource(resource.id) : await resourceService.current?.AddFavoriteResource(params);
            if (isAxiosError(response)) {
                const err = response as AxiosError;
                message.error(`Ocurrio un error al ${ favorite ? 'remover' : 'agregar' } el recurso a favorito: ${ err.message }`);
                setActionFavorite(false);
            } else {
                notification.success({
                    duration: 2,
                    message: 'Operación finalizada',
                    description: `Recurso ${ favorite ? 'removido de' : 'agregado a' } favoritos`
                });
                setFavorite(!favorite);
                setActionFavorite(false);
            }
        }
    };

    const getPublicUrl = async () => {
        if (resourceService.current && resource !== undefined) {
            setActionUrlPublic(true);
            await navigator.clipboard.writeText(publicUrl);
            notification.success({
                duration: 2,
                message: 'Operación finalizada',
                description: `Url publica copiada`
            });
            setActionUrlPublic(false);
        }
    };

    const copyPrivateUrl = async () => {
        setActionUrl(true);
        const url = window.location.href;
        await navigator.clipboard.writeText(url);
        notification.success({
            duration: 2,
            message: 'Operación finalizada',
            description: `Url copiada`
        });
        setActionUrl(false);
    };

    return (
        <Row gutter={ [8, 24] } className="container-actions">
            <Fragment>
                <Col xs={ 24 } sm={ 24 } md={ 24 } lg={ 12 } xl={ 12 }>
                    { resource !== undefined && keycloak.authenticated && (
                        <RatingAction
                            resource={ resource }
                            setRating={ setRating }
                            setScore={ setScore }
                        />
                    ) }
                </Col>
                <Col xs={ 24 } sm={ 24 } md={ 24 } lg={ 12 } xl={ 12 } className={ 'col-btn-action' }>
                    <Row gutter={ [24, 8] }>
                        <Col xs={ 6 } sm={ 4 } md={ 8 } lg={ 8 } xl={ 8 }>
                            <Popconfirm
                                title={ `¿Estas seguro de ${ favorite ? 'remover el recurso de' : 'agregar el recurso a' } favoritos ?` }
                                onConfirm={ changeFavorite }
                                okText="Si"
                                cancelText="No"
                            >
                                <Button
                                    loading={ actionFavorite }
                                    className={ `btn-size btn-resource-text  ${ favorite ? 'btn-is-favorite' : 'btn-resource-action' }` }
                                    icon={ favorite ? <FavoriteOnIcon /> : <FavoriteOffIcon /> }
                                    type={ 'text' }
                                >
                                    { 'Favoritos' }
                                </Button>
                            </Popconfirm>
                        </Col>
                        { publicUrl.length > 0 && (
                            <Col xs={ 10 } sm={ 6 } md={ 8 } lg={ 8 } xl={ 8 }>
                                <Button
                                    loading={ actionUrlPublic }
                                    onClick={ getPublicUrl }
                                    className={ 'btn-size btn-resource-text btn-resource-radius btn-resource-share' }
                                >{ 'Compartir con mi clase' }</Button>
                            </Col>
                        ) }
                        <Col xs={ 6 } sm={ 5 } md={ 8 } lg={ 8 } xl={ 8 }>
                            <Button
                                loading={ actionUrl }
                                onClick={ copyPrivateUrl }
                                className={ 'btn-size btn-pr btn-resource-text btn-resource-action btn-resource-radius' }
                            >{ 'Copiar URL' }</Button>
                        </Col>
                    </Row>
                </Col>
            </Fragment>
        </Row>
    );
};

export default ActionViews;
