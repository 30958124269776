import { routeType }        from '../../types/routes';
import SchoolHome           from '../../views/admin/schools';
import ApproveUsersHome     from '../../views/admin/users/approve-users';
import ProfileAdmin         from '../../views/admin/profile';
import Resource             from '../../views/resource/show/resource';
import EditResource         from '../../views/resource/resource/edit';
import EditDidacticMap      from '../../views/resource/didacticMap/edit';
import DidacticMap          from '../../views/resource/show/didacticLetter';
import NewResource          from '../../views/resource/resource/create';
import NewDidacticMap       from '../../views/resource/didacticMap';
import StudentsGuide        from '../../views/resource/show/ActivityGuide';
import React                from 'react';
import FavoriteResources    from '../../views/resource/favorite';
import ActivityGuidesHome   from '../../views/activity_guides/Home';
import CreateActivityGuides from '../../views/activity_guides/CreateActivityGuides';
import UpdateActivityGuides from '../../views/activity_guides/UpdateActivityGuides';
import HomePlanning         from '../../views/plannings/Home';
import CreatePlanning       from '../../views/plannings/CreatePlanning';
import UpdatePlanning       from '../../views/plannings/UpdatePlanning';
import AssociatedResources  from '../../views/plannings/components/AssociatedResources';

const routes: routeType[] = [
    {
        path: '',
        component: ProfileAdmin
    },
    {
        path: 'users/approve',
        component: ApproveUsersHome
    },
    {
        path: 'schools',
        component: SchoolHome
    },
    {
        path: 'update/didactic_map/:didactic_map_id',
        component: EditDidacticMap
    },
    {
        path: 'new/didactic_map/',
        component: NewDidacticMap
    },
    {
        path: 'didactic_map/:didactic_map_id',
        component: DidacticMap
    },
    {
        path: 'new/resource',
        component: NewResource
    },
    {
        path: 'resource/:resource_id',
        component: Resource
    },
    {
        path: 'update/resource/:resource_id',
        component: EditResource
    },
    {
        path: 'activity_guide/s/:activity_guide_code',
        component: () => <StudentsGuide publicGuide={ false } />
    },
    {
        path: 'favorite/resource',
        component: FavoriteResources
    },
    {
        path: 'activity_guides',
        component: ActivityGuidesHome
    },
    {
        path: 'activity_guides/create/:draft_id?',
        component: () => <CreateActivityGuides />
    },
    {
        path: 'activity_guides/edit/:activity_guide_id',
        component: () => <UpdateActivityGuides />
    },
    {
        path: 'plannings/',
        component: HomePlanning
    },
    {
        path: 'plannings/new',
        component: CreatePlanning
    },
    {
        path: 'plannings/edit/:planning_id',
        component: UpdatePlanning
    },
    {
        path: 'plannings/associated/resources/:unit_id',
        component: AssociatedResources
    },
];
export default routes;
