import { PlanningCategoriesTypes } from '../../../types/entities/plannings';
import { AssignmentType }          from '../../../interfaces/entities/IAnnualPlanning';
import { monthsValuesType }        from './types';
import { months, MonthsTypes }     from '../../../types/entities/months';

export const removeZerosFromDecimal = (dev: number) => !isNaN(dev)
    ? (dev - Math.floor(dev)) !== 0 ? dev.toFixed(2) : dev.toString()
    : '0';

export const getAssignmentsForAPI = (assignmentValues: [string, number][]) => assignmentValues.reduce(
    (sum, [field, value]) => {
        const [, cat, month] = field.split('.');

        if (!sum.hasOwnProperty(cat)) {
            sum[cat as PlanningCategoriesTypes] = months.reduce(
                (a, b) => Object.assign(a, { [b]: 0 }), {}
            ) as monthsValuesType;
        }

        const categoryObject = sum[cat as PlanningCategoriesTypes];
        categoryObject[month as MonthsTypes] = value;

        return Object.assign(sum, {
            [cat as PlanningCategoriesTypes]: categoryObject
        });
    }, {} as AssignmentType
);