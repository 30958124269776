import React, { Dispatch, Fragment, SetStateAction, useState } from 'react';
import { Button, Col, Input, notification, Row }               from 'antd';
import ImageFileType                                           from '../../../../images/type-resource/file-text.svg';
import { DeleteTwoTone, DownloadOutlined }                     from '@ant-design/icons';
import IRecord                                                 from '../../../../../interfaces/entities/IRecord';
import ReactPlayer                                             from 'react-player';

type Props = {
    oldRecord: IRecord[];
    setOldRecord: Dispatch<SetStateAction<IRecord[]>>;
};

const RecordsOld: React.FC<Props> = ({ oldRecord, setOldRecord }) => {

    const [nameTemporal, setNameTemporal] = useState<string>('');

    const EditNameRecord = (e: any) => {
        setNameTemporal(e.target.value);
    };

    const handleActiveFile = (id: string) => {
        if (oldRecord !== null) {
            const fileSelected = oldRecord.find(data => data.id === id);
            if (fileSelected !== undefined) {
                const newData = oldRecord.filter(data => data.id !== id);
                const data = [...newData, {
                    id: fileSelected.id,
                    name: fileSelected.name,
                    file_url: fileSelected.file_url,
                    external_link: fileSelected.external_link,
                    resource_id: fileSelected.resource_id,
                    status_id: fileSelected.status_id === 5 ? 1 : 5
                }];

                setOldRecord(data);
                setNameTemporal('');
            }
        }
    };

    const removeFileList = (id: string) => {
        if (id.trim() !== null) {
            const newData = oldRecord.filter(data => data.id !== id);
            setOldRecord(newData);
        }
    };

    const handleEditFile = (id: string) => {
        if (oldRecord !== null && id !== null) {
            const fileSelected = oldRecord.find(data => data.id === id);
            if (fileSelected !== undefined) {
                const extension = getExtensionFile(fileSelected.name);
                const name = getNameFile(nameTemporal);
                if (name !== undefined && name.length > 0) {
                    fileSelected.name = `${ name }.${ extension }`;
                    setNameTemporal('');
                } else {
                    messageErrorName();
                }
            }
        } else {
            messageErrorName();
        }
    };

    const messageErrorName = () => {
        notification['error']({
            message: 'Ocurrio un problema',
            description:
                'Porfavor ingresa un nombre valido para el archivo.',
        });
    };

    const getNameFile = (name: string) => {
        const path_split = name.split('.');
        return path_split.shift();
    };

    const getExtensionFile = (name: string) => {
        const path_split = name.split('.');
        return path_split.pop();
    };

    return (
        <Fragment>
            { oldRecord !== null && (
                oldRecord.map((file) => (
                    <Row key={ file.id }
                         className={ file.status_id === 5 ? 'active-edit-file' : '' }
                         justify="center"
                    >
                        { file.status_id === 5 ?
                            !file.external_link
                                ?
                                <Fragment>
                                    <Col span={ 23 }>
                                        <h2 className="title-sub-1">Renombrar archivo</h2>
                                    </Col>
                                    <Col span={ 23 } className="rename-file">
                                        <Input
                                            onChange={ EditNameRecord }
                                            className="input-resource"
                                            placeholder="Renombrar archivo"
                                            defaultValue={ file.name }
                                        />
                                    </Col>
                                </Fragment>
                                :
                                <Fragment>
                                    <Col span={ 16 }>
                                        <ReactPlayer
                                            style={ { borderBottomRightRadius: '16px' } }
                                            url={ file.file_url }
                                            width="100%"
                                            height="40vh"
                                        />
                                    </Col>
                                </Fragment>
                            : null
                        }
                        <Col xs={ 20 } sm={ 23 } md={ 23 } lg={ 23 } xl={ 23 } onClick={ () => {
                            handleActiveFile(file.id);
                        } }>
                            <div className="list-item-file">
                                <img src={ ImageFileType } className="img-type-file" alt="" /> { file.name }
                            </div>
                        </Col>
                        { file.status_id === 5 ?
                            <Fragment>
                                <Col xs={ 1 } sm={ 1 } md={ 1 } lg={ 1 } xl={ 1 }>
                                    <div className="btn-action-file-remove">
                                        <Button type="primary"
                                                target="_blank"
                                                href={ file.file_url }
                                                className="record-download"
                                                icon={ <DownloadOutlined /> }>
                                        </Button>
                                    </div>
                                </Col>
                                <Col span={ 23 }>
                                    <Button onClick={ () => {
                                        handleEditFile(file.id);
                                    } }
                                            className="btn-primary-resources" shape="round" block>Guardar</Button>
                                </Col>
                            </Fragment>
                            :
                            <Fragment>
                                <Col xs={ 1 } sm={ 1 } md={ 1 } lg={ 1 } xl={ 1 }>
                                    <div className="btn-action-file-remove">
                                        <Button onClick={ () => {
                                            removeFileList(file.id);
                                        } } type="text" icon={ <DeleteTwoTone /> } />
                                    </div>
                                </Col>
                            </Fragment>
                        }
                    </Row>
                ))
            )
            }
        </Fragment>
    );
};
export default RecordsOld;
