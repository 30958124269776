import FormRules             from '../../../../types/utils/forms/FormRules';
import { IFormFieldsSchool } from './types';

const rules: FormRules<IFormFieldsSchool> = {
    infrastructure_code: [
        {
            required: true,
            message: 'El código de infraestructura es requerido'
        },
        {
            whitespace: true,
            message: 'No se permiten solo espacios en blanco, por favor ingrese un código de infraestructura valido'
        }
    ],
    odoo_code: [
        {
            required: true,
            message: 'El código de ODOO es requerido'
        },
        {
            whitespace: true,
            message: 'No se permiten solo espacios en blanco, por favor ingrese un código de ODOO valido'
        }
    ],
    name: [
        {
            required: true,
            message: 'El nombre del colegio es requerido'
        },
        {
            whitespace: true,
            message: 'No se permiten solo espacios en blanco, por favor ingrese un nombre valido'
        }
    ],
    public_content: [
        {
            required: true,
            message: 'La disponibilidad del cotenido es requerido'
        },
    ]
};
export default rules;
