import { PlanningCategoriesTypes } from './plannings';
import { MonthsTypes }             from './months';

export type CategoryType = {
    category: PlanningCategoriesTypes
    name: string
    disabled: boolean
    total: number
    data?: {
        [k in MonthsTypes]: number
    }
}

const categories: CategoryType[] = [
    {
        category: 'LEC',
        name: 'Días lectivos por mes',
        disabled: false,
        total: 0
    },
    {
        category: 'EXT',
        name: 'Días para actividades extracurriculares',
        disabled: false,
        total: 0
    },
    {
        category: 'DEV',
        name: 'Dias para desarrollo',
        disabled: true,
        total: 0
    },
    {
        category: 'RES',
        name: 'Horas clases por mes',
        disabled: true,
        total: 0
    },
];

export default categories;