import React, { Fragment }              from 'react';
import { Button, Col, Row, Typography } from 'antd';
import { useHistory, useParams }        from 'react-router-dom';
import Img401                           from '../images/errors/401.svg';
import { useKeycloak }                  from '@react-keycloak/web';

const { Text, Title } = Typography;

const Unauthenticated: React.FC = () => {
    const history = useHistory();
    const { redirect } = useParams<{ redirect?: string }>();
    const { keycloak } = useKeycloak();

    return (
        <Fragment>
            <Row gutter={ [24, 8] } className="row-errors">
                <Col span={ 24 }>
                    <img src={ Img401 } alt="401" />
                </Col>
                <Col span={ 24 }>
                    <p><Title>¡Oooh! Algo a salido mal</Title></p>
                    <p><Title level={ 4 }>
                        <Text type="secondary">No tienes permiso para acceder a la sección especificada.
                        </Text>
                    </Title></p>
                </Col>
                <Col span={ 12 } offset={ 6 }>
                    <Row gutter={ [24, 8] }>
                        { keycloak.authenticated
                            ?
                            <Col span={ 24 }>
                                <Button type={ 'primary' } shape={ 'round' } block onClick={ () => {
                                    history.goBack();
                                } }>
                                    Regresar
                                </Button>
                            </Col>
                            :
                            <Col span={ 24 }>
                                <Button type={ 'primary' } shape={ 'round' } block onClick={ () =>
                                    keycloak.login({
                                        redirectUri: window.location.origin + redirect?.split('-').join('/') ?? '',
                                    })
                                }>
                                    Login
                                </Button>
                            </Col>
                        }
                    </Row>
                </Col>
            </Row>
        </Fragment>
    );
};

export default Unauthenticated;
