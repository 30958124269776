import { Moment } from 'moment';

export interface IFormFields {
    name: string,
    description: string,
    unit_id: string,
    date: string,
    helper_date?: Moment,
    duration: number,
    helpers_duration?: Moment,
    hours: string,
    helper_time?: Moment,
    template: boolean,
    resource_state_id: number,
    labels: string[],
    indicators: string[],
    related_resource?: string[],
    components?: { key: string, value: string }[],
    formComponents?: { [k in ComponentFormEntitiesEnum]: string },
    school_id?: string
}

export type InsertResponse = {
    data: { resource_state_id: number },
}

export enum FormEntitiesEnum {
    grade = 'grade',
    subject = 'subject',
    indicator = 'indicator',
    unit = 'unit',
    form = 'form'
}

export enum ComponentFormEntitiesEnum {
    'METHODOLOGY.BEGINNING.FEEDBACK' = 'METHODOLOGY.BEGINNING.FEEDBACK',
    'METHODOLOGY.BEGINNING.PRESENTATION' = 'METHODOLOGY.BEGINNING.PRESENTATION',
    'METHODOLOGY.BEGINNING.PROBLEM' = 'METHODOLOGY.BEGINNING.PROBLEM',
    'METHODOLOGY.DEVELOPMENT.KNOWLEDGE' = 'METHODOLOGY.DEVELOPMENT.KNOWLEDGE',
    'METHODOLOGY.DEVELOPMENT.UNDERSTANDING' = 'METHODOLOGY.DEVELOPMENT.UNDERSTANDING',
    'METHODOLOGY.DEVELOPMENT.APPLICATION' = 'METHODOLOGY.DEVELOPMENT.APPLICATION',
    'METHODOLOGY.DEVELOPMENT.ANALYSIS' = 'METHODOLOGY.DEVELOPMENT.ANALYSIS',
    'METHODOLOGY.DEVELOPMENT.SYNTHESIS' = 'METHODOLOGY.DEVELOPMENT.SYNTHESIS',
    'METHODOLOGY.DEVELOPMENT.ASSESSMENT' = 'METHODOLOGY.DEVELOPMENT.ASSESSMENT',
    'METHODOLOGY.DEVELOPMENT.CULMINATION' = 'METHODOLOGY.DEVELOPMENT.CULMINATION',
    'METHODOLOGY.DEVELOPMENT.GRADE_RESOURCES' = 'METHODOLOGY.DEVELOPMENT.GRADE_RESOURCES',
    'METHODOLOGY.DEVELOPMENT.KNOWLEDGE_ASSESSMENT' = 'METHODOLOGY.DEVELOPMENT.KNOWLEDGE_ASSESSMENT',
    'METHODOLOGY.DEVELOPMENT.SUMMARY' = 'METHODOLOGY.DEVELOPMENT.SUMMARY'
}

export type LoadingFormType = {
    [k in FormEntitiesEnum]: boolean
}
