import React, { Dispatch, SetStateAction, useState } from 'react';
import IResource                                     from '../../../../../../interfaces/entities/IResource';
import useServiceByRef                               from '../../../../../../utils/hooks/useServiceByRef';
import ResourceService                               from '../../../../../../api/services/ResourceService';
import { Modal, Form, Input, message, notification } from 'antd';
import { useHistory }                                from 'react-router-dom';
import { isAxiosError }                              from '../../../../../../utils/services';
import { AxiosError } from 'axios';
import rules          from './rules';

type Props = {
    resource: IResource
    visibleDeny: boolean
    setVisibleDeny: Dispatch<SetStateAction<boolean>>
};
const { Item } = Form;
const { TextArea } = Input;

const ModalDenyResource: React.FC<Props> = ({ resource, visibleDeny, setVisibleDeny }) => {
    const history = useHistory();
    const resourceService = useServiceByRef(ResourceService);
    const [action, setAction] = useState<boolean>(false);

    //CONF
    const [form] = Form.useForm();

    const layoutForm = {
        labelCol: { span: 24 },
        wrapperCol: { span: 24 },
    };

    const denyResource = async (values: {}) => {
        if (resource !== undefined && resourceService.current && values !== null) {
            setAction(true);
            const response = await resourceService.current.deniedResource(resource.id, values);
            if (isAxiosError(response)) {
                const err = response as AxiosError;
                message.error(`Ocurrio un error al denegar el recurso: ${ err.message }`);
            } else {
                notification.success({
                    duration: 2,
                    message: 'Operación finalizada',
                    description: `El recurso ha sido denegado`
                });
                history.goBack();
            }
            setAction(false);
        }
    };

    return (
        <Modal
            className={ 'btn-modal-maintenance' }
            visible={ visibleDeny }
            onOk={ () => {
                form
                    .validateFields()
                    .then((values) => {
                        form.resetFields();
                        denyResource(values);
                    })
                    .catch((info) => {
                        notification['error']({
                            message: 'Datos incompletos',
                            description: info,
                        });
                    });
            } }
            onCancel={ () => {
                setVisibleDeny(false);
            } }
            centered={ true }
            destroyOnClose={ true }
            confirmLoading={ action }
            title={ <h4 className="modal-title-denied">Razón de denegado</h4> }
            okText={ 'Enviar' }
            cancelButtonProps={ { style: { display: 'none' } } }
            okButtonProps={ { shape: 'round', style:{ display: resource.resource_denied ? 'none' : 'inline' } } }
        >
            <Form
                { ...layoutForm }
                name="basic"
                className="item-resource"
                form={ form }
                requiredMark={ false }
                initialValues={resource.resource_denied}
            >
                <Item
                    key={ 'reason' }
                    label={ <h3 className="title-sub-1">{ 'Razón' }</h3> }
                    name="reason"
                    hasFeedback
                    rules={ rules.reason }
                >
                    <Input maxLength={ 255 } className="input-resource" placeholder="Razón de denegar el recurso" readOnly={resource.resource_state_id === 4 }/>
                </Item>
                <Item
                    key={ 'description' }
                    label={ <h3 className="title-sub-1">{ 'Descripción' }</h3> }
                    name="description"
                    hasFeedback
                    rules={ rules.description }
                >
                    <TextArea maxLength={ 255 } className="input-resource" rows={ 7 }
                              placeholder="Escribe una descripción de la razón" readOnly={resource.resource_state_id === 4 } />
                </Item>
            </Form>
        </Modal>
    );
};
export default ModalDenyResource;
