import { IAxiosState }   from '../../interfaces/store/states/IAxiosState';
import axios             from 'axios';
import { IAxiosActions } from '../../interfaces/store/actions/IAxiosActions';
import AppServiceConfig  from '../../api/client-configuration/AppServiceConfiguration';

const initialState: IAxiosState = {
    instance: axios.create(AppServiceConfig),
    token: null,
    readyForRequests: false
};

const axiosReducer = (state = initialState, action: IAxiosActions) => {
    switch (action.type) {
        case 'SET_READY':
            return {
                ...state,
                readyForRequests: action.value
            };
        case 'SET_AXIOS_INSTANCE':
            return {
                ...state,
                instance: action.value
            };
        case 'SET_TOKEN':
            return {
                ...state,
                token: action.value
            };
        default:
            return state;
    }
};

export default axiosReducer;