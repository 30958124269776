import { routeType }      from '../../types/routes';
import NotFoundError404   from '../../views/errors/404';
import Unauthenticated    from '../../views/auth/Unauthenticated';
import Server500          from '../../views/errors/500';
import User401            from '../../views/errors/401';
import UserSchoolRegister from '../../views/teacher/register/UserSchoolRegister';

const routes: routeType[] = [
    {
        path: '/unauthenticated/:redirect?',
        component: Unauthenticated
    },
    {
        path: '/404',
        component: NotFoundError404
    },
    {
        path: '/500',
        component: Server500
    },
    {
        path: '/401',
        component: User401
    },
    {
        path: '/register/user_school',
        component: UserSchoolRegister
    },
];
export default routes;
