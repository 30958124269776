import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk                                                      from 'redux-thunk';
import resourceCollection                                         from './reducers/resource-collection';
import axios                                                      from './reducers/axios';
import auth                                                       from './reducers/auth';
import activityGuideCollection                                    from './reducers/activity-guide-collection';

const rootStore = combineReducers({ axios, resourceCollection, auth, activityGuideCollection });

let middleware;

if (process.env.NODE_ENV === 'production') {
    middleware = compose(applyMiddleware(thunk));
} else {
    middleware = compose(
        applyMiddleware(thunk),
        // @ts-ignore
        (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__()) || compose
    );
}

const store = createStore(rootStore, middleware);

export default store;