import React, {
    forwardRef,
    Fragment,
    ReactElement,
    Ref,
    useCallback,
    useEffect,
    useImperativeHandle,
    useRef,
    useState
}                                                                                   from 'react';
import { AxiosError, AxiosResponse }                                                from 'axios';
import { handleAxiosError, isAxiosError }                                           from '../../utils/services';
import { Button, Empty, Form, message, Modal, Row, Table }                          from 'antd';
import { ColumnsType }                                                              from 'antd/lib/table';
import { GetRowKey }                                                                from 'rc-table/lib/interface';
import { ExtraActionCallbacks, FunctionsHandler, RestActionCallbacks, RestActions } from './types';
import { ColumnType }                                                               from 'antd/lib/table/interface';
import ActionButtons                                                                from './ActionButtons';
import actionInfo                                                                   from './actionsInfo';
import { TablePaginationConfig }                                                    from 'antd/es/table';
import IPaginatedData
                                                                                    from '../../interfaces/IPaginatedData';
import useService                                                                   from '../../utils/hooks/useService';
import { RestServiceConstructableType }                                             from '../../types/api/services';
import { useKeycloak }                                                              from '@react-keycloak/web';
import { DownloadOutlined, UploadOutlined }                                         from '@ant-design/icons';
import { handleImportResponse, validateFile }                                       from './import-utils';

type Props<E> = {
    columns: ColumnsType<E>,
    entityIdentifier: GetRowKey<E>,
    actions: RestActionCallbacks<E> | ExtraActionCallbacks<E>,
    paginate?: boolean,
    serviceClass: RestServiceConstructableType<E>,
    exportUrl?: string,
    importUri?: string | null,
    customGetData?: (page?: number) => Promise<AxiosResponse<E[] | IPaginatedData<E>> | AxiosError | null>
};

const EntityRestTable = <E extends object>(props: Props<E>, ref: Ref<FunctionsHandler>) => {
    const isMounted = useRef(true);

    const {
        columns,
        entityIdentifier,
        actions,
        paginate = false,
        serviceClass,
        exportUrl,
        importUri = null,
        customGetData
    } = props;

    const { initialized } = useKeycloak();

    const service = useService(serviceClass);

    const [data, setData] = useState<E[]>([]);

    const [page, setPage] = useState(1);
    const [paginatedData, setPaginatedData] = useState<IPaginatedData<E> | null>(null);

    const [prevPageFetch, setPrevPageFetch] = useState<number | null>(null);
    // const [dataFetched, setDataFetched] = useState(false);

    const [loading, setLoading] = useState(true);
    const [tableColumns, setTableColumns] = useState<ColumnsType<E>>();
    const [restActions, setRestActions] = useState<ColumnType<E>>();
    const [error, setError] = useState<string | null>(null);

    const uploadFileInputRef = useRef<HTMLInputElement | null>();
    const [modal, contextHolder] = Modal.useModal();

    const [paginationInfo, setPaginationInfo] = useState<TablePaginationConfig>({
        onChange: p => {
            if (isMounted.current) setPage(p);
        },
        showTotal: total => `Total: ${ total }`
    });

    useImperativeHandle(ref, () => ({ getData }));

    const handleResponseData = useCallback((res: AxiosResponse<E[] | IPaginatedData<E>> | AxiosError) => {
        if (isAxiosError(res)) {
            const err = res as AxiosError;

            setData([]);
            if (isMounted.current)
                handleAxiosError(err, {
                    default: error => setError(error.message)
                });
        } else {
            if (isMounted.current) {
                if (paginate) {
                    const { data: resData } = res as AxiosResponse<IPaginatedData<E>>;
                    setPaginatedData(resData);
                } else {
                    const { data: resData } = res as AxiosResponse<E[]>;
                    setData(resData);
                }
            }
        }
    }, [paginate]);

    const getData = useCallback(async () => {
        if (typeof customGetData !== 'undefined')
            return;

        if (service === null || !initialized)
            return;

        if (prevPageFetch === page)
            return;

        if (isMounted.current)
            setLoading(true);

        const res = await service.all(paginate ? page : undefined);

        if (paginate) {
            setPrevPageFetch(page);
        } else {
            // TODO: Implement 'not call same petition' validation when component its not paginated
        }

        handleResponseData(res);

        if (isMounted.current)
            setLoading(false);

    }, [customGetData, handleResponseData, initialized, page, paginate, prevPageFetch, service]);

    const handleCustom = useCallback(async () => {
        if (typeof customGetData === 'undefined')
            return;

        const res = await customGetData(paginate ? page : undefined);

        if (res !== null) {
            handleResponseData(res);
        } else {
            // TODO: Handle null error
        }

        if (isMounted.current)
            setLoading(false);
    }, [customGetData, handleResponseData, page, paginate]);

    useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);

    useEffect(() => {
        if (isMounted.current && paginatedData !== null) {
            setPaginationInfo(prevState => ({
                ...prevState,
                ...paginatedData,
                pageSize: paginatedData.per_page,
                current: paginatedData.current_page
            }));
        }
    }, [paginatedData]);

    useEffect(() => {
        if (isMounted.current)
            setRestActions({
                title: 'Acciones',
                render: (_, record) => (
                    <ActionButtons<E>
                        actions={ Object.keys(actionInfo) as RestActions[] }
                        entity={ record }
                        callbacks={ actions }
                        getData={ getData }
                    />
                )
            });
    }, [actions, getData]);

    useEffect(() => {
        if (isMounted.current)
            setTableColumns(columns);
    }, [columns]);

    useEffect(() => {
        // noinspection JSIgnoredPromiseFromCall
        handleCustom();
        // noinspection JSIgnoredPromiseFromCall
        getData();
    }, [getData, handleCustom]);

    const onUploadFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        if (service === null || importUri === null)
            return;

        const data = new FormData();

        const file = validateFile(event.target.files);

        if (file !== null) {
            data.append('file', file);

            const res = await service.import(importUri, data);

            handleImportResponse(res, getData, modal);
        } else {
            message.error('Debes seleccionar un archivo válido (XLSX)');
        }
    };

    return (
        <Fragment>
            { importUri !== null && (
                <Form>
                    { contextHolder }
                    <input
                        ref={ r => uploadFileInputRef.current = r }
                        type="file"
                        name="file"
                        style={ { display: 'none' } }
                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                        onChange={ onUploadFileChange }
                    />
                </Form>
            ) }

            <Row justify="end">
                <Button.Group>
                    { exportUrl && (
                        <Button
                            title="Exportar"
                            type="primary"
                            icon={ <DownloadOutlined /> }
                            href={ exportUrl }
                            download
                        />
                    ) }

                    { importUri !== null && (
                        <Button
                            title="Importar"
                            type="primary"
                            icon={ <UploadOutlined /> }
                            onClick={ () => {
                                if (uploadFileInputRef.current) {
                                    uploadFileInputRef.current.click();
                                }
                            } }
                        />
                    ) }
                </Button.Group>
            </Row>

            <Table<E>
                className={ 'table-maintenance' }
                pagination={ paginate ? paginationInfo : false }
                rowKey={ entityIdentifier }
                // TODO: Refactor this to a "better solution"
                columns={ [...(tableColumns ?? []), restActions ?? {}] }
                dataSource={ paginate ? paginatedData?.data ?? [] : data }
                loading={ loading }
                locale={ {
                    emptyText: error ? (<Empty description={ error } />) : undefined
                } }
            />
        </Fragment>
    );
};

export default forwardRef(EntityRestTable) as <E extends object>(props: Props<E> & { ref?: Ref<FunctionsHandler> }) => ReactElement;