import React, { Fragment }              from 'react';
import { Button, Col, Row, Typography } from 'antd';
import Img401                           from '../images/errors/401.svg';
import { useKeycloak }                  from '@react-keycloak/web';

const { Text, Title } = Typography;

type Props = {
    code?: number;
    message?: string;
    titleError?: string
}

const Teacher401: React.FC<Props> = ({ code, message, titleError }) => {
    const { keycloak } = useKeycloak();

    return (
        <Fragment>
            <Row gutter={ [24, 8] } className="row-errors">
                <Col span={ 24 }>
                    <img src={ Img401 } alt="401" />
                </Col>
                <Col span={ 24 }>
                    <p>
                        <Title>{ titleError !== undefined ? titleError : '¡Oooh! Registro incompleto del usuario' }</Title>
                    </p>
                    <p><Title level={ 4 }>
                        <Text
                            type="secondary">{ code !== undefined
                            ?
                            code === 401 ?
                                'Por favor, registrate en Docente Soy para poder hacer uso de este.'
                                : 'Docente no tiene colegio asociados, contacte con el administrador'
                            :
                            message !== undefined ? message : ''
                        }
                        </Text>
                    </Title></p>
                </Col>
                <Col span={ 12 } offset={ 6 }>
                    <Row gutter={ [24, 8] }>
                        <Col span={ 24 }>
                            <Button type={ 'primary' } shape={ 'round' } block
                                    onClick={ () => {
                                        keycloak.logout({
                                            redirectUri: window.location.origin
                                        });
                                    } }
                            >
                                Inicio
                            </Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Fragment>
    );
};

export default Teacher401;
