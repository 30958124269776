import React, { Fragment }                    from 'react';
import { Avatar, List, Skeleton, Typography } from 'antd';
import Icon                                   from '@ant-design/icons';
import BookOpenImg                            from '../images/book-open.png';

import INotification
              from '../../interfaces/entities/INotification';
import moment from 'moment';

const { Text } = Typography;

type Props = {
    redirectByUser: (action: string) => void;
    loading: boolean;
    notifications: INotification[];
};

export const Notifications: React.FC<Props> = ({ redirectByUser, loading, notifications }) => {

    const BookIcon = () => (
        <Icon component={ () => (<img alt="notifcations" src={ BookOpenImg } />) } />
    );

    const parseDate = (date: Date) => {
        return moment(date).format('DD/MM/YYYY h:mm:ss a');
    };
    
    return (
        <Skeleton loading={ loading }
                  round={ true }
                  active
                  avatar
                  paragraph={ { rows: 3 } }
                  className="skeleton-notification">
            <List
                className={ 'content-notification' }
                itemLayout="vertical"
                size="small"
                dataSource={ notifications }
                renderItem={ item => (
                    <List.Item
                        key={ item.id }
                    >
                        <List.Item.Meta
                            avatar={
                                <Avatar icon={ <BookIcon /> }
                                        style={ {
                                            backgroundColor: '#0066CC',
                                            marginTop: '10%'
                                        } } /> }
                            title={ <p onClick={ () => {
                                redirectByUser(`/resource/${ item.resource_id }`);
                            } }>{ item.resource?.name }</p> }
                            description={
                                <Fragment>
                                    <p><Text type={
                                        item.message.includes('Pendiente aprobación') ? 'warning' : item.message.includes('Ha sido denegado') ? 'danger' : 'success'
                                    }>{ item.message }</Text>
                                    </p>
                                    <p><Text type="secondary"> { parseDate(item.created_at) }</Text>
                                    </p>
                                </Fragment>
                            }
                        />
                    </List.Item>
                ) }
            />
        </Skeleton>
    );
};
export default Notifications;