import { IResourceCollectionState }   from '../../interfaces/store/states/IResourceCollectionState';
import { IResourceCollectionActions } from '../../interfaces/store/actions/IResourceCollectionActions';

const initialState: IResourceCollectionState = {
    resources: null,
    total: 1
};

const resourceCollectionReducer = (state = initialState, action: IResourceCollectionActions) => {
    switch (action.type) {
        case "SET_RESOURCE_COLLECTION_PAGE":
            return {
                ...state,
                resources: action.value.resources,
                total: action.value.total
            };
        default:
            return state;
    }
};

export default resourceCollectionReducer;