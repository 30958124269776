import { Button, Form, Input, notification, Radio } from 'antd';
import React, { Fragment, useState }                from 'react';
import useService                                   from '../../../../utils/hooks/useService';
import AuthService                                  from '../../../../api/services/AuthService';
import { handleAxiosError, isAxiosError }           from '../../../../utils/services';
import { AxiosError }                               from 'axios';
import setFormErrorsByAxiosResponse                 from '../../../../utils/forms/setFormErrorsByAxiosResponse';
import rules                                        from './rules';
import fields                                       from './fields';

const { Item, useForm } = Form;

export interface IFormFields {
    name: string
    last_name: string
    code: string
    email: string
    gender: string
}

type Props = {
    closeModal: () => void
}

const RegisterForm: React.FC<Props> = ({ closeModal }) => {
    const [form] = useForm<IFormFields>();
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const service = useService(AuthService);

    const handleSubmit = async (values: IFormFields) => {
        setLoading(true);

        const { email } = values;
        const data = { ...values, user_name: email };

        if (service !== null) {
            const res = await service.register(data);

            if (isAxiosError(res)) {
                handleAxiosError(res as AxiosError, {
                    422: r => setFormErrorsByAxiosResponse(form, r),
                    default: error => notification.error({
                        message: 'Ha ocurrido un error',
                        description: error.message
                    })
                });
            } else {
                setSuccess(true);
            }
        }

        setLoading(false);
    };

    return (
        <Fragment>
            { !success && (
                <Form<IFormFields>
                    form={ form }
                    onFinish={ handleSubmit }
                    layout="vertical"
                    hideRequiredMark
                >
                    { fields.map(({ label, name, rules: r }, i) => (
                        <Item
                            key={ i }
                            name={ name }
                            label={ label }
                            rules={ r }
                            hasFeedback
                        >
                            <Input type={ name.includes('email') ? 'email' : 'text' } />
                        </Item>
                    )) }

                    <Item
                        name="gender"
                        label="Género"
                        rules={ rules.gender }
                        hasFeedback
                    >
                        <Radio.Group buttonStyle="solid">
                            <Radio.Button value="f">F</Radio.Button>
                            <Radio.Button value="m">M</Radio.Button>
                        </Radio.Group>
                    </Item>

                    <Item>
                        <Button
                            block
                            size="large"
                            type="primary"
                            htmlType="submit"
                            loading={ loading }
                        >
                            Crear Cuenta
                        </Button>
                    </Item>
                </Form>
            ) }

            { success && (
                <div>
                    <div className="success-text">
                        Se enviaran a tu correo electrónico las credenciales de ingreso.
                        Este proceso pude tomar un tiempo pronto enviaremos tus credenciales.
                    </div>

                    <Button
                        block
                        size="large"
                        type="primary"
                        onClick={ closeModal }
                    >
                        Regresar
                    </Button>
                </div>
            ) }
        </Fragment>
    );
};

export default RegisterForm;
