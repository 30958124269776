import { Rule } from 'antd/es/form';
import rules    from './rules';

type Field = {
    name: string,
    label: string,
    rules: Rule[]
}

const fields: Field[] = [
    {
        name: 'name',
        label: 'Nombre',
        rules: rules.name
    },
    {
        name: 'last_name',
        label: 'Apellido',
        rules: rules.last_name
    },
    {
        name: 'code',
        label: 'Código de Infraestructura',
        rules: rules.code
    },
    {
        name: 'email',
        label: 'Correo Electrónico',
        rules: rules.email
    },
];

export default fields;
