import { ThunkAction }                from 'redux-thunk';
import { IAppState }                  from '../../interfaces/store/IAppState';
import { IResourceCollectionActions } from '../../interfaces/store/actions/IResourceCollectionActions';
import {
    SET_RESOURCE_COLLECTION_PAGE
}                                     from './constants/resource-collection';
import { IResourceCollectionState }   from '../../interfaces/store/states/IResourceCollectionState';

interface IResourceCollectionAction<R, E = null> extends ThunkAction<R, IAppState, E, IResourceCollectionActions> {
}

export const setResourceCollectionPage = (value: IResourceCollectionState): IResourceCollectionAction<void> => (dispatch => dispatch({
    type: SET_RESOURCE_COLLECTION_PAGE,
    value
}));