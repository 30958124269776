export enum FormEntitiesEnum {
    grade = 'grade',
    subject = 'subject',
    academicCharge = 'academicCharge',
    form = 'form'
}
export type LoadingType = {
    [k in FormEntitiesEnum]: boolean
}

export type ErrorsType = {
    [k in FormEntitiesEnum]: string | undefined
}

export interface IStoreAcademicCharge {
    teacher_id: string | undefined,
    grade_id: number,
    subject_id: number
}