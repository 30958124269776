import { useEffect, useRef }        from "react";
import { ServiceConstructableType } from "../../types/api/services";
import Service                      from "../../api/abstract/Service";
import { useSelector }              from "react-redux";
import { IAppState }                from "../../interfaces/store/IAppState";
import { AxiosInstance }            from "axios";

function useServiceByRef<T extends Service>(serviceClass: ServiceConstructableType<T>) {
    const instance = useRef<T>();
    const axiosInstance = useSelector<IAppState, AxiosInstance>(({ axios }) => axios.instance);

    useEffect(() => {
        instance.current = new serviceClass(axiosInstance);

        return () => {
            instance.current = undefined;
        };
    }, [axiosInstance, serviceClass]);

    return instance;
}

export default useServiceByRef;