import { Col, Row, Spin, Tag }  from 'antd';
import React                    from 'react';
import IAcademicCharge          from '../../../../../../interfaces/entities/IAcademicCharge';
import { IStoreAcademicCharge } from '../../type';

type Props = {
    removeTag: boolean;
    academicCharge: IAcademicCharge[];
    removeAcademicCharge: (id: number) => Promise<void>;
    newCharge: IStoreAcademicCharge[];
    removeTemporalStore: (id: any) => void;
    getNameSubject: (id_subject: number) => string | undefined;
    getNameGrade: (id_grade: number) => string | undefined;
    loadingSyncCharge: boolean;
    ifUserESchool: boolean;
};

const ListAcademicCharge: React.FC<Props> = ({
                                                 removeTag,
                                                 academicCharge,
                                                 removeAcademicCharge,
                                                 newCharge,
                                                 removeTemporalStore,
                                                 getNameSubject,
                                                 getNameGrade,
                                                 loadingSyncCharge,
                                                 ifUserESchool
                                             }) => {
    return (
        <Spin spinning={ removeTag }>
            <Spin spinning={ loadingSyncCharge }>
                <Row>
                    <Col span={ 24 }>
                        <>
                            { academicCharge !== null && !ifUserESchool ?
                                academicCharge.map((value) => (
                                    <Tag closable key={ value.id } className={ 'tag-charge' }
                                         onClose={ e => {
                                             e.preventDefault();
                                             removeAcademicCharge(value.id);
                                         } }>
                                        { value.academic_subject?.subject?.name } - { value.academic_subject?.grade?.name }
                                    </Tag>
                                ))
                                : academicCharge.map((value) => (
                                    <Tag key={ value.id } className={ 'tag-charge' }>
                                        { value.academic_subject?.subject?.name } - { value.academic_subject?.grade?.name }
                                    </Tag>
                                ))
                            }
                            { newCharge !== null && !ifUserESchool ?
                                newCharge.map((value, key) => (
                                    <Tag closable key={ key } color="orange"
                                         onClose={ e => {
                                             e.preventDefault();
                                             removeTemporalStore(key);
                                         } }>
                                        { getNameSubject(value.subject_id) } - { getNameGrade(value.grade_id) }
                                    </Tag>
                                ))
                                : null
                            }
                        </>
                    </Col>
                </Row>
            </Spin>
        </Spin>
    );
};
export default ListAcademicCharge;