import React, { Dispatch, SetStateAction, useEffect, useState }                         from 'react';
import { ITeacher }                                                                     from '../../../../../interfaces/entities/IUser';
import ISchool
                                                                                        from '../../../../../interfaces/entities/ISchool';
import { isAxiosError }                                                                 from '../../../../../utils/services';
import { AxiosError, AxiosResponse }                                                    from 'axios';
import { message, notification, Modal, Table, Space, Button }                           from 'antd';
import useServiceByRef
                                                                                        from '../../../../../utils/hooks/useServiceByRef';
import TeacherService
                                                                                        from '../../../../../api/services/TeacherService';
import { CheckOutlined, DeleteOutlined, ExclamationCircleOutlined, UserDeleteOutlined } from '@ant-design/icons';
import AcademicCharge                                                                   from '../../AcademicCharge';


type Props = {
    selectedSchool?: ISchool | undefined;
    searchTeachersBySchool: (id: string) => Promise<void>;
    actionChangeStatus: (id: string, status: number) => void;
    loading: boolean;
    setLoading: Dispatch<SetStateAction<boolean>>;
    create: boolean;
    action: boolean;
    setAction: Dispatch<SetStateAction<boolean>>;
};

const { confirm } = Modal;

const ActiveUsersTable: React.FC<Props> = ({
                                               selectedSchool,
                                               searchTeachersBySchool,
                                               actionChangeStatus,
                                               loading,
                                               setLoading,
                                               create, action, setAction
                                           }) => {
    const teacherService = useServiceByRef(TeacherService);
    const [teachers, setTeachers] = useState<ITeacher[]>([]);

    useEffect(() => {
        if (create) {
            setTeachers([]);
        }
    }, [create]);

    useEffect(() => {
        const getActiveUsers = async () => {
            if (selectedSchool !== undefined && teacherService.current) {
                setLoading(true);
                const response = await teacherService.current.TeacherBySchool(selectedSchool.id);

                if (isAxiosError(response)) {
                    const err = response as AxiosError;
                    message.error(`Ocurrio un error al obtener los docentes: ${ err.message }`);
                } else {
                    const { data } = response as AxiosResponse<ITeacher[]>;
                    setTeachers(data);
                }
                setLoading(false);
            }
        };

        if (selectedSchool !== undefined) {
            getActiveUsers().finally();
        } else {
            setTeachers([]);
        }
    }, [selectedSchool, teacherService, setLoading, action]);

    const deleteTeacher = async (id: string) => {
        setLoading(true);
        if (teacherService.current) {
            const response = await teacherService.current.deleteTeacher(id);
            if (isAxiosError(response)) {
                const err = response as AxiosError;
                message.error(`Ocurrio un error al eliminar el docente: ${ err.message }`);
            } else {
                setAction(!action);
                notification.success({
                    message: 'Operación finalizada',
                    duration: 2,
                    description: `El usuario ha sido eliminado`,
                });
                if (selectedSchool !== undefined) {
                    searchTeachersBySchool(selectedSchool.id).finally();
                }
            }
            setLoading(false);
        }
    };

    const actionDelete = (id: string) => {
        confirm({
            title: '¿Estas segur@ de eliminar el usuario?',
            icon: <ExclamationCircleOutlined />,
            content: 'El usuario sera eliminado y los recursos no podran ser visibles.',
            okType: 'primary',
            okText: 'Si',
            cancelText: 'No',
            okButtonProps: { shape: 'round', danger: true, style: { background: '#FF5C5C' } },
            cancelButtonProps: { shape: 'round' },
            onOk() {
                deleteTeacher(id).finally();
            },
        });
    };

    return (
        <Table<ITeacher> className={ 'table-maintenance' } rowKey="id" dataSource={ teachers } scroll={ { x: '100%' } }
                         loading={ loading }
                         columns={ [
                             {
                                 title: 'Docentes',
                                 render: (_, teachers) => (`${ teachers.name }, ${ teachers.last_name }`),
                             },
                             {
                                 title: '| Colegio',
                                 render: (_, teachers) => (`${ teachers.teachers_schools?.[0].school?.name }`)
                             },
                             {
                                 title: '| Ver carga académica',
                                 render: (_, teachers) => (<AcademicCharge teacher_id={ teachers.id } />)
                             },
                             {
                                 title: '| Acción',
                                 render: (_, teachers) => (
                                     <Space>
                                         { teachers.status_id !== 1
                                             ?
                                             <Button className={ 'btn-table-action-active' } shape={ 'circle' }
                                                     onClick={ () => {
                                                         actionChangeStatus(teachers.id, teachers.status_id);
                                                     } }
                                                     icon={ <CheckOutlined /> } />
                                             :
                                             <Button className={ 'btn-table-action-deactivate' } shape={ 'circle' }
                                                     onClick={ () => {
                                                         actionChangeStatus(teachers.id, teachers.status_id);
                                                     } }
                                                     icon={ <UserDeleteOutlined /> } />
                                         }
                                         <Button className={ 'btn-table-action-delete' } shape={ 'circle' }
                                                 onClick={ () => {
                                                     actionDelete(teachers.id);
                                                 } }
                                                 icon={ <DeleteOutlined /> } />
                                     </Space>

                                 )
                             },
                         ] }
        />
    );
};
export default ActiveUsersTable;