import IActivityGuide                from "../../../../interfaces/entities/IActivityGuide";
import { AxiosError, AxiosResponse } from "axios";
import ActivityGuideService          from "../../../../api/services/ActivityGuideService";
import { isAxiosError }              from "../../../../utils/services";

type getActivityGuideType = (
    activity_guide_code: string,
    callback: (res: IActivityGuide) => void,
    onError: (err: AxiosError) => any,
    setLoading: (loading: boolean) => void,
    service: ActivityGuideService,
    selected_school_id: string | null
) => void;

export const getActivityGuide: getActivityGuideType = async (activity_guide_code, callback, onError, setLoading, service, selected_school_id) => {
    setLoading(true);

    const response = await service.get(activity_guide_code, selected_school_id !== null ? { school_id: selected_school_id } : null);

    if (isAxiosError(response)) {
        onError(response as AxiosError);
    } else {
        const { data } = response as AxiosResponse<IActivityGuide>;
        callback(data);
    }

    setLoading(false);
};

