import React, { Fragment, useEffect, useState } from 'react';
import ResourcesList                            from '../../../../components/Resources';
import { useParams }                            from 'react-router-dom';
import BreadCrumbResources                      from '../../../resource/components/BreadCrumbResources';
import { useSelector }                          from 'react-redux';
import { IAppState }                            from '../../../../interfaces/store/IAppState';

interface RouteParams {
    unit_id: string
}

const AssociatedResources: React.FC = () => {
    const params = useParams<RouteParams>();
    const schoolID = useSelector<IAppState, string | null>(({ auth }) => auth.selected_school_id);

    const [paramsSearch, setParamsSearch] = useState<object>();

    useEffect(() => {
        if (params.unit_id.trim().length > 0) {
            setParamsSearch({ 'school_id': schoolID, 'unit_id': params.unit_id });
        }
    }, [params, schoolID]);

    return (
        <Fragment>
            <BreadCrumbResources resource name={ 'Recursos asociados' } />
            { paramsSearch !== undefined && (
                <ResourcesList
                    paginated
                    associatedResources={ paramsSearch }
                />
            ) }
        </Fragment>
    );
};
export default AssociatedResources;
