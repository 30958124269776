import FormRules            from '../../../../types/utils/forms/FormRules';
import { IFormRulesUpdate } from './types';

export const updateRules: FormRules<IFormRulesUpdate> = {
    resource_type_id: [
        {
            required: true,
            message: 'El tipo de recurso es requerido'
        },
    ],
    name: [
        {
            required: true,
            message: 'El nombre del recurso es requerido'
        },
        {
            whitespace: true,
            message: 'No se permiten solo espacios en blanco, por favor ingrese un nombre valido'
        },
        {
            max: 255,
            message: 'El limite de caracteres es de 255'
        },
        {
            type: 'string',
            message: 'El campo es tipo texto'
        }
    ],
    description: [
        {
            required: true,
            message: 'La descripción del recurso es requerido'
        },
        {
            whitespace: true,
            message: 'No se permiten solo espacios en blanco, por favor ingrese una descripción valida'
        },
        {
            max: 255,
            message: 'El limite de caracteres es de 255'
        },
        {
            type: 'string',
            message: 'El campo es tipo texto'
        }
    ],
    labels: [
        {
            required: true,
            message: 'Las palabras claves son requeridas, Favor de seleccionar o ingresar una palabra clave'
        },
        {
            whitespace: true,
            message: 'No se permiten solo espacios en blanco, por favor ingrese al menos una palabra clave'
        },
        {
            type: 'array',
            message: 'El campo debe de ser un arreglo'
        }
    ],
    grade_id: [
        {
            required: true,
            message: 'El grado es requerido'
        },
    ],
    subject_id: [
        {
            required: true,
            message: 'La materia es requrida'
        },
    ],
    unit_id: [
        {
            required: true,
            message: 'La unidad es requerida'
        },
    ],
    topic: [
        {
            type: 'string',
            message: 'El campo es tipo texto'
        }
    ],
    indicators: [
        {
            required: true,
            message: 'Seleccione un indicador o elimine la fila'
        }
    ],
};
