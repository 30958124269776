export const getParamsService = (page?: number, school_id?: string | null) => {
    if (page !== undefined && school_id !== undefined) {
        return `?page=${ page }&school_id=${ school_id }`;
    } else if (page !== undefined && school_id === undefined) {
        return `?page=${ page }`;
    } else if (page === undefined && school_id !== undefined) {
        return `?school_id=${ school_id }`;
    } else {
        return '';
    }
};