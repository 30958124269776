import { routeType } from '../../types/routes';
import PublicHome    from '../../views/public/home';
import StudentsGuide from "../../views/resource/show/ActivityGuide";
import ResourceShow  from '../../views/resource/show/resource';
import React         from "react";

const routesPublic: routeType[] = [
    {
        path: '',
        component: PublicHome,
    },
    {
        path: 'p/activity_guide/:activity_guide_code',
        component: () => <StudentsGuide publicGuide={ true }/>
    },
    {
        path: 'p/resource/:resource_id',
        component: ResourceShow,
    }
];

export default routesPublic;
