import React, { Dispatch, SetStateAction, useEffect }         from 'react';
import ISchool                                                from '../../../../interfaces/entities/ISchool';
import { Button, Form, Input, message, notification, Switch } from 'antd';
import { IFormFields }                                        from '../type';
import SchoolService                                          from '../../../../api/services/SchoolService';
import { handleAxiosError, isAxiosError }                     from '../../../../utils/services';
import { AxiosError, AxiosResponse }                          from 'axios';
import useServiceByRef                                        from '../../../../utils/hooks/useServiceByRef';
import rules                                                  from './rules';
import setFormErrorsByAxiosResponse
                                                              from '../../../../utils/forms/setFormErrorsByAxiosResponse';

type Props = {
    selectedSchool: ISchool | undefined;
    setSelectedSchool: Dispatch<SetStateAction<ISchool | undefined>>;
    create: boolean;
    setCreate: Dispatch<SetStateAction<boolean>>;
    setRefresh: Dispatch<SetStateAction<boolean>>;
    setRefreshSchool: Dispatch<SetStateAction<boolean>>;
    refreshSchool: boolean;
};

const CreateSchool: React.FC<Props> = ({
                                           selectedSchool,
                                           create,
                                           setCreate,
                                           setSelectedSchool,
                                           setRefresh,
                                           setRefreshSchool,
                                           refreshSchool
                                       }) => {
    // STATE CONFIG
    const [form] = Form.useForm();

    // INITIAL VALUES FORM
    // eslint-disable-next-line react-hooks/exhaustive-deps
    let initialValuesSchool: IFormFields = {
        infrastructure_code: !create ? selectedSchool?.infrastructure_code : '',
        odoo_code: !create ? selectedSchool?.odoo_code : '',
        name: !create ? selectedSchool?.name : '',
        status_id: selectedSchool?.status_id,
        public_content: create ? true : selectedSchool?.school_config?.public_content
    };

    const schoolService = useServiceByRef(SchoolService);

    //useEFFECT
    useEffect(() => {
        form.resetFields();
    }, [initialValuesSchool, create, form, selectedSchool]);

    // Change content privacy status
    const changePublicContentStatus = async (_: boolean) => {
        if (schoolService.current && selectedSchool?.id !== undefined && !create) {
            try {
                setRefresh(true);
                const response = await schoolService.current.changeContentPrivacy(selectedSchool.id);

                if (isAxiosError(response)) {
                    const err = response as AxiosError;
                    message.error(`Ocurrio un error al modificar la privacidad del contenido : ${ err.message }`);
                } else {
                    const { data } = response as AxiosResponse<ISchool>;
                    setSelectedSchool(data);
                    setRefreshSchool(!refreshSchool);
                    notification.success({
                        duration: 2,
                        message: 'Operación finalizada',
                        description: `El contenido es ${ data.school_config?.public_content ? 'publica' : 'privado' }`
                    });
                }
                setRefresh(false);
            } catch (e) {
                notification.error({
                    message: 'Ocurrio un error al modificar la privacidad del contenido',
                    duration: 2,
                    description: e,
                });
            }
        }
    };


    // active & deactivate school
    const changeStatusSchool = async () => {
        if (schoolService.current && selectedSchool !== undefined) {
            try {
                setRefresh(true);
                const response = await schoolService.current.changeStatus(selectedSchool.id);

                if (isAxiosError(response)) {
                    const err = response as AxiosError;
                    message.error(`Ocurrio un error al ${ selectedSchool.status_id === 1 ? 'desactivar' : 'activar' } el colegio: ${ err.message }`);
                } else {
                    const { data } = response as AxiosResponse<ISchool>;
                    setSelectedSchool(data);
                    setRefreshSchool(!refreshSchool);
                    notification.success({
                        duration: 2,
                        message: 'Operación finalizada',
                        description: `El colegio ha sido ${ selectedSchool.status_id === 1 ? 'activado' : 'desactivado' }`
                    });
                }
                setRefresh(false);
            } catch (e) {
                notification.error({
                    message: `Ocurrio un error al ${ selectedSchool.status_id === 1 ? 'desactivar' : 'activar' } el colegio`,
                    duration: 2,
                    description: e,
                });
            }
        }
    };

    // ACTION FORM
    const onFinish = async (values: object) => {
        if (SchoolService !== null && Object.entries(values).length > 0) {
            if (!create && selectedSchool !== undefined) {
                await updateSchool(selectedSchool?.id, values);
            } else {
                await storeSchool(values);
            }
        }
    };

    const storeSchool = async (data: object) => {
        try {
            if (schoolService.current) {
                setRefresh(true);
                const response = await schoolService.current.store(data);

                if (isAxiosError(response)) {
                    const err = response as AxiosError;
                    message.error(`Ocurrio un error al crear el colegio: ${ err.message }`);
                } else {
                    const { data } = response as AxiosResponse<ISchool>;
                    setSelectedSchool(data);
                    setRefreshSchool(!refreshSchool);
                    notification.success({
                        duration: 2,
                        message: 'Operación finalizada',
                        description: 'Colegio creado'
                    });
                }
                setRefresh(false);
            }
        } catch (e) {
            notification.error({
                message: 'Ocurrio un error al crear el colegio',
                duration: 2,
                description: e,
            });
        }
    };

    const updateSchool = async (id: string, data: object) => {
        try {
            setRefresh(true);
            if (schoolService.current) {
                const response = await schoolService.current.update(id, data);

                if (isAxiosError(response)) {
                    const err = response as AxiosError;
                    handleAxiosError(err, {
                        422: response => {
                            setFormErrorsByAxiosResponse(form, response);
                            notification['error']({
                                message: 'Ocurrio un error',
                                description: 'Favor verificar que los datos sean validos',
                            });
                        },
                        500: error => notification.error({
                            message: 'Ha ocurrido un error al registrar el colegio',
                            description: error.message,
                        })
                    });

                } else {
                    const { data } = response as AxiosResponse<ISchool>;
                    setSelectedSchool(data);
                    setRefreshSchool(!refreshSchool);
                    notification.success({
                        duration: 2,
                        message: 'Operación finalizada',
                        description: 'Colegio modificado'
                    });
                }
            }
            setRefresh(false);
        } catch (e) {
            notification.error({
                message: 'Ocurrio un error al modificar el colegio',
                duration: 2,
                description: e,
            });
        }
    };

    const onCancelCreate = () => {
        setCreate(false);
        form.resetFields();
    };

    return (
        <Form
            form={ form }
            name={ 'frmNewSchool' }
            className={ 'item-new-school' }
            onFinish={ onFinish }
            initialValues={ initialValuesSchool }
            requiredMark={ false }
        >
            <Form.Item
                label={ <h3 className="title-sub-1">{ 'ID de infraestructura' }</h3> }
                name="infrastructure_code"
                rules={ rules.infrastructure_code }
                labelCol={ { span: 24 } }
                wrapperCol={ { span: 24 } }
                hasFeedback
            >
                <Input className="input-resource" placeholder={ 'Ingresa el ID del colegio' }
                       disabled={ selectedSchool?.status_id === 2 } />
            </Form.Item>
            <Form.Item
                label={ <h3 className="title-sub-1">{ 'Codigo ODOO' }</h3> }
                name="odoo_code"
                rules={ rules.odoo_code }
                labelCol={ { span: 24 } }
                wrapperCol={ { span: 24 } }
                hasFeedback
            >
                <Input className="input-resource" placeholder={ 'Ingresa el codgio ODOO del colegio' }
                       disabled={ selectedSchool?.status_id === 2 } />
            </Form.Item>
            <Form.Item
                label={ <h3 className="title-sub-1">{ 'Nombre del colegio' }</h3> }
                name={ 'name' }
                rules={ rules.name }
                labelCol={ { span: 24 } }
                wrapperCol={ { span: 24 } }
                hasFeedback
            >
                <Input className="input-resource" placeholder="Ingresa el nombre del colegio"
                       disabled={ selectedSchool?.status_id === 2 } />
            </Form.Item>
            <div className={ 'status_school_actions' }>
                <div>
                    <Form.Item
                        name={ 'public_content' }
                        label={ 'Contenido público' }
                        valuePropName={ 'checked' }
                        rules={ rules.public_content }
                    >
                        <Switch onChange={ changePublicContentStatus }
                                disabled={ create ? false : selectedSchool?.status_id === 2 } />
                    </Form.Item>
                </div>
                { create ? '' : selectedSchool !== undefined
                    ?
                    <div className={ 'xls' }>
                        <Button shape={ 'round' }
                                className={ selectedSchool?.status_id === 1 ? 'school-deactivate-btn' : 'school-active-btn' }
                                onClick={ changeStatusSchool }
                        >
                            { selectedSchool?.status_id === 1 ? 'Desactivar' : 'Activar' }
                        </Button>
                    </div>
                    : ''
                }
            </div>
            { create
                ?
                <div className={ 'align-flex-end' }>
                    <Button type="primary" htmlType="submit" className={ 'btn-save-school' }>
                        { 'Guardar' }
                    </Button>
                    &nbsp;&nbsp;
                    <Button htmlType="button" onClick={ onCancelCreate } className={ 'btn-cancel-school' }>
                        Cancelar
                    </Button>
                </div>
                : selectedSchool !== undefined && selectedSchool.status_id === 1 ?
                    <div className={ 'align-flex-end' }>
                        <Button type="primary" htmlType="submit" className={ 'btn-save-school' }>
                            { 'Modificar' }
                        </Button>
                        &nbsp;&nbsp;
                        <Button htmlType="button" onClick={ onCancelCreate } className={ 'btn-cancel-school' }>
                            Cancelar
                        </Button>
                    </div>
                    : ''
            }
        </Form>
    );
};
export default CreateSchool;
