import React, { useEffect, useState, Fragment, Dispatch, SetStateAction } from 'react';
import useServiceByRef
                                                                          from '../../../../utils/hooks/useServiceByRef';
import RatingService                                                      from '../../../../api/services/RatingService';
import Icon                                                               from '@ant-design/icons';
import RateImg                                                            from '../../../images/rateoff.svg';
import { isAxiosError }                                                   from '../../../../utils/services';
import { AxiosError, AxiosResponse }                                      from 'axios';
import { message, notification, Rate, Spin }                              from 'antd';
import IResource
                                                                          from '../../../../interfaces/entities/IResource';
import { IRate }                                                          from './types';

type Props = {
    resource: IResource;
    setRating: Dispatch<SetStateAction<number>>;
    setScore: Dispatch<SetStateAction<number>>;
}

const RatingAction: React.FC<Props> = ({ resource, setRating, setScore }) => {
    // SERVICE
    const ratingService = useServiceByRef(RatingService);

    // States
    const [average, setAverage] = useState<number>(0);
    const [actionAverage, setActionAverage] = useState<boolean>(false);

    // ICON IMG RATE
    const FavoriteRate = () => <img src={ RateImg } alt="Icon_rate" />;
    const RateIcon = (data: any) => <Icon component={ FavoriteRate } { ...data } />;

    useEffect(() => {
        const rateByUser = async () => {
            setActionAverage(true);
            const response = await ratingService.current?.getByUser(resource.id);
            if (isAxiosError(response)) {
                const err = response as AxiosError;
                message.error(`Ocurrio un error al obtener la calificación asignada por el usuario: ${ err.message }`);
            } else {
                const { data } = response as AxiosResponse<number>;
                setAverage(data);
            }
            setActionAverage(false);
        };

        rateByUser().finally();
    }, [ratingService, resource]);

    /**
     * store rating
     * @param value
     */
    const rateResource = async (value: number) => {
        if (value > 0 && ratingService.current) {
            setActionAverage(true);
            const data = {
                'rating': value
            };
            const response = await ratingService.current?.rate(resource.id, data);
            if (isAxiosError(response)) {
                const err = response as AxiosError;
                message.error(`Ocurrio un error al calificar el recurso: ${ err.message }`);
            } else {
                const { data } = response as AxiosResponse<IRate>;
                setAverage(data.user_rate);
                setRating(data.rating_global);
                setScore(data.score);

                notification.success({
                    duration: 2,
                    message: 'Operación finalizada',
                    description: `Recurso calificado`
                });

            }
            setActionAverage(false);
        }
    };

    return (
        <Fragment>
            <p>{ '¿Qué te parecio este recurso?' }</p>
            <Spin spinning={ actionAverage }>
                <Rate character={ <RateIcon /> } value={ average } onChange={ rateResource } />
            </Spin>
        </Fragment>
    );
};
export default RatingAction;