import React, { Fragment, useCallback, useEffect, useState }           from 'react';
import { useHistory, useParams }                                       from 'react-router-dom';
import useServiceByRef                                                 from '../../../utils/hooks/useServiceByRef';
import PlanningService, { PlanningRelationsEnum }                      from '../../../api/services/PlanningService';
import { handleAxiosError, isAxiosError }                              from '../../../utils/services';
import { AxiosError, AxiosResponse }                                   from 'axios';
import IAnnualPlanning
                                                                       from '../../../interfaces/entities/IAnnualPlanning';
import { Button, Col, Form, message, notification, Row, Select, Spin } from 'antd';
import { useSelector }                                                 from 'react-redux';
import { IAppState }                                                   from '../../../interfaces/store/IAppState';
import PlanningTable                                                   from '../components/PlanningTable';
import { handleToggleLoading, initialLoadingValues }                   from '../CreatePlanning/functions';
import categories, { CategoryType }                                    from '../../../types/entities/categories';
import BottomFormFields                                                from '../components/BottomFormFields';
import { ErrorsType, FormValuesType, ViewEntitiesEnum }                from '../CreatePlanning/types';
import { PlanningTableFunctionsHandler, PlanningTableInteractionType } from '../components/types';
import './styles.less';
import setFormErrorsByAxiosResponse
                                                                       from '../../../utils/forms/setFormErrorsByAxiosResponse';
import { getAssignmentsForAPI }                                        from '../components/functions';
import { MonthsTypes }                                                 from '../../../types/entities/months';
import '../Home/style-home.less';
import UnitPlanningTable
                                                                       from '../components/UnitPLanning/UnitPlanningTable';
import IUnit                                                           from '../../../interfaces/entities/IUnit';

type Params = { planning_id: string };

const UpdatePlanning: React.FC = () => {
    const { planning_id } = useParams<Params>();

    const planningTableRef = React.createRef<PlanningTableFunctionsHandler>();

    const [form] = Form.useForm();
    const history = useHistory();
    const planningService = useServiceByRef(PlanningService);

    const readyForRequests = useSelector<IAppState, boolean>(({ axios }) => axios.readyForRequests);

    const [loading, setLoading] = useState(true);

    const [viewLoading, setViewLoading] = useState(initialLoadingValues);
    const [errors] = useState({} as ErrorsType);

    const [planning, setPlanning] = useState<IAnnualPlanning | null>(null);
    const [units, setUnits] = useState<IUnit[]>([]);
    const [data, setData] = useState<CategoryType[]>([]);

    const [interaction, setInteraction] = useState<PlanningTableInteractionType>('AUTO');

    const getPlanning = useCallback(async () => {
        setLoading(true);
        if (typeof planningService.current !== 'undefined') {
            const res = await planningService.current.get(planning_id, [
                PlanningRelationsEnum.grade,
                PlanningRelationsEnum.subject
            ]);

            if (isAxiosError(res)) {
                message.error('La planificación especificada no ha sido encontrada!');

                history.push('/teacher/plannings');
            } else {
                const { data } = res as AxiosResponse<IAnnualPlanning>;
                setPlanning(data);
                if (data.units !== undefined) {
                    setUnits(data.units);
                }

            }
        }
        setLoading(false);
    }, [history, planningService, planning_id]);

    const handleFinish = async (values: FormValuesType) => {
        handleToggleLoading(setViewLoading, ViewEntitiesEnum.form);

        if (planningService.current === undefined) {
            message.error('Ha ocurrido un error con la conexión al servidor...');
            return;
        }

        const assignmentValues = Object.entries<number>(values).filter(([key]) => key.indexOf('assignments') !== -1);

        const apiData = {
            total_hours: values.total_hours,
            assignments: getAssignmentsForAPI(assignmentValues)
        };

        const res = await planningService.current.update(planning_id, apiData);

        if (isAxiosError(res)) {
            const err = res as AxiosError;

            handleAxiosError(err, {
                422: res => setFormErrorsByAxiosResponse(form, res),
                default: error => notification.error({
                    message: 'Ha ocurrido un error al modificar los datos de la planificación',
                    description: error.message
                })
            });
        } else {
            notification.success({
                message: 'La planificación ha sido modificada éxitosamente!',
            });
        }

        handleToggleLoading(setViewLoading, ViewEntitiesEnum.form);
    };

    useEffect(() => {
        if (readyForRequests) {
            // noinspection JSIgnoredPromiseFromCall
            getPlanning();
        }
    }, [getPlanning, readyForRequests]);

    useEffect(() => {
        const planningData: CategoryType[] = Object.entries(planning?.assignments ?? {}).reduce((sum, [cat, months]) => {
            const catData = categories.find(c => c.category === cat) as CategoryType;

            sum.push({
                ...catData,
                data: months,
                total: Object.keys(months).reduce((a, k) => a + months[k as MonthsTypes], 0)
            });

            return sum;
        }, [] as CategoryType[]);

        setData(planningData);
    }, [planning]);

    useEffect(() => {
        if (planning != null) {
            form.setFieldsValue({
                total_hours: planning.total_hours,
                grade_id: planning.academic_charge?.academic_subject?.grade_id,
                academic_subject_id: planning.academic_charge?.academic_subject_id
            });
        }
    }, [form, planning]);

    useEffect(() => {
        if (interaction === 'AUTO' && planningTableRef.current !== null) {
            planningTableRef.current.calcDependantValues();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [interaction]);

    return (
        <Fragment>
            <Spin size="large" spinning={ loading || viewLoading.form }>
                <Row>
                    <Col span={ 24 }>
                        <h1 className={ 'title-plannings' }>Editar Planificación</h1>
                    </Col>
                    <Col span={ 24 }>
                        <Button
                            type="primary"
                            className={ 'btn-plannings' }
                            onClick={ () => history.push('/teacher/plannings') }
                        >
                            Regresar
                        </Button>
                    </Col>
                </Row>
                <Row className="row-btn-plannings" id="update-planning-component">
                    { planning !== null && (
                        <Col className="inner-spinner-container">
                            <Form
                                form={ form }
                                layout="vertical"
                                onFinish={ handleFinish }
                            >
                                <Row gutter={ 20 }>
                                    <Col span={ 12 }>
                                        <Form.Item
                                            label="Grado"
                                            name="grade_id"
                                        >
                                            <Select
                                                className="select-plannings"
                                                disabled={ true }
                                                options={ [{
                                                    label: planning.academic_charge?.academic_subject?.grade?.name,
                                                    value: planning.academic_charge?.academic_subject?.grade_id ?? ''
                                                }] }
                                            />
                                        </Form.Item>
                                    </Col>

                                    <Col span={ 12 }>
                                        <Form.Item
                                            label="Materia"
                                            name="academic_subject_id"
                                        >
                                            <Select
                                                className="select-plannings"
                                                disabled={ true }
                                                options={ [{
                                                    label: planning.academic_charge?.academic_subject?.subject?.name,
                                                    value: planning.academic_charge?.academic_subject_id ?? ''
                                                }] }
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col span={ 24 }>
                                        <PlanningTable
                                            interaction={ interaction }
                                            form={ form }
                                            loading={ viewLoading }
                                            data={ data }
                                            ref={ planningTableRef }
                                            tableTitle={ () => (
                                                <div className="update-planning-table-title">
                                                    Planificación
                                                </div>
                                            ) }
                                        />
                                    </Col>
                                </Row>

                                <BottomFormFields
                                    handleInteractionChange={ setInteraction }
                                    planningTableRef={ planningTableRef }
                                    loading={ viewLoading }
                                    errors={ errors }
                                />
                            </Form>
                        </Col>
                    ) }
                </Row>
                { units !== undefined && planning?.academic_charge?.academic_subject_id !== undefined && (
                    <UnitPlanningTable
                        planning_units={ units }
                        planning_id={ planning.id }
                        academic_subject_id={ planning?.academic_charge?.academic_subject_id } />
                ) }
            </Spin>
        </Fragment>
    );
};

export default UpdatePlanning;
