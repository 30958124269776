import RestService                                  from '../abstract/RestService';
import { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import IRating                                      from '../../interfaces/entities/IRating';
import IRatingOfResource                            from '../../interfaces/entities/IRatingOfResource';
import { IRate }                                    from '../../views/resource/show/components/types';

class RatingService extends RestService<IRating> {
    constructor(axios: AxiosInstance) {
        super('ratings_resources', axios);
    }

    async all(page?: number): Promise<AxiosResponse<IRating[]> | AxiosError> {
        try {
            const params = page !== undefined ? `?page=${ page }` : '';
            return await this.http(this.uri + params);
        } catch (e) {
            return e as AxiosError;
        }
    }

    async getRatingByResource(resource_id: string): Promise<AxiosResponse<IRatingOfResource> | AxiosError> {
        try {
            return await this.http.get(`${ this.uri }/get_by/resource/${ resource_id }`);
        } catch (e) {
            return e as AxiosError;
        }
    }

    async rate(resource_id: string, params: object): Promise<AxiosResponse<IRate> | AxiosError> {
        try {
            return await this.http.put(`${ this.uri }/rate/${ resource_id }`, params);
        } catch (e) {
            return e as AxiosError;
        }
    }

    async getByUser(resource_id: string): Promise<AxiosResponse<number> | AxiosError> {
        try {
            return await this.http.get(`${ this.uri }/get_by/user/resource/${ resource_id }`);
        } catch (e) {
            return e as AxiosError;
        }
    }

}

export default RatingService;