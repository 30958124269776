import React, { Fragment, useCallback, useEffect, useState } from 'react';
import {
    Button,
    Card,
    Col,
    Divider,
    Empty,
    Modal,
    notification,
    Rate,
    Row,
    Skeleton,
    Tabs,
    Tag,
    Typography
}                                                            from 'antd';
import {
    useHistory,
    useLocation,
    useParams
}                                                            from 'react-router-dom';
import IActivityGuide                                        from '../../../../interfaces/entities/IActivityGuide';
import {
    useSelector
}                                                            from 'react-redux';
import {
    IAppState
}                                                            from '../../../../interfaces/store/IAppState';
import {
    IKeycloakUserInfo
}                                                            from '../../../../interfaces/store/states/IAuthState';
import useServiceByRef                                       from '../../../../utils/hooks/useServiceByRef';
import ActivityGuideService                                  from '../../../../api/services/ActivityGuideService';
import {
    useKeycloak
}                                                            from '@react-keycloak/web';
import Recommendation                                        from '../components/recomendation';
import RateImg                                               from '../../../images/rateoff.svg';
import Icon                                                  from '@ant-design/icons';
import {
    handleAxiosError,
    isAxiosError
}                                                            from '../../../../utils/services';
import {
    AxiosError
}                                                            from 'axios';
import {
    getActivityGuide
}                                                            from './helpers';
import IResource                                             from '../../../../interfaces/entities/IResource';
import BreadCrumbResources                                   from '../../components/BreadCrumbResources';
import moment                                                from 'moment';
import {
    days
}                                                            from '../../../../types/entities/activityGuides';
import Actions                                               from './components/Actions';
import ResourceTypesShow                                     from '../components/resource-types';
import Link                                                  from 'antd/lib/typography/Link';

const { TabPane } = Tabs;

type QueryParams = {
    activity_guide_code: string
}

type activityTypeGuide = {
    publicGuide: boolean
};

const { Paragraph, Text } = Typography;

const ActivityGuideShow: React.FC<activityTypeGuide> = ({ publicGuide }) => {
    const { activity_guide_code } = useParams<QueryParams>();
    const [activityGuide, setActivityGuide] = useState<IActivityGuide>();
    const [loading, setLoading] = useState<boolean>(false);

    const readyForAuthRequests = useSelector<IAppState, boolean>(({ axios }) => axios.readyForRequests);
    const selected_school_id = useSelector<IAppState, string | null>(({ auth }) => auth.selected_school_id);
    const userAuth = useSelector<IAppState, IKeycloakUserInfo | null>(({ auth }) => auth.keycloakUserInfo);

    const [rating, setRating] = useState<number>(0);
    const [score, setScore] = useState<number>(0);

    const service = useServiceByRef(ActivityGuideService);
    const history = useHistory();
    const location = useLocation();
    const { keycloak } = useKeycloak();

    const FavoriteRate = () => <img src={ RateImg } alt="Icon_rate" />;
    const RateIcon = (data: any) => <Icon component={ FavoriteRate } { ...data } />;

    const incrementView = useCallback(async () => {
        if (readyForAuthRequests) {
            if (service.current) {
                const response = await service.current.incrementView(activity_guide_code);

                if (isAxiosError(response)) {
                    handleAxiosError(response as AxiosError, {
                        404: () => {
                            notification.error({
                                message: 'Guia de actividades no encontrada',
                                description: 'La guia de actividades que desea visualizar no existe.',
                            });
                        },
                        default: () => {
                            notification.error({
                                message: 'Ha ocurrido un error al intentar obtener los datos de la guia de actividades seleccionada. Inténtelo más tarde',
                            });
                        }
                    });
                }
            }
        }
    }, [activity_guide_code, service, readyForAuthRequests]);

    useEffect(() => {

        if (publicGuide) {
            setLoading(true);
            if (!/((?=.*[a-zA-Z])(?=.*\d*)([a-zA-Z]{10}|[a-zA-Z\d]{10}))$/.test(activity_guide_code)) {
                notification.error({
                    message: 'Guia de actividades no encontrada',
                    description: 'El código brindado es incorrecto.',
                    duration: 1.5,
                });
                return () => {
                    setActivityGuide(undefined);
                };
            }
        }

        if (service.current) {
            if (publicGuide || (!publicGuide && readyForAuthRequests && keycloak.authenticated))
                getActivityGuide(
                    activity_guide_code,
                    (aG) => {
                        setActivityGuide(aG);
                        if (aG.scores !== undefined && aG.rating !== undefined) {
                            setScore(aG.scores);
                            setRating(aG.rating);
                        }
                    },
                    (err) => {
                        handleAxiosError(err, {
                            404: () => notification.error({
                                message: 'Guia de actividades no encontrada',
                                description: 'La guia de actividades no existe.',
                            }),
                            default: () => notification.error({
                                message: 'Guia de actividades no encontrada',
                                description: 'Ha ocurrido un error al intentar obtener los datos de la guia de actividades seleccionada. Inténtelo más tarde',
                            })
                        });

                        history.push('/');
                    },
                    _l => setLoading(_l),
                    service.current,
                    selected_school_id
                );

        }
    }, [activity_guide_code, service, history, location, readyForAuthRequests, publicGuide, selected_school_id, keycloak]);

    useEffect(() => {
        incrementView().finally();
    }, [incrementView]);

    const urlPublic = (resource: IResource) => {

        let url = '';

        if (resource.resource_type_id >= 1 && resource.resource_type_id <= 5) {
            url = '/p/resource/' + resource.id;
        }

        return window.location.host + url;
    };

    const confirmDeleteAction = () => {
            const modal = Modal.info({
                maskClosable: false,
                closable: false,
                okText: <></>,
                icon: <></>,
                className: 'confirm-delete-activity-guide-modal',
                width: '50%',
                content:
                    <div style={ { textAlign: 'center' } }>
                        <h2 style={ { color: '#FF5C5C' } }>Eliminar recurso</h2>
                        <p>¿Estás seguro de eliminar la guia de actividades?</p>
                        <Button className={ 'btn-resource-radius btn-confirm btn-resource-copy-guide' }
                                onClick={ () => modal.destroy() }
                        >Regresar</Button>
                        <Button className={ 'btn-resource-radius btn-confirm btn-activity-guide-delete' }
                                onClick={ () => {
                                    deleteActivityGuide(modal).finally();
                                } }
                        >Eliminar</Button>
                    </div>

            });
        }
    ;

    const deleteActivityGuide = async (modal: { destroy: () => void }) => {
            setLoading(true);
            if (service.current && activityGuide) {
                if (activityGuide.user_id === userAuth?.sub || keycloak.hasResourceRole('ADMIN' || keycloak.hasResourceRole('LXD'))) {
                    const response = await service.current.delete(activityGuide.id);
                    if (isAxiosError(response)) {
                        const err = response as AxiosError;
                        handleAxiosError(err, {
                            403: _ => notification.error({
                                message: 'Ocurrio un error',
                                description: 'No cuenta con los permisos necesarios para eliminar esta guía de actividades'
                            }),
                            409: _ => notification.error({
                                message: 'Ocurrio un error',
                                description: 'La guía de actividades no puede ser eliminada'
                            }),
                            500: error => notification.error({
                                message: 'Ocurrio un error',
                                description: error.message
                            }),
                            default: error => notification.error({
                                message: 'Ocurrio un error',
                                description: error.message
                            })
                        });
                        setLoading(false);
                    } else {
                        notification.success({
                            message: 'Operación finalizada',
                            description: 'La guía seleccionada fue eliminada con éxito. Redirigiendo al inicio.',
                            duration: 1,
                            onClose: () => {
                                setLoading(false);
                                history.push(`/${ getPrefixRole() }`);
                            }
                        });
                        modal.destroy();
                    }
                } else {
                    setLoading(false);
                    notification.error({
                        message: 'Ocurrio un error',
                        description: 'No cuenta con los permisos necesarios para eliminar esta guía de actividades'
                    });
                }
            }
        }
    ;

    const editActivityGuide = async () => {
            if (activityGuide) {
                if (activityGuide.user_id === userAuth?.sub) {
                    history.push(`/${ getPrefixRole() }/activity_guides/edit/${ activityGuide.id }`);
                } else {
                    setLoading(false);
                    notification.error({
                        message: 'Ocurrio un error',
                        description: 'No cuenta con los permisos necesarios para editar esta guía de actividades'
                    });
                }
            }
        }
    ;

    const getPrefixRole = () => {
            if (keycloak.hasResourceRole('ADMIN')) {
                return 'admin';
            } else if (keycloak.hasResourceRole('TEACHER')) {
                return 'teacher';
            } else if (keycloak.hasResourceRole('LXD')) {
                return 'lxd';
            }
        }
    ;

    return (
        <Fragment>
            <Row gutter={ [48, 8] }>
                <Col lg={ keycloak.authenticated ? 16 : 21 } md={ keycloak.authenticated ? 16 : 21 } sm={ 24 } xs={ 24 }
                     className="content-view-resource">
                    <Skeleton className="skeleton-actions-resource" active loading={ loading } />
                    {
                        activityGuide !== undefined && !loading && (
                            <Fragment key={ 'content-view' }>
                                <div className="content-view-info">
                                    {
                                        keycloak.authenticated && (
                                            <BreadCrumbResources activityGuide name={ activityGuide?.title ?? '' } />
                                        )
                                    }
                                    <Row gutter={ [24, 24] }>
                                        <Col>
                                            { keycloak.hasResourceRole('ADMIN') || keycloak.hasResourceRole('LXD')
                                                ? userAuth?.sub === activityGuide?.user_id ?
                                                    <Row>
                                                        <Col>
                                                            <Button className="btn-review-round" danger
                                                                    onClick={ confirmDeleteAction }>Eliminar</Button>
                                                        </Col>
                                                        <Col>
                                                            <Button
                                                                className="btn-review-round"
                                                                onClick={ editActivityGuide }>Editar</Button>
                                                        </Col>
                                                    </Row>
                                                    :
                                                    <Button className="btn-review-round" danger
                                                            onClick={ confirmDeleteAction }>Eliminar</Button>
                                                : null
                                            }
                                        </Col>
                                        {
                                            keycloak.hasResourceRole('TEACHER') && userAuth?.sub === activityGuide?.user_id
                                                ?
                                                <Fragment>
                                                    <Col>
                                                        <Button className="btn-review-round" danger
                                                                onClick={ confirmDeleteAction }>Eliminar</Button>
                                                    </Col>
                                                    <Col>
                                                        <Button
                                                            className="btn-review-round"
                                                            onClick={ editActivityGuide }>Editar</Button>
                                                    </Col>
                                                </Fragment>
                                                : null
                                        }
                                    </Row>
                                    <h2 className="resource-title">{ activityGuide?.title }</h2>
                                    <Row gutter={ [48, 24] }>
                                        <Col lg={ 12 } md={ 12 } sm={ 24 } xs={ 24 }>
                                            { activityGuide?.labels?.map((label, index) => (
                                                <Tag className="tag-resource"
                                                     key={ index }>{ label.name.length > 20 ? label.name.substring(0, 20) + '...' : label.name }
                                                </Tag>
                                            )) }
                                        </Col>
                                        <Col lg={ 12 } md={ 12 } sm={ 24 } xs={ 24 } style={ { textAlign: 'end' } }>
                                            {
                                                keycloak.authenticated && activityGuide && (
                                                    <Fragment>
                                                        <Rate character={ <RateIcon /> } value={ rating }
                                                              disabled={ true } />
                                                        <span
                                                            style={ {
                                                                color: '#A0A4A8',
                                                                marginLeft: '1em'
                                                            } }>Calificaciones { score }</span>
                                                    </Fragment>
                                                )
                                            }
                                        </Col>
                                    </Row>
                                    <Divider />
                                    <h3 className="format-text-info teacher-author">{ activityGuide?.user?.name } { activityGuide?.user?.last_name }</h3>
                                    <h3 className="format-text-info created-at-resource">
                                        Creado
                                        el { moment(activityGuide?.created_at).format('D') } de { moment(activityGuide?.created_at).format('MMM') } del { moment(activityGuide?.created_at).format('Y') }
                                    </h3>
                                    <div className="text-content-view-resource">
                                        <h3 className="format-text-info">{ activityGuide?.description }</h3>
                                        <Row>
                                            <Col lg={ 24 } md={ 24 } sm={ 24 } xs={ 24 } className="resources-wrap">
                                                <Card title={
                                                    <Row style={ {
                                                        backgroundColor: '#173C7B',
                                                        color: 'black !important',
                                                        paddingLeft: '10px',
                                                        paddingRight: '10px'
                                                    } } className="ant-card-head-indicators">
                                                        <Col span={ 24 } className="resource-title"
                                                             style={ {
                                                                 borderTopRightRadius: '20px',
                                                                 borderTopLeftRadius: '20px'
                                                             } }>
                                            <span style={ {
                                                color: '#FFF',
                                                fontSize: '18px',
                                                lineHeight: '24px'
                                            } }>Indicador(es) de logros</span>
                                                        </Col>
                                                    </Row>
                                                }
                                                      className="indicators"
                                                >
                                                    <Skeleton active loading={ loading } />
                                                    {
                                                        activityGuide?.indicators?.map((value) => (
                                                            <p key={ value.id }>
                                                                { value.name }
                                                            </p>
                                                        ))
                                                    }
                                                </Card>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <br />
                                            <Col lg={ 24 } md={ 24 } sm={ 24 } xs={ 24 }>
                                                <Tabs defaultActiveKey={ days[0] } type="card" size={ 'small' }
                                                      style={ { marginTop: '10px' } }
                                                      className={ 'tabs-days-guides' }
                                                >
                                                    {
                                                        days.map(day => (
                                                            activityGuide?.assigned_resources[day] !== undefined && (
                                                                <TabPane tab={ day } key={ day }
                                                                         className={ 'tabs-container' }>
                                                                    { activityGuide.assigned_resources[day]?.indication === undefined || activityGuide.assigned_resources[day]?.indication === null
                                                                        ? <Empty description={ 'Vacio' } />
                                                                        : null
                                                                    }
                                                                    {
                                                                        (
                                                                            activityGuide.assigned_resources[day] !== undefined &&
                                                                            (
                                                                                activityGuide.assigned_resources[day]?.indication !== undefined && (
                                                                                    <div
                                                                                        dangerouslySetInnerHTML={ { __html: activityGuide.assigned_resources[day]?.indication as string } } />
                                                                                )
                                                                            )
                                                                        )
                                                                    }
                                                                    <Divider />
                                                                    {
                                                                        activityGuide?.assigned_resources[day]?.resources.map((resource) =>
                                                                                <Fragment key={ resource.id }>
                                                                                    <h3 style={ {
                                                                                        color: '#173C7B',
                                                                                        fontSize: '1.6em',
                                                                                        fontWeight: 'bold',
                                                                                    } }>{ resource.name }</h3>
                                                                                    { (resource.resource_type_id === 1 || resource.resource_type_id === 2 || resource.resource_type_id === 3) && (
                                                                                        <p>{ resource.description }</p>
                                                                                    ) }
                                                                                    <Row justify="center">
                                                                                        <Col span={ 20 }>
                                                                                            { resource.records !== undefined && (
                                                                                                <ResourceTypesShow
                                                                                                    resource={ resource } />
                                                                                            ) }
                                                                                        </Col>
                                                                                    </Row>
                                                                                    <br />
                                                                                    <span>
                                                                                     <span
                                                                                         style={ { fontWeight: 'bold' } }>URL del recurso</span>
                                                                                     <Link
                                                                                         href={ `/p/resource/${ resource.id }` }>
                                                                <Paragraph
                                                                    style={ { color: 'blue' } }
                                                                    className="url-hyperlink"
                                                                    copyable={ { tooltips: 'Copiar url' } }>{ urlPublic(resource) }</Paragraph>
                                                            </Link>
                                                                                </span>
                                                                                    <Divider
                                                                                        style={ { background: '#A0A4A8 !important' } }>
                                                                                        <div style={ {
                                                                                            width: '100%',
                                                                                            height: '1px'
                                                                                        } } />
                                                                                    </Divider>
                                                                                </Fragment>
                                                                        )
                                                                    }
                                                                </TabPane>)
                                                        ))
                                                    }
                                                </Tabs>
                                            </Col>
                                        </Row>
                                    </div>
                                    {
                                        keycloak.authenticated && activityGuide && service.current && (
                                            <Col span={ 24 } className="col-actions-guides">
                                                <Actions
                                                    activityGuide={ activityGuide }
                                                    setRating={ setRating }
                                                    setScore={ setScore }
                                                />
                                            </Col>
                                        )
                                    }
                                </div>
                            </Fragment>
                        )
                    }
                </Col>

                <Col span={ 1 } />
                { keycloak.authenticated && activityGuide && service.current && true && (
                    <Recommendation
                        rendering={ loading }
                        labels={ activityGuide.labels }
                    />
                ) }
            </Row>
        </Fragment>
    );
};

export default ActivityGuideShow;
