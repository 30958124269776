import React, { useEffect, useState } from 'react';
import FavoriteImg                    from '../../../views/images/favorite_icon.png';
import Icon, { PlusCircleOutlined }   from '@ant-design/icons';
import { Button, Col, Row }           from 'antd';
import { useHistory }                 from 'react-router-dom';
import keycloak                       from '../../../keycloak.config';
import userApproveImg                 from '../../../views/images/check-circle.svg';

type Props = {
    logged: boolean
    isTeacher?: boolean
    isLxd?: boolean
    isAdmin?: boolean
}

const ButtonsProfile: React.FC<Props> = ({ logged, isAdmin }) => {
    const history = useHistory();
    const [prefix, setPrefix] = useState<string>('');

    const FavoriteImgICon = () => <img className="IconExport" src={ FavoriteImg } alt="Icon_favorite" />;
    const FavoriteIcon = (data: any) => <Icon component={ FavoriteImgICon } { ...data } />;

    const UserImgICon = () => <img className="IconExport" src={ userApproveImg } alt="Icon_favorite" />;
    const UserApproveIcon = (data: any) => <Icon component={ UserImgICon } { ...data } />;

    useEffect(() => {
        const getPrefix = () => {
            if (keycloak.hasResourceRole('ADMIN')) {
                setPrefix('admin');
            } else if (keycloak.hasResourceRole('TEACHER')) {
                setPrefix('teacher');
            } else if (keycloak.hasResourceRole('LXD')) {
                setPrefix('lxd');
            }
        };

        if (logged) {
            getPrefix();
        }
    }, [logged]);

    const redirectCreateResource = () => {
        const url = `/${ prefix }/new/resource`;
        if (url !== '' && prefix !== '') {
            history.push(url);
        }
    };

    const redirectFavorite = () => {
        const url = `/${ prefix }/favorite/resource`;
        if (url !== '' && prefix !== '') {
            history.push(url);
        }
    };

    const redirectUsersApprove = () => {
        const url = `/${ prefix }/users/approve`;
        if (url !== '' && prefix !== '') {
            history.push(url);
        }
    };

    return (
        <>
            {
                logged && (
                    <Row gutter={ [16, 8] } className="container-buttons">
                        <Col lg={ isAdmin ? 8 : 18 } md={ isAdmin ? 8 : 18 } sm={ 24 } xs={ 24 }>
                            <Button
                                className="btn-create-text btn-option-home background-btn-resource"
                                icon={ <PlusCircleOutlined /> }
                                size={ 'large' }
                                block
                                onClick={ redirectCreateResource }
                            >
                                { 'Crear nuevo recurso' }
                            </Button>
                        </Col>
                        { isAdmin && (
                            <Col lg={ 8 } md={ 8 } sm={ 24 } xs={ 24 }>
                                <Button
                                    className="btn-favorite-text btn-option-home"
                                    icon={ <UserApproveIcon /> }
                                    size={ 'large' }
                                    onClick={ redirectUsersApprove }
                                    block>
                                    { 'Aprobar cuentas' }
                                </Button>
                            </Col>
                        ) }
                        <Col lg={ isAdmin ? 8 : 6 } md={ isAdmin ? 8 : 6 } sm={ 24 } xs={ 24 }>
                            <Button
                                className="btn-favorite-text btn-option-home"
                                icon={ <FavoriteIcon /> }
                                size={ 'large' }
                                onClick={ redirectFavorite }
                                block>
                                { 'Recurso favorito' }
                            </Button>
                        </Col>
                    </Row>
                )
            }
        </>
    );
};
export default ButtonsProfile;