import RestService                                  from '../abstract/RestService';
import IDay                                         from '../../interfaces/entities/IDay';
import { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import IPaginatedData                               from '../../interfaces/IPaginatedData';

class DayService extends RestService<IDay> {
    constructor(axios: AxiosInstance) {
        super('days', axios);
    }

    async all(): Promise<AxiosResponse<IDay[] | IPaginatedData<IDay>> | AxiosError> {
        try {
            return await this.http.get(this.uri);
        } catch (e) {
            return e as AxiosError;
        }
    }

}

export default DayService;