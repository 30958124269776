import * as React                                     from 'react';
import { Fragment, useCallback, useEffect, useState } from 'react';
import useServiceByRef                                from '../../../utils/hooks/useServiceByRef';
import PlanningService, { PlanningRelationsEnum }     from '../../../api/services/PlanningService';
import { useSelector }                                from 'react-redux';
import { IAppState }                                  from '../../../interfaces/store/IAppState';
import { AxiosError, AxiosResponse }                  from 'axios';
import { isAxiosError }                               from '../../../utils/services';
import IAnnualPlanning                                from '../../../interfaces/entities/IAnnualPlanning';
import EntityRestTable                                from '../../../components/EntityRestTable';
import columns                                        from './columns';
import actions                                        from './actions';
import { Button, Col, Form, Row, Select }             from 'antd';
import { useHistory }                                 from 'react-router-dom';
import IGrade                                         from '../../../interfaces/entities/IGrade';
import GradeService                                   from '../../../api/services/GradeService';
import { filterOptionsFromLabel }                     from '../../../utils/forms/filterOptions';
import { searchAcademicSubjects }                     from '../CreatePlanning/functions';
import AcademicSubjectService                         from '../../../api/services/AcademicSubjectService';
import IAcademicSubject                               from '../../../interfaces/entities/IAcademicSubject';
import './style-home.less';
import keycloak                                       from '../../../keycloak.config';
import { SearchAcademicSubject }                      from '../CreatePlanning/types';

type FormValues = { grade_id: string, academic_subject_id: string };

export const PlanningsHome = () => {
    const history = useHistory();
    const [form] = Form.useForm<FormValues>();
    const school_id = useSelector<IAppState, string | null>(({ auth }) => auth.selected_school_id);
    const readyForRequests = useSelector<IAppState, boolean>(({ axios }) => axios.readyForRequests);
    const [loading, setLoading] = useState({ grades: false, academic_subjects: false });
    const [errors, setErrors] = useState<{ grades: string | undefined, academic_subjects: string | undefined }>({
        grades: undefined,
        academic_subjects: undefined
    });

    const annualPlanningServiceRef = useServiceByRef(PlanningService);
    const gradeService = useServiceByRef(GradeService);
    const asService = useServiceByRef(AcademicSubjectService);

    const [grades, setGrades] = useState<IGrade[]>([]);
    const [academicSubjects, setAcademicSubjects] = useState<IAcademicSubject[]>([]);
    const [as_id, setAsId] = useState<string | null>(null);

    const [prefixRol, setPrefixRol] = useState<string>('');

    const getPlannings = useCallback(async (page: number | undefined) => {
        if (typeof annualPlanningServiceRef.current !== 'undefined' && readyForRequests) {

            let params;

            if (keycloak.hasResourceRole('TEACHER') && school_id !== null) {
                params = {
                    school_id,
                    with: [PlanningRelationsEnum.grade, PlanningRelationsEnum.subject, PlanningRelationsEnum.school_year]
                };
            } else if (keycloak.hasResourceRole('ADMIN')) {
                params = {
                    with: [PlanningRelationsEnum.grade, PlanningRelationsEnum.subject, PlanningRelationsEnum.school_year]
                };
            }

            if (as_id !== null) {
                params = Object.assign(params, { academic_subject_id: as_id });
            }

            if (params)
                return await annualPlanningServiceRef.current.search(params, page);
        }

        return null;
    }, [annualPlanningServiceRef, as_id, school_id, readyForRequests]);

    const getGrades = useCallback(async () => {
        setLoading(prevState => ({ ...prevState, grades: true }));

        if (gradeService.current) {
            let res;
            if (keycloak.hasResourceRole('TEACHER')) {
                res = await gradeService.current.teacherGrade();
            } else {
                res = await gradeService.current.all();
            }

            if (isAxiosError(res)) {
                const err = res as AxiosError;
                setErrors(prevState => ({ ...prevState, grades: err.message }));
            } else {
                const { data } = res as AxiosResponse<IGrade[]>;
                setGrades(data);
            }
        }
        setLoading(prevState => ({ ...prevState, grades: false }));
    }, [gradeService]);

    const handleGradeChange = async (grade_id: number) => {
        setLoading(prevState => ({ ...prevState, academic_subjects: true }));
        if (grade_id === undefined) {
            form.resetFields(['academic_subject_id']);
            setAsId(null);
        } else {
            const data: SearchAcademicSubject = { grade_id: grade_id, school_id: school_id };
            await searchAcademicSubjects(asService, data, setAcademicSubjects);
        }

        setLoading(prevState => ({ ...prevState, academic_subjects: false }));
    };

    const getPrefix = () => {
        if (keycloak.hasResourceRole('TEACHER')) {
            setPrefixRol('teacher');
        } else if (keycloak.hasResourceRole('ADMIN')) {
            setPrefixRol('admin');
        }
    };

    useEffect(() => {
        if (readyForRequests) {
            // noinspection JSIgnoredPromiseFromCall
            getGrades();
        }
    }, [getGrades, readyForRequests]);

    useEffect(() => {
        if (readyForRequests) {
            getPrefix();
        }
    }, [readyForRequests]);

    return (
        <Fragment>
            <Row>
                <Col span={ 24 }>
                    <h1 className={ 'title-plannings' }>Planificación Anual</h1>
                </Col>
                {/*TODO: configuration so that administrators can make annual plans, it will be done later; according to requirements*/ }
                { keycloak.hasResourceRole('TEACHER') && (
                    <Col span={ 24 }>
                        <Button
                            type="primary"
                            className={ 'btn-plannings' }
                            size="large"
                            onClick={ () => history.push(`/${ prefixRol }/plannings/new`) }
                        >
                            Agregar planificación
                        </Button>
                    </Col>
                ) }
            </Row>
            <Row className="row-btn-plannings">
                <Col span={ 24 }>
                    <Form
                        form={ form }
                        layout="vertical"
                    >
                        <Row gutter={ 16 }>
                            <Col span={ 8 }>
                                <Form.Item
                                    name="grade_id"
                                    hasFeedback={ errors.grades !== undefined }
                                    validateStatus={ errors.grades !== undefined ? 'error' : undefined }
                                    help={ errors.grades }
                                >
                                    <Select
                                        allowClear
                                        showSearch
                                        className="select-plannings"
                                        loading={ loading.grades }
                                        disabled={ errors.grades !== undefined }
                                        filterOption={ filterOptionsFromLabel }
                                        options={ grades.map(({ id, name }) => ({
                                            label: name,
                                            value: id
                                        })) }
                                        onChange={ handleGradeChange }
                                    />
                                </Form.Item>
                            </Col>

                            <Col span={ 8 }>
                                <Form.Item
                                    name="academic_subject_id"
                                    hasFeedback={ errors.academic_subjects !== undefined }
                                    validateStatus={ errors.academic_subjects !== undefined ? 'error' : undefined }
                                    help={ errors.academic_subjects }
                                >
                                    <Select
                                        allowClear
                                        showSearch
                                        className="select-plannings"
                                        loading={ loading.academic_subjects }
                                        disabled={ (form.getFieldValue('grade_id') ?? '').length === 0 }
                                        filterOption={ filterOptionsFromLabel }
                                        options={ academicSubjects.map(({ id, subject }) => ({
                                            label: subject?.name,
                                            value: id
                                        })) }
                                        onChange={ id => {
                                            if (id !== undefined) {
                                                setAsId(id as string);
                                            } else {
                                                setAsId(null);
                                            }
                                        } }
                                    />
                                </Form.Item>
                            </Col>

                        </Row>
                    </Form>
                </Col>
                <Col span={ 24 }>
                    <EntityRestTable<IAnnualPlanning>
                        serviceClass={ PlanningService }
                        columns={ columns }
                        actions={ actions(history) }
                        entityIdentifier={ ap => ap.id }
                        customGetData={ getPlannings }
                        paginate
                    />
                </Col>
            </Row>
        </Fragment>
    );
};

export default PlanningsHome;
