import React, { Fragment, useEffect, useState }                      from 'react';
import { useHistory }                                                from 'react-router-dom';
import { useKeycloak }                                               from '@react-keycloak/web';
import { useDispatch, useSelector }                                  from 'react-redux';
import ActivityGuideService
                                                                     from '../../api/services/ActivityGuideService';
import { IAppState }                                                 from '../../interfaces/store/IAppState';
import IActivityGuide
                                                                     from '../../interfaces/entities/IActivityGuide';
import { handleAxiosError, isAxiosError }                            from '../../utils/services';
import { AxiosError, AxiosResponse }                                 from 'axios';
import { Card, Col, Empty, Grid, Image, Pagination, Row, Spin, Tag } from 'antd';
import IPaginatedData                                                from '../../interfaces/IPaginatedData';
import { IActivityGuideCollectionState }                             from '../../interfaces/store/states/IActivityGuideCollectionState';
import { setActivityGuideCollection }                                from '../../store/actions/activity-guide-collection';
import moment                                                        from 'moment';
import ILabel                                                        from '../../interfaces/entities/ILabel';
import ImgDocument                                                   from '../../views/images/document.svg';
import DocumentType
                                                                     from '../../views/images/type-resource/file-text.svg';
import Server500                                                     from '../../views/errors/500';
import Searcher                                                      from '../../views/components/Searcher';
import { FormatTag }                                                 from '../../utils/FormatTag';
import { redirectByRole }                                            from '../../utils/redirectByRole';
import useService                                                    from '../../utils/hooks/useService';

const { useBreakpoint } = Grid;

type Props = {
    home?: boolean
    profile?: boolean
    favorite?: boolean
    myResources?: boolean
    draft?: boolean
    paginated?: boolean
    filter?: boolean
}

const ActivityGuidesListShow: React.FC<Props> = ({
                                                     home,
                                                     profile,
                                                     favorite,
                                                     myResources,
                                                     draft,
                                                     paginated,
                                                     filter
                                                 }) => {

    const history = useHistory();
    const { keycloak } = useKeycloak();
    const dispatch = useDispatch();
    const screens = useBreakpoint();

    const activityGuideService = useService(ActivityGuideService);

    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);

    const activityGuideCollectionInstance = useSelector<IAppState, IActivityGuide[] | null>(({ activityGuideCollection }) => activityGuideCollection.activityGuide);
    const ActivityGuideCollectionTotal = useSelector<IAppState, number>(({ activityGuideCollection }) => activityGuideCollection.total);
    const readyForAuthRequests = useSelector<IAppState, boolean>(({ axios }) => axios.readyForRequests);
    const schoolID = useSelector<IAppState, string | null>(({ auth }) => auth.selected_school_id);

    const [paramsSearch, setParamsSearch] = useState<object>();
    const [activityAction, setActivityAction] = useState<boolean>(true);

    useEffect(() => {

        const initialState: IActivityGuideCollectionState = {
            activityGuide: null,
            total: 0
        };

        const getActivityGuides = async () => {
            if (activityGuideService !== null && activityAction) {
                setLoading(true);
                setError(false);

                let response;
                if (home && paramsSearch === undefined) {
                    response = await activityGuideService.allActivityGuides(paginated ? page : undefined, schoolID ?? null);
                } else if (home && paramsSearch !== undefined) {
                    response = await activityGuideService.filter(paramsSearch, page);
                } else if (profile && myResources) {
                    response = await activityGuideService.getMyGuides(paginated ? page : undefined, schoolID ?? null);
                } else if (profile && draft) {
                    response = await activityGuideService.getMyDraft(paginated ? page : undefined, schoolID ?? null);
                } else if (!profile && !home && favorite) {
                    response = await activityGuideService.getFavorite(paginated ? page : undefined, schoolID ?? null);
                }
                if (isAxiosError(response)) {
                    handleAxiosError(response as AxiosError, {
                        404: () => setError(true),
                        default: () => {
                            //    TODO: define message by token expiration if necessary
                        }
                    });
                } else {
                    const { data } = response as AxiosResponse<IPaginatedData<IActivityGuide>>;
                    if (data.data && data.data.length > 0) {
                        const setData: IActivityGuideCollectionState = {
                            activityGuide: data.data,
                            total: data.total
                        };
                        dispatch(setActivityGuideCollection(setData));
                    } else {
                        dispatch(setActivityGuideCollection(initialState));
                    }
                    setActivityAction(false);
                }
                setLoading(false);
            }
        };

        if (readyForAuthRequests) {
            if (keycloak.hasResourceRole('TEACHER') && schoolID !== null && activityGuideService !== null) {
                getActivityGuides().finally();
            } else if (!keycloak.hasResourceRole('TEACHER') && activityGuideService !== null) {
                getActivityGuides().finally();
            }
        }
    }, [keycloak, activityGuideService, dispatch, readyForAuthRequests, schoolID, home, profile, myResources, draft, favorite, paginated, paramsSearch, page, activityAction]);

    const redirectToResource = (id: string) => {
        const routePrefix = draft ? `activity_guides/create/${ id }` : `activity_guide/s/${ id }`;
        const url = redirectByRole(routePrefix);
        history.push(`${ url }`);
    };

    return (
        <Fragment>
            { filter && (
                <Searcher setParamsSearch={ setParamsSearch } setActionResource={ setActivityAction } />
            ) }
            <Row justify="center" className="resource-container" gutter={ [24, 32] }>
                {
                    loading && (
                        <Spin size="large" />
                    )
                }
                { activityGuideCollectionInstance !== null && !loading && ActivityGuideCollectionTotal > 0 && !error
                    ?
                    <Fragment>
                        {
                            activityGuideCollectionInstance.map((activityGuide, index) => (
                                <Col xs={ 24 } sm={ 24 } md={ 12 } lg={ 12 } xl={ 12 } className="resources-wrap"
                                     key={ index }>
                                    <Card key={ activityGuide.id } title={
                                        <Row style={ {
                                            backgroundColor: `${ activityGuide.unit?.academic_subject?.subject?.color }`,
                                            paddingLeft: '10px',
                                            paddingRight: '10px',
                                            borderTopLeftRadius: '16px',
                                            borderTopRightRadius: '16px',
                                            borderColor: 'none'
                                        } }>
                                            <Col span={ 12 }>
                                                <p className="title-card">{ activityGuide.user?.name } { activityGuide.user?.last_name }</p>
                                                <p className="sub-title-card">{ moment(activityGuide.created_at).fromNow() }</p>
                                            </Col>
                                            <Col span={ 12 } className="resource-subtitle"
                                                 style={ { textAlign: 'center' } }>
                                                { activityGuide.unit?.academic_subject?.subject?.name }
                                            </Col>
                                        </Row>
                                    } bordered={ false } hoverable={ true }
                                          onClick={ () => {
                                              redirectToResource(activityGuide.code);
                                          } }
                                          className={ draft ? 'resource-pending' : '' }
                                    >
                                        <Row>
                                            <Col xs={ 24 } sm={ 24 } md={ 12 } lg={ 13 } xl={ 14 }
                                                 className="resource-card-body-tex">
                                                <Row>
                                                    <Col span={ 24 }>
                                                        <p className="resource-title">
                                                            { activityGuide.title }
                                                        </p>
                                                    </Col>
                                                    <Col span={ 24 } className="tag-card">
                                                        { activityGuide.labels.map((label: ILabel, index: number) => (
                                                            <Tag key={ index }>{ FormatTag(label.name, screens) }</Tag>
                                                        )) }
                                                    </Col>
                                                    <Col span={ 24 } className="resource-description">
                                                        <p>
                                                            { activityGuide.description }
                                                        </p>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            {
                                                screens.md && (
                                                    <Col xs={ 24 } sm={ 24 } md={ 12 } lg={ 11 } xl={ 10 }
                                                         className="resource_file">
                                                        <Image style={ { borderBottomRightRadius: '16px' } }
                                                               height={ '100%' }
                                                               preview={ false }
                                                               src={ ImgDocument } alt="" />
                                                        <div className="type_resource">
                                                            <img src={ DocumentType }
                                                                 alt="Type_document" />
                                                        </div>
                                                    </Col>
                                                )
                                            }
                                        </Row>
                                    </Card>
                                </Col>
                            ))
                        }
                    </Fragment>
                    : !loading && !error ? <Empty /> : null
                }
                {
                    paginated && ActivityGuideCollectionTotal !== null && !error && (
                        <Col span={ 24 } style={ { textAlign: 'center', marginTop: '3em' } }>
                            <Pagination total={ ActivityGuideCollectionTotal }
                                        className="paginator"
                                        current={ page }
                                        onChange={ (page) => {
                                            setPage(page);
                                            setActivityAction(true);
                                        } }
                                        defaultCurrent={ 1 }
                                        showSizeChanger={ false }
                                        showTotal={ total => `Total: ${ total }` } />
                        </Col>
                    )
                }
                { error && (
                    <Server500 message="Estamos trabajando en solucionar el  problema, Da click en el botón" />
                ) }
            </Row>
        </Fragment>
    );
};
export default ActivityGuidesListShow;
