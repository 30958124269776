import RestService                                  from '../abstract/RestService';
import { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import IAcademicCharge                              from '../../interfaces/entities/IAcademicCharge';

interface SearchParams {
    academic_subject_id?: string
    school_year_id?: string
    teacher_id?: string
    with?: ('school_year' | 'academic_subject')[]
}

class AcademicChargeService extends RestService<IAcademicCharge> {
    constructor(axios: AxiosInstance) {
        super('academic_charges', axios);
    }

    async all(page?: number): Promise<AxiosResponse<IAcademicCharge[]> | AxiosError> {
        try {
            const params = page !== undefined ? `?page=${ page }` : '';
            return await this.http(this.uri + params);
        } catch (e) {
            return e as AxiosError;
        }
    }

    async AcademicChargeByTeacher(id: string): Promise<AxiosResponse<IAcademicCharge[]> | AxiosError> {
        try {
            return await this.http.get(`${ this.uri }/get_by/teacher/${ id }`);
        } catch (e) {
            return e as AxiosError;
        }
    }

    async store(params: object): Promise<AxiosResponse<IAcademicCharge> | AxiosError> {
        try {
            return await this.http.post(`${ this.uri }`, params);
        } catch (e) {
            return e as AxiosError;
        }
    }

    async delete(id: number): Promise<AxiosResponse | AxiosError> {
        try {
            return await this.http.delete(`${ this.uri }/${ id }`);
        } catch (e) {
            return e as AxiosError;
        }
    }

    async search(params: SearchParams): Promise<AxiosResponse<IAcademicCharge[]> | AxiosError> {
        try {
            return this.http.post(`${ this.uri }/search`, params);
        } catch (e) {
            return e as AxiosError;
        }
    }
}

export default AcademicChargeService;