import React, { Dispatch, SetStateAction, useState }      from 'react';
import { AxiosError }                                     from 'axios';
import { Button, Col, message, Modal, notification, Row } from 'antd';
import { isAxiosError }                                   from '../../../../../../utils/services';
import useServiceByRef                                    from '../../../../../../utils/hooks/useServiceByRef';
import IResource                                          from '../../../../../../interfaces/entities/IResource';
import ResourceService                                    from '../../../../../../api/services/ResourceService';
import { useHistory }                                     from 'react-router-dom';

type Props = {
    resource: IResource
    visibleApprove: boolean
    setVisibleApprove: Dispatch<SetStateAction<boolean>>
};

const ModalApproveResource: React.FC<Props> = ({resource,visibleApprove,setVisibleApprove}) => {
    const history = useHistory();
    const resourceService = useServiceByRef(ResourceService);
    const [action, setAction] = useState<boolean>(false);

    const approveResource = async () => {
        if (resourceService.current && resource !== undefined)
        {
            setAction(true);
            const response = await resourceService.current?.approvedResource(resource.id);
            if (isAxiosError(response)) {
                const err = response as AxiosError;
                message.error(`Ocurrio un error al aprobar el recurso: ${ err.message }`);
            } else {
                notification.success({
                    duration: 2,
                    message: 'Operación finalizada',
                    description: `El recurso ha sido aprobado' `
                });
                history.goBack();
            }
            setAction(false);
        }
    }
    return(
        <Modal
            className={ 'btn-modal-maintenance' }
            visible={ visibleApprove }
            onOk={ approveResource }
            centered={ true }
            destroyOnClose={ true }
            confirmLoading={ action }
            closable={false}
            footer={ [
                <Row gutter={ [16, 16] } key={resource.id}>
                    <Col span={ 12 }>
                        <Button key="back"
                                onClick={ () => {
                                    setVisibleApprove(false);
                                } }
                                shape="round" type="primary"
                                ghost block>
                            Regresar
                        </Button>
                    </Col>
                    <Col span={ 12 }>
                        <Button key="submit"
                                loading={ action } onClick={ approveResource }
                                shape="round" type="primary"
                                block>
                            Aprobar
                        </Button>
                    </Col>
                </Row>
            ] }
        >
            <h4 className="modal-approve-resource modal-text-center">Aprobar recurso</h4>
            <p className="modal-text-center">¿Estás seguro de aprobar este recurso?</p>
        </Modal>
    );
}
export default ModalApproveResource;