import React, { Fragment } from 'react';
import ImgType             from '../../../../views/images/type-resource/image.svg';
import IRecord             from '../../../../interfaces/entities/IRecord';
import { Image }           from 'antd';

type Props = {
    records?: IRecord[];
};

const ImageResourceType: React.FC<Props> = ({ records }) => {
    return (
        <Fragment>
            { records !== undefined && records.length > 0 && (
                <Image
                    style={ { borderBottomRightRadius: '16px' } }
                    height={ '100%' }
                    preview={ false }
                    src={ records?.[0].file_url }
                    alt={ records?.[0].name } />
            ) }
            <div className="type_resource">
                <img src={ ImgType } alt="Type_Image" />
            </div>
        </Fragment>
    );
};
export default ImageResourceType;
