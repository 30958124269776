import RestService                                  from '../abstract/RestService';
import { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import IESchoolAcademicCharge                       from '../../interfaces/entities/IESchoolAcademicCharge';

class ESchoolAcademicChargeService extends RestService<IESchoolAcademicCharge> {
    constructor(axios: AxiosInstance) {
        super('e_school/academic_charge', axios);
    }

    async all(page?: number): Promise<AxiosResponse<IESchoolAcademicCharge[]> | AxiosError> {
        try {
            const params = page !== undefined ? `?page=${ page }` : '';
            return await this.http(this.uri + params);
        } catch (e) {
            return e as AxiosError;
        }
    }

    async AcademicChargeByTeacher(id: string): Promise<AxiosResponse<IESchoolAcademicCharge> | AxiosError> {
        try {
            return await this.http.get(`${ this.uri }/get_by/teacher/${ id }`);
        } catch (e) {
            return e as AxiosError;
        }
    }

    async AcademicChargeSyncLogged(): Promise<AxiosResponse<IESchoolAcademicCharge> | AxiosError> {
        try {
            return await this.http.get(`${ this.uri }/sync/logged`);
        } catch (e) {
            return e as AxiosError;
        }
    }

}

export default ESchoolAcademicChargeService;