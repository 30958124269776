import { Card, Col, notification, Spin, Tag }   from 'antd';
import React, { useEffect, useState, Fragment } from 'react';
import useServiceByRef                          from '../../../../utils/hooks/useServiceByRef';
import { handleAxiosError, isAxiosError }       from '../../../../utils/services';
import { AxiosError, AxiosResponse }            from 'axios';
import IResource                                from '../../../../interfaces/entities/IResource';
import ResourceService                          from '../../../../api/services/ResourceService';
import { useHistory }                           from 'react-router-dom';
import ILabel                                   from '../../../../interfaces/entities/ILabel';
import { useSelector }                          from 'react-redux';
import { IAppState }                            from '../../../../interfaces/store/IAppState';
import keycloak                                 from '../../../../keycloak.config';
import { redirectByRole }                       from '../../../../utils/redirectByRole';

type Props = {
    rendering: boolean
    labels: ILabel[] | undefined
}

const Recommendation: React.FC<Props> = ({ rendering, labels }) => {
    const history = useHistory();
    const resourceService = useServiceByRef(ResourceService);

    const [loadingRecommendation, setLoadingRecommendation] = useState<boolean>(false);
    const [refreshRecommendation, setRefreshRecommendation] = useState<boolean>(false);
    const [recommendations, setRecommendations] = useState<IResource[]>([]);
    const [labelsId, setLabelsId] = useState<string[]>([]);
    const schoolID = useSelector<IAppState, string | null>(({ auth }) => auth.selected_school_id);

    const readyForAuthRequests = useSelector<IAppState, boolean>(({ axios }) => axios.readyForRequests);

    useEffect(() => {
        if (labels) {
            setLabelsId(labels.map(label => label['id']));
        }
    }, [labels]);

    useEffect(() => {
        if (readyForAuthRequests && labelsId.length > 0 && keycloak.authenticated && labels !== undefined) {
            const getRecommendationsLatest = async () => {
                if (resourceService.current) {
                    setLoadingRecommendation(true);
                    const response = await resourceService.current.filterByLabels({ labels: labelsId }, schoolID ?? null);
                    if (isAxiosError(response)) {
                        handleAxiosError(response as AxiosError, {
                            404: () => {
                                const err = response as AxiosError;
                                notification.success({
                                    message: err.message,
                                    description: 'No se encontraron recomendaciones',
                                    duration: 3,
                                });
                            },
                            default: () => {
                                //    TODO: define message by token expiration if necessary
                                setRefreshRecommendation(true);
                            }
                        });
                        setLoadingRecommendation(false);
                    } else {
                        const { data } = response as AxiosResponse<IResource[]>;
                        if (data.length > 0) {
                            setRecommendations(data);
                        }
                        setLoadingRecommendation(false);
                        setRefreshRecommendation(false);
                    }
                }
            };
            if (keycloak.hasResourceRole('TEACHER') && schoolID !== null) {
                getRecommendationsLatest().finally();
            } else if (!keycloak.hasResourceRole('TEACHER')) {
                getRecommendationsLatest().finally();
            }
        }
    }, [resourceService, refreshRecommendation, labelsId, schoolID, labels, readyForAuthRequests]);

    const redirectToResource = (url: string) => {
        setRefreshRecommendation(true);
        if (keycloak.authenticated && url !== '') {
            const urlRole = redirectByRole(url);
            history.push(`${ urlRole }`);
        } else {
            keycloak.login();
        }
    };

    return (
        <Fragment>
            <Col offset={ 1 } lg={ 6 } md={ 6 } sm={ 24 } xs={ 24 } className="content-recommendation-resource">
                <h2 className="title-recommendation">{ 'Recomendaciones' }</h2>
                {
                    loadingRecommendation && !rendering && (
                        <Col span={ 24 } className="spinning-actions-resource">
                            <Spin size="large" />
                        </Col>
                    )
                }
                { !rendering && !loadingRecommendation && recommendations !== undefined && (
                    <>
                        { recommendations.map((resource, index) => (
                            <Card key={ index } title={ resource.unit?.academic_subject?.subject?.name }
                                  className="recommendation-card"
                                  headStyle={ {
                                      backgroundColor: `${ resource.unit?.academic_subject?.subject?.color }`,
                                      borderTopLeftRadius: '0.5em',
                                      borderTopRightRadius: '0.5em'
                                  } }
                                  bordered={ false } hoverable={ true } onClick={ () => redirectToResource(
                                resource.resource_type_id === 6
                                    ? `didactic_map/${ resource.didactic_map?.id }`
                                    : `resource/${ resource.id }`
                            ) }
                            >
                                <div className={ 'body-card-recommendation' }>
                                    <p className={ 'recommendation-title-card' }>{ resource.name }</p>
                                    <div className="tag-card">
                                        { resource.labels?.map((label, index_) => (
                                            <Tag key={ index_ }
                                                 className="tag-resource">{ label.name.length > 20 ? label.name.substring(0, 20) + '...' : label.name }</Tag>
                                        )) }
                                    </div>
                                </div>
                            </Card>
                        )) }
                    </>
                ) }
            </Col>
        </Fragment>
    );
};
export default Recommendation;
