import React, { useCallback, useEffect, useState } from 'react';
import useServiceByRef                             from '../../../utils/hooks/useServiceByRef';
import { useSelector }                             from 'react-redux';
import { IAppState }                               from '../../../interfaces/store/IAppState';
import ActivityGuideService, {
    StoreActivityGuideParams,
    UpdateActivyGuideParams
}                                                  from '../../../api/services/ActivityGuideService';
import ActivityGuideForm, { handleFinishType }     from '../ActivityGuideForm';
import { isAxiosError }                            from '../../../utils/services';
import { message, notification, Spin }             from 'antd';
import formatActivityGuideData                     from '../ActivityGuideForm/formatActivityGuideData';
import { useHistory, useParams }                   from 'react-router-dom';
import { AxiosError, AxiosResponse }               from 'axios';
import IActivityGuide                              from '../../../interfaces/entities/IActivityGuide';
import { redirectByRole }                          from '../../../utils/redirectByRole';

type Props = {
    teacher?: boolean
}

type Params = {
    draft_id?: string
}

type HandleFormActionType = (handleProps: handleFinishType) => void;

const CreateActivityGuides: React.FC<Props> = (props) => {
    const { teacher = false } = props;
    const history = useHistory();
    const { draft_id } = useParams<Params>();
    const [draft, setDraft] = useState<IActivityGuide | null>(null);

    const selected_school_id = useSelector<IAppState, string | null>(({ auth }) => auth.selected_school_id);

    const activityGuideService = useServiceByRef(ActivityGuideService);

    const getDraft = useCallback(async () => {
        if (
            draft_id !== undefined &&
            activityGuideService.current &&
            ((teacher && selected_school_id !== null) || !teacher)
        ) {
            const res = await activityGuideService.current.getDraft(draft_id, selected_school_id);

            if (isAxiosError(res)) {
                message.error('El borrador al cual deseas acceder no existe o no tienes permisos para modificarlo.');
                const url = redirectByRole('activity_guides/create/');
                history.push(`${ url }`);
            } else {
                const { data } = res as AxiosResponse<IActivityGuide>;
                setDraft(data);
            }
        }
    }, [activityGuideService, draft_id, history, selected_school_id, teacher]);

    const handleFinish: HandleFormActionType = async (handleFinishProps) => {
        const { values, labels, rerender, form } = handleFinishProps;
        if (typeof activityGuideService.current === 'undefined') {
            return;
        }

        const data = formatActivityGuideData('CREATE', {
            activityGuide: null, values, labels, teacher, selected_school_id
        }) as StoreActivityGuideParams;

        if (draft_id !== undefined) {
            data.draft_id = draft_id;
        }

        if (Object.keys(data.resources).length === 0) {
            form.setFields([{
                name: 'resources',
                errors: ['Debes agregar una indicación o seleccionar un recurso en por lo menos un día asignado.']
            }]);

            rerender();
        } else {
            form.setFields([{
                name: 'resources',
                errors: []
            }]);

            rerender();

            const res = await activityGuideService.current.store(data);

            if (isAxiosError(res)) {
                const err = res as AxiosError;
                notification.error({
                    message: 'Ha ocurrido un error. La guía de actividades no pudo ser ingresada...',
                    description: err.message
                });
            } else {
                notification.success({
                    message: 'La guía de actividades se ha registrado éxitosamente'
                });

                const url = redirectByRole('');
                history.push(`${ url }`);
            }
        }
    };

    const handleAsDraft: HandleFormActionType = async (handleDraftProps) => {
        const { values, labels } = handleDraftProps;

        if (typeof activityGuideService.current === 'undefined') {
            return;
        }

        const data = formatActivityGuideData('CREATE', {
            activityGuide: null, values, labels, teacher, selected_school_id,
        }) as StoreActivityGuideParams;

        data.is_draft = true;

        const res = await activityGuideService.current.store(data);

        if (isAxiosError(res)) {
            const err = res as AxiosError;
            notification.error({
                message: 'Ha ocurrido un error. El borrador no ha podido ser guardado...',
                description: err.message
            });
        } else {
            const { data } = res as AxiosResponse<IActivityGuide>;

            notification.success({
                message: 'Se ha guardado el borrador'
            });

            const url = redirectByRole(`activity_guides/create/${ data.id }`);
            history.push(`${ url }`);
        }
    };

    const handleUpdateDraft: HandleFormActionType = async (handleFinishProps) => {
        if (typeof activityGuideService.current === 'undefined') {
            return;
        }

        const { values, labels } = handleFinishProps;

        if (draft === null)
            return;

        if (typeof activityGuideService.current === 'undefined')
            return;

        const data = formatActivityGuideData('UPDATE', {
            values, labels, activityGuide: draft, teacher, selected_school_id
        }) as UpdateActivyGuideParams;

        const res = await activityGuideService.current.updateDraft(draft.id, data);

        if (isAxiosError(res)) {
            const err = res as AxiosError;
            notification.error({
                message: 'Ha ocurrido un error. El borrador no ha podido ser modificado...',
                description: err.message
            });
        } else {
            notification.success({
                message: 'El borrador ha sido modificado'
            });
        }
    };

    useEffect(() => {
        // noinspection JSIgnoredPromiseFromCall
        getDraft();
    }, [getDraft]);

    return (
        <div id="create-activity-guides-component">
            { draft_id !== undefined && (
                <Spin spinning={ draft === null }>
                    { draft !== null && (
                        <ActivityGuideForm
                            activityGuide={ draft }
                            mode="UPDATE"
                            onFinish={ handleFinish }
                            onDraft={ handleUpdateDraft }
                        />
                    ) }
                </Spin>
            ) }

            { draft_id === undefined && (
                <ActivityGuideForm onFinish={ handleFinish } onDraft={ handleAsDraft } />
            ) }
        </div>
    );
};

export default CreateActivityGuides;
