import { RouteProps }      from 'react-router-dom';
import React, { Fragment } from 'react';
import LayoutRoute         from '../LayoutRoute';
import LayoutResource      from '../../../views/layouts/Resource';
import AdminLayout       from '../../../views/layouts/Admin';

interface Props extends RouteProps {
    resource: boolean
}

const PrincipalRoute: React.FC<Props> = ({ ...rest }) => {
    return (
        <Fragment>
            <LayoutRoute
                { ...rest }
                Layout={ rest.resource ? LayoutResource : AdminLayout }
                keyCloakValidation={ keycloak => keycloak.hasResourceRole('PRINCIPAL') }
            >
            </LayoutRoute>
        </Fragment>
    );
};
export default PrincipalRoute;