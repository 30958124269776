import React, { Fragment, useEffect, useState } from 'react';
import ResourcesList                            from '../../../components/Resources';
import { useKeycloak }                          from '@react-keycloak/web';

const Home: React.FC = () => {
    const { initialized, keycloak } = useKeycloak();
    const [logged, setLogged] = useState<boolean>(false);

    useEffect(() => {
        if (initialized) {
            if (keycloak.authenticated) {
                setLogged(true);
            }
        }
    }, [keycloak, initialized]);


    return (
        <Fragment>
            <ResourcesList
                paginated
                filter
                logged={ logged }
                profile={ false }
                favorite={ false }
                home={ true }
            />
        </Fragment>
    );
};

export default Home;
