import { Button, Col, Form, Row, Select, Spin } from 'antd';
import React                                    from 'react';
import IGrade                                   from '../../../../../../interfaces/entities/IGrade';
import ISubject                                 from '../../../../../../interfaces/entities/ISubject';
import { LoadingType }                          from '../../type';
import { FormInstance }                         from 'antd/es/form';
import { OptionData }                           from 'rc-select/lib/interface';

type Props = {
    form: FormInstance;
    handleGradesOptionsFormat: (subjects: IGrade[]) => OptionData[];
    grades: IGrade[];
    loading: LoadingType;
    assignGrade: (id: number) => void;
    subjects: ISubject[];
    handleSubjectsOptionsFormat: (subjects: ISubject[]) => OptionData[];
    assignSubject: (id: number) => void;
    temporaryStore: () => void;
    loadingSyncCharge: boolean;
};

const CreateAcademicCharge: React.FC<Props> = ({
                                                   form, grades,
                                                   loading,
                                                   assignGrade,
                                                   handleGradesOptionsFormat,
                                                   subjects,
                                                   handleSubjectsOptionsFormat,
                                                   assignSubject,
                                                   temporaryStore,
                                                   loadingSyncCharge
                                               }) => {
    return (
        <Form
            layout={ 'vertical' }
            form={ form }
            name={ 'frmChargeAcademic' }
        >
            <Spin spinning={ loadingSyncCharge }>
                <Row gutter={ [24, 32] }>
                    <Col xs={ 24 } sm={ 24 } md={ 12 } lg={ 12 } xl={ 12 }>
                        <Form.Item
                            label={ <h3 className="title-sub-1">{ 'Grado' }</h3> }
                            name="grade_id"
                            key={ 'grade_id' }
                        >
                            <Select
                                allowClear
                                showArrow
                                showSearch
                                placeholder={ 'Selecciona' }
                                className={ 'select-schools' }
                                options={ handleGradesOptionsFormat(grades ?? []) }
                                loading={ loading.grade }
                                onChange={ assignGrade }
                                getPopupContainer={ trigger => trigger.parentNode }
                                optionFilterProp="label"
                                disabled={ grades === undefined }
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={ 24 } sm={ 24 } md={ 12 } lg={ 12 } xl={ 12 }>
                        <Form.Item
                            label={ <h3 className="title-sub-1">{ 'Materia' }</h3> }
                            name="subject_id"
                            key={ 'subject_id' }
                        >
                            <Select
                                allowClear
                                showArrow
                                showSearch
                                placeholder={ 'Selecciona' }
                                className={ 'select-schools' }
                                options={ handleSubjectsOptionsFormat(subjects ?? []) }
                                loading={ loading.subject }
                                onChange={ assignSubject }
                                getPopupContainer={ trigger => trigger.parentNode }
                                optionFilterProp="label"
                                disabled={ subjects === undefined }
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={ 24 }>
                        <Button disabled={ loading.grade && loading.subject } type={ 'primary' }
                                onClick={ temporaryStore }>Agregar</Button>
                    </Col>
                </Row>
            </Spin>
        </Form>
    );
};
export default CreateAcademicCharge;
