import React, { Fragment } from 'react';
import ReactPlayer         from 'react-player';
import VideoType           from '../../../../views/images/type-resource/video.svg';
import './video.less';
import IRecord             from '../../../../interfaces/entities/IRecord';
import { useKeycloak }     from '@react-keycloak/web';

type Props = {
    records?: IRecord[];
};

const VideoResourceType: React.FC<Props> = ({ records }) => {
    const { keycloak } = useKeycloak();
    return (
        <Fragment>
            <div className="video-home">
                { records !== undefined && records.length > 0 && (
                    <ReactPlayer
                        style={ { borderBottomRightRadius: '16px' } }
                        className={ 'video-resource-action' }
                        url={ records?.[0].file_url }
                        width="97%"
                        height="100%"
                        controls={ keycloak.authenticated }
                    />
                ) }
            </div>
            <div className="type_resource">
                <img src={ VideoType }
                     alt="Type_document" />
            </div>
        </Fragment>
    );
};
export default VideoResourceType;
