import { ModalFuncProps } from 'antd';
import React              from 'react';
import RegisterForm       from './RegisterForm';

const registerModalProps: (closeModal: () => void) => ModalFuncProps = (closeModal) => ({
    width: '40%',
    className: 'mdl-register-user',
    icon: false,
    okButtonProps: {
        hidden: true
    },
    maskClosable: true,
    title: (
        <h1 className="mdl-register-user-title">Crear cuenta</h1>
    ),
    content: <RegisterForm closeModal={ closeModal } />,
});

export default registerModalProps;