import React                                                                        from 'react';
import { Button, Checkbox, Col, Form, InputNumber, Row }                            from 'antd';
import { LoadingType, PlanningTableFunctionsHandler, PlanningTableInteractionType } from './types';
import { ErrorsType }                                                               from '../CreatePlanning/types';
import './BottomFormFields.less';
import { months }                                                                   from '../../../types/entities/months';

type Props = {
    planningTableRef: React.RefObject<PlanningTableFunctionsHandler | undefined>
    loading: LoadingType
    errors: ErrorsType
    handleInteractionChange?: (interactionType: PlanningTableInteractionType) => void
}

const BottomFormFields: React.FC<Props> = (props) => {
    const { planningTableRef, loading, errors, handleInteractionChange } = props;

    const updateAllPlanningValues = () => {
        const { current } = planningTableRef;

        if (current !== null && current !== undefined) {
            months.forEach(m => current.handleTableChange(m, false));
            current.calcCategoriesTotals();
        }
    };

    return (
        <div id="bottom-form-fields-component">
            <Row align="middle">
                <Col span={ 4 }>
                    <Form.Item
                        label="Total de horas semanales"
                        name="total_hours"
                        initialValue={ 5 }
                    >
                        <InputNumber
                            style={ { width: '100%' } }
                            size="large"
                            onChange={ updateAllPlanningValues }
                            type="number"
                            min={ 1 }
                            max={ 40 }
                        />
                    </Form.Item>
                </Col>

                { handleInteractionChange !== undefined && (
                    <Col className="interaction-col">
                        <Form.Item
                            name="interaction_type"
                            valuePropName="checked"
                        >
                            <Checkbox
                                onChange={ e => handleInteractionChange(e.target.checked ? 'MANUAL' : 'AUTO') }
                            >
                                Cambiar horas clase/mes de forma manual
                            </Checkbox>
                        </Form.Item>
                    </Col>
                ) }
            </Row>

            <Row justify="center">
                <Col span={ 8 }>
                    <Button
                        block
                        type="primary"
                        htmlType="submit"
                        loading={ loading.form }
                        disabled={ Object.keys(errors).length !== 0 }
                        className={ 'btn-plannings' }
                    >
                        Guardar
                    </Button>
                </Col>
            </Row>
        </div>
    );
};

export default BottomFormFields;