import RestService                                  from '../abstract/RestService';
import { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import IResourceType                                from '../../interfaces/entities/IResourceType';

class ResourceTypeService extends RestService<IResourceType> {
    constructor(axios: AxiosInstance) {
        super('resource_types', axios);
    }

    async all(): Promise<AxiosResponse<IResourceType[]> | AxiosError> {
        try {
            return await this.http.get(this.uri);
        } catch (e) {
            return e as AxiosError;
        }
    }

    async getAllForHome(): Promise<AxiosResponse<IResourceType[]> | AxiosError> {
        try {
            return await this.http.get(`${this.uri}/for_home`);
        } catch (e) {
            return e as AxiosError;
        }
    }
}

export default ResourceTypeService;