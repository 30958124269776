import IDidacticMap                                 from "../../interfaces/entities/IDidacticMap";
import RestService                                  from "../abstract/RestService";
import { AxiosError, AxiosInstance, AxiosResponse } from "axios";
import { IFormFields, InsertResponse }              from "../../views/resource/didacticMap/types";
import IPaginatedData                               from '../../interfaces/IPaginatedData';

class DidacticMapService extends RestService<IDidacticMap> {
    constructor(axios: AxiosInstance) {
        super('didactic_map', axios);
    }

    async all(page?: number): Promise<AxiosResponse<IDidacticMap[] | IPaginatedData<IDidacticMap>> | AxiosError> {
        try {
            const params = page !== undefined ? `?page=${ page }` : '';
            return await this.http.get(`${ this.uri }${ params }`);
        } catch ( e ) {
            return e as AxiosError;
        }
    }

    async store(params: IFormFields): Promise<AxiosResponse<InsertResponse> | AxiosError> {
        try {
            return await this.http.post(this.uri, params);
        } catch ( e ) {
            return e as AxiosError;
        }
    }

    async update(params: IFormFields, id: string): Promise<AxiosResponse<InsertResponse> | AxiosError> {
        try {
            return await this.http.put(`${ this.uri }/${ id }`, params);
        } catch ( e ) {
            return e as AxiosError;
        }
    }

    async get(id: string): Promise<AxiosResponse<IDidacticMap> | AxiosError> {
        try {
            return await this.http.get(`${ this.uri }/${ id }`);
        } catch ( e ) {
            return e as AxiosError;
        }
    }

    async delete(id: string): Promise<AxiosResponse | AxiosError> {
        try {
            return await this.http.delete(`${ this.uri }/${ id }`);
        } catch ( e ) {
            return e as AxiosError;
        }
    }

    async copy(didactic_map_id: string): Promise<AxiosResponse | AxiosError> {
        try {
            return await this.http.post(`${ this.uri }/copy`, { didactic_map_id });
        } catch ( e ) {
            return e as AxiosError;
        }
    }

    async print(didactic_map_id: string): Promise<AxiosResponse | AxiosError> {
        try {
            return await this.http.post(`${ this.uri }/print/${ didactic_map_id }`);
        } catch ( e ) {
            return e as AxiosError;
        }
    }

}

export default DidacticMapService;