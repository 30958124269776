import Keycloak from 'keycloak-js';

const keycloakConfig: Keycloak.KeycloakConfig = {
    url: process.env.REACT_APP_KEYCLOACK_HOST,
    realm: process.env.REACT_APP_KEYCLOAK_REALM ?? 'master',
    clientId: process.env.REACT_APP_KEYCLOACK_CLIENT_ID as string
};

const keycloak: Keycloak.KeycloakInstance = Keycloak(keycloakConfig);

export default keycloak;