import { AxiosError }                                           from 'axios';
import { isAxiosErrorType, handleAxiosErrorType, ResponseKeys } from '../types/utils/services';

export const isAxiosError: isAxiosErrorType = object => {
    if (!object)
        return false;

    const err = object as AxiosError;

    const serverError = err.isAxiosError !== undefined && err.isAxiosError;
    const clientError = (err.code !== undefined && err.code !== '200') || (err.response?.status !== undefined && err.response?.status !== 200);

    return (serverError || clientError);
};

export const handleAxiosError: handleAxiosErrorType = (error, callbacks) => {
    const allowCodes = Object.keys(callbacks);

    const callDefault = () => {
        if (typeof callbacks.default === 'function') {
            callbacks.default(error);
        }
    }

    if (error.code !== undefined && error.code === '500') {
        if (typeof callbacks['500'] === 'function') {
            callbacks["500"](error);
        } else if (typeof callbacks.default === 'function') {
            callbacks.default(error);
        }
    } else {
        const status: string | undefined = error.response?.status.toString();

        if (status && allowCodes.indexOf(status) !== -1) {
            try {
                const key = allowCodes[allowCodes.indexOf(status)] as unknown as ResponseKeys;
                const call = callbacks[key];

                if (call !== undefined && typeof call === 'function' && error.response !== undefined) {
                    call(error.response);
                } else {
                    callDefault();
                }

            } catch ( e ) {
                callDefault();
            }
        } else {
            callDefault();
        }

    }
};