import React, { useCallback, useEffect, useState }       from 'react';
import { useHistory, useParams }                         from 'react-router-dom';
import useServiceByRef                                   from '../../../utils/hooks/useServiceByRef';
import ActivityGuideService, { UpdateActivyGuideParams } from '../../../api/services/ActivityGuideService';
import { useSelector }                                   from 'react-redux';
import { IAppState }                                     from '../../../interfaces/store/IAppState';
import { handleAxiosError, isAxiosError }                from '../../../utils/services';
import IActivityGuide                                    from '../../../interfaces/entities/IActivityGuide';
import { AxiosError, AxiosResponse }                     from 'axios';
import ActivityGuideForm, { handleFinishType }           from '../ActivityGuideForm';
import { IKeycloakUserInfo }                             from '../../../interfaces/store/states/IAuthState';
import { message, notification, Spin }                   from 'antd';
import formatActivityGuideData                           from '../ActivityGuideForm/formatActivityGuideData';

import { redirectByRole } from '../../../utils/redirectByRole';

type Props = {
    teacher?: boolean
}

type Params = {
    activity_guide_id: string
}

const UpdateActivityGuides: React.FC<Props> = ({ teacher = false }) => {
    const { activity_guide_id } = useParams<Params>();
    const activityGuideService = useServiceByRef(ActivityGuideService);
    const history = useHistory();

    const selected_school_id = useSelector<IAppState, string | null>(({ auth }) => auth.selected_school_id);
    const keycloakInfo = useSelector<IAppState, IKeycloakUserInfo | null>(({ auth }) => auth.keycloakUserInfo);

    const [activityGuide, setActivityGuide] = useState<IActivityGuide | null>(null);

    const getActivityGuide = useCallback(async () => {
        const schoolParam = teacher && selected_school_id !== null ? { school_id: selected_school_id } : null;

        if (activityGuideService.current && ((teacher && schoolParam !== null) || !teacher)) {
            const res = await activityGuideService.current.get(activity_guide_id, schoolParam);

            if (isAxiosError(res)) {
                handleAxiosError(res as AxiosError, {
                    404: () => message.error('La guía de actividades a la cual quieres acceder no existe.'),
                    403: () => message.error('No tienes permisos para acceder a esta guía de actividades.'),
                    default: error => message.error(`Ocurrió un error al intentar obtener la guía de actividades. ${ error.message }.`)
                });

                const url = redirectByRole('activity_guides');
                history.push(`${ url }`);
            } else {
                const { data } = res as AxiosResponse<IActivityGuide>;
                setActivityGuide(data);
            }
        }
    }, [activityGuideService, activity_guide_id, history, selected_school_id, teacher]);

    const onFinish = async (handleFinishProps: handleFinishType) => {
        const { values, labels, rerender, form } = handleFinishProps;

        if (activityGuide === null)
            return;

        if (typeof activityGuideService.current === 'undefined')
            return;

        const data = formatActivityGuideData('UPDATE', {
            values, labels, activityGuide, teacher, selected_school_id
        }) as UpdateActivyGuideParams;

        if (Object.keys(data.resources).length === 0) {
            form.setFields([{
                name: 'resources',
                errors: ['Debes agregar una indicación o seleccionar un recurso en por lo menos un día asignado.']
            }]);

            rerender();
        } else {
            form.setFields([{
                name: 'resources',
                errors: []
            }]);

            rerender();

            const res = await activityGuideService.current.update(activityGuide.id, data);

            if (isAxiosError(res)) {
                const err = res as AxiosError;
                notification.error({
                    message: 'Ha ocurrido un error. La guía de actividades no pudo ser modificada...',
                    description: err.message
                });
            } else {
                notification.success({
                    message: 'La guía de actividades se ha modificado éxitosamente'
                });

                // Maybe redirect to another view? Or reset form. Dunno, ask for indications.
                const url = redirectByRole('');
                history.push(`${ url }`);
            }
        }
    };

    useEffect(() => {
        // noinspection JSIgnoredPromiseFromCall
        getActivityGuide();
    }, [getActivityGuide]);

    useEffect(() => {
        if (activityGuide !== null && keycloakInfo !== null) {
            if (
                activityGuide.user_id !== keycloakInfo.sub
                && (!teacher || activityGuide.authorship?.user_school?.school_id !== selected_school_id)
            ) {
                // noinspection JSIgnoredPromiseFromCall
                message.error('No puedes modificar esta guía de actividades porque no te pertenece.');
                // Redirect cuz the activity guide doesn't belongs to the user
                const url = redirectByRole('activity_guides');
                history.push(`${ url }`);
            }
        }
    }, [activityGuide, history, keycloakInfo, selected_school_id, teacher]);

    return (
        <div>
            <Spin spinning={ activityGuide === null }>
                { activityGuide !== null && (
                    <ActivityGuideForm activityGuide={ activityGuide } mode="UPDATE" onFinish={ onFinish } />
                ) }
            </Spin>
        </div>
    );
};

export default UpdateActivityGuides;
