import React, { Fragment } from 'react';
import Img404                                        from '../images/errors/404.svg';
import { Button, Col, Row, Typography } from 'antd';
import { useHistory }                   from 'react-router-dom';

const { Text, Title } = Typography;

type Props = {
   message?: string;
}

const NotFoundError404: React.FC<Props> = ({message}) => {
    const history = useHistory();
    return (
        <Fragment>
            <Row gutter={ [24, 8] } className="row-errors">
                <Col span={ 24 }>
                    <img src={ Img404 } alt="404"/>
                </Col>
                <Col span={ 24 }>
                    <p><Title>¡Oooh! Algo a salido mal</Title></p>
                    <p><Title level={ 4 }>
                        <Text type="secondary">
                            {message !== undefined ? message : 'Estamos trabajando en solucionar el problema.'}
                        </Text>
                    </Title></p>
                </Col>
                <Col span={ 12 } offset={ 6 }>
                    <Button type={ 'primary' } shape={ 'round' } block onClick={()=>{history.goBack()}}>
                        Regresar
                    </Button>
                </Col>
            </Row>
        </Fragment>
    );
};
export default NotFoundError404;