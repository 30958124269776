import RestService                                  from '../abstract/RestService';
import { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import INotification                                from '../../interfaces/entities/INotification';
import IPaginatedData                               from '../../interfaces/IPaginatedData';

class NotificationService extends RestService<INotification>{
    constructor(axios: AxiosInstance) {
        super('notifications', axios);
    }

    /**
     * GET ALL NOTIFICATION BY USER
     * @param page
     */
    async all(page?: number): Promise<AxiosResponse<INotification[] | IPaginatedData<INotification>> | AxiosError> {
        try {
            const params = page !== undefined ? `?page=${ page }` : '';
            return await this.http.get(`${this.uri}/get_by/user` + params);
        } catch (e) {
            return e as AxiosError;
        }
    }

    /**
     *  DELETE ALL NOTIFICATION
     */
    async delete():Promise<AxiosResponse | AxiosError>{
        try {
            return await this.http.delete(`${this.uri}`);
        }catch (e) {
            return e as AxiosError;
        }
    }
}
export default NotificationService;