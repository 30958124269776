import { DayType } from '../../../types/entities/days';

export type FormValues = {
    title: string,
    description: string,
    labels: string[],
    grade_id: string,
    academic_subject_id: string,
    unit_id: string,
    indicator_id: string[],
    accessibility: 'PUBLIC' | 'PRIVATE',
    resources: AssignedResourcesType
}

export type AssignedResourcesType = {
    [k in DayType]: {
        indication: string | undefined | null,
        id: string[]
    }
}

export enum AssignationEntitiesEnum {
    grades = 'grades',
    academic_subjects = 'academic_subjects',
    units = 'units',
    indicators = 'indicators'
}

export enum EntitiesEnum {
    days = 'days',
    labels = 'labels'
}