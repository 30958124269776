import React, { Fragment, useEffect, useState } from 'react';
import ResourcesList                            from '../../../components/Resources';
import { Row, Col, Tabs }               from 'antd';
import { useKeycloak }                          from '@react-keycloak/web';
import ActivityGuidesListShow                   from '../../../components/ActivityGuides';
import BreadCrumbResources                      from '../components/BreadCrumbResources';

const { TabPane } = Tabs;

const FavoriteResources: React.FC = () => {
    const { initialized, keycloak } = useKeycloak();
    const [logged, setLogged] = useState<boolean>(false);

    useEffect(() => {
        if (initialized) {
            if (keycloak.authenticated) {
                setLogged(true);
            }
        }
    }, [keycloak, initialized]);

    return (
        <Fragment>
            <BreadCrumbResources name="Favoritos" />
            {
                logged && (
                    <Fragment>
                        <Row>
                            <Col span={ 24 }>
                                <h2 className="title-favorite">{ 'Favoritos' }</h2>
                            </Col>
                        </Row>
                        <Tabs className="tabs-profile" defaultActiveKey="1" destroyInactiveTabPane={ true }>
                            <TabPane tab="Mis recursos" key="1">
                                <ResourcesList
                                    paginated
                                    logged={ logged }
                                    favorite={ true }
                                    profile={ false }
                                    draft={ false }
                                    myResources={ false }
                                    pendingApproval={ false }
                                />
                            </TabPane>
                            <TabPane tab="Guia de actividades" key={ 'all_activity guide' }>
                                <ActivityGuidesListShow
                                    paginated
                                    profile={ false }
                                    myResources={ false }
                                    home={ false }
                                    draft={ false }
                                    favorite
                                />
                            </TabPane>
                        </Tabs>
                    </Fragment>
                )
            }
        </Fragment>
    );
};
export default FavoriteResources;
