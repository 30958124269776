import { SearchAcademicSubject, ViewEntitiesEnum } from './types';
import React                                       from 'react';
import AcademicSubjectService        from '../../../api/services/AcademicSubjectService';
import IAcademicSubject              from '../../../interfaces/entities/IAcademicSubject';
import AcademicChargeService         from '../../../api/services/AcademicChargeService';
import Keycloak                      from 'keycloak-js';
import IAcademicCharge               from '../../../interfaces/entities/IAcademicCharge';
import { isAxiosError }              from '../../../utils/services';
import { AxiosError, AxiosResponse } from 'axios';
import { LoadingType }               from '../components/types';

export const initialLoadingValues = Object.keys(ViewEntitiesEnum).reduce(
    (a, b) => Object.assign(a, { [b]: false }), {}
) as LoadingType;

type aType = (
    service: React.MutableRefObject<AcademicSubjectService | undefined>,
    data: SearchAcademicSubject,
    onSuccess: (data: IAcademicSubject[]) => void,
    onError?: (err: AxiosError) => void,
) => void;

type bType = (
    service: React.MutableRefObject<AcademicChargeService | undefined>,
    as_id: string,
    keycloak: Keycloak.KeycloakInstance,
    onSuccess: (data: IAcademicCharge[]) => void,
    onError?: (err: AxiosError) => void
) => void;

export const searchAcademicSubjects: aType = async (service, data, onSuccess, onError) => {
    if (service.current) {
        const {grade_id, school_id} = data;

        const res = school_id !== null ?
            await service.current.search({
                grade_id,school_id,
                with: ['subject']
            })
            : await service.current.search({
            grade_id,
            with: ['subject']
        });

        if (isAxiosError(res)) {
            const err = res as AxiosError;
            if (typeof onError !== 'undefined') {
                onError(err);
            }
        } else {
            const { data } = res as AxiosResponse<IAcademicSubject[]>;
            onSuccess(data);
        }
    }
};

export const searchAcademicCharges: bType = async (service, as_id, keycloak, onSuccess, onError) => {
    if (service.current) {
        const res = await service.current.search({
            academic_subject_id: as_id,
            // @ts-ignore
            teacher_id: keycloak.userInfo?.sub ?? null,
            with: ['school_year']
        });

        if (isAxiosError(res)) {
            const err = res as AxiosError;
            if (typeof onError !== 'undefined') {
                onError(err);
            }
        } else {
            const { data } = res as AxiosResponse<IAcademicCharge[]>;
            onSuccess(data);
        }
    }
};

export const handleToggleLoading = (setLoading: React.Dispatch<React.SetStateAction<LoadingType>>, entity: ViewEntitiesEnum) => {
    setLoading(prevState => ({ ...prevState, [entity]: !prevState[entity] }));
};