import RestService                                  from '../abstract/RestService';
import IAcademicSubject                             from '../../interfaces/entities/IAcademicSubject';
import { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import IPaginatedData                               from '../../interfaces/IPaginatedData';

interface SearchParams {
    grade_id?: number;
    subject_id?: number;
    school_id?: string;
    with?: ('subject' | 'grade')[];
}

class AcademicSubjectService extends RestService<IAcademicSubject> {
    constructor(axios: AxiosInstance) {
        super('academic_subjects', axios);
    }

    async all(page: number | undefined): Promise<AxiosResponse<IAcademicSubject[] | IPaginatedData<IAcademicSubject>> | AxiosError> {
        try {
            const params = page !== undefined ? `?page=${ page }` : '';
            return await this.http.get(this.uri + params);
        } catch (e) {
            return e as AxiosError;
        }
    }

    async search(data: SearchParams): Promise<AxiosResponse<IAcademicSubject[]> | AxiosError> {
        try {
            return await this.http.post(`${ this.uri }/search`, data);
        } catch (e) {
            return e as AxiosError;
        }
    }
}

export default AcademicSubjectService;