import React, { useEffect, useState }           from 'react';
import useServiceByRef                          from '../../../utils/hooks/useServiceByRef';
import { handleAxiosError, isAxiosError }       from '../../../utils/services';
import { AxiosError, AxiosResponse }            from 'axios';
import { notification, Row, Skeleton } from 'antd';
import { useKeycloak }                          from '@react-keycloak/web';
import UsersSchoolsService                      from '../../../api/services/UsersSchoolsService';
import IUserSchool                              from '../../../interfaces/entities/IUserSchool';
import { useSelector }                          from 'react-redux';
import { IAppState }                            from '../../../interfaces/store/IAppState';
import BannerTeachers                           from './users-schools';
import BannerUserSSO                            from './users-sso';
import './index.less';

type Props = {
    logged: boolean
    isTeacher?: boolean
    isLxd?: boolean
    isAdmin?: boolean,
    isDirector?: boolean,
}

const BannerProfile: React.FC<Props> = ({ logged, isTeacher }) => {
    //SERVICES
    const userSchoolService = useServiceByRef(UsersSchoolsService);
    const readyForAuthRequests = useSelector<IAppState, boolean>(({ axios }) => axios.readyForRequests);

    // STATE USERS
    const [user_school, setUserSchool] = useState<IUserSchool>();

    //state keycloak
    const [loading, setLoading] = useState<boolean>(true);
    const { keycloak } = useKeycloak();

    useEffect(() => {
        if (isTeacher) {
            const getUser = async () => {
                if (readyForAuthRequests && logged) {
                    setLoading(true);
                    if (userSchoolService.current) {
                        const response = await userSchoolService.current.getUserProfile();
                        if (isAxiosError(response)) {
                            handleAxiosError(response as AxiosError, {
                                404: () => {
                                    const err = response as AxiosError;
                                    notification.success({
                                        message: err.message,
                                        description: 'Ocurrio un error al obtener los datos del usuario',
                                        duration: 3,
                                    });
                                },
                                default: () => {
                                    //    TODO: define message by token expiration if necessary
                                }
                            });
                        } else {
                            const { data } = response as AxiosResponse<IUserSchool>;
                            setUserSchool(data);
                        }
                    }
                    setLoading(false);
                }
            };
            getUser().finally();
        } else if (readyForAuthRequests && keycloak.authenticated) {
            setLoading(false);
        }

    }, [isTeacher, logged, userSchoolService, readyForAuthRequests, keycloak.authenticated]);

    return (
        <div className="banner-user">
            { loading && (
                <Skeleton active className="skeleton-banner-user" />
            ) }
            <Row gutter={ [40, 16] }>
                {
                    !loading && isTeacher && user_school !== undefined
                        ? <BannerTeachers
                            isTeacher
                            user_school={ user_school }
                            loading={ loading }
                        />
                        : !isTeacher
                        ? <BannerUserSSO
                            isDirector={ keycloak.hasResourceRole('PRINCIPAL') }
                            isLxd={ keycloak.hasResourceRole('LXD') }
                            isAdmin={ keycloak.hasResourceRole('ADMIN') }
                        /> : null
                }
            </Row>
        </div>
    );
};
export default BannerProfile;
