import { Col, Form, Input, Row, Select, TimePicker, DatePicker, Button, Card, message, notification } from 'antd';
import { DeleteOutlined }                                                                             from '@ant-design/icons';
import SuneEditor
                                                                                                      from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import React, { useCallback, useEffect, useRef, useState }                                            from 'react';
import moment, { Moment }                                                                             from 'moment';
import { useHistory }                                                                                 from 'react-router-dom';
import Labels
                                                                                                      from '../../../components/labels/';
import { ArrayLabels }                                                                                from '../../../components/labels/types';
import useServiceByRef
                                                                                                      from '../../../utils/hooks/useServiceByRef';
import GradeService
                                                                                                      from '../../../api/services/GradeService';
import SubjectService
                                                                                                      from '../../../api/services/SubjectService';
import IndicatorService
                                                                                                      from '../../../api/services/IndicatorService';
import IUnitService
                                                                                                      from '../../../api/services/UnitService';
import IUnit
                                                                                                      from '../../../interfaces/entities/IUnit';
import { useKeycloak }                                                                                from '@react-keycloak/web';
import {
    handleAxiosError,
    isAxiosError
}                                                                                                     from '../../../utils/services';
import { AxiosError, AxiosResponse }                                                                  from 'axios';
import IGrade
                                                                                                      from '../../../interfaces/entities/IGrade';
import useService
                                                                                                      from '../../../utils/hooks/useService';
import ISubject
                                                                                                      from '../../../interfaces/entities/ISubject';
import IIndicator
                                                                                                      from '../../../interfaces/entities/IIndicator';
import Contents
                                                                                                      from './components/contents';
import { ComponentFormEntitiesEnum, IFormFields, InsertResponse, LoadingFormType }                    from './types';
import {
    handleFinishStore,
    initialDisabledValues,
    initialLoadingValues,
    resourceStates,
    sunEditorButtonList
}                                                                                                     from './helpers';
import ResourcesSearch
                                                                                                      from '../components/resourcesSearch/index';
import { ResourcesList }                                                                              from '../components/resourcesSearch/types';
import setFormErrorsByAxiosResponse
                                                                                                      from '../../../utils/forms/setFormErrorsByAxiosResponse';
import DidacticMapService
                                                                                                      from '../../../api/services/DidacticMapService';
import { storeRules }                                                                                 from './rules';
import { useSelector }                                                                                from 'react-redux';
import { IAppState }                                                                                  from '../../../interfaces/store/IAppState';
import FooterResource
                                                                                                      from '../components/footer';
import { redirectByRole }                                                                             from '../../../utils/redirectByRole';

const { TextArea } = Input;

const NewDidacticLetter: React.FC = () => {
    const history = useHistory();
    const [form] = Form.useForm();
    const ref = useRef<ArrayLabels>(null);
    const searcherRef = useRef<ResourcesList>(null);

    const readyForAuthRequests = useSelector<IAppState, boolean>(({ axios }) => axios.readyForRequests);
    const selected_school_id = useSelector<IAppState, string | null>(({ auth }) => auth.selected_school_id);

    const [grades, setGrades] = useState<IGrade[]>([]);
    const [subjects, setSubjects] = useState<ISubject[]>([]);
    const [units, setUnits] = useState<IUnit[]>([]);
    const [indicators, setIndicators] = useState<IIndicator[]>([]);
    const [validateIndicator, setValidateIndicator] = useState<boolean>(false);
    const [loading, setLoading] = useState<LoadingFormType>(initialLoadingValues);
    const [disabled, setDisabled] = useState<LoadingFormType>({ ...initialDisabledValues, form: false });
    const [selectedData, setSelectedDate] = useState<Moment>(moment);
    const [selectedIndicators, setSelectedIndicators] = useState<IIndicator[]>([]);
    const [stateId, setStateId] = useState<number>(0);
    const [academicSubject, setAcademicSubject] = useState<{
        grade_id: number, subject_id: number
    }>({ grade_id: 0, subject_id: 0 });
    const { initialized, keycloak } = useKeycloak();
    const gradeService = useService(GradeService);
    const subjectService = useServiceByRef(SubjectService);
    const unitService = useServiceByRef(IUnitService);
    const indicatorService = useServiceByRef(IndicatorService);
    const service = useServiceByRef(DidacticMapService);

    const layoutForm = {
        labelCol: { span: 24 },
        wrapperCol: { span: 24 },
    };

    const onFinish = (values: IFormFields) => {
        if (service.current) {
            const { formComponents } = values;
            values.resource_state_id = stateId;
            if (formComponents) {
                const components: { key: string, value: string }[] = [];
                Object.keys(formComponents).forEach((value) => {
                    if (formComponents[value as ComponentFormEntitiesEnum] !== undefined) {
                        components.push({ key: value, value: formComponents[value as ComponentFormEntitiesEnum] });
                    }
                });

                values.components = components;
            }
            const labels: string[] = [];
            ref?.current?.labels.forEach((value) => {
                labels.push(value.name);
            });
            values.labels = labels;

            values.date = values.helper_date?.format('Y-MM-DD') as string;

            values.hours = values.helper_time?.format('Y-MM-DD H:mm') as string;

            values.duration = parseInt(values.helpers_duration?.format('HH') as string);

            const relatedResources: string[] = [];

            if (searcherRef?.current?.resources !== undefined) {
                if (searcherRef?.current?.resources.length > 0) {
                    searcherRef?.current?.resources.forEach((value) => {
                        relatedResources.push(value.id);
                    });
                }
            }
            if (keycloak.hasResourceRole('TEACHER', process.env.REACT_APP_KEYCLOACK_CLIENT_ID))
                if (selected_school_id !== null)
                    values.school_id = selected_school_id;

            values.related_resource = relatedResources;

            if (values.indicators.length === 0) {
                values.indicators = [];
            }

            handleFinishStore(values, onSuccess, onError, loading1 => setLoading((S) => ({
                ...S,
                form: loading1
            })), service.current);
        }
    };

    const onError = (err: AxiosError) => {
        handleAxiosError(err, {
            422: response => {
                setFormErrorsByAxiosResponse(form, response);
                notification['error']({
                    message: 'Ocurrio un error',
                    description: 'Favor verificar que los datos sean validos',
                });
            },
            500: error => notification.error({
                message: 'Ha ocurrido un error al registrar la carta didacticas',
                description: error.message,
            })
        });
    };


    const onSuccess = (res: InsertResponse) => {
        form.resetFields();
        const url = redirectByRole('');
        history.push(`${ url }`);

        notification.success({
            duration: 3.5,
            message: 'Operación finalizada',
            description: `Se ha registrado la carta didactica${ res.data.resource_state_id === 3 ? ', pasara por revisión antes de ser publicada.' : '.' }`,
        });
    };


    const getGrade = useCallback(async () => {
        setLoading(prevState => ({ ...prevState, grade: true }));
        if (initialized) {
            if (keycloak.hasResourceRole('TEACHER', process.env.REACT_APP_KEYCLOACK_CLIENT_ID)) {
                if (grades.length === 0) {
                    if (gradeService !== null) {
                        const response = await gradeService.teacherGrade();

                        if (!isAxiosError(response)) {
                            const { data } = response as AxiosResponse<IGrade[]>;
                            if (data.length > 0) {
                                setDisabled((s) => ({
                                    ...s,
                                    grade: false, form: false
                                }));
                                setGrades(data);
                            } else {
                                message.error({
                                    content: <>Lo siento, no posee materias asignadas</>,
                                    duration: 2
                                });
                            }
                        }
                    }
                }
            } else {
                if (grades.length === 0) {
                    if (gradeService !== null) {
                        const response = await gradeService.all();

                        if (!isAxiosError(response)) {
                            const { data } = response as AxiosResponse<IGrade[]>;
                            if (data.length > 0) {
                                setDisabled((s) => ({
                                    ...s,
                                    grade: false, form: false
                                }));
                                setGrades(data);
                            } else {
                                message.error({
                                    content: <>No posees materias asignadas</>,
                                    duration: 2
                                });
                            }
                        }
                    }
                }
            }
        }
        setLoading(prevState => ({ ...prevState, grade: false }));
    }, [gradeService, grades, keycloak, initialized]);

    useEffect(() => {
        if (readyForAuthRequests)
            getGrade().finally();

    }, [getGrade, readyForAuthRequests]);

    const handleGradesChange = async (id: number) => {
        form.setFields([{
            name: 'subject',
            value: null
        }, {
            name: 'unit_id',
            value: null
        }, {
            name: 'indicators',
            value: null
        }]);
        setLoading((s) => ({ ...s, subject: true }));
        setDisabled((s) => ({ ...initialDisabledValues, grade: false, form: false }));
        if (subjectService.current) {
            const response = keycloak.hasResourceRole('TEACHER') ? await subjectService.current.getByAcademicCharge(id) : await subjectService.current.getByGrade(id);
            if (!isAxiosError(response)) {
                const { data } = response as AxiosResponse<ISubject[]>;
                if (data.length > 0) {
                    setAcademicSubject((s) => ({ ...s, grade_id: id }));
                    setSubjects(data);
                    setDisabled((s) => ({ ...s, subject: false }));
                } else {
                    message.error({
                        content: <>No se encontraron materias disponibles en el grado seleccionado</>,
                        icon: 'error',
                        duration: 2
                    });
                }
            } else {
                message.error({
                    content: <>No se encontraron materias disponibles en el grado seleccionado</>,
                    icon: 'error',
                    duration: 2
                });
            }
        }
        setLoading((s) => ({ ...s, subject: false }));
    };

    const handleSubjectChange = async (id: number) => {
        form.setFields([{
            name: 'unit_id',
            value: null
        }, {
            name: 'indicators',
            value: null
        }]);
        setLoading((s) => ({ ...s, unit: true }));
        setDisabled((s) => ({ ...initialDisabledValues, grade: false, subject: false, form: false }));
        if (unitService.current) {
            const response = await unitService.current.getByAcademicSubject({
                grade_id: academicSubject.grade_id,
                subject_id: id
            });

            if (!isAxiosError(response)) {
                const { data } = response as AxiosResponse<IUnit[]>;
                if (data.length > 0) {
                    setDisabled((s) => ({ ...s, unit: false }));
                    setAcademicSubject((s) => ({ ...s, subject_id: id }));
                    setUnits(data);
                } else {
                    message.error({
                        content: <>No se encontraron unidades disponibles en el grado y la materia seleccionada</>,
                        icon: 'error',
                        duration: 2
                    });
                }
            } else {
                message.error({
                    content: <>No se encontraron unidades disponibles en el grado y la materia seleccionada</>,
                    icon: 'error',
                    duration: 2
                });
            }
        }
        setLoading((s) => ({ ...s, unit: false }));
    };

    const handleUnitChange = async (id: string) => {
        form.setFields([{
            name: 'indicators',
            value: null
        }]);
        setLoading((s) => ({ ...s, indicator: true }));
        setDisabled((s) => ({ ...initialDisabledValues, grade: false, subject: false, unit: false, form: false }));
        if (indicatorService.current) {
            const response = await indicatorService.current.getByUnit(id);

            if (!isAxiosError(response)) {
                const { data } = response as AxiosResponse<IIndicator[]>;
                if (data.length > 0) {
                    setDisabled((s) => ({ ...s, indicator: false }));
                    setIndicators(data);
                } else {
                    message.error({
                        content: <>No se encontraron indicadores</>,
                        icon: 'error',
                        duration: 2
                    });
                }
            } else {
                message.error({
                    content: <>No se encontraron indicadores</>,
                    icon: 'error',
                    duration: 2
                });
            }
        }
        setLoading((s) => ({ ...s, indicator: false }));
    };

    const handleIndicatorChange = async (id: string) => {
        setValidateIndicator(true);
        if (indicators.length > 0) {
            const provIndicators = Object.assign([], selectedIndicators);
            let exist = false;
            let existIndex = 0;

            provIndicators.some((value: IIndicator, index) => {
                if (value.id === id) {
                    exist = true;
                    existIndex = index;
                }
                return value.id === id;
            });

            if (exist) {
                form.setFields([
                    {
                        name: ['indicators', existIndex],
                        errors: ['No se puede registrar el mismo indicador']
                    }
                ]);
            } else {
                setSelectedIndicators((s) => ([...s, indicators.find((indi) => indi.id === id) as unknown as IIndicator]));
                form.setFields([
                    {
                        name: ['indicators', existIndex],
                        errors: []
                    }
                ]);
            }

        } else {
            setSelectedIndicators([indicators.find((indi) => indi.id === id) as unknown as IIndicator]);
        }
    };

    const handleRemoveIndicator = (callback: () => void, field: any) => {
        const fieldValue = form.getFieldValue(['indicators', field.name]);

        if (fieldValue !== undefined) {
            const newSelectedIndicators = selectedIndicators.filter((selectedIndicator) => (selectedIndicator.id !== fieldValue));

            setSelectedIndicators(newSelectedIndicators);
            callback();
        } else {
            callback();
        }
    };

    useEffect(() => {
        if (validateIndicator) {
            if (selectedIndicators.length === 0) {
                form.resetFields(['indicators']);
            }
        }
    }, [selectedIndicators, validateIndicator, form]);

    useEffect(() => {
        form.setFields([
            { name: 'helper_time', value: selectedData }
        ]);
    }, [selectedData, form]);

    return (
        <div className="content-resource">
            <Form
                { ...layoutForm }
                form={ form }
                name="basic"
                className="item-resource"
                onFinish={ onFinish }
            >
                <h2 className="title-resource">Crear recurso </h2>
                <Form.Item
                    label={ <h3 className="title-sub-1">{ 'Nombre' }</h3> }
                    name="name"
                    rules={ storeRules.name }
                    hasFeedback
                >
                    <Input className="input-resource" placeholder="¿Cual es el nombre del recurso?" />
                </Form.Item>
                <Form.Item
                    hasFeedback
                    label={ <h3 className="title-sub-1">{ 'Tags/palabras claves' }</h3> }
                >
                    <Labels ref={ ref } />
                </Form.Item>
                <Form.Item
                    label={ <h3 className="title-sub-1">{ 'Descripción' }</h3> }
                    name="description"
                    rules={ storeRules.description }
                    hasFeedback
                >
                    <TextArea className="input-resource" rows={ 7 } placeholder="Escribe una descripción del recurso" />
                </Form.Item>
                <Row gutter={ [16, 16] }>
                    <Col span={ 8 }>
                        <Form.Item
                            label={ <h3 className="title-sub-1">{ 'Grado' }</h3> }
                            name="grade"
                            rules={ [{ required: true, message: 'Selecciona un grado' }] }
                            hasFeedback
                        >
                            <Select placeholder="Seleccione un grado"
                                    options={ grades.map((value) => ({ value: value.id, label: value.name })) }
                                    className="select-resource"
                                    loading={ loading.grade }
                                    disabled={ disabled.grade }
                                    onChange={ (value: number) => handleGradesChange(value) }
                                    allowClear
                                    showArrow
                                    showSearch
                                    getPopupContainer={ trigger => trigger.parentNode }
                                    optionFilterProp="label"
                            />
                        </Form.Item>
                    </Col>
                    <Col span={ 8 }>
                        <Form.Item
                            label={ <h3 className="title-sub-1">{ 'Materia' }</h3> }
                            name="subject"
                            rules={ [{ required: true, message: 'Selecciona una materia' }] }
                            hasFeedback
                        >
                            <Select placeholder="Seleccione una materia"
                                    className="select-resource"
                                    loading={ loading.subject }
                                    disabled={ disabled.subject }
                                    options={ subjects.map((value) => ({ value: value.id, label: value.name })) }
                                    onChange={ (value: number) => handleSubjectChange(value) }
                                    allowClear
                                    showArrow
                                    showSearch
                                    getPopupContainer={ trigger => trigger.parentNode }
                                    optionFilterProp="label"
                            />
                        </Form.Item>
                    </Col>
                    <Col span={ 8 }>
                        <Form.Item
                            label={ <h3 className="title-sub-1">{ 'Unidad' }</h3> }
                            name="unit_id"
                            rules={ storeRules.unit_id }
                            hasFeedback
                        >
                            <Select placeholder="Seleccione una Unidad"
                                    className="select-resource"
                                    loading={ loading.unit }
                                    disabled={ disabled.unit }
                                    options={ units.map((value) => ({ value: value.id, label: value.name })) }
                                    onChange={ (value: string) => handleUnitChange(value) }
                                    allowClear
                                    showArrow
                                    showSearch
                                    getPopupContainer={ trigger => trigger.parentNode }
                                    optionFilterProp="label"
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={ [16, 16] }>
                    <Col span={ 8 }>
                        <Form.Item
                            label={ <h3 className="title-sub-1">{ 'Fecha' }</h3> }
                            name="helper_date"
                            rules={ storeRules.helper_date }
                            hasFeedback
                        >
                            <DatePicker placeholder="Seleccione una fecha" className="select-resource"
                                        format={ 'Y-MM-D' }
                                        onChange={ (current: Moment | null) => setSelectedDate(current as Moment) } />
                        </Form.Item>
                    </Col>
                    <Col span={ 8 }>
                        <Form.Item
                            label={ <h3 className="title-sub-1">{ 'Hora' }</h3> }
                            name="helper_time"
                            rules={ storeRules.helper_time }
                            hasFeedback
                        >
                            <TimePicker
                                placeholder="Seleccione una hora"
                                className="select-resource"
                                defaultValue={ moment(selectedData, 'HH:mm') } format="HH:mm" />
                        </Form.Item>
                    </Col>
                    <Col span={ 8 }>
                        <Form.Item
                            label={ <h3 className="title-sub-1">{ 'Duración' }</h3> }
                            name="helpers_duration"
                            rules={ storeRules.helpers_duration }
                            hasFeedback
                        >
                            <TimePicker
                                placeholder="Seleccione el tiempo de duración"
                                className="select-resource"
                                format={ 'HH' }
                                defaultOpenValue={ moment('00:00:00', 'HH:mm:ss') } />
                        </Form.Item>
                    </Col>
                </Row>
                <h3 className="title-sub-1">Indicadores de logro </h3>
                <Form.List
                    name="indicators"
                >
                    { (fields, { add, remove }) => (
                        <div>
                            { fields.map((value, index, array) => (
                                <Row
                                    gutter={ [16, 16] }
                                    key={ index }
                                >
                                    <Col span={ 22 }>
                                        <Form.Item
                                            { ...value }
                                            key={ index }
                                            fieldKey={ value.fieldKey + 1 }
                                            hasFeedback
                                            rules={ storeRules.indicators }
                                        >
                                            <Select placeholder="Selecciona un indicador de logro"
                                                    className="select-resource"
                                                    options={ indicators.map((value) => ({
                                                        value: value.id,
                                                        label: value.name
                                                    })) }
                                                    loading={ loading.indicator }
                                                    disabled={ disabled.indicator }
                                                    onChange={ (value: string) => handleIndicatorChange(value) }
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={ 2 }>
                                        { fields.length > 1 ? (
                                            <DeleteOutlined
                                                onClick={ () => handleRemoveIndicator(() => remove(value.name), value) }
                                            />
                                        ) : null }
                                    </Col>
                                </Row>
                            )) }

                            <Button block shape="round" className="btn-primary-resources" onClick={ () => add() }
                                    disabled={ disabled.indicator }>
                                Agregar otro indicador
                            </Button>

                            { fields.length === 0 ? add() : <></> }
                        </div>
                    ) }
                </Form.List>
                <br />
                <Row gutter={ [16, 16] }>
                    <Col span={ 8 }>
                        <Form.Item
                            hasFeedback
                        >
                            <Card
                                headStyle={ { backgroundColor: '#0066CC' } }
                                title={ <h3 className="title-sub-1-pr">{ 'Contenidos Conceptuales' }</h3> }>
                                <div className="input-extra">
                                    { selectedIndicators.length > 0 &&
                                    (<Contents
                                        indicators={ selectedIndicators }
                                        content_type={ 'CONCEPTUAL' }
                                        teacher={ keycloak.hasResourceRole('Teacher', process.env.REACT_APP_KEYCLOACK_CLIENT_ID) }
                                    />)
                                    }
                                </div>
                            </Card>
                        </Form.Item>
                    </Col>
                    <Col span={ 8 }>
                        <Form.Item
                            hasFeedback
                        >
                            <Card
                                headStyle={ { backgroundColor: '#0066CC' } }
                                title={ <h3 className="title-sub-1-pr">{ 'Contenidos Procedimentales' }</h3> }>
                                <div className="input-extra">
                                    { selectedIndicators.length > 0 &&
                                    (<Contents
                                        indicators={ selectedIndicators }
                                        content_type={ 'PROCEDURAL' }
                                        teacher={ keycloak.hasResourceRole('Teacher', process.env.REACT_APP_KEYCLOACK_CLIENT_ID) }
                                    />)
                                    }
                                </div>
                            </Card>
                        </Form.Item>
                    </Col>
                    <Col span={ 8 }>
                        <Form.Item
                            hasFeedback
                        >
                            <Card
                                headStyle={ { backgroundColor: '#0066CC' } }
                                title={ <h3 className="title-sub-1-pr">{ 'Contenidos Actitudinales' }</h3> }>
                                <div className="input-extra">
                                    { selectedIndicators.length > 0 &&
                                    (<Contents
                                        indicators={ selectedIndicators }
                                        content_type={ 'ATTITUDINAL' }
                                        teacher={ keycloak.hasResourceRole('Teacher', process.env.REACT_APP_KEYCLOACK_CLIENT_ID) }
                                    />)
                                    }
                                </div>
                            </Card>
                        </Form.Item>
                    </Col>
                </Row>
                <Card
                    className="card-content-resource"
                    headStyle={ { backgroundColor: '#0066CC' } }
                    title={ <h3 className="title-sub-1-pr">{ 'Metodología' } <br /> { 'Inicio' }</h3> }>
                    <Row>
                        <Col span={ 24 }>
                            <Form.Item
                                label={ <h3
                                    className="title-sub-1">{ 'Realimentación / Detección de presaberes:' }</h3> }
                                name={ ['formComponents', 'METHODOLOGY.BEGINNING.FEEDBACK'] }
                                className="input-methodology"
                                hasFeedback
                            >
                                <SuneEditor
                                    setOptions={ {
                                        buttonList: sunEditorButtonList
                                    } }
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={ [48, 24] }>
                        <Col span={ 24 }>
                            <Form.Item
                                label={ <h3
                                    className="title-sub-1">{ 'Presentación del tema o indicador de logro:' }</h3> }
                                name={ ['formComponents', 'METHODOLOGY.BEGINNING.PRESENTATION'] }
                                className="input-methodology"
                                hasFeedback
                            >
                                <SuneEditor
                                    setOptions={ {
                                        buttonList: sunEditorButtonList
                                    } }
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={ [48, 24] }>
                        <Col span={ 24 }>
                            <Form.Item
                                label={ <h3 className="title-sub-1">{ 'Situación problema:' }</h3> }
                                name={ ['formComponents', 'METHODOLOGY.BEGINNING.PROBLEM'] }
                                className="input-methodology"
                                hasFeedback
                            >
                                <SuneEditor
                                    setOptions={ {
                                        buttonList: sunEditorButtonList
                                    } }
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Card>
                <Card
                    className="card-content-resource"
                    headStyle={ { backgroundColor: '#0066CC' } }
                    title={ <h3 className="title-sub-1-pr">{ 'Metodología' } <br /> { 'Desarrollo' }</h3> }>
                    <Row>
                        <Col span={ 24 }>
                            <Form.Item
                                label={ <h3 className="title-sub-1">{ 'Conocimiento:' }</h3> }
                                name={ ['formComponents', 'METHODOLOGY.DEVELOPMENT.KNOWLEDGE'] }
                                className="input-methodology"
                                hasFeedback
                            >
                                <SuneEditor
                                    setOptions={ {
                                        buttonList: sunEditorButtonList
                                    } }
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={ 24 }>
                            <Form.Item
                                label={ <h3 className="title-sub-1">{ 'Compresión:' }</h3> }
                                name={ ['formComponents', 'METHODOLOGY.DEVELOPMENT.UNDERSTANDING'] }
                                className="input-methodology"
                                hasFeedback
                            >
                                <SuneEditor
                                    setOptions={ {
                                        buttonList: sunEditorButtonList
                                    } }
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={ 24 }>
                            <Form.Item
                                label={ <h3 className="title-sub-1">{ 'Aplicación:' }</h3> }
                                name={ ['formComponents', 'METHODOLOGY.DEVELOPMENT.APPLICATION'] }
                                className="input-methodology"
                                hasFeedback
                            >
                                <SuneEditor
                                    setOptions={ {
                                        buttonList: sunEditorButtonList
                                    } }
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={ 24 }>
                            <Form.Item
                                label={ <h3 className="title-sub-1">{ 'Análisis:' }</h3> }
                                name={ ['formComponents', 'METHODOLOGY.DEVELOPMENT.ANALYSIS'] }
                                className="input-methodology"
                                hasFeedback
                            >
                                <SuneEditor
                                    setOptions={ {
                                        buttonList: sunEditorButtonList
                                    } }
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={ 24 }>
                            <Form.Item
                                label={ <h3 className="title-sub-1">{ 'Síntesis:' }</h3> }
                                name={ ['formComponents', 'METHODOLOGY.DEVELOPMENT.SYNTHESIS'] }
                                className="input-methodology"
                                hasFeedback
                            >
                                <SuneEditor
                                    setOptions={ {
                                        buttonList: sunEditorButtonList
                                    } }
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={ 24 }>
                            <Form.Item
                                label={ <h3 className="title-sub-1">{ 'Valoración:' }</h3> }
                                name={ ['formComponents', 'METHODOLOGY.DEVELOPMENT.ASSESSMENT'] }
                                className="input-methodology"
                                hasFeedback
                            >
                                <SuneEditor
                                    setOptions={ {
                                        buttonList: sunEditorButtonList
                                    } }
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={ 24 }>
                            <Form.Item
                                label={ <h3 className="title-sub-1">{ 'Culminación:' }</h3> }
                                name={ ['formComponents', 'METHODOLOGY.DEVELOPMENT.CULMINATION'] }
                                className="input-methodology"
                                hasFeedback
                            >
                                <SuneEditor
                                    setOptions={ {
                                        buttonList: sunEditorButtonList
                                    } }
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={ 24 }>
                            <Form.Item
                                label={ <h3 className="title-sub-1">{ 'Recursos de clase ' }</h3> }
                                name={ ['formComponents', 'METHODOLOGY.DEVELOPMENT.GRADE_RESOURCE'] }
                                className="input-methodology"
                                hasFeedback
                            >
                                <SuneEditor
                                    setOptions={ {
                                        buttonList: sunEditorButtonList
                                    } }
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={ 24 }>
                            <Card className="suggested-resource">
                                <Row>
                                    <Col span={ 24 }>
                                        <Form.Item
                                            label={ <h3 className="title-sub-1">{ 'Recursos sugeridos:' }</h3> }
                                            className="input-methodology"
                                            hasFeedback
                                        >
                                            <ResourcesSearch ref={ searcherRef } grade_id={ academicSubject.grade_id }
                                                             subject_id={ academicSubject.subject_id } />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={ 24 }>
                            <Form.Item
                                label={ <h3 className="title-sub-1">{ 'Evaluación de conocimientos:' }</h3> }
                                name={ ['formComponents', 'METHODOLOGY.DEVELOPMENT.KNOWLEDGE_ASSESSMENT'] }
                                className="input-methodology"
                                hasFeedback
                            >
                                <SuneEditor
                                    setOptions={ {
                                        buttonList: sunEditorButtonList
                                    } }
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={ 24 }>
                            <Form.Item
                                label={ <h3 className="title-sub-1">{ 'Resumen:' }</h3> }
                                name={ ['formComponents', 'METHODOLOGY.DEVELOPMENT.SUMMARY'] }
                                className="input-methodology"
                                hasFeedback
                            >
                                <SuneEditor
                                    setOptions={ {
                                        buttonList: sunEditorButtonList
                                    } }
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Card>
                <Form.Item
                    label={ <h3 className="title-sub-1">{ 'Públicar como' }</h3> }
                    name="template"
                    rules={ storeRules.template }
                    hasFeedback
                >
                    <Select
                        placeholder="Seleccione un estado de publicación"
                        options={ resourceStates.map((value) => ({ value: value.id, label: value.name })) }
                        className="select-resource"
                    />
                </Form.Item>
                <Form.Item>
                    <Row gutter={ [40, 16] }>
                        <Col span={ 6 }>
                            <Button htmlType="submit" shape="round"
                                    className="btn-action-size btn-eraser-text" block
                                    loading={ loading.form } onClick={ () => setStateId(2) }>Guardar borrador</Button>
                        </Col>
                        <Col span={ 18 }>
                            <Button shape="round"
                                    htmlType="submit"
                                    className="btn-primary-resources btn-action-size"
                                    block
                                    loading={ loading.form } onClick={ () => setStateId(1) }>Publicar </Button>
                        </Col>
                    </Row>
                </Form.Item>
                <FooterResource />
            </Form>
        </div>
    );
};
export default NewDidacticLetter;
