import React, { Fragment, useState } from 'react';
import { Button, Modal }             from 'antd';
import { EyeOutlined }               from '@ant-design/icons';
import ModalContent                  from './ModalContent';
import IUnit
                                     from '../../../../../interfaces/entities/IUnit';
import IUnitSabi                     from '../../../../../interfaces/entities/IUnitSabi';

type Props = {
    unit: IUnit;
    unitsSabi: IUnitSabi[];
}

const ModalDetailPlanning: React.FC<Props> = ({ unit, unitsSabi }) => {

    //STATE - MODAL
    const [showUnitIndicatorMdl, setShowUnitIndicatorMdl] = useState(false);


    const toggleIndicator: () => void = () => {
        if (unit !== undefined) {
            setShowUnitIndicatorMdl(true);
        }
    };

    const onIndicatorMdlClose = () => {
        setShowUnitIndicatorMdl(false);
        Modal.destroyAll();
    };

    return (
        <Fragment>
            <Button title="Detalle" className={ 'btn-table-action-deactivate' }
                    shape={ 'circle' }
                    icon={ <EyeOutlined /> }
                    onClick={ toggleIndicator }
            />
            { showUnitIndicatorMdl && unitsSabi !== undefined && unitsSabi !== null && (
                <ModalContent showUnitIndicatorMdl={ showUnitIndicatorMdl }
                              unit={ unit } onIndicatorMdlClose={ onIndicatorMdlClose } unitsSabi={ unitsSabi } />
            ) }
        </Fragment>
    );
};
export default ModalDetailPlanning;
