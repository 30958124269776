import { ThunkAction }                     from 'redux-thunk';
import { IAppState }                       from '../../interfaces/store/IAppState';
import { IActivityGuideCollectionActions } from '../../interfaces/store/actions/IActivityGuideCollectionActions';
import { IActivityGuideCollectionState }   from '../../interfaces/store/states/IActivityGuideCollectionState';
import { SET_ACTIVITY_GUIDE_COLLECTION }   from './constants/activity-guide-collection';

interface IActivityGuideCollectionAction<R, E = null> extends ThunkAction<R, IAppState, E, IActivityGuideCollectionActions> {
}

export const setActivityGuideCollection = (value: IActivityGuideCollectionState): IActivityGuideCollectionAction<void> => (dispatch => dispatch({
    type: SET_ACTIVITY_GUIDE_COLLECTION,
    value
}));