import React, { Fragment, useCallback, useEffect, useState } from 'react';
import Router                                                from './routes/Router';
import { useKeycloak }                                       from '@react-keycloak/web';
import { IAppState }                                         from './interfaces/store/IAppState';
import { useDispatch, useSelector }                          from 'react-redux';
import axios, { AxiosError, AxiosResponse }                  from 'axios';
import { setAxiosInstance, setToken }                        from './store/actions/axios';
import { setKeycloakUserInfo, setSchools }                   from './store/actions/auth';
import { IKeycloakUserInfo }                                 from './interfaces/store/states/IAuthState';
import AuthService                                           from './api/services/AuthService';
import { handleAxiosError, isAxiosError }                    from './utils/services';
import ISchool                                               from './interfaces/entities/ISchool';
import AppServiceConfig                                      from './api/client-configuration/AppServiceConfiguration';
import useServiceByRef                                       from './utils/hooks/useServiceByRef';
import ESchoolAcademicChargeService                          from './api/services/ESchoolAcademicChargeService';
import { Spin }                                              from 'antd';
import Teacher401                                            from './views/errors/401';
import UserSchoolRegister                                    from './views/teacher/register/UserSchoolRegister';
import useGetSabiRoles                                       from './utils/hooks/useGetDocenteSoyRoles';

function App() {
    const dispatch = useDispatch();
    const { error } = useGetSabiRoles();
    const { initialized, keycloak } = useKeycloak();
    const token = useSelector<IAppState, string | null>(({ axios }) => axios.token);
    const authService = useServiceByRef(AuthService);

    const readyForAuthRequests = useSelector<IAppState, boolean>(({ axios }) => axios.readyForRequests);

    const [userActive, setUserActive] = useState<boolean>(false);

    const [userRegisterIncomplete, setUSerRegisterIncomplete] = useState<boolean>(false);
    const [userDisabled, setUserDisabled] = useState<boolean>(false);


    const userSSO = useSelector<IAppState, IKeycloakUserInfo | null>(({ auth }) => auth.keycloakUserInfo);

    const getSchools = useCallback(async () => {
        if (typeof authService.current !== 'undefined' && readyForAuthRequests) {
            const res = await authService.current.getSchools();

            if (!isAxiosError(res)) {
                const { data } = res as AxiosResponse<ISchool[]>;
                if (data.length > 0)
                    dispatch(setSchools(data));
            }
        }
    }, [authService, dispatch, readyForAuthRequests]);

    //Service AcademicCharge
    const academicChargeESchoolService = useServiceByRef(ESchoolAcademicChargeService);

    keycloak.onAuthRefreshSuccess = function () {
        if (this.token) {
            dispatch(setToken(this.token));
        }
    };

    useEffect(() => {
        if (keycloak.hasResourceRole('TEACHER') && !error) {
            // noinspection JSIgnoredPromiseFromCall
            getSchools();
        }
    }, [error, getSchools, keycloak]);

    useEffect(() => {
        if (initialized && keycloak.token) {
            dispatch(setToken(keycloak.token));
        }
    }, [initialized, keycloak.token, dispatch]);

    useEffect(() => {
        if (token !== null && !error) {
            const axiosInstance = axios.create({
                ...AppServiceConfig,
                headers: {
                    Authorization: 'Bearer ' + token
                }
            });

            dispatch(setAxiosInstance(axiosInstance));
        }
    }, [token, dispatch, error]);

    useEffect(() => {
        if (initialized && keycloak.authenticated) {
            keycloak.loadUserInfo().then(user => dispatch(setKeycloakUserInfo(user as IKeycloakUserInfo)));
        }
    }, [dispatch, initialized, keycloak]);

    useEffect(() => {
        const syncAcademicChargeESchoolLogged = async () => {
            if (academicChargeESchoolService.current) {
                await academicChargeESchoolService.current.AcademicChargeSyncLogged();
            }
        };
        if (readyForAuthRequests && keycloak.hasResourceRole('TEACHER') && userActive) {
            syncAcademicChargeESchoolLogged().finally();
        }

    }, [keycloak, academicChargeESchoolService, readyForAuthRequests, userActive]);

    useEffect(() => {

        const validateUser = async () => {
            if (authService.current && userSSO !== null) {
                const response = await authService.current.validatedUser({ email: userSSO.email });

                if (isAxiosError(response)) {
                    handleAxiosError(response as AxiosError, {
                        401: () => {
                            setUserDisabled(true);
                        },
                        404: () => {
                            setUSerRegisterIncomplete(true);
                        },
                        default: () => {
                            //    TODO: define message by token expiration if necessary
                        }
                    });
                } else {
                    const { data } = response as AxiosResponse;
                    if (data !== null && data !== undefined) {
                        setUserActive(true);
                    } else {
                        setUserActive(false);
                    }

                }
            }
        };
        validateUser().finally();
    }, [authService, keycloak, readyForAuthRequests, userSSO]);

    return (
        <Fragment>
            { !initialized && (
                <div className="container-spinning">
                    <Spin size="large"
                          className="spin-app"
                          tip="Cargando..."
                          style={ { textAlign: 'center' } } />
                </div>
            ) }

            { initialized && !error && !userRegisterIncomplete && (
                <Router />
            ) }

            {
                ((error && userRegisterIncomplete) || (!error && userRegisterIncomplete)) && (
                    <UserSchoolRegister />
                )
            }
            {
                userDisabled && (
                    <Teacher401
                        message="El usuario aún no ha sido validado, porfavor contacte con el administrador."
                        titleError="Usuario no validado" />
                )
            }
        </Fragment>
    );
}

export default App;
