import { FormInstance }  from 'antd/lib/form';
import { AxiosResponse } from 'axios';
import { DataField }     from '../../types/utils/forms/DataField';

const setFormErrorsByAxiosResponse = <T extends { errors: object }>(form: FormInstance, payload?: AxiosResponse<T>) => {
    if (payload !== undefined && payload.data !== undefined && payload.data.hasOwnProperty('errors')) {
        if (payload.data.errors !== undefined) {
            const fields: DataField[] = [];

            Object.entries(payload.data.errors).forEach(([key, value]) => {
                const errors = [value as string];
                const data = { name: key.split('.').map(k => isNaN(parseInt(k)) ? k : parseInt(k)), errors };

                fields.push(data as unknown as DataField);
            });

            form.setFields(fields);
        }
    }
};

export default setFormErrorsByAxiosResponse;