import { RouteProps }      from 'react-router-dom';
import React, { Fragment } from 'react';
import LayoutRoute         from '../LayoutRoute';
import LayoutResource      from '../../../views/layouts/Resource';
import HomeAuth            from '../../../views/layouts/HomeAuth';

interface Props extends RouteProps {
    resource: boolean
}

const LxdRoute: React.FC<Props> = ({ ...rest }) => {
    return (
        <Fragment>
            <LayoutRoute
                { ...rest }
                Layout={ rest.resource ? LayoutResource : HomeAuth }
                keyCloakValidation={ keycloak => keycloak.hasResourceRole('LXD') }
            >
            </LayoutRoute>
        </Fragment>
    );
};
export default LxdRoute;