import RestService                                  from '../abstract/RestService';
import { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import ISubject                                     from '../../interfaces/entities/ISubject';

class SubjectService extends RestService<ISubject> {
    constructor(axios: AxiosInstance) {
        super('subject', axios);
    }

    async all(page?: number): Promise<AxiosResponse<ISubject[]> | AxiosError> {
        try {
            const params = page !== undefined ? `?page=${ page }` : '';
            return await this.http.get(this.uri + params);
        } catch (e) {
            return e as AxiosError;
        }
    }

    async allFromSABI(page?: number): Promise<AxiosResponse<ISubject[]> | AxiosError> {
        try {
            const params = page !== undefined ? `?page=${ page }` : '';
            return await this.http.get(`sabi/${ this.uri }${ params }`);
        } catch (e) {
            return e as AxiosError;
        }
    }

    async getByGrade(id: number): Promise<AxiosResponse<ISubject[]> | AxiosError> {
        try {
            return await this.http.get(`${ this.uri }/get_by/grade/${ id }`);
        } catch (e) {
            return e as AxiosError;
        }
    }

    async getByAcademicCharge(id: number): Promise<AxiosResponse<ISubject[]> | AxiosError> {
        try {
            return await this.http.get(`teacher/${ this.uri }/get_by/teacher/grade/${ id }`);
        } catch (e) {
            return e as AxiosError;
        }
    }

    async store(params: object): Promise<AxiosResponse | AxiosError> {
        try {
            return await this.http.post(`${ this.uri }`, params);
        } catch (e) {
            return e as AxiosError;
        }
    }

    async get(id: string): Promise<AxiosResponse<ISubject> | AxiosError> {
        try {
            return await this.http.get(`${ this.uri }/${ id }`);
        } catch (e) {
            return e as AxiosError;
        }
    }

    async delete(id: string): Promise<AxiosResponse | AxiosError> {
        try {
            return await this.http.delete(`${ this.uri }/${ id }`);
        } catch (e) {
            return e as AxiosError;
        }
    }

    async getByTeacher(grade: number, id: string): Promise<AxiosResponse<ISubject[]> | AxiosError> {
        try {
            return await this.http.get(`${ this.uri }/get_by/grade/${ grade }/teacher/${ id }`);
        } catch (e) {
            return e as AxiosError;
        }
    }
}

export default SubjectService;