import Service                       from './Service';
import { AxiosError, AxiosResponse } from 'axios';
import IPaginatedData                from '../../interfaces/IPaginatedData';

export default abstract class RestService<E> extends Service {
    abstract all(page?: number): Promise<AxiosResponse<E[] | IPaginatedData<E>> | AxiosError>;

    async import(uri: string, data: FormData): Promise<AxiosResponse | AxiosError> {
        try {
            return await this.http.post(`imports/${ uri }`, data, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
        } catch (e) {
            return e as AxiosError;
        }
    }
}