import React, { Fragment, useEffect, useState }               from 'react';
import ResourceService
                                                              from '../../api/services/ResourceService';
import { handleAxiosError, isAxiosError }                     from '../../utils/services';
import { AxiosError, AxiosResponse }                          from 'axios';
import { Card, Col, Empty, Pagination, Row, Spin, Tag, Grid } from 'antd';
import './index.less';
import IResource
                                                              from '../../interfaces/entities/IResource';
import IPaginatedData                                         from '../../interfaces/IPaginatedData';
import moment                                                 from 'moment';
import { useHistory }                                         from 'react-router-dom';
import { useKeycloak }                                        from '@react-keycloak/web';
import { useDispatch, useSelector }                           from 'react-redux';
import { setResourceCollectionPage }                          from '../../store/actions/resource-collection';
import { IAppState }                                          from '../../interfaces/store/IAppState';
import Server500                                              from '../../views/errors/500';
import Searcher                                               from '../../views/components/Searcher';
import ResourceTypes                                          from './type-resources';
import ILabel                                                 from '../../interfaces/entities/ILabel';
import { FormatTag }                                          from '../../utils/FormatTag';
import { redirectByRole }                                     from '../../utils/redirectByRole';
import useService                                             from '../../utils/hooks/useService';

const { useBreakpoint } = Grid;

type Props = {
    userID?: string
    filter?: boolean
    paginated?: boolean
    logged?: boolean
    favorite?: boolean
    profile?: boolean
    myResources?: boolean
    home?: boolean
    draft?: boolean
    pendingApproval?: boolean
    denied?: boolean
    associatedResources?: object
}

const ResourcesList: React.FC<Props> = ({
                                            paginated,
                                            profile,
                                            favorite,
                                            logged,
                                            userID,
                                            home,
                                            draft,
                                            pendingApproval,
                                            myResources,
                                            denied, filter, associatedResources
                                        }) => {

    const history = useHistory();
    const { keycloak } = useKeycloak();
    const dispatch = useDispatch();
    const screens = useBreakpoint();

    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState<number>(1);
    const resourceService = useService(ResourceService);
    const [rendering, setRendering] = useState(true);
    const [empty, setEmpty] = useState<boolean>(false);
    const resourceCollectionInstance = useSelector<IAppState, IResource[] | null>(({ resourceCollection }) => resourceCollection.resources);
    const resourceTotalInstance = useSelector<IAppState, number>(({ resourceCollection }) => resourceCollection.total);

    const readyForRequests = useSelector<IAppState, boolean>(({ axios }) => axios.readyForRequests);
    const schoolID = useSelector<IAppState, string | null>(({ auth }) => auth.selected_school_id);

    const [error, setError] = useState<boolean>(false);

    const [paramsSearch, setParamsSearch] = useState<object>();

    const [actionResource, setActionResource] = useState<boolean>(true);

    useEffect(() => {
        if (associatedResources !== undefined) {
            setParamsSearch(associatedResources);
            setActionResource(true);
        }
    }, [associatedResources]);

    useEffect(() => {
        const getResources = async () => {
            if (resourceService !== null) {

                setRendering(true);
                setError(false);

                let response;
                if (home && !logged && !profile && paramsSearch === undefined && !keycloak.authenticated) {
                    response = await resourceService.allPublic(paginated ? page : undefined);
                } else if (logged && home && paramsSearch === undefined && readyForRequests) {
                    response = await resourceService.allResources(page, schoolID ?? null);
                } else if (paramsSearch) {
                    response = await resourceService.filterResources(paramsSearch, page);
                } else if (logged && profile && myResources && !home && readyForRequests) {
                    response = await resourceService.getProfileMyResources(page);
                } else if (logged && profile && draft && !home && readyForRequests) {
                    response = await resourceService.getProfileDraft(page);
                } else if (logged && profile && pendingApproval && !home && readyForRequests) {
                    response = await resourceService.getProfilePendingApproval(page);
                } else if (logged && favorite && !home && !profile && readyForRequests) {
                    response = await resourceService.getFavorite(page);
                } else if (logged && profile && denied && !home && readyForRequests) {
                    response = await resourceService.DeniedResourceByUser(page);
                }
                if (response) {
                    if (isAxiosError(response)) {
                        handleAxiosError(response as AxiosError, {
                            404: () => {
                                setError(true);
                                setRendering(false);
                            },
                            default: () => {
                                //    TODO: define message by token expiration if necessary
                            }
                        });
                    } else {
                        const { data } = response as AxiosResponse<IPaginatedData<IResource>>;

                        if (data.data?.length > 0 && data.data) {
                            const setData = {
                                resources: data.data,
                                total: data.total
                            };
                            dispatch(setResourceCollectionPage(setData));
                            setPageSize(data.per_page);
                            setEmpty(false);
                        } else {
                            const setData = {
                                resources: [],
                                total: 0
                            };
                            dispatch(setResourceCollectionPage(setData));
                            setPageSize(1);
                            setEmpty(true);
                        }
                        setActionResource(false);
                        setRendering(false);
                    }
                }
            }
        };
        if (keycloak.authenticated) {
            if (keycloak.hasResourceRole('TEACHER') && readyForRequests && schoolID !== null && resourceService !== null && actionResource) {
                getResources().finally();
            } else if (!keycloak.hasResourceRole('TEACHER') && readyForRequests && resourceService !== null && actionResource) {
                getResources().finally();
            }
        } else if (!keycloak.authenticated && !readyForRequests && resourceService !== null && actionResource) {
            getResources().finally();
        }

    }, [keycloak, dispatch, paginated, page, resourceService, profile, draft, home, pendingApproval,
        userID, myResources, logged, favorite, denied, readyForRequests, schoolID, paramsSearch, actionResource]);


    const redirectToResource = (url: string) => {
        if (logged && url !== '') {
            const urlRole = redirectByRole(url);
            history.push(`${ urlRole }`);
        } else {
            keycloak.login();
        }
    };

    return (
        <Fragment>
            { filter && (
                <Searcher home={ home } resources={ true } setParamsSearch={ setParamsSearch }
                          setActionResource={ setActionResource } />
            ) }
            <Row justify="center" className="resource-container" gutter={ [24, 32] }>
                {
                    rendering && !error && (
                        <Spin size="large" className="spin-home" />
                    )
                }
                { resourceCollectionInstance !== null && !rendering && resourceTotalInstance > 0 && !error
                    ?
                    <Fragment>
                        {
                            resourceCollectionInstance.map((resource, index) => (
                                <Fragment>
                                    <Col xs={ 24 } sm={ 24 } md={ 12 } lg={ 12 } xl={ 12 } className="resources-wrap"
                                         key={ index }>
                                        <Card title={
                                            <Row style={ {
                                                backgroundColor: `${ resource.unit?.academic_subject?.subject?.color }`,
                                                paddingLeft: '10px',
                                                paddingRight: '10px',
                                                borderTopLeftRadius: '16px',
                                                borderTopRightRadius: '16px',
                                                borderColor: 'none'
                                            } }>
                                                <Col span={ 12 }>
                                                    <p className="title-card">{ resource.user?.name } { resource.user?.last_name }</p>
                                                    <p className="sub-title-card">{ moment(resource.created_at).fromNow() }</p>
                                                </Col>
                                                <Col span={ 12 } className="resource-subtitle"
                                                     style={ { textAlign: 'center' } }>
                                                    { resource.unit?.academic_subject?.subject?.name }
                                                </Col>
                                            </Row>
                                        } bordered={ false } hoverable={ true }
                                              onClick={ () => {
                                                  redirectToResource(
                                                      resource.didactic_map !== null && resource.didactic_map !== undefined
                                                          ? `didactic_map/${ resource.didactic_map.id }`
                                                          : `resource/${ resource.id }`
                                                  );
                                              } }
                                              className={ pendingApproval || denied || draft ? 'resource-pending' : '' }
                                        >
                                            <Row className="card-body-list">
                                                <Col xs={ 24 } sm={ 24 } md={ 12 } lg={ 13 } xl={ 14 }
                                                     className="resource-card-body-tex">
                                                    <Row>
                                                        <Col span={ 24 }>
                                                            <p className="resource-title">
                                                                { resource.name }
                                                            </p>
                                                        </Col>
                                                        <Col span={ 24 } className="tag-card">
                                                            { resource.labels?.map((label: ILabel, index: number) => (
                                                                <Tag
                                                                    key={ index }>{ FormatTag(label.name, screens) }</Tag>
                                                            )) }
                                                        </Col>
                                                        <Col span={ 24 } className="resource-description">
                                                            { resource.description !== null && (
                                                                <p>{ resource.description }</p>
                                                            ) }
                                                            { resource.url !== null && resource.url !== '' && resource.resource_type_id !== 3 && resource.resource_type_id !== 4
                                                            && resource.resource_type_id !== 5 && (
                                                                <p>url: <a href={ resource.url }>{ resource.url }</a>
                                                                </p>
                                                            ) }
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                {
                                                    screens.md && (
                                                        <Col xs={ 24 } sm={ 24 } md={ 12 } lg={ 11 } xl={ 10 }
                                                             className="resource_file">
                                                            <ResourceTypes resource={ resource } />
                                                        </Col>
                                                    )
                                                }
                                            </Row>
                                        </Card>
                                    </Col>
                                </Fragment>
                            ))
                        }
                    </Fragment>
                    : !rendering && !error ? <Empty /> : null
                }
                {
                    !empty && paginated && resourceCollectionInstance !== null && resourceTotalInstance !== null && !error && (
                        <Col span={ 24 } style={ { textAlign: 'center', marginTop: '3em' } }>
                            <Pagination total={ resourceTotalInstance }
                                        responsive
                                        className="paginator"
                                        pageSize={ pageSize }
                                        current={ page }
                                        onChange={ (page) => {
                                            setPage(page);
                                            setActionResource(true);
                                        } }
                                        defaultCurrent={ 1 }
                                        showSizeChanger={ false }
                                        showTotal={ resourceTotalInstance => `Total: ${ resourceTotalInstance }` } />
                        </Col>
                    )
                }
                {
                    !empty && error && (
                        <Server500 message="Estamos trabajando en solucionar el  problema, Da click en el botón" />
                    )
                }
            </Row>
        </Fragment>
    );
};
export default ResourcesList;

