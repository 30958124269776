import { IActivityGuideCollectionState }   from '../../interfaces/store/states/IActivityGuideCollectionState';
import { IActivityGuideCollectionActions } from '../../interfaces/store/actions/IActivityGuideCollectionActions';

const initialState: IActivityGuideCollectionState = {
    activityGuide: null,
    total: 0
};

const activityGuideCollectionReducer = (state = initialState, action: IActivityGuideCollectionActions) => {
    switch (action.type) {
        case 'SET_ACTIVITY_GUIDE_COLLECTION':
            return {
                ...state,
                activityGuide: action.value.activityGuide,
                total: action.value.total
            };
        default:
            return state;
    }
};

export default activityGuideCollectionReducer;