import React        from 'react';
import { Col, Row } from 'antd';

const FooterResource: React.FC = () => {
    return (
        <Row className="footer-container">
            <Col span={ 24 }>
                <p className="footer-resource">
                    Antes de que este recurso sea público pasara a revisión por nuestro equipo.
                </p>
            </Col>
        </Row>
    );
};
export default FooterResource;
