import { Button, Col, Form, Input, Row, Select, message, notification, Spin } from 'antd';
import React, { Fragment, useEffect, useRef, useState }                       from 'react';
import FooterResource                                                         from '../../components/footer';
import Labels
                                                                              from '../../../../components/labels';
import { ArrayLabels }                                                        from '../../../../components/labels/types';
import { DeleteOutlined, DeleteTwoTone, PlusOutlined }                        from '@ant-design/icons';
import IIndicator
                                                                              from '../../../../interfaces/entities/IIndicator';
import useServiceByRef
                                                                              from '../../../../utils/hooks/useServiceByRef';
import IndicatorService
                                                                              from '../../../../api/services/IndicatorService';
import ResourceTypeService
                                                                              from '../../../../api/services/ResourceTypeService';
import { IFormFields, LoadingFormType }                                       from './types';
import { handleFinish, initialDisabledValues, initialLoadingValues }          from './helpers';
import IResourceType
                                                                              from '../../../../interfaces/entities/IResourceType';
import { IResourceFile }                                                      from '../components/records/types';
import { useSelector }                                                        from 'react-redux';
import { IAppState }                                                          from '../../../../interfaces/store/IAppState';
import { AxiosError, AxiosResponse }                                          from 'axios';
import { handleAxiosError, isAxiosError }                                     from '../../../../utils/services';
import { storeRules }                                                         from './rules';
import IGrade
                                                                              from '../../../../interfaces/entities/IGrade';
import GradeService
                                                                              from '../../../../api/services/GradeService';
import { useKeycloak }                                                        from '@react-keycloak/web';
import ISubject
                                                                              from '../../../../interfaces/entities/ISubject';
import SubjectService
                                                                              from '../../../../api/services/SubjectService';
import IUnit
                                                                              from '../../../../interfaces/entities/IUnit';
import IUnitService
                                                                              from '../../../../api/services/UnitService';
import ResourceService
                                                                              from '../../../../api/services/ResourceService';
import setFormErrorsByAxiosResponse
                                                                              from '../../../../utils/forms/setFormErrorsByAxiosResponse';
import RecordsResource                                                        from '../components/records';
import { useHistory }                                                         from 'react-router-dom';

const { TextArea } = Input;
const { Item } = Form;

const NewResource: React.FC = () => {

        //CONFIGURATION
        const history = useHistory();
        const [form] = Form.useForm();
        const { keycloak } = useKeycloak();
        const ref = useRef<ArrayLabels>(null);
        const readyForAuthRequests = useSelector<IAppState, boolean>(({ axios }) => axios.readyForRequests);
        const schoolID = useSelector<IAppState, string | null>(({ auth }) => auth.selected_school_id);

        //SERVICES
        const unitService = useServiceByRef(IUnitService);
        const gradeService = useServiceByRef(GradeService);
        const subjectService = useServiceByRef(SubjectService);
        const resourceService = useServiceByRef(ResourceService);
        const indicatorService = useServiceByRef(IndicatorService);
        const resourceTypeService = useServiceByRef(ResourceTypeService);

        // USE STATE
        const [loading, setLoading] = useState<LoadingFormType>(initialLoadingValues);
        const [processing, setProcessing] = useState<boolean>(false);
        const [disabled, setDisabled] = useState<LoadingFormType>({ ...initialDisabledValues, form: false });

        const [resourceTypes, setResourceTypes] = useState<IResourceType[]>([]);

        const [units, setUnits] = useState<IUnit[]>([]);
        const [grades, setGrades] = useState<IGrade[]>([]);
        const [subjects, setSubjects] = useState<ISubject[]>([]);
        const [selectedGrade, setSelectedGrade] = useState<number>(0);

        const [indicators, setIndicators] = useState<IIndicator[]>([]);
        const [validateIndicator, setValidateIndicator] = useState<boolean>(false);
        const [selectedIndicators, setSelectedIndicators] = useState<IIndicator[]>([]);

        // to upload files
        const [typeSelected, setTypeSelected] = useState<number>(0);
        const [stateResource, setStateResource] = useState<number>(0);
        const [fileData, setFileData] = useState<IResourceFile[]>([]);

        // layout Form
        const layoutForm = {
            labelCol: { span: 24 },
            wrapperCol: { span: 24 },
        };

        //useEffect Initial
        useEffect(() => {
            if (readyForAuthRequests) {
                /**
                 * GET Resources Types
                 */
                const getResourcesTypes = async () => {
                    if (resourceTypeService.current) {
                        setLoading((s) => ({ ...s, resource_types: true }));
                        const response = await resourceTypeService.current.all();
                        if (isAxiosError(response)) {
                            const err = response as AxiosError;
                            message.error(`Ocurrio un error al obtener los tipos de recurso: ${ err.message }`);
                        } else {
                            const { data } = response as AxiosResponse<IResourceType[]>;
                            if (data.length > 0) {
                                setResourceTypes(data);
                            }
                        }
                        setLoading((s) => ({ ...s, resource_types: false }));
                    }
                };

                /**
                 * Get grades with validation if you are a teacher
                 */
                const getGrades = async () => {
                    setLoading(prevState => ({ ...prevState, grade: true }));
                    if (keycloak.hasResourceRole('TEACHER', process.env.REACT_APP_KEYCLOACK_CLIENT_ID)) {
                        if (gradeService.current) {
                            const response = await gradeService.current.teacherGrade();
                            if (!isAxiosError(response)) {
                                const { data } = response as AxiosResponse<IGrade[]>;
                                if (data.length > 0) {
                                    setGrades(data);
                                } else {
                                    message.error({
                                        content: <>Lo siento, no posee materias asignadas</>,
                                        duration: 2
                                    });
                                }
                            }
                        }
                    } else {
                        if (gradeService.current) {
                            const response = await gradeService.current.all();
                            if (!isAxiosError(response)) {
                                const { data } = response as AxiosResponse<IGrade[]>;
                                if (data.length > 0) {
                                    setGrades(data);
                                } else {
                                    message.error({
                                        content: <>Lo siento, no posee materias asignadas</>,
                                        duration: 2
                                    });
                                }
                            }
                        }
                    }
                    setLoading(prevState => ({ ...prevState, grade: false }));
                };

                // CALL FUNCTIONS
                getResourcesTypes().finally();
                getGrades().finally();
            }
        }, [readyForAuthRequests, resourceTypeService, gradeService, keycloak]);

        /**
         * Get Subject where grade ID
         * @param id
         */
        const handleGradesChange = async (id: number) => {
            form.setFields([{
                name: 'subject_id',
                value: null
            }, {
                name: 'unit_id',
                value: null
            }, {
                name: 'indicators',
                value: null
            }]);
            setDisabled(() => ({
                ...initialDisabledValues,
                subject: true,
                unit: true,
                indicator: true
            }));
            setLoading((s) => ({ ...s, subject: true }));
            if (subjectService.current) {
                const response = keycloak.hasResourceRole('TEACHER') ? await subjectService.current.getByAcademicCharge(id) : await subjectService.current.getByGrade(id);
                if (!isAxiosError(response)) {
                    const { data } = response as AxiosResponse<ISubject[]>;
                    if (data.length > 0) {
                        setSubjects(data);
                        setSelectedGrade(id);
                        setDisabled((s) => ({ ...s, subject: false }));
                    } else {
                        message.error({
                            content: <>No se encontraron materias disponibles en el grado seleccionado</>,
                            icon: 'error',
                            duration: 2
                        });
                    }
                }
            }
            setLoading((s) => ({ ...s, subject: false }));
        };

        /**
         * Get Unit where Subject ID & Grade ID
         * @param id
         */
        const handleSubjectChange = async (id: number) => {
            form.setFields([{
                name: 'unit',
                value: null
            }, {
                name: 'indicators',
                value: null
            }]);
            setDisabled(() => ({
                ...initialDisabledValues,
                subject: false,
                unit: true,
                indicator: true
            }));
            setLoading((s) => ({ ...s, unit: true }));
            if (unitService.current) {
                const response = await unitService.current.getByAcademicSubject({
                    grade_id: selectedGrade,
                    subject_id: id
                });

                if (!isAxiosError(response)) {
                    const { data } = response as AxiosResponse<IUnit[]>;
                    if (data.length > 0) {
                        setDisabled((s) => ({ ...s, unit: false }));
                        setUnits(data);
                    } else {
                        message.error({
                            content: <>No se encontraron materias disponibles en el grado seleccionado</>,
                            icon: 'error',
                            duration: 2
                        });
                    }
                }
            }
            setLoading((s) => ({ ...s, unit: false }));
        };

        /**
         * Get Indicators & topics where Unit ID
         * @param id
         */
        const handleUnitChange = async (id: string) => {
            form.setFields([{
                name: 'indicators',
                value: null
            }]);
            setDisabled(() => ({
                ...initialDisabledValues,
                subject: false,
                unit: false,
                indicator: true
            }));
            getIndicators(id).finally();
        };

        // INDICATORS FUNCTIONS
        useEffect(() => {
            if (validateIndicator) {
                if (selectedIndicators.length === 0) {
                    form.resetFields(['indicators']);
                }
            }
        }, [selectedIndicators, validateIndicator, form]);

        /**
         * Get Indicators where Unit ID
         * @param id
         */
        const getIndicators = async (id: string) => {
            setIndicators([]);
            if (indicatorService.current) {
                setDisabled(() => ({
                    ...initialDisabledValues,
                    subject: false,
                    unit: false,
                    indicator: false
                }));
                setLoading((s) => ({ ...s, indicator: true }));
                const response = await indicatorService.current.getByUnit(id);

                if (!isAxiosError(response)) {
                    const { data } = response as AxiosResponse<IIndicator[]>;
                    if (data.length > 0) {
                        setIndicators(data);
                        setDisabled((s) => ({ ...s, indicator: false }));
                    } else {
                        message.error({
                            content: <>No se encontraron indicadores disponibles en la unidad</>,
                            icon: 'error',
                            duration: 2
                        });
                    }
                }
            }
            setLoading((s) => ({ ...s, indicator: false }));
        };

        const handleIndicatorChange = async (id: string) => {
            setValidateIndicator(true);
            if (indicators.length > 0) {
                const provIndicators = Object.assign([], selectedIndicators);
                let existIndex = 0;
                let exist = false;

                provIndicators.some((value: IIndicator, index) => {
                    if (value.id === id) {
                        existIndex = index;
                        exist = true;
                    }
                    return value.id === id;
                });

                if (exist) {
                    form.setFields([
                        {
                            name: ['indicators', existIndex],
                            errors: ['No puede registrar el mismo indicador']
                        }
                    ]);
                } else {
                    setSelectedIndicators((s) => ([...s, indicators.find((indi) => indi.id === id) as unknown as IIndicator]));
                    form.setFields([
                        {
                            name: ['indicators', existIndex],
                            errors: []
                        }
                    ]);
                }

            } else {
                setSelectedIndicators([indicators.find((indi) => indi.id === id) as unknown as IIndicator]);
            }
        };

        const handleRemoveIndicator = (callback: () => void, field: any) => {
            const fieldValue = form.getFieldValue(['indicators', field.name]);

            if (fieldValue !== undefined) {
                const newSelectedIndicators = selectedIndicators.filter((selectedIndicator) => (selectedIndicator.id !== fieldValue));

                setSelectedIndicators(newSelectedIndicators);
                callback();
            } else {
                callback();
            }
        };

        //SUBMIT FORM
        const rerender = () => {
            // This forces the component to rerender.
            setLoading(val => ({ ...val }));
        };

        const onError = (err: AxiosError) => {
            setProcessing(false);
            handleAxiosError(err, {
                422: response => setFormErrorsByAxiosResponse(form, response),
                default: error => notification.error({
                    message: error.message,
                    description: 'Lo sentimos, ocurrio un error al momento de guardar el recurso',
                })
            });

            rerender();
        };

        const onSuccess = () => {
            setProcessing(false);
            notification.success({
                duration: 3.5,
                message: 'Operación finalizada',
                description: `Recurso creado Exitosamente`,
            });

            form.resetFields();
            setStateResource(0);
            setFileData([]);
            redirectToProfile();
        };

        const redirectToProfile = () => {
            if (keycloak.hasResourceRole('ADMIN')) {
                history.push(`/admin`);
            } else if (keycloak.hasResourceRole('LXD')) {
                history.push(`/lxd`);
            } else if (keycloak.hasResourceRole('TEACHER')) {
                history.push(`/teacher`);
            }
        };


        /**
         * disable the submit event with the enter key, restriction necessary when a new label is entered
         * @param event
         */
        const handleOnEnter = (event: React.KeyboardEvent<HTMLFormElement>) => {
            if (event.key === 'Enter') {
                event.preventDefault();
            }
        };
        return (
            <div className="content-resource">
                <Form
                    { ...layoutForm }
                    name="basic"
                    className="item-resource"
                    form={ form }
                    onFinish={ v => {
                        if (resourceService.current) {
                            setProcessing(true);
                            const labels = ref.current?.labels;
                            const resource_state_id = stateResource;
                            const files = fileData;
                            const school_id = schoolID;
                            const data = { ...v, resource_state_id, labels, files, school_id };
                            handleFinish(data as IFormFields, onSuccess, onError, resourceService.current);
                        }
                    }
                    }
                    requiredMark={ false }
                    onKeyDown={ e => {
                        handleOnEnter(e);
                    } }
                >
                    <Spin className="spin-create" tip="Procesando..." spinning={ processing } size="large">
                        <h2 className="title-resource">Crear recurso </h2>
                        <Item
                            label={ <h3 className="title-sub-1">{ 'Tipo de recurso' }</h3> }
                            name="resource_type_id"
                            hasFeedback
                            rules={ storeRules.resource_type_id }
                        >
                            <Select placeholder="Seleccione un tipo de recurso"
                                    options={ resourceTypes.map((value) => ({ value: value.id, label: value.name })) }
                                    className="select-resource"
                                    loading={ loading.resource_types }
                                    disabled={ resourceTypes.length < 0 }
                                    onChange={ (value: number) => setTypeSelected(value) }
                                    getPopupContainer={ trigger => trigger.parentNode }
                                    optionFilterProp="label"
                                    allowClear
                                    showArrow
                                    showSearch
                            />
                        </Item>
                        <Item
                            label={ <h3 className="title-sub-1">{ 'Nombre' }</h3> }
                            name="name"
                            hasFeedback
                            rules={ storeRules.name }
                        >
                            <Input className="input-resource" placeholder="¿Cual es el nombre del recurso?" />
                        </Item>
                        <Item
                            label={ <h3 className="title-sub-1">{ 'Tags/palabras claves' }</h3> }
                            name="labels"
                            hasFeedback
                        >
                            <Labels ref={ ref } />
                        </Item>
                        <Item
                            label={ <h3 className="title-sub-1">{ 'Descripción' }</h3> }
                            name="description"
                            hasFeedback
                            rules={ storeRules.description }
                        >
                            <TextArea className="input-resource" rows={ 7 }
                                      placeholder="Escribe una descripción del recurso" />
                        </Item>
                        <Item
                            label={ <h3 className="title-sub-1">{ 'Grado' }</h3> }
                            name="grade_id"
                            hasFeedback
                            rules={ storeRules.grade_id }
                        >
                            <Select placeholder="Seleccione un grado"
                                    options={ grades.map((value) => ({ value: value.id, label: value.name })) }
                                    className="select-resource"
                                    loading={ loading.grade }
                                    disabled={ grades.length < 0 }
                                    onChange={ (value: number) => handleGradesChange(value) }
                                    getPopupContainer={ trigger => trigger.parentNode }
                                    optionFilterProp="label"
                                    allowClear
                                    showArrow
                                    showSearch
                            />
                        </Item>
                        <Item
                            label={ <h3 className="title-sub-1">{ 'Materia' }</h3> }
                            name="subject_id"
                            hasFeedback
                            rules={ storeRules.subject_id }
                        >
                            <Select placeholder="Seleccione una materia"
                                    className="select-resource"
                                    loading={ loading.subject }
                                    disabled={ disabled.subject }
                                    options={ subjects.map((value) => ({ value: value.id, label: value.name })) }
                                    onChange={ (value: number) => handleSubjectChange(value) }
                                    getPopupContainer={ trigger => trigger.parentNode }
                                    optionFilterProp="label"
                                    allowClear
                                    showArrow
                                    showSearch
                            />
                        </Item>
                        <Item
                            label={ <h3 className="title-sub-1">{ 'Unidad' }</h3> }
                            name="unit_id"
                            hasFeedback
                            rules={ storeRules.unit_id }
                        >
                            <Select placeholder="Seleccione una Unidad"
                                    className="select-resource"
                                    loading={ loading.unit }
                                    disabled={ disabled.unit }
                                    options={ units.map((value) => ({ value: value.id, label: value.name })) }
                                    onChange={ (value: string) => handleUnitChange(value) }
                                    getPopupContainer={ trigger => trigger.parentNode }
                                    optionFilterProp="label"
                                    allowClear
                                    showArrow
                                    showSearch
                            />
                        </Item>
                        <Item
                            label={ <h3 className="title-sub-1">{ 'Tema' }</h3> }
                            name="topic"
                            hasFeedback
                            rules={ storeRules.topic }
                        >
                            <Input className="input-resource" placeholder="Ingrese un tema" />
                        </Item>
                        <h3 className="title-sub-1">Indicadores de logro </h3>
                        <Form.List
                            name="indicators"
                        >
                            { (fields, { add, remove }) => (
                                <div>
                                    { fields.map((value, index) => (
                                        <Row
                                            gutter={ [16, 16] }
                                            key={ index }
                                        >
                                            <Col span={ 22 }>
                                                <Form.Item
                                                    { ...value }
                                                    key={ index }
                                                    fieldKey={ value.fieldKey + 1 }
                                                    rules={ storeRules.indicators }
                                                    hasFeedback
                                                >
                                                    <Select placeholder="Selecciona un indicador de logro"
                                                            className="select-resource"
                                                            options={ indicators.map((value) => ({
                                                                value: value.id,
                                                                label: value.name
                                                            })) }
                                                            loading={ loading.indicator }
                                                            disabled={ disabled.indicator }
                                                            onChange={ (value: string) => handleIndicatorChange(value) }
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col span={ 2 }>
                                                { fields.length > 1 ? (
                                                    <DeleteOutlined
                                                        onClick={ () => handleRemoveIndicator(() => remove(value.name), value) }
                                                    />
                                                ) : null }
                                            </Col>
                                        </Row>
                                    )) }

                                    <Button block shape="round" className="btn-primary-resources" onClick={ () => add() }
                                            disabled={ disabled.indicator }>
                                        <>
                                            { fields.length > 1 ? 'Agregar otro indicador' : 'Agregar indicador' }
                                        </>
                                    </Button>

                                    { fields.length === 0 ? add() : <></> }
                                </div>
                            ) }
                        </Form.List>
                        <Item
                            label={ <h3 className="title-sub-1">{ 'Url' }</h3> }
                            name="url"
                            hasFeedback
                        >
                            <Input className="input-resource"
                                   placeholder="Ingrese una url adicional  Ej. juego online, link" />
                        </Item>
                        { typeSelected >= 3 && (
                            <Fragment>
                                <RecordsResource
                                    form={ form }
                                    typeSelected={ typeSelected }
                                    setFileData={ setFileData }
                                    fileData={ fileData }
                                />

                                {
                                    typeSelected === 3 && (
                                        <Form.List name="records_external_link">
                                            { (fields, { add, remove }) => (
                                                <>
                                                    { fields.map(({ key, name, fieldKey, ...restField }) => (
                                                        <Row
                                                            gutter={ [16, 16] }
                                                            key={ key }
                                                        >
                                                            <Col span={ 22 }>
                                                                <Form.Item
                                                                    { ...restField }
                                                                    name={ [name, 'link_file'] }
                                                                    fieldKey={ [fieldKey, 'link_file'] }
                                                                    rules={ [{
                                                                        required: true,
                                                                        message: 'El link del video es requerido'
                                                                    },
                                                                        {
                                                                            whitespace: true
                                                                        },
                                                                        {
                                                                            validator: async (_, value) => {
                                                                                const url = value.match('^(http:\\/\\/|https:\\/\\/)(vimeo\\.com|youtu\\.be|www\\.youtube\\.com)\\/([\\w\\/]+)([\\?].*)?$');
                                                                                if (!url) {
                                                                                    return Promise.reject(new Error('La url ingresada no es valida, por favor ingresar una url de Youtube o Vimeo'));
                                                                                }
                                                                            }
                                                                        },
                                                                    ] }

                                                                    hasFeedback
                                                                >
                                                                    <Input className="input-resource" type="url"
                                                                           placeholder="Url video" />
                                                                </Form.Item>
                                                            </Col>
                                                            <Col span={ 2 }>
                                                                <DeleteTwoTone onClick={ () => remove(name) }
                                                                               style={ { fontSize: '24px' } } />
                                                            </Col>
                                                        </Row>
                                                    )) }
                                                    <Form.Item>
                                                        <Button block shape="round" className="btn-primary-resources"
                                                                onClick={ () => add() }
                                                                icon={ <PlusOutlined /> }>
                                                            Agregar videos por medio de link
                                                        </Button>
                                                    </Form.Item>
                                                </>
                                            ) }
                                        </Form.List>
                                    )
                                }
                            </Fragment>
                        ) }
                        <Row gutter={ [40, 16] } className="btn-action-resource-new">
                            <Col xs={ 24 } sm={ 24 } md={ 6 } lg={ 6 } xl={ 6 }>
                                <Button shape="round" htmlType="submit" className="btn-eraser-text" block
                                        onClick={ () => setStateResource(2) }
                                >
                                    Guardar borrador
                                </Button>
                            </Col>
                            <Col xs={ 24 } sm={ 24 } md={ 18 } lg={ 18 } xl={ 18 }>
                                <Button shape="round" htmlType="submit" className="btn-primary-resources" block
                                        onClick={ () => setStateResource(1) }
                                >
                                    Publicar
                                </Button>
                            </Col>
                        </Row>
                        <FooterResource />
                    </Spin>
                </Form>
            </div>
        );
    }
;
export default NewResource;
