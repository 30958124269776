import RestService                                  from '../abstract/RestService';
import { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import ISchool                                      from '../../interfaces/entities/ISchool';


class SchoolService extends RestService<ISchool> {
    constructor(axios: AxiosInstance) {
        super('schools', axios);
    }

    async all(page?: number): Promise<AxiosResponse<ISchool[]> | AxiosError> {
        try {
            const params = page !== undefined ? `?page=${ page }` : '';
            return await this.http(this.uri + params);
        } catch (e) {
            return e as AxiosError;
        }
    }

    async store(params?: object): Promise<AxiosResponse | AxiosError> {
        try {
            return await this.http.post(`${ this.uri }`, params);
        } catch (e) {
            return e as AxiosError;
        }
    }

    async get(id?: string): Promise<AxiosResponse<ISchool> | AxiosError> {
        try {
            return await this.http.get(`${ this.uri }/${ id }`);
        } catch (e) {
            return e as AxiosError;
        }
    }

    async update(id: string, params?: object): Promise<AxiosResponse | AxiosError> {
        try {
            return await this.http.put(`${ this.uri }/${ id }`, params);
        } catch (e) {
            return e as AxiosError;
        }
    }

    async delete(id: string): Promise<AxiosResponse | AxiosError> {
        try {
            return await this.http.delete(`${ this.uri }/${ id }`);
        } catch (e) {
            return e as AxiosError;
        }
    }

    async changeContentPrivacy(id: string): Promise<AxiosResponse | AxiosError> {
        try {
            return await this.http.put(`${ this.uri }/change_config/${ id }`, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
        } catch (e) {
            return e as AxiosError;
        }
    }

    async changeStatus(id: string): Promise<AxiosResponse | AxiosError> {
        try {
            return await this.http.put(`${ this.uri }/change_status/${ id }`, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
        } catch (e) {
            return e as AxiosError;
        }
    }

    async getByUser(id?: string): Promise<AxiosResponse<ISchool> | AxiosError> {
        try {
            return await this.http.get(`${ this.uri }/get_by/user/${ id }`);
        } catch (e) {
            return e as AxiosError;
        }
    }

}

export default SchoolService;