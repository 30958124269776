import RestService                                  from '../abstract/RestService';
import IAnnualPlanning, { AssignmentType }          from '../../interfaces/entities/IAnnualPlanning';
import { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import IPaginatedData                               from '../../interfaces/IPaginatedData';

export enum PlanningRelationsEnum {
    academic_charge = 'academic_charge',
    teacher = 'academic_charge.teacher',
    academic_subject = 'academic_charge.academic_subject',
    grade = 'academic_charge.academic_subject.grade',
    subject = 'academic_charge.academic_subject.subject',
    school_year = 'academic_charge.school_year'
}

type SearchParams = {
    teacher_id?: string
    academic_subject_id?: string,
    academic_charge_id?: string
    school_id?: string
    with?: PlanningRelationsEnum[]
};

class PlanningService extends RestService<IAnnualPlanning> {
    constructor(axios: AxiosInstance) {
        super('annual_plannings', axios);
    }

    async all(page?: number): Promise<AxiosResponse<IAnnualPlanning[] | IPaginatedData<IAnnualPlanning>> | AxiosError> {
        const params = page !== undefined ? `?page=${ page }` : '';

        try {
            return await this.http.get(this.uri + params);
        } catch (e) {
            return e as AxiosError;
        }
    }

    async getDefaultData(): Promise<AxiosResponse<AssignmentType> | AxiosError> {
        try {
            return await this.http.get(this.uri + '/default');
        } catch (e) {
            return e as AxiosError;
        }
    }

    async store(data: object): Promise<AxiosResponse | AxiosError> {
        try {
            return await this.http.post(this.uri, data);
        } catch (e) {
            return e as AxiosError;
        }
    }

    async search(params: SearchParams, page?: number): Promise<AxiosResponse<IAnnualPlanning[] | IPaginatedData<IAnnualPlanning>> | AxiosError> {
        const queryParams = page !== undefined ? `?page=${ page }` : '';

        try {
            return await this.http.post(`${ this.uri }/search${ queryParams }`, params);
        } catch (e) {
            return e as AxiosError;
        }
    }

    async get(id: string, relations: PlanningRelationsEnum[] = []): Promise<AxiosResponse<IAnnualPlanning> | AxiosError> {
        const params = relations.reduce(
            (s, r, i) => `${ s }${ i === 0 ? '' : '&' }with[]=${ r }`,
            relations.length > 0 ? '?' : ''
        );

        try {
            return await this.http.get(`${ this.uri }/${ id }${ params }`);
        } catch (e) {
            return e as AxiosError;
        }
    }

    async update(id: string, data: object): Promise<AxiosResponse | AxiosError> {
        try {
            return await this.http.put(`${ this.uri }/${ id }`, data);
        } catch (e) {
            return e as AxiosError;
        }
    }

    async updateUnitPivot(id: string, data: object): Promise<AxiosResponse | AxiosError> {
        try {
            return await this.http.put(`${ this.uri }/unit/planning/${ id }`, data);
        } catch (e) {
            return e as AxiosError;
        }
    }
}

export default PlanningService;
