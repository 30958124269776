import React, { useEffect, useState }     from 'react';
import { Button, Col, Row, Space, Table } from 'antd';
import moment                             from 'moment';
import { FileOutlined }                   from '@ant-design/icons';
import ModalDetailPlanning                from './modal/ModalDetailPlanning';
import { redirectByRole }                 from '../../../../utils/redirectByRole';
import { useHistory }                     from 'react-router-dom';
import IUnit                              from '../../../../interfaces/entities/IUnit';
import useServiceByRef                    from '../../../../utils/hooks/useServiceByRef';
import UnitService                        from '../../../../api/services/UnitService';
import IUnitSabi                          from '../../../../interfaces/entities/IUnitSabi';
import { handleAxiosError, isAxiosError } from '../../../../utils/services';
import { AxiosError, AxiosResponse }      from 'axios';
import ModalEditUnitPlanning              from './EditUnitPlanning/ModalEditUnitPlanning';

type Props = {
    planning_id: string
    academic_subject_id: string
    planning_units: IUnit[]
}

const UnitPlanningTable: React.FC<Props> = ({ academic_subject_id, planning_id, planning_units }) => {

    const history = useHistory();
    const unitService = useServiceByRef(UnitService);

    const [unitsSabi, setUnitsSabi] = useState<IUnitSabi[]>([]);
    const [units, setUnits] = useState<IUnit[]>([]);

    const [refreshSabi, setRefreshSabi] = useState<boolean>(false);

    const redirectToResources = (unit: string) => {
        if (unit.length > 0) {
            const urlRole = redirectByRole(`plannings/associated/resources/${ unit }`);
            history.push(`${ urlRole }`);
        }
    };

    useEffect(() => {
        if (planning_units !== undefined) {
            setUnits(planning_units);
        }
    }, [academic_subject_id, planning_units, unitService]);

    useEffect(() => {
        const unitByAcademicSubjectSabi = async () => {
            if (unitService.current) {
                const response = await unitService.current?.getByAcademicSubjectSABI({ academic_subject_id: academic_subject_id });
                if (isAxiosError(response)) {
                    handleAxiosError(response as AxiosError, {
                        404: () => {
                        },
                        default: () => {
                            setRefreshSabi(true);
                            //    TODO: define message by token expiration if necessary
                        }
                    });
                } else {
                    const { data } = response as AxiosResponse<IUnitSabi[]>;
                    setUnitsSabi(data);
                }
            }
        };

        if (!refreshSabi && academic_subject_id.length > 0) {
            unitByAcademicSubjectSabi().finally();
        }
    }, [academic_subject_id, refreshSabi, unitService]);

    const totalHoursUnit = (data: IUnit[]) => {
        let total_hours = 0;
        data.map(value => total_hours += value.pivot?.hours_classes !== undefined ? value.pivot?.hours_classes : 0);

        return total_hours;
    };

    return (
        <Row key={ 'units_planning' }>
            <Col span={ 24 }>
                <Table<IUnit>
                    loading={ unitsSabi === undefined && units !== undefined }
                    className={ 'table-unit-planning' }
                    key={ 'units_planning_table' }
                    rowKey="id"
                    dataSource={ units }
                    title={ () => 'Planes de unidad' }
                    footer={ () => {
                        return (
                            <Row>
                                <Col span={ 4 } offset={ 6 }>
                                    <strong>TOTAL N° HORAS CLASES</strong>:{ totalHoursUnit(units) }
                                </Col>
                            </Row>
                        );
                    } }
                    columns={ [
                        {
                            title: 'Nombre Unidad',
                            render: (_, units) => (`${ units.name }`),
                        },
                        {
                            title: 'Indicadores de logro',
                            render: (_, units) => (`${ units.indicators !== undefined ? units.indicators.length : 0 }`),
                        },
                        {
                            title: 'Nº Horas clase',
                            render: (_, units) => (`${ units.pivot !== undefined ? units.pivot.hours_classes : 0 }`),
                        },
                        {
                            title: 'Fecha inicial',
                            render: (_, units) => (`${ units.pivot !== undefined && units.pivot.start_date !== null ? moment(units.pivot.start_date).format('DD/MM/YY') : 'dd-mm-yy' }`),
                        },
                        {
                            title: 'Fecha final',
                            render: (_, units) => (`${ units.pivot !== undefined && units.pivot.end_date !== null ? moment(units.pivot.end_date).format('DD/MM/YY') : 'dd-mm-yy' }`),
                        },
                        {
                            title: 'Opciones',
                            render: (_, units) => (
                                <Space>
                                    <ModalEditUnitPlanning unit={ units } planning_id={ planning_id }
                                                           setUnits={ setUnits } />
                                    <ModalDetailPlanning unit={ units } unitsSabi={ unitsSabi } />
                                    <Button
                                        onClick={ () => redirectToResources(units.id) }
                                        title="Recursos relacionados"
                                        className={ 'btn-table-action-active' }
                                        shape={ 'circle' }
                                        icon={ <FileOutlined /> } />
                                </Space>
                            )
                        }
                    ] }
                />
            </Col>
        </Row>
    );
};
export default UnitPlanningTable;
