import React, { Fragment }       from 'react';
import IUserSchool               from '../../../../interfaces/entities/IUserSchool';
import { Button, Col, Row, Tag } from 'antd';
import keycloak                  from '../../../../keycloak.config';

type Props = {
    isTeacher?: boolean;
    user_school: IUserSchool;
    loading: boolean;
}

const BannerTeachers: React.FC<Props> = ({ isTeacher, user_school, loading }) => {
        return (
            <Fragment>
                { !loading && user_school !== undefined && (
                    <Fragment>
                        <Col span={ 20 }>
                            <Row className="banner-usr-data">
                                <Col span={ 24 }>
                                    <h3 className="banner-tittle">
                                        { user_school.user?.name } { user_school.user?.last_name }
                                    </h3>
                                </Col>
                                <Col span={ 24 }>
                                    <h4 className="banner-school">
                                        { user_school.school?.name }
                                    </h4>
                                </Col>
                                <Col span={ 24 }>
                                    <p className="banner-tag"><Tag
                                        className="tag-card">
                                        { user_school.type?.name }
                                    </Tag></p>
                                </Col>
                                { user_school.academic_charge !== undefined && isTeacher && (
                                    <Col span={ 24 }>
                                        <h2 className="banner-subject"> Maestro de:
                                            <ul id="menu">{ user_school.academic_charge.map((academic_charge, index) =>
                                                <li key={ index }> { academic_charge.academic_subject?.subject?.name } - { academic_charge.academic_subject?.grade?.name }
                                                    <span className="separator"> • </span>
                                                </li>
                                            ) }  </ul>
                                        </h2>
                                    </Col>
                                ) }
                            </Row>
                        </Col>
                        <Col span={ 4 } className="banner-session">
                            <Button className="btn-closed-session"
                                    onClick={ () => keycloak.logout({ redirectUri: window.location.origin }) } type="link"
                                    danger>{ 'Cerrar sesión' }</Button>
                        </Col>
                    </Fragment>
                ) }
            </Fragment>
        );
    }
;
export default BannerTeachers;
