import React, { Fragment } from 'react';
import AudioType           from '../../../../views/images/type-resource/audio.svg';
import PLayPlayer          from '../../../../views/images/player/play-circle.svg';
import PauseSVG            from '../../../../views/images/player/pause-circle.svg';
import LeftPlayer          from '../../../../views/images/player/chevrons-left.svg';
import RightPlayer         from '../../../../views/images/player/chevrons-right.svg';
import AudioPlayer         from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.less';
import Icon                from '@ant-design/icons';
import IRecord             from '../../../../interfaces/entities/IRecord';
import { useKeycloak }     from '@react-keycloak/web';

type Props = {
    records?: IRecord[];
};

const AudioResourceType: React.FC<Props> = ({ records }) => {
    const { keycloak } = useKeycloak();
    return (
        <Fragment>
            <div className="audio-home">
                { records !== undefined && records.length > 0 && keycloak.authenticated ?
                    <AudioPlayer

                        src={ records?.[0].file_url }
                        showFilledVolume={ false }
                        customIcons={ {
                            play: <Icon component={ () => (
                                <img src={ PLayPlayer } alt="play" className="icon-play-player" />) } />,
                            pause: <Icon component={ () => (
                                <img src={ PauseSVG } alt="pause" className="icon-play-player" />) } />,
                            rewind: <Icon component={ () => (
                                <img src={ LeftPlayer } alt="pause" className="icon-play-player" />) } />,
                            forward: <Icon component={ () => (
                                <img src={ RightPlayer } alt="pause" className="icon-play-player" />) } />,
                        } }
                    />
                    :
                    <AudioPlayer
                        src={ '' }
                        showFilledVolume={ false }
                        customIcons={ {
                            play: <Icon component={ () => (
                                <img src={ PLayPlayer } alt="play" className="icon-play-player" />) } />,
                            pause: <Icon component={ () => (
                                <img src={ PauseSVG } alt="pause" className="icon-play-player" />) } />,
                            rewind: <Icon component={ () => (
                                <img src={ LeftPlayer } alt="pause" className="icon-play-player" />) } />,
                            forward: <Icon component={ () => (
                                <img src={ RightPlayer } alt="pause" className="icon-play-player" />) } />,
                        } }
                    />
                }
            </div>
            <div className="type_resource">
                <img src={ AudioType }
                     alt="Type_document" />
            </div>
        </Fragment>
    );
};
export default AudioResourceType;
