import React                from 'react';
import { routeType }        from '../../types/routes';
import ProfileTeacher       from '../../views/teacher/profile';
import NewResource          from '../../views/resource/resource/create';
import Resource             from '../../views/resource/show/resource';
import StudentsGuide        from '../../views/resource/show/ActivityGuide';
import FavoriteResources    from '../../views/resource/favorite';
import UpdateResource       from '../../views/resource/resource/edit';
import CreatePlanning       from '../../views/plannings/CreatePlanning';
import HomePlanning         from '../../views/plannings/Home';
import UpdatePlanning       from '../../views/plannings/UpdatePlanning';
import NewDidacticLetter    from '../../views/resource/didacticMap/index';
import DidacticMap          from '../../views/resource/show/didacticLetter/';
import EditDidacticMap      from '../../views/resource/didacticMap/edit';
import CreateActivityGuides from '../../views/activity_guides/CreateActivityGuides';
import UpdateActivityGuides from '../../views/activity_guides/UpdateActivityGuides';
import ActivityGuidesHome   from '../../views/activity_guides/Home';
import AssociatedResources  from '../../views/plannings/components/AssociatedResources';

const routes: routeType[] = [
    {
        path: '',
        component: ProfileTeacher
    },
    {
        path: 'resource/:resource_id',
        component: Resource
    },
    {
        path: 'new/resource',
        component: NewResource
    },
    {
        path: 'update/resource/:resource_id',
        component: UpdateResource
    },
    {
        path: 'favorite/resource',
        component: FavoriteResources
    },
    {
        path: 'new/didactic_map/',
        component: NewDidacticLetter
    },
    {
        path: 'didactic_map/:didactic_map_id',
        component: DidacticMap
    },
    {
        path: 'update/didactic_map/:didactic_map_id',
        component: EditDidacticMap
    },
    {
        path: 'plannings/',
        component: HomePlanning
    },
    {
        path: 'plannings/new',
        component: CreatePlanning
    },
    {
        path: 'plannings/edit/:planning_id',
        component: UpdatePlanning
    },
    {
        path: 'activity_guides',
        component: ActivityGuidesHome
    },
    {
        path: 'activity_guide/s/:activity_guide_code',
        component: () => <StudentsGuide publicGuide={ false } />
    },
    {
        path: 'activity_guides/create/:draft_id?',
        component: () => <CreateActivityGuides teacher />
    },
    {
        path: 'activity_guides/edit/:activity_guide_id',
        component: () => <UpdateActivityGuides teacher />
    },
    {
        path: 'plannings/associated/resources/:unit_id',
        component: AssociatedResources
    },
];

export default routes;
