import React, { Dispatch, Fragment, SetStateAction, useState } from 'react';
import { Button, Col, Form, Input, notification, Row, Upload } from 'antd';
import normFile                                                from '../../../../../utils/forms/normFile';
import handleFiles                                             from '../../../../../utils/forms/handleImageFile';
import { DeleteTwoTone, UploadOutlined }                       from '@ant-design/icons';
import ImageFileType                                           from '../../../../images/type-resource/file-text.svg';
import { FormInstance }                                        from 'antd/es/form';
import { RcFile }                                              from 'antd/es/upload';
import { IResourceFile }                                       from './types';

type Props = {
    form: FormInstance
    typeSelected: number
    setFileData: Dispatch<SetStateAction<IResourceFile[]>>
    fileData: IResourceFile[]
};

const { Item } = Form;
const { Dragger } = Upload;

const RecordsResource: React.FC<Props> = ({ form, typeSelected, setFileData, fileData }) => {

    //STATE
    const [nameTemporal, setNameTemporal] = useState<string>('');
    const [fileSize, setFileSize] = useState<number>(0);
    const [countFile, setCountFile] = useState<number>(1);

    const filesResource = (src: string | null, file: RcFile) => {
        const size = sizeMegaBits(file.size);
        if (src !== null && size <= 25) {
            if (countFile <= 5) {
                const extension = getExtensionFile(file.name);
                const name_file = getNameFile(file.name);
                const data = [...fileData, {
                    uuid: file.uid,
                    name_file: name_file !== undefined ? name_file : '',
                    src: src,
                    file: file as Blob,
                    active: false,
                    extension: extension !== undefined ? extension : ''
                }];
                const filesSizeNew = fileSize + file.size;

                setFileData(data);
                setFileSize(filesSizeNew);
                setCountFile(countFile + 1);
            }
        } else {
            notification.error({
                message: 'Tamaño de archivos',
                duration: 2,
                description: 'El tamaño de los archivos excenden los 25MB',
            });
        }
    };

    const changeNameTemporal = (e: any) => {
        setNameTemporal(e.target.value);
    };

    const handleEditFile = (id: string) => {
        if (fileData !== null) {
            const fileSelected = fileData.find(data => data.uuid === id);
            if (fileSelected !== undefined) {
                fileSelected.name_file = `${ nameTemporal }`;
                setNameTemporal('');
            }
        }
    };

    const handleActiveFile = (id: string) => {
        if (fileData !== null) {
            const fileSelected = fileData.find(data => data.uuid === id);
            if (fileSelected !== undefined) {
                const newData = fileData.filter(data => data.uuid !== id);
                const data = [...newData, {
                    uuid: fileSelected.uuid,
                    name_file: fileSelected.name_file,
                    src: fileSelected.src,
                    file: fileSelected.file,
                    active: !fileSelected.active,
                    extension: fileSelected.extension
                }];

                setFileData(data);
                setNameTemporal('');
                disabledFile();
            }
        }
    };

    const disabledFile = () => {
        if (fileData !== null) {
            const fileActive = fileData.find(file => file.active);
            if (fileActive !== undefined) fileActive.active = false;
        }
    };

    const getNameFile = (name: string) => {
        const path_split = name.split('.');
        return path_split.shift();
    };

    const getExtensionFile = (name: string) => {
        const path_split = name.split('.');
        return path_split.pop();
    };

    const sizeMegaBits = (size: number) => {
        return size / 1000000;
    };

    const removeFileList = (id: string) => {
        if (id.trim() !== null) {
            const file = fileData.find(data => data.uuid === id);
            if (file !== undefined) {
                const removeSize = fileSize - file.file.size;
                setFileSize(removeSize);
                setCountFile(countFile - 1);
            }

            const newData = fileData.filter(data => data.uuid !== id);
            setFileData(newData);
        }
    };

    return (
        <Fragment>
            <Item
                name="records"
                hidden
            >
                <Input />
            </Item>

            <Item
                label={ <h3 className="title-sub-1">{ 'Subir archivos' }</h3> }
                extra={ 'Maximo 5 archivos, 25MB en total .' }
                className="drag-files"
                name="files"
                valuePropName="fileList"
                getValueFromEvent={ normFile }
                help={ form.getFieldError(['files'])[0] ?? '' }
                validateStatus={ form.getFieldError('files').length > 0 ? 'error' : '' }
                hasFeedback
            >
                <Dragger name="recordsFiles"
                         accept={ typeSelected === 3 ? 'video/*' : typeSelected === 4 ? 'image/*' : typeSelected === 5 ? 'audio/*' : 'file_extension|.doc, .docx, .xls,.xlsx,.ppt,.pptx,.txt,.zip,.rar,' +
                             'application/pdf' }
                         beforeUpload={ file => handleFiles(form, 'files', file, typeSelected, src => filesResource(src, file)) }
                         onChange={ ({ file, fileList }) => {
                             const filesRecords = fileList.find(f => f.uid === file.uid) ?? null;
                             form.setFieldsValue({ filesRecords });
                         } }
                         showUploadList={ false }
                         maxCount={ 5 }
                >
                    <div className="img-data-cont">
                        <div>
                            <p className="ant-upload-drag-icon">
                                <UploadOutlined />
                            </p>
                            <p className="ant-upload-text">
                                Arrastra o
                                selecciona { typeSelected === 3 ? 'videos' : typeSelected === 4 ? 'imagenes' : typeSelected === 5 ? 'audios' : 'documentos' }
                            </p>
                        </div>
                    </div>
                </Dragger>
            </Item>
            { fileData !== null && (
                fileData.map((file) => (
                    file.src !== null ?
                        <Row key={ file.uuid } className={ file.active ? 'active-edit-file' : '' }>
                            { file.active ?
                                <Fragment>
                                    <Col span={ 23 }>
                                        <h2 className="title-sub-1">Renombrar archivo</h2>
                                    </Col>
                                    <Col span={ 23 } className="rename-file">
                                        <Input onChange={ changeNameTemporal }
                                               className="input-resource"
                                               placeholder="Renombrar archivo"
                                               defaultValue={ file.name_file }
                                        />
                                    </Col>
                                </Fragment>
                                : null }
                            <Col xs={ 20 } sm={ 23 } md={ 23 } lg={ 23 } xl={ 23 } onClick={ () => {
                                handleActiveFile(file.uuid);
                            } }>
                                <div className="list-item-file">
                                    <img src={ ImageFileType } className="img-type-file" alt="" /> { file.name_file }
                                </div>
                            </Col>
                            <Col xs={ 1 } sm={ 1 } md={ 1 } lg={ 1 } xl={ 1 }>
                                <div className="btn-action-file-remove">
                                    <Button onClick={ () => {
                                        removeFileList(file.uuid);
                                    } } type="text" icon={ <DeleteTwoTone /> } />
                                </div>
                            </Col>
                            { file.active ?
                                <Col span={ 23 }>
                                    <Button onClick={ () => {
                                        handleEditFile(file.uuid);
                                    } }
                                            className="btn-primary-resources" shape="round" block>Guardar</Button>
                                </Col>
                                : null
                            }
                        </Row>
                        : null
                ))
            )
            }
        </Fragment>
    );
};
export default RecordsResource;
