import keycloak from '../keycloak.config';

export const redirectByRole = (url: string) => {
    if (keycloak.hasResourceRole('ADMIN')) {
        return `/admin/${ url }`;
    } else if (keycloak.hasResourceRole('TEACHER')) {
        return `/teacher/${ url }`;
    } else if (keycloak.hasResourceRole('LXD')) {
        return `/lxd/${ url }`;
    } else if (keycloak.hasResourceRole('PRINCIPAL')) {
        return `/director/${ url }`;
    } else {
        return '';
    }
};
