import RestService                                  from '../abstract/RestService';
import { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import IGrade                                       from '../../interfaces/entities/IGrade';

class GradeService extends RestService<IGrade> {
    constructor(axios: AxiosInstance) {
        super('grade', axios);
    }

    async all(page?: number): Promise<AxiosResponse<IGrade[]> | AxiosError> {
        try {
            const params = page !== undefined ? `?page=${ page }` : '';
            return await this.http.get(this.uri + params);
        } catch ( e ) {
            return e as AxiosError;
        }
    }

    async allFromSABI(page?: number): Promise<AxiosResponse<IGrade[]> | AxiosError> {
        try {
            const params = page !== undefined ? `?page=${ page }` : '';
            return await this.http.get(`sabi/${ this.uri }${ params }`);
        } catch ( e ) {
            return e as AxiosError;
        }
    }

    async teacherGrade(): Promise<AxiosResponse<IGrade[]> | AxiosError> {
        try {
            return await this.http.get(`teacher/${ this.uri }`);
        } catch ( e ) {
            return e as AxiosError;
        }
    }

    async store(params: object): Promise<AxiosResponse | AxiosError> {
        try {
            return await this.http.post(`${ this.uri }`, params);
        } catch ( e ) {
            return e as AxiosError;
        }
    }

    async get(id: string): Promise<AxiosResponse<IGrade> | AxiosError> {
        try {
            return await this.http.get(`${ this.uri }/${ id }`);
        } catch ( e ) {
            return e as AxiosError;
        }
    }

    async delete(id: string): Promise<AxiosResponse | AxiosError> {
        try {
            return await this.http.delete(`${ this.uri }/${ id }`);
        } catch ( e ) {
            return e as AxiosError;
        }
    }

    async getBySubject(id: number): Promise<AxiosResponse<IGrade[]> | AxiosError> {
        try {
            return await this.http.get(`${ this.uri }/get_by/subject/${ id }`);
        } catch (e) {
            return e as AxiosError;
        }
    }

    async getByTeacher(id: string): Promise<AxiosResponse<IGrade[]> | AxiosError> {
        try {
            return await this.http.get(`${ this.uri }/get_by/teacher/${ id }`);
        } catch (e) {
            return e as AxiosError;
        }
    }

}

export default GradeService;