import { RouteProps }    from 'react-router-dom';
import React             from 'react';
import LayoutRoute       from '../LayoutRoute';
import AdminLayout       from '../../../views/layouts/Admin';
import LayoutResource      from '../../../views/layouts/Resource';
import HomeAuth            from '../../../views/layouts/HomeAuth';

interface Props extends RouteProps {
    layoutActive: string
}

const AdminRoute: React.FC<Props> = ({ ...rest }) => {
    return (
        <LayoutRoute
            { ...rest }
            Layout={ rest.layoutActive === 'maintenance' ? AdminLayout : rest.layoutActive === 'resource' ? LayoutResource: HomeAuth }
            keyCloakValidation={ (keycloak) => keycloak.hasResourceRole('ADMIN') }
        >
        </LayoutRoute>
    );
};
export default AdminRoute;