import FormRules      from '../../../../types/utils/forms/FormRules';
import { IFormRules } from './types';

export const storeRules: FormRules<IFormRules> = {
    resource_type_id: [
        {
            required: true,
            message: 'Seleccione el tipo de recurso'
        },
    ],
    name: [
        {
            required: true,
            message: 'El nombre del recurso es requerido'
        },
        {
            whitespace: true,
            message: 'No se permiten solo espacios en blanco, por favor ingrese un nombre valido'
        },
        {
            max: 255,
            message: 'El limite de caracteres es de 255'
        },
        {
            type: 'string',
            message: 'El campo es tipo texto'
        }
    ],
    description: [
        {
            required: true,
            message: 'La descripción del recurso es requerido'
        },
        {
            whitespace: true,
            message: 'No se permiten solo espacios en blanco, por favor ingrese una descripción valida'
        },
        {
            max: 255,
            message: 'El limite de caracteres es de 255'
        },
        {
            type: 'string',
            message: 'El campo es tipo texto'
        }
    ],
    labels: [
        {
            required: true,
            message: 'Seleccione o ingrese una palabra clave'
        },
        {
            whitespace: true,
            message: 'No se permiten solo espacios en blanco, por favor ingrese al menos una palabra clave'
        },
        {
            type: 'array',
            message: 'El campo debe de ser un arreglo'
        }
    ],
    grade_id: [
        {
            required: true,
            message: 'Seleccione un grado'
        },
    ],
    subject_id: [
        {
            required: true,
            message: 'Seleccione una materia'
        },
    ],
    unit_id: [
        {
            required: true,
            message: 'Seleccione una unidad'
        },
    ],
    topic: [
        {
            type: 'string',
            message: 'El campo es tipo texto'
        }
    ],
    indicators: [
        {
            required: true,
            message: 'Seleccione un indicador o elimine la fila'
        }
    ],
};
