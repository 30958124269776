import FormRules      from '../../../types/utils/forms/FormRules';
import { FormValues } from './types';

const rules: FormRules<FormValues> = {
    title: [{ required: true, message: 'El titulo es requerido' }],
    description: [{ required: true, message: 'La descripción es requerido' }],
    academic_subject_id: [],
    accessibility: [{ required: true, message: 'La accesibilidad es requerido' }],
    grade_id: [],
    indicator_id: [{ type: 'array', min: 1, message:'El indicador es requerido' }],
    labels: [],
    unit_id: [],
    resources: []
};

export default rules;
