import { IKeycloakUserInfo } from '../../interfaces/store/states/IAuthState';
import * as types            from '../actions/constants/auth';
import ISchool               from '../../interfaces/entities/ISchool';
import { ThunkAction }       from 'redux-thunk';
import { IAppState }         from '../../interfaces/store/IAppState';
import { IAuthActions }      from '../../interfaces/store/actions/IAuthActions';

interface IAuthAction<R, E = null> extends ThunkAction<R, IAppState, E, IAuthActions> {
}

export const setKeycloakUserInfo = (user: IKeycloakUserInfo): IAuthActions => ({
    type: types.SET_KEYCLOAK_USER_INFO,
    value: user
});

export const setSchools = (schools: ISchool[]): IAuthAction<void> => dispatch => {
    dispatch(setSelectedSchoolId(schools[0].id ?? null));

    return {
        type: types.SET_SCHOOLS,
        value: schools
    };
};

export const setSelectedSchoolId = (school_id: string): IAuthActions => ({
    type: types.SET_SELECTED_SCHOOL_ID,
    value: school_id
});