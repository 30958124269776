import { UploadFile } from 'antd/lib/upload/interface';

export const imgRegex = /[/.](gif|jpg|jpeg|png)$/i;
export const videoRegex = /[/.](mp4|flv|mov|wmv)$/i;
export const documentRegex = /[/.](doc|docx|xls|xlsx|ppt|pptx|txt|zip|rar|pdf)$/i;
export const audioRegex = /[/.](mp3|wav|mpeg3|mpeg|acc|wave|)$/i;

type getBase64FromImage_Type = (file: File) => Promise<string | ArrayBuffer | null>;

export const getBase64FromImage: getBase64FromImage_Type = file => (
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    })
);

type isImageType = (file: File | UploadFile) => boolean;
type isVideType = (file: File | UploadFile) => boolean;
type isAudioType = (file: File | UploadFile) => boolean;
type isDocumentType = (file: File | UploadFile) => boolean;

export const isImage: isImageType = file => imgRegex.test(file.type ?? '');
export const isVideo: isVideType = file => videoRegex.test(file.type ?? '');
export const isAudio: isAudioType = file => audioRegex.test(file.type ?? '');
export const isDocument: isDocumentType = file => documentRegex.test(file.type ?? '');

