import React, { Fragment, useState } from 'react';
import { Button, Col, Row }          from 'antd';
import IResource
                                     from '../../../../interfaces/entities/IResource';
import ModalDeleteResource           from './modals/delete';
import { useKeycloak }               from '@react-keycloak/web';
import ModalDenyResource             from './modals/deny';
import ModalApproveResource          from './modals/approve';
import ModalPublishResource          from './modals/publish';
import { useHistory }                from 'react-router-dom';
import { IKeycloakUserInfo }         from '../../../../interfaces/store/states/IAuthState';
import { useSelector }               from 'react-redux';
import { IAppState }                 from '../../../../interfaces/store/IAppState';
import { redirectByRole }            from '../../../../utils/redirectByRole';
import BreadCrumbResources           from '../../components/BreadCrumbResources';

type Props = {
    resource: IResource
};

const ResourceReview: React.FC<Props> = ({ resource }) => {
    const { keycloak } = useKeycloak();
    const history = useHistory();

    const userKeyCloak = useSelector<IAppState, IKeycloakUserInfo | null>(({ auth }) => auth.keycloakUserInfo);

    const [visibleDelete, setVisibleDelete] = useState<boolean>(false);
    const [visibleDeny, setVisibleDeny] = useState<boolean>(false);
    const [visibleApprove, setVisibleApprove] = useState<boolean>(false);
    const [visiblePublish, setVisiblePublish] = useState<boolean>(false);

    const redirectToEdit = () => {
        if (resource !== undefined) {
            const urlRole = redirectByRole(`update/resource/${ resource.id }`);
            history.push(`${ urlRole }`);
        }
    };

    return (
        <Fragment>
            { resource !== undefined && (
                <Fragment>
                    <BreadCrumbResources resource name={ resource.name } />
                    { resource.resource_state_id === 1
                        ?
                        <>
                            {
                                userKeyCloak?.sub === resource.user?.id
                                    ?
                                    <Row gutter={ [24, 24] }>
                                        <Col><Button className="btn-review-round" onClick={ () => {
                                            setVisibleDelete(true);
                                        } } danger>Eliminar</Button></Col>
                                        <Col>
                                            <Button
                                                className="btn-review-round btn-review-approved"
                                                onClick={ redirectToEdit }
                                            >Editar
                                            </Button>
                                        </Col>
                                    </Row>
                                    :
                                    <>
                                        { (keycloak.hasResourceRole('ADMIN') || keycloak.hasResourceRole('LXD')) && (
                                            <Row gutter={ [24, 24] }>
                                                <Col><Button className="btn-review-round" danger onClick={ () => {
                                                    setVisibleDelete(true);
                                                } }>Eliminar</Button></Col>
                                            </Row>
                                        ) }
                                    </>
                            }
                        </>
                        : resource.resource_state_id === 2
                            ? <>
                                { keycloak.hasResourceRole('TEACHER') || keycloak.hasResourceRole('LXD')
                                    ? <>
                                        { userKeyCloak?.sub === resource.user?.id ?
                                            <Row gutter={ [24, 24] }>
                                                <Col><Button className="btn-review-round" onClick={ () => {
                                                    setVisibleDelete(true);
                                                } } danger>Eliminar</Button></Col>
                                                <Col><Button onClick={ () => {
                                                    setVisiblePublish(true);
                                                } }
                                                             className="btn-review-round btn-review-approved">Publicar</Button></Col>
                                            </Row>
                                            : null
                                        }
                                    </>
                                    : null
                                }
                            </>
                            : resource.resource_state_id === 3
                                ? <>
                                    { keycloak.hasResourceRole('ADMIN') || keycloak.hasResourceRole('LXD')
                                        ? <Row gutter={ [24, 24] }>
                                            <Col><Button className="btn-review-round" onClick={ () => {
                                                setVisibleDeny(true);
                                            } } danger>Denegar</Button></Col>
                                            <Col>
                                                <Button onClick={ () => {
                                                    setVisibleApprove(true);
                                                } } className="btn-review-round btn-review-approved">
                                                    Aprobar
                                                </Button>
                                            </Col>
                                        </Row>
                                        : null
                                    }
                                </>
                                : resource.resource_state_id === 4
                                    ? <>
                                        { keycloak.hasResourceRole('TEACHER')
                                            ?
                                            <>
                                                { userKeyCloak?.sub === resource.user?.id ?
                                                    <Row gutter={ [24, 24] }>
                                                        <Col><Button className="btn-review-round" onClick={ () => {
                                                            setVisibleDeny(true);
                                                        } } danger>
                                                            Razón de denegado</Button></Col>
                                                        <Col>
                                                            <Button
                                                                className="btn-review-round btn-review-approved"
                                                                onClick={ redirectToEdit }
                                                            >Editar
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                                    : null }
                                            </>
                                            : null
                                        }
                                    </>
                                    : null
                    }
                    <ModalDeleteResource resource={ resource } visibleDelete={ visibleDelete }
                                         setVisibleDelete={ setVisibleDelete } />
                    <ModalDenyResource resource={ resource } visibleDeny={ visibleDeny }
                                       setVisibleDeny={ setVisibleDeny } />
                    <ModalApproveResource resource={ resource } visibleApprove={ visibleApprove }
                                          setVisibleApprove={ setVisibleApprove } />
                    <ModalPublishResource resource={ resource } visiblePublish={ visiblePublish }
                                          setVisiblePublish={ setVisiblePublish } />
                </Fragment>
            ) }
        </Fragment>
    );
};

export default ResourceReview;
