import React, { Dispatch, Fragment, SetStateAction, useState } from 'react';
import IUnit                                                   from '../../../../../interfaces/entities/IUnit';
import { Button, Modal }                                       from 'antd';
import { EditOutlined }                                        from '@ant-design/icons';
import ModalFormEdit                                           from './ModalEditForm';

type Props = {
    unit: IUnit
    planning_id: string
    setUnits: Dispatch<SetStateAction<IUnit[]>>
}

const ModalEditUnitPlanning: React.FC<Props> = ({ unit, planning_id, setUnits }) => {

        const [showModalEditUnit, setShowModalEditUnit] = useState<boolean>(false);
        const toggleEditUnit = () => {
            setShowModalEditUnit(true);
        };

        const onModalEditUnitMdlClose = () => {
            Modal.destroyAll();
            setShowModalEditUnit(false);
        };
        return (
            <Fragment>
                <Button title="Editar" className={ 'btn-table-action-info' }
                        shape={ 'circle' }
                        icon={ <EditOutlined /> }
                        onClick={ toggleEditUnit }
                />
                { showModalEditUnit && unit !== undefined && planning_id.length > 0 && (
                    <ModalFormEdit unit={ unit } planning_id={ planning_id } showModalEditUnit={ showModalEditUnit }
                                   onModalEditUnitMdlClose={ onModalEditUnitMdlClose } setUnits={ setUnits } />
                ) }
            </Fragment>
        );
    }
;
export default ModalEditUnitPlanning;
