import { routeType }     from '../../types/routes';
import NewResource       from '../../views/resource/resource/create';
import Resource          from '../../views/resource/show/resource';
import NewDidacticMap    from '../../views/resource/didacticMap';
import StudentsGuide     from "../../views/resource/show/ActivityGuide";
import React             from "react";
import ActivityGuidesHome   from '../../views/activity_guides/Home';

const routes: routeType[] = [
    {
        path: 'resource/:id',
        component: Resource
    },
    {
        path: 'new/resource',
        component: NewResource
    },
    {
        path: 'new/didactic_map',
        component: NewDidacticMap
    },
    {
        path: 'activity_guide/s/:activity_guide_code',
        component: () => <StudentsGuide publicGuide={ false }/>
    },
    {
        path:'list/activity_guides',
        component: ActivityGuidesHome
    }
];

export default routes;
