import React          from 'react';
import { Fragment }   from 'react';
import LayoutRoute    from '../LayoutRoute';
import ErrorsLayout   from '../../../views/layouts/Error';
import { RouteProps } from 'react-router-dom';

interface Props extends RouteProps {
}

const ErrorRoute: React.FC<Props> = ({ ...rest }) => {
    return (
        <Fragment>
            <LayoutRoute
                { ...rest }
                Layout={ ErrorsLayout }
            />
        </Fragment>
    );
};
export default ErrorRoute;