import ISchool from '../../../interfaces/entities/ISchool';
import { OptionData }                    from 'rc-select/lib/interface';
import { FormEntitiesEnum, LoadingType } from './type';

export const handleSchoolsOptionsFormat: (subjects: ISchool[]) => OptionData[] = (schools) => {
    return schools.map(s => ({
        label: `${s.name}`,
        value: s.id,
        key: s.id
    }));
};

export const initialLoadingValues = Object.keys(FormEntitiesEnum).reduce((a, b) => Object.assign(a, { [b]: false }), {}) as LoadingType;