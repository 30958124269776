import React, { Dispatch, SetStateAction, useState } from 'react';
import IAcademicCharge                               from '../../../../../interfaces/entities/IAcademicCharge';
import IGrade                                        from '../../../../../interfaces/entities/IGrade';
import ISubject                                      from '../../../../../interfaces/entities/ISubject';
import { IStoreAcademicCharge, LoadingType }        from '../type';
import { Form, message, Modal, notification } from 'antd';
import AcademicChargeService                        from '../../../../../api/services/AcademicChargeService';
import { isAxiosError }                              from '../../../../../utils/services';
import { AxiosError }                                from 'axios';
import CreateAcademicCharge
                                                     from './Create';
import {
    handleGradesOptionsFormat,
    handleSubjectsOptionsFormat
}                                                    from '../helpers';
import ListAcademicCharge                            from './List';
import useServiceByRef                               from '../../../../../utils/hooks/useServiceByRef';

type Props = {
    showAcademicChargeMdl: boolean;
    onAcademicChargeMdlClose: () => void;
    getAcademicCharge: () => Promise<void>;
    teacher_id: string;
    academicCharge: IAcademicCharge[];
    grades: IGrade[];
    subjects: ISubject[];
    loading: LoadingType;
    newCharge: IStoreAcademicCharge[];
    setNewCharge: Dispatch<SetStateAction<IStoreAcademicCharge[]>>;
    ifUserESchool: boolean;
    loadingSyncCharge: boolean;
};

const ModalAcademicCharge: React.FC<Props> = ({
                                                  showAcademicChargeMdl,
                                                  onAcademicChargeMdlClose,
                                                  getAcademicCharge,
                                                  teacher_id,
                                                  academicCharge,
                                                  grades,
                                                  subjects,
                                                  loading,
                                                  newCharge,
                                                  setNewCharge,
                                                  ifUserESchool,
                                                  loadingSyncCharge
                                              }) => {

    const [form] = Form.useForm();
    const [removeTag, setRemoveTag] = useState<boolean>(false);
    const academicChargeService = useServiceByRef(AcademicChargeService);

    //STATE CREATE TEMPORARY ACADEMIC SUBJECT
    const [gradeSelected, setGradeSelected] = useState<number>(0);
    const [subjectSelected, setSubjectSelected] = useState<number>(0);

    // remove tag academic charge
    const removeAcademicCharge = async (id: number) => {
        if (academicChargeService.current && Number.isInteger(id) && id !== undefined) {
            try {
                setRemoveTag(true);
                const response = await academicChargeService.current.delete(id);

                if (isAxiosError(response)) {
                    const err = response as AxiosError;
                    message.error(`Ocurrio un error al obtener los docentes: ${ err.message }`);
                } else {
                    getAcademicCharge().finally();
                    notification.success({
                        duration: 2,
                        message: 'Operación finalizada',
                        description: `La carga academica ha sido removida`
                    });
                }
                setRemoveTag(false);
            } catch (e) {
                notification.error({
                    message: 'Ocurrio un error al obtener los docentes',
                    duration: 2,
                    description: e,
                });
            }
        }
    };

    const assignGrade = (id: number) => {
        setGradeSelected(id);
    };

    const assignSubject = (id: number) => {
        setSubjectSelected(id);
    };

    const temporaryStore = () => {
        if (teacher_id !== null && gradeSelected !== undefined && subjectSelected !== undefined) {
            const data = {
                teacher_id: teacher_id,
                grade_id: gradeSelected,
                subject_id: subjectSelected
            };
            setNewCharge([...newCharge, data]);
            form.resetFields();
        } else {
            notification.error({
                duration: 2,
                message: 'Ocurrio un error',
                description: `favor de seleccionar grado y materia' }`
            });
        }
    };

    const removeTemporalStore = (id: any) => {
        setRemoveTag(true);
        const searhKeys = newCharge.filter((key) => newCharge.indexOf(key) !== id);
        setNewCharge(searhKeys);
        setRemoveTag(false);
    };

    const getNameGrade = (id_grade: number) => {
        const grade = grades.find(grade => grade.id === id_grade);
        if (grade !== undefined) return grade.name;
    };

    const getNameSubject = (id_subject: number) => {
        const subject = subjects.find(subject => subject.id === id_subject);
        if (subject !== undefined) return subject.name;
    };

    const storeAcademicCharge = async () => {
        if (newCharge !== undefined && academicChargeService.current) {
            try {
                const response = await academicChargeService.current.store(newCharge);

                if (isAxiosError(response)) {
                    const err = response as AxiosError;
                    message.error(`Ocurrio un error al guardar la carga académica: ${ err.message }`);
                } else {
                    getAcademicCharge().finally();
                    setNewCharge([]);
                    notification.success({
                        duration: 2,
                        message: 'Operación finalizada',
                        description: `La(s) carga(s) academica(s) ha sido creada(s)`
                    });
                }
            } catch (e) {
                notification.error({
                    message: 'Ocurrio un error al guardar la carga académica',
                    duration: 2,
                    description: e,
                });
            }
        } else {
            onAcademicChargeMdlClose();
        }
    };

    return (
        <Modal
            visible={ showAcademicChargeMdl }
            title={ <h3 className="title-sub-1">{ 'Carga académica' }</h3> }
            okText={ 'Guardar ' }
            className={ 'btn-modal-maintenance' }
            cancelButtonProps={ { style: { display: 'none' } } }
            onCancel={ onAcademicChargeMdlClose }
            onOk={ (e) => {
                newCharge !== undefined && newCharge.length > 0
                    ? storeAcademicCharge()
                    : e.preventDefault();
            } }
            okButtonProps={ { style: { display: ifUserESchool ? 'none' : '' } } }
            forceRender
        >
            <>
                { !ifUserESchool && (
                    <CreateAcademicCharge
                        form={ form }
                        grades={ grades }
                        loading={ loading }
                        assignGrade={ assignGrade }
                        subjects={ subjects }
                        handleSubjectsOptionsFormat={ handleSubjectsOptionsFormat }
                        handleGradesOptionsFormat={ handleGradesOptionsFormat }
                        assignSubject={ assignSubject }
                        temporaryStore={ temporaryStore }
                        loadingSyncCharge={ loadingSyncCharge }
                    />
                ) }
            </>

            <ListAcademicCharge
                removeTag={ removeTag }
                academicCharge={ academicCharge }
                removeAcademicCharge={ removeAcademicCharge }
                newCharge={ newCharge }
                removeTemporalStore={ removeTemporalStore }
                getNameSubject={ getNameSubject }
                getNameGrade={ getNameGrade }
                loadingSyncCharge={ loadingSyncCharge }
                ifUserESchool={ifUserESchool}
            />
        </Modal>
    );
};

export default ModalAcademicCharge;