import { FormEntitiesEnum, IFormFields, InsertResponse, LoadingFormType } from './types';
import DidacticMapService
                                                                          from '../../../api/services/DidacticMapService';
import { AxiosError, AxiosResponse }                                      from 'axios';
import { isAxiosError }                                                   from '../../../utils/services';
import IDidacticMap
                                                                          from '../../../interfaces/entities/IDidacticMap';

export const initialLoadingValues = Object.keys(FormEntitiesEnum).reduce((a, b) => Object.assign(a, { [b]: false }), {}) as LoadingFormType;
export const initialDisabledValues = Object.keys(FormEntitiesEnum).reduce((a, b) => Object.assign(a, { [b]: true }), {}) as LoadingFormType;

type handleFinishType = (
    values: IFormFields,
    onSuccess: (data: InsertResponse) => void,
    onError: (err: AxiosError) => any,
    setLoading: (loading: boolean) => void,
    service: DidacticMapService
) => void;

type handleFinishUpdateType = (
    didactic_map_id: string,
    values: IFormFields,
    onSuccess: (data: InsertResponse) => void,
    onError: (err: AxiosError) => any,
    setLoading: (loading: boolean) => void,
    service: DidacticMapService
) => void;

type getDidacticMapType = (
    didactic_map_id: string,
    callback: (res: IDidacticMap) => void,
    onError: (err: AxiosError) => any,
    setLoading: (loading: boolean) => void,
    service: DidacticMapService
) => void;

export const sunEditorButtonList = [['undo', 'redo'],
    ['bold', 'underline', 'italic', 'strike', 'list'],
    ['table', 'link', 'image'],
    ['fullScreen', 'codeView']];

export const handleFinishStore: handleFinishType = async (values, onSuccess, onError, setLoading, service) => {
    setLoading(true);

    const response = await service.store(values);

    if (!isAxiosError(response)) {
        const { data } = response as AxiosResponse<InsertResponse>;
        onSuccess(data);
    } else {
        const error = response as AxiosError;
        onError(error);
    }

    setLoading(false);
};

export const handleFinisUpdate: handleFinishUpdateType = async (didactic_map_id, values, onSuccess, onError, setLoading, service) => {
    setLoading(true);

    const response = await service.update(values, didactic_map_id);

    if (!isAxiosError(response)) {
        const { data } = response as AxiosResponse<InsertResponse>;
        onSuccess(data);
    } else {
        const error = response as AxiosError;
        onError(error);
    }

    setLoading(false);
};

export const getDidacticMap: getDidacticMapType = async (didactic_map_id, callback, onError, setLoading, service) => {
    setLoading(true);
    const res = await service.get(didactic_map_id);

    if (isAxiosError(res)) {
        onError(res as AxiosError);
    } else {
        const { data } = res as AxiosResponse<IDidacticMap>;
        callback(data);
    }

    setLoading(false);
};


export const resourceStates = [
    {
        id: 1,
        code: 'PUBLIC',
        name: 'Público',
        description: 'Public'
    },
    {
        id: 0,
        code: 'PRIVADO',
        name: 'Privado',
        description: 'Private'
    }
];
