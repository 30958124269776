import { actionInfoType } from './types';

const actionInfo: actionInfoType = {
    details: {
        content: 'Detalles'
    },
    delete: {
        content: 'Eliminar',
        danger: true
    },
    update: {
        content: 'Modificar'
    }
};

export default actionInfo;