import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { useKeycloak }                                       from '@react-keycloak/web';
import { Form, Modal, Select, Space, Spin, Switch }          from 'antd';
import './index.less';
import IUserSchool                                           from '../../../../interfaces/entities/IUserSchool';
import useService                                            from '../../../../utils/hooks/useService';
import AuthService                                           from '../../../../api/services/AuthService';
import { isAxiosError }                                      from '../../../../utils/services';
import { AxiosError, AxiosResponse }                         from 'axios';
import UsersSchoolsService                    from '../../../../api/services/UsersSchoolsService';
import EntityRestTable                        from '../../../../components/EntityRestTable';
import { ClockCircleOutlined, CloseOutlined } from '@ant-design/icons';
import RejectedUsersTable                     from './RejectedUsersTable';
import ISchool                                from '../../../../interfaces/entities/ISchool';
import SchoolService                          from '../../../../api/services/SchoolService';
import columns                                from './columns';
import tableActions                           from './tableActions';
import SearchBySchool                         from './SearchBySchool';
import { filterOptionsFromChildren }          from '../../../../utils/forms/filterOptions';
import BreadCrumbResources                    from '../../../resource/components/BreadCrumbResources';
import { useSelector }                        from 'react-redux';
import { IAppState }                          from '../../../../interfaces/store/IAppState';

const ApproveUsersHome = () => {
    const { initialized, keycloak } = useKeycloak();
    const authService = useService(AuthService);
    const schoolService = useService(SchoolService);
    const [modal, contextModal] = Modal.useModal();
    const [showRejected, setShowRejected] = useState(false);
    const [schools, setSchools] = useState<ISchool[]>([]);
    const [schoolsLoading, setSchoolsLoading] = useState(false);
    const [schoolError, setSchoolError] = useState<string | null>(null);
    const [selectedSchools, setSelectedSchools] = useState<string[]>([]);
    const [approveLoading, setApproveLoading] = useState(false);

    const readyForRequests = useSelector<IAppState, boolean>(({ axios }) => axios.readyForRequests);

    const getSchools = useCallback(async (service: SchoolService) => {
        setSchoolsLoading(true);
        const res = await service.all();

        if (isAxiosError(res)) {
            setSchoolError((res as AxiosError).message);
        } else {
            const { data } = res as AxiosResponse<ISchool[]>;
            setSchools(data);
        }

        setSchoolsLoading(false);
    }, []);

    useEffect(() => {
        if (schoolService != null && readyForRequests) {
            // noinspection JSIgnoredPromiseFromCall
            getSchools(schoolService);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [schoolService]);

    return (
        <Spin spinning={ approveLoading }>
            <BreadCrumbResources name="Aprobación de cuentas" />
            <div id="approve-users-component">
                { contextModal }

                <h1>Aprobación de cuentas</h1>

                <div className="school-selector-container">
                    <span>Selecciona un colegio</span>

                    <Form.Item
                        hasFeedback={ schoolError !== null }
                        validateStatus={ schoolError !== null ? 'error' : undefined }
                        help={ schoolError }
                    >
                        <Select
                            showSearch
                            allowClear
                            mode="multiple"
                            className={ 'select-schools' }
                            placeholder="Buscar un colegio"
                            filterOption={ filterOptionsFromChildren }
                            loading={ schoolsLoading }
                            disabled={ schoolError !== null }
                            onChange={ (v: string[]) => setSelectedSchools(v) }
                        >
                            { schools.map(({ id, name, infrastructure_code }, i) => (
                                <Select.Option key={ i } value={ id } title={ `${ infrastructure_code } ${ name }` }>
                                    <b>[{ infrastructure_code }]</b> { name }
                                </Select.Option>
                            )) }
                        </Select>
                    </Form.Item>
                </div>

                <Space>
                    <Switch
                        defaultChecked
                        checkedChildren={ <ClockCircleOutlined title="Peticiones activas" /> }
                        unCheckedChildren={ <CloseOutlined title="Peticiones rechazadas" /> }
                        onChange={ c => setShowRejected(!c) }

                    />

                    <h3>Docentes con peticiones { showRejected ? 'rechazadas' : 'activas' }</h3>
                </Space>

                { (initialized && keycloak.authenticated) && (
                    <Fragment>
                        { showRejected && (
                            <RejectedUsersTable school_id={ selectedSchools } />
                        ) }

                        { selectedSchools.length === 0 && !showRejected && (
                            <EntityRestTable<IUserSchool>
                                columns={ columns }
                                entityIdentifier={ u => u.id }
                                serviceClass={ UsersSchoolsService }
                                actions={ tableActions(authService, modal, setApproveLoading) }
                                paginate
                            />
                        ) }

                        { selectedSchools.length > 0 && !showRejected && (
                            <SearchBySchool setApproveLoading={ setApproveLoading } school_id={ selectedSchools } />
                        ) }
                    </Fragment>
                ) }
            </div>
        </Spin>
    );
};

export default ApproveUsersHome;
