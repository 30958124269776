import RestService                                  from '../abstract/RestService';
import { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import IPaginatedData                               from '../../interfaces/IPaginatedData';
import IUserSchool                                  from '../../interfaces/entities/IUserSchool';

class UsersSchoolsService extends RestService<IUserSchool> {
    constructor(axios: AxiosInstance) {
        super('users_schools', axios);
    }

    async all(page: number | undefined): Promise<AxiosResponse<IUserSchool[] | IPaginatedData<IUserSchool>> | AxiosError> {
        try {
            const params = page !== undefined ? `?page=${ page }` : '';
            return await this.http.get(`${ this.uri }${ params }`);
        } catch (e) {
            return e as AxiosError;
        }
    }

    async search(page: number | undefined, data: object): Promise<AxiosResponse<IUserSchool[] | IPaginatedData<IUserSchool>> | AxiosError> {
        try {
            const params = page !== undefined ? `?page=${ page }` : '';
            return await this.http.post(`${ this.uri }/search${ params }`, data);
        } catch (e) {
            return e as AxiosError;
        }
    }

    async getRejectedUsers(page: number | undefined): Promise<AxiosResponse<IUserSchool[] | IPaginatedData<IUserSchool>> | AxiosError> {
        try {
            const params = page !== undefined ? `?page=${ page }` : '';
            return await this.http.get(`${ this.uri }/rejected${ params }`);
        } catch (e) {
            return e as AxiosError;
        }
    }

    async getUserProfile(): Promise<AxiosResponse<IUserSchool> | AxiosError> {
        try {
            return await this.http.get(`${this.uri}/profile`);
        }catch (e) {
            return e as AxiosError;
        }
    }
}

export default UsersSchoolsService;