import { ColumnsType } from 'antd/lib/table';
import IAnnualPlanning from '../../../interfaces/entities/IAnnualPlanning';

const columns: ColumnsType<IAnnualPlanning> = [
    {
        title: 'Grado',
        render: (_, p) => p.academic_charge?.academic_subject?.grade?.name
    },
    {
        title: 'Materia',
        render: (_, p) => p.academic_charge?.academic_subject?.subject?.name
    },
    {
        title: 'Año',
        render: (_, p) => p.academic_charge?.school_year?.year
    }
];

export default columns;