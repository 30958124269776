import React, { Fragment, useEffect, useState }             from 'react';
import { Button, Col, message, Row, Select, Spin }          from 'antd';
import { LoadingType, FormEntitiesEnum }                    from './type';
import { handleSchoolsOptionsFormat, initialLoadingValues } from './helpers';
import ISchool                                              from '../../../interfaces/entities/ISchool';
import SchoolService                                        from '../../../api/services/SchoolService';
import TeacherService                                       from '../../../api/services/TeacherService';
import { isAxiosError }                                     from '../../../utils/services';
import { AxiosError, AxiosResponse }                        from 'axios';
import CreateSchool                                         from './create';
import UserApprovalTable                                    from './approval-acounts';
import useServiceByRef                                      from '../../../utils/hooks/useServiceByRef';
import { useSelector }                                      from 'react-redux';
import { IAppState }                                        from '../../../interfaces/store/IAppState';

const Schools: React.FC = () => {

// STATE CONFIG
    const [loading, setLoading] = useState<LoadingType>(initialLoadingValues);
    const [create, setCreate] = useState(false);
    const readyForAuthRequests = useSelector<IAppState, boolean>(({ axios }) => axios.readyForRequests);

    const [refresh, setRefresh] = useState(false);
    const [refreshSchool, setRefreshSchool] = useState<boolean>(false);

    //STATE
    const [schools, setSchools] = useState<ISchool[]>([]);
    const [selectedSchool, setSelectedSchool] = useState<ISchool>();

    //SERVICES
    const schoolService = useServiceByRef(SchoolService);

    //useEFFECT
    useEffect(() => {
        if (readyForAuthRequests) {
            const getSchools = async () => {
                setLoading(validate => ({ ...validate, [FormEntitiesEnum.schools]: true }));

                if (schoolService.current) {
                    const response = await schoolService.current.all();

                    if (isAxiosError(response)) {
                        const err = response as AxiosError;
                        message.error(`Ocurrio un error al obtener los colegios: ${ err.message }`);
                    } else {
                        const { data } = response as AxiosResponse<ISchool[]>;
                        setSchools(data);
                        setRefresh(false);
                        setLoading(validate => ({ ...validate, [FormEntitiesEnum.schools]: false }));
                    }
                }
            };
            getSchools().finally();
        }
    }, [schoolService, refreshSchool, readyForAuthRequests]);

    //SEARCH TEACHER BY SCHOOL
    const searchTeachersBySchool = async (id: string) => {
        if (TeacherService !== null && id !== '' && id !== undefined && schools !== undefined) {
            const dataSchool = schools.find(school => school.id === id);
            setSelectedSchool(dataSchool);
            setCreate(false);
        } else {
            setSelectedSchool(undefined);
        }
    };

    // CREATE ACTIVE SCHOOL
    function handleCreateSchool() {
        setCreate(true);
        setSelectedSchool(undefined);
    }

    return (
        <Fragment>
            <Row>
                <Col span={ 24 }>
                    <h1 className={ 'title-maintenance' }>Colegios</h1>
                </Col>
            </Row>
            <Row gutter={ [24, 32] }>
                <Col xs={ 24 } sm={ 24 } md={ 12 } lg={ 12 } xl={ 12 }>
                    <Select
                        allowClear
                        showArrow
                        showSearch
                        style={ { width: '100%' } }
                        placeholder={ 'Selecciona un colegio' }
                        options={ handleSchoolsOptionsFormat(schools ?? []) }
                        loading={ loading.schools }
                        className={ 'select-schools' }
                        onChange={ searchTeachersBySchool }
                        dropdownMatchSelectWidth={ false }
                        getPopupContainer={ trigger => trigger.parentNode }
                        optionFilterProp="label"
                        disabled={ schools === undefined }
                    />
                </Col>
                <Col xs={ 24 } sm={ 24 } md={ 6 } lg={ 4 } xl={ 4 }>
                    <Button
                        type={ 'primary' }
                        className={ 'new-school' }
                        block
                        onClick={ handleCreateSchool }
                    >
                        Agregar nuevo
                    </Button>
                </Col>
            </Row>
            <div className={ 'content-new-school' }>
                <Spin spinning={ refresh }>
                    <CreateSchool
                        selectedSchool={ selectedSchool }
                        setSelectedSchool={ setSelectedSchool }
                        create={ create } setCreate={ setCreate }
                        setRefreshSchool={ setRefreshSchool }
                        setRefresh={ setRefresh }
                        refreshSchool={ refreshSchool }
                    />
                    <h2 className={ 'sub-title-maintenance' }>Aprobación de cuentas </h2>
                    { TeacherService !== null && (
                        <UserApprovalTable
                            selectedSchool={ selectedSchool }
                            searchTeachersBySchool={ searchTeachersBySchool }
                            create={ create }
                        />
                    ) }
                </Spin>
            </div>
        </Fragment>
    );
};

export default Schools;