import { IResourceFile } from '../components/records/types';

export interface IFormRules {
    resource_type_id: number,
    name: string,
    description: string,
    labels: string[],
    grade_id: string,
    subject_id: string,
    unit_id: string,
    topic: string,
    indicators: string[],
}

export enum FormEntitiesEnum {
    resource_types = 'resource_types',
    grade = 'grade',
    subject = 'subject',
    indicator = 'indicator',
    unit = 'unit',
    form = 'form'
}

export type LoadingFormType = {
    [k in FormEntitiesEnum]: boolean
}

export type ErrorsType = {
    [k in FormEntitiesEnum]: string | undefined
}

export type InsertResponse = {
    loading: boolean
};

export interface IFormFields {
    resource_type_id: number,
    name: string,
    description: string,
    labels: IFormLabels[],
    unit_id: string,
    topic: string,
    url: string,
    indicators: string[],
    files: IResourceFile[] | null,
    resource_state_id: number,
    school_id: string | null,
    records_external_link: IRecordsExternalLink[]
}

export interface IFormLabels {
    id: string,
    name: string
}

export interface IRecordsExternalLink {
    link_file: string
}
