import React      from 'react';
import { Layout } from 'antd';
import HeaderAuth from '../components/header';
import FooterAuth from '../components/footer';
import '../styles/style';

const { Header, Footer, Content } = Layout;

const ResourceLayout: React.FC = ({ children }) => {
    return (
        <Layout className="auth-layout container">
            <Header className={ 'auth-header' }>
                <HeaderAuth />
            </Header>
            <Content className="content-resource-view">
                { children }
            </Content>
            <Footer className={ 'esefoot' }> <FooterAuth /></Footer>
        </Layout>
    );
};

export default ResourceLayout;
