import RestService                                  from '../abstract/RestService';
import IIndicator                                   from '../../interfaces/entities/IIndicator';
import { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import IPaginatedData                               from '../../interfaces/IPaginatedData';
import { IIndicatorSABI }                           from '../../interfaces/entities/IUnitSabi';

class IndicatorService extends RestService<IIndicator> {
    constructor(axios: AxiosInstance) {
        super('indicator', axios);
    }

    async all(page: number | undefined): Promise<AxiosResponse<IIndicator[] | IPaginatedData<IIndicator>> | AxiosError> {
        try {
            const params = page !== undefined ? `?page=${ page }` : '';
            return await this.http.get(`${ this.uri }${ params }`);
        } catch (e) {
            return e as AxiosError;
        }
    }

    async allFromSABI(param: { unit_id: string }): Promise<AxiosResponse<IIndicator[] | IPaginatedData<IIndicator>> | AxiosError> {
        try {
            return await this.http.post(`sabi/${ this.uri }`, param);
        } catch (e) {
            return e as AxiosError;
        }
    }

    async getByUnit(id: string): Promise<AxiosResponse<IIndicator[]> | AxiosError> {
        try {
            return await this.http.get(`${ this.uri }/get_by/unit/${ id }`);
        } catch (e) {
            return e as AxiosError;
        }
    }

    async getByUnitFromSabi(param: { unit_id: string }): Promise<AxiosResponse<IIndicatorSABI[]> | AxiosError> {
        try {
            return await this.http.post(`sabi/${ this.uri }`, param);
        } catch (e) {
            return e as AxiosError;
        }
    }
}

export default IndicatorService;
