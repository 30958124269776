import * as React                                                                                     from 'react';
import { forwardRef, useEffect, useImperativeHandle, useState }                                       from 'react';
import { CategoryType }                                                                               from '../../../types/entities/categories';
import { planningCategories }                                                                         from '../../../types/entities/plannings';
import { Form, InputNumber, Table }                                                                   from 'antd';
import { Column }                                                                                     from 'rc-table';
import { FieldData }                                                                                  from '../../../types/utils/forms/DataField';
import { FormInstance }                                                                               from 'rc-field-form';
import { removeZerosFromDecimal }                                                                     from './functions';
import { LoadingType, monthsValuesType, PlanningTableFunctionsHandler, PlanningTableInteractionType } from './types';
import { PanelRender }                                                                                from 'rc-table/lib/interface';
import {
    months,
    MonthsTypes
}                                                                                                     from '../../../types/entities/months';
import './PlanningTable.less';

type Props = {
    loading: LoadingType
    data: CategoryType[]
    form: FormInstance
    tableTitle?: PanelRender<CategoryType>
    interaction?: PlanningTableInteractionType
};

const maxLecValues: monthsValuesType = {
    JAN: 31,
    FEB: 28,
    MAR: 31,
    APR: 30,
    MAY: 31,
    JUN: 30,
    JUL: 31,
    AUG: 31,
    SEP: 30,
    OCT: 31,
    NOV: 30
};

const initialMaxValue = months.reduce((a, b) => Object.assign(a, { [b]: maxLecValues[b] }), {});

const PlanningTable = forwardRef<PlanningTableFunctionsHandler, Props>((props, ref) => {
    const { form, loading, data, tableTitle, interaction = 'AUTO' } = props;

    const [extMax, setExtMax] = useState<{ [k in MonthsTypes]?: number }>(initialMaxValue);
    const [devMax, setDevMax] = useState<{ [k in MonthsTypes]?: number }>(initialMaxValue);

    useImperativeHandle(ref, () => ({ handleTableChange, calcCategoriesTotals, calcDependantValues }));

    const calcDependantValues = () => {
        const total_hours: number = form.getFieldValue('total_hours');

        const values = months.reduce((data, month) => {
            let lec = parseInt(form.getFieldValue(`assignments.LEC.${ month }.value`));
            let ext = parseInt(form.getFieldValue(`assignments.EXT.${ month }.value`));

            if (lec < ext) {
                ext = lec;
            }

            const dev = lec - ext;
            const res = dev / 5 * total_hours;

            return Object.assign(data, {
                [`assignments.EXT.${ month }.value`]: isNaN(ext) ? 0 : ext,
                [`assignments.DEV.${ month }.value`]: Number(removeZerosFromDecimal(dev)),
                [`assignments.RES.${ month }.value`]: Number(removeZerosFromDecimal(res)),
            });
        }, {});


        form.setFieldsValue(values);
    };

    const handleTableChange = (month: MonthsTypes, updateTotal = true) => {
        let lec = parseInt(form.getFieldValue(`assignments.LEC.${ month }.value`));
        let ext = parseInt(form.getFieldValue(`assignments.EXT.${ month }.value`));
        const lecInput = document.getElementById(`LEC_${ month }`) as HTMLInputElement | null;

        const total_hours: number = form.getFieldValue('total_hours');

        lec = isNaN(lec) ? 0 : lec;
        ext = isNaN(ext) ? 0 : ext;

        if (interaction === 'AUTO') {
            if (lecInput !== null && parseInt(lecInput.value) !== lec) {
                setExtMax(prevState => ({ ...prevState, ...{ [month]: lec } }));
            }

            if (lec < ext) {
                form.setFieldsValue({
                    [`assignments.EXT.${ month }.value`]: lec
                });
            }

            const dev = lec - ext;
            const res = dev / 5 * total_hours;

            form.setFieldsValue({
                [`assignments.DEV.${ month }.value`]: Number(removeZerosFromDecimal(dev)),
                [`assignments.RES.${ month }.value`]: Number(removeZerosFromDecimal(res)),
            });
        } else {
            setDevMax(prevState => ({ ...prevState, ...{ [month]: lec } }));
        }

        if (updateTotal) {
            calcCategoriesTotals();
        }
    };

    const calcCategoriesTotals = () => {
        planningCategories.forEach(cat => {
            const totalTd = document.querySelector(`.${ cat }.cat_total`);

            if (totalTd !== null) {
                const total = months.reduce((sum, month) => {
                    const value = form.getFieldValue(`assignments.${ cat }.${ month }.value`);
                    return sum + Number(value);
                }, 0);

                totalTd.innerHTML = removeZerosFromDecimal(total);
            }
        });
    };

    useEffect(() => {
        const extMaxValues = data.filter(catType => catType.category === 'LEC').reduce((maxCollector, catType) => {
            const valuesPerMonth = Object.entries(catType.data ?? {}).reduce(
                (sum, [month, value]) => ({ ...sum, ...{ [month]: value } }), {}
            );

            return Object.assign(maxCollector, valuesPerMonth);
        }, {} as monthsValuesType);

        setExtMax(extMaxValues);

        const fields = data.reduce(
            (fieldsSum, cat) => months.reduce(
                (fieldsSum2, mont) => {
                    fieldsSum2.push({
                        name: `assignments.${ cat.category }.${ mont }.value`,
                        value: cat.data !== undefined ? cat.data[mont] : 0
                    });

                    return fieldsSum2;
                }, fieldsSum
            ), [] as FieldData[]
        );

        form.setFields(fields);
    }, [data, form]);

    return (
        <Table
            loading={ loading.default_data }
            rowKey={ c => c.category }
            dataSource={ data }
            pagination={ false }
            title={ tableTitle }
            scroll={ { x: '100%' } }
            size={ 'small' }
        >
            <Column<CategoryType>
                key="categories"
                title="Mes"
                dataIndex="name"
                fixed={ 'left' }
            />

            { months.map(month => {
                if (data === undefined)
                    return null;

                return (
                    <Column<CategoryType>
                        key={ month }
                        title={ month }
                        dataIndex="months"
                        className="inputs-table"
                        render={ (_, cat) => (
                            <Form.Item
                                noStyle
                                key={ `${ cat.category }_${ month }` }
                                name={ `assignments.${ cat.category }.${ month }.value` }
                                initialValue={ 0 }
                            >
                                <InputNumber
                                    id={ `${ cat.category }_${ month }` }
                                    name={ `assignments.${ cat.category }.${ month }.value` }
                                    disabled={ interaction !== 'MANUAL' ? cat.disabled : false }
                                    step={ 1 }
                                    max={
                                        cat.category === 'EXT' && interaction === 'AUTO'
                                            ? extMax[month]
                                            : cat.category === 'DEV' ? devMax[month] : maxLecValues[month]
                                    }
                                    min={ 0 }
                                    onChange={ () => handleTableChange(month) }
                                    parser={ val => Number(removeZerosFromDecimal(Number(val))) }
                                />
                            </Form.Item>
                        ) }
                    />
                );
            }) }

            <Column<CategoryType>
                key="total"
                title="TOTAL"
                dataIndex="total"
                render={ (_, cat) => (
                    <span className={ `${ cat.category } cat_total` }>{ cat.total }</span>
                ) }
            />
        </Table>
    );
});

export default PlanningTable;