import React, { Fragment } from 'react';
import ImgDocument         from '../../../../views/images/document.svg';
import DocumentType        from '../../../../views/images/type-resource/file-text.svg';

const ActivityGuideResourceType: React.FC = () => {
    return (
        <Fragment>
            <img className="img-card"
                 src={ ImgDocument } alt="" />
            <div className="type_resource">
                <img src={ DocumentType }
                     alt="Type_document" />
            </div>
        </Fragment>
    );
};
export default ActivityGuideResourceType;