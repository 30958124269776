import React                     from 'react';
import OtherResourceType         from './others';
import VideoResourceType         from './video';
import ImageResourceType         from './image';
import AudioResourceType         from './audio';
import DidacticMapResourceType   from './didactic-map';
import ActivityGuideResourceType from './activity-guide';
import IResource                 from '../../../interfaces/entities/IResource';

type Props = {
    resource: IResource
};

const ResourceTypes: React.FC<Props> = ({ resource }) => {
    return (
        <>
            { resource.resource_type_id === 1 || resource.resource_type_id === 2 ?
                <OtherResourceType />
                : resource.resource_type_id === 3 ?
                    <VideoResourceType
                        records={resource.records} />
                    : resource.resource_type_id === 4 ?
                        <ImageResourceType
                            records={resource.records}
                           />
                        : resource.resource_type_id === 5 ?
                            <AudioResourceType
                                records={resource.records} />
                            : resource.resource_type_id === 6 ?
                                <DidacticMapResourceType
                                    description={ resource.description.substr(0, 100) } />
                                : resource.resource_type_id === 7 ?
                                    <ActivityGuideResourceType />
                                    : ''
            }
        </>
    );
};
export default ResourceTypes;