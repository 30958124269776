import React, { useEffect, useRef } from 'react';
import { Col, Form }                from 'antd';
import IDay                         from '../../../interfaces/entities/IDay';
import ResourceSearcher             from '../../resource/components/resourcesSearch';
import SuneEditor                   from 'suneditor-react';
import SunEditor                    from 'suneditor-react';
import { sunEditorButtonList }      from '../../resource/didacticMap/helpers';
import { ResourcesList }            from '../../resource/components/resourcesSearch/types';
import { FormInstance }             from 'rc-field-form';
import { FormValues }               from './types';
import IResource                    from '../../../interfaces/entities/IResource';

type Props = {
    day: IDay,
    form: FormInstance<FormValues>,
    assignedData?: {
        indication: string,
        resources: IResource[]
    }
    grade_id?: number,
    subject_id?: number
}

const DayAssignation: React.FC<Props> = ({ day, form, assignedData, grade_id, subject_id }) => {
    const searcherRef = useRef<ResourcesList>(null);
    const editor = useRef<SunEditor>();

    const getSunEditorInstance = (sunEditor: SunEditor) => {
        editor.current = sunEditor;
    };

    useEffect(() => {
        const { current } = editor;
        if (current !== undefined && assignedData !== undefined) {
            current.editor.setContents(assignedData.indication);
        }
    }, [assignedData]);

    const onChange = (resources: IResource[]) => {
        const resource_id = resources.map(r => r.id);
        form.setFieldsValue({
            resources: {
                [day.id]: {
                    id: resource_id
                }
            }
        });
    };

    return (
        <Col className="day-assignation-container" span={ 24 }>
            <div className="day-assignation-title">{ day.name }</div>

            <div className="day-assignation-content">
                <Form.Item
                    noStyle
                    name={ ['resources', day.id, 'indication'] }
                    initialValue={ assignedData?.indication ?? null }
                    hasFeedback
                >
                    <SuneEditor
                        ref={ getSunEditorInstance }
                        setOptions={ {
                            height: '200px',
                            buttonList: sunEditorButtonList
                        } }
                    />
                </Form.Item>
                <h3 className="title-sub-1">{ 'Buscador de recurso:' }</h3>
                <ResourceSearcher
                    grade_id={ grade_id }
                    subject_id={ subject_id }
                    stored_resources={ assignedData?.resources }
                    onSelectedResourcesChange={ onChange }
                    ref={ searcherRef }
                />
            </div>
        </Col>
    );
};

export default DayAssignation;
