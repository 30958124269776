import { Alert, Button, Col, Form, Input, Row, Select, Spin } from 'antd';
import React, { useCallback, useEffect, useState }            from 'react';
import { EntitiesEnum, FormValues }                           from './types';
import rules                                                  from './rules';
import AssignIndicators, { IndicatorsAssignedData }           from './AssignIndicators';
import DayAssignation                                         from './DayAssignation';
import { OptionData }                                         from 'rc-select/lib/interface';
import ILabel                                                 from '../../../interfaces/entities/ILabel';
import IDay                                                   from '../../../interfaces/entities/IDay';
import { genLoading }                                         from '../../../utils/views';
import { isAxiosError }                                       from '../../../utils/services';
import { AxiosError, AxiosResponse }                          from 'axios';
import useServiceByRef                                        from '../../../utils/hooks/useServiceByRef';
import LabelService                                           from '../../../api/services/LabelService';
import DayService                                             from '../../../api/services/DayService';
import { useSelector }                                        from 'react-redux';
import { IAppState }                                          from '../../../interfaces/store/IAppState';
import { FormInstance }                                       from 'rc-field-form';
import IActivityGuide                                         from '../../../interfaces/entities/IActivityGuide';
import './styles.less';

export type handleFinishType = {
    values: FormValues
    labels: ILabel[]
    form: FormInstance<FormValues>
    rerender: () => void
}

type Props = {
    onFinish: (props: handleFinishType) => void
    mode?: 'CREATE' | 'UPDATE'
    activityGuide?: IActivityGuide
    onDraft?: (props: handleFinishType) => void
}

type LoadingType = {
    [k in EntitiesEnum]: boolean
}

type ErrorsType = {
    [k in EntitiesEnum]: string | undefined
}

const accessibilityTypes: OptionData[] = [
    {
        value: 'PUBLIC',
        label: 'Público'
    },
    {
        value: 'PRIVATE',
        label: 'Privado'
    }
];

const initialLoading = genLoading<LoadingType>(EntitiesEnum);

const getFormInitialValues = (mode: 'CREATE' | 'UPDATE', activityGuide: null | IActivityGuide) => {
    if (mode === 'CREATE') {
        return {
            accessibility: accessibilityTypes[0].value,
            grade_id: undefined,
            academic_subject_id: undefined,
            unit_id: undefined,
            indicator_id: [],
            labels: []
        };
    } else if (mode === 'UPDATE' && activityGuide !== null) {
        return {
            title: activityGuide.title,
            description: activityGuide.description,
            accessibility: activityGuide.accessibility,
            labels: activityGuide.labels.map(l => l.name),
            unit_id: activityGuide.unit?.id,
            indicator_id: activityGuide.indicators.map(i => i.id),
            grade_id: activityGuide.unit?.academic_subject?.grade_id,
            academic_subject_id: activityGuide.unit?.academic_subject_id
        };
    }
};

const { Item, useForm } = Form;

const ActivityGuideForm: React.FC<Props> = (props) => {
    const { onFinish, mode = 'CREATE', activityGuide = null, onDraft } = props;

    const [form] = useForm();
    const readyForRequests = useSelector<IAppState, boolean>(({ axios }) => axios.readyForRequests);

    const [labels, setLabels] = useState<ILabel[]>([]);
    const [days, setDays] = useState<IDay[]>([]);

    const [loading, setLoading] = useState(initialLoading);
    const [errors, setErrors] = useState({} as ErrorsType);
    const [formInitialValues, setFormInitialValues] = useState(getFormInitialValues(mode, activityGuide));

    const [gradeId, setGradeId] = useState<number>(0);
    const [subjectId, setSubjectId] = useState<number>(0);

    const labelService = useServiceByRef(LabelService);
    const dayService = useServiceByRef(DayService);

    const [indicatorsAssignedData, setIndicatorsAssignedData] = useState<IndicatorsAssignedData | undefined>(undefined);

    const buttonLoading = Object.values(loading).indexOf(true) !== -1;

    const rerender = () => setLoading(prevState => ({ ...prevState }));

    const getLabels = useCallback(async () => {
        setLoading(prevState => ({ ...prevState, labels: true }));

        if (readyForRequests && labelService.current) {
            const res = await labelService.current.all();

            if (isAxiosError(res)) {
                const err = res as AxiosError;
                setErrors(prevState => ({ ...prevState, labels: err.message }));
            } else {
                const { data } = res as AxiosResponse<ILabel[]>;
                setLabels(data);
            }
        }

        setLoading(prevState => ({ ...prevState, labels: false }));
    }, [labelService, readyForRequests]);

    const getDays = useCallback(async () => {
        setLoading(prevState => ({ ...prevState, days: true }));

        if (dayService.current) {
            const res = await dayService.current.all();

            if (isAxiosError(res)) {
                const err = res as AxiosError;
                setErrors(prevState => ({ ...prevState, days: err.message }));
            } else {
                const { data } = res as AxiosResponse<IDay[]>;
                setDays(data);
            }
        }

        setLoading(prevState => ({ ...prevState, days: false }));
    }, [dayService]);

    useEffect(() => {
        // noinspection JSIgnoredPromiseFromCall
        getLabels();
        // noinspection JSIgnoredPromiseFromCall
        getDays();
    }, [getDays, getLabels]);

    useEffect(() => {
        if (activityGuide !== null) {
            setIndicatorsAssignedData({
                indicator_id: activityGuide.indicators.map(i => i.id),
                unit_id: activityGuide.unit?.id as string,
                academic_subject_id: activityGuide.unit?.academic_subject_id as string,
                grade_id: activityGuide.unit?.academic_subject?.grade_id as unknown as number
            });

            setFormInitialValues(getFormInitialValues(mode, activityGuide));

            if (activityGuide.unit?.academic_subject?.grade_id !== undefined && activityGuide.unit?.academic_subject?.subject_id !== undefined) {
                if (Number.isSafeInteger(activityGuide.unit?.academic_subject?.grade_id)) {
                    setGradeId(Number(activityGuide.unit?.academic_subject?.subject_id));
                }
                if (Number.isInteger(activityGuide.unit?.academic_subject?.subject_id)) {
                    setSubjectId(Number(activityGuide.unit?.academic_subject?.subject_id));
                }
            }
        }

    }, [activityGuide, mode]);

    return (
        <Form<FormValues>
            id="activity-guide-form-component"
            form={ form }
            layout="vertical"
            scrollToFirstError
            onFinish={ values => onFinish({ form, values, labels, rerender }) }
            initialValues={ formInitialValues }
        >
            <h2 className="title-resource">{ mode === 'CREATE' ? 'Crear' : 'Modificar' } Guía de Actividades</h2>
            <Row>
                <Col span={ 24 }>
                    <Item
                        name="title"
                        label={ <h3 className="title-sub-1">{ 'Título' }</h3> }
                        rules={ rules.title }
                        hasFeedback
                    >
                        <Input className="input-resource" placeholder="Escribe un título para la actividad" />
                    </Item>

                    <Item
                        name="labels"
                        label={ <h3 className="title-sub-1">{ 'Tags/palabras claves' }</h3> }
                        rules={ rules.labels }
                        hasFeedback={ errors.labels !== undefined }
                        validateStatus={ errors.labels !== undefined ? 'error' : undefined }
                        help={ errors.labels }
                    >
                        <Select
                            mode="tags"
                            className="select-resource"
                            loading={ loading.labels }
                            options={ labels.map(({ name }) => ({
                                value: name
                            })) }
                        />
                    </Item>

                    <Item
                        name="description"
                        label={ <h3 className="title-sub-1">{ 'Descripción' }</h3> }
                        rules={ rules.description }
                        hasFeedback
                    >
                        <Input.TextArea rows={ 5 } placeholder="Escribe una descripción para el recurso" className="input-resource" />
                    </Item>
                </Col>
            </Row>

            <AssignIndicators
                form={ form }
                assignedData={ indicatorsAssignedData }
                setGradeId={ setGradeId }
                setSubjectId={ setSubjectId }
            />

            <Spin spinning={ loading.days } size="large">
                <Row gutter={ [0, 30] }>
                    <Item name={ ['resources'] } noStyle>
                        <Input type="hidden" />
                    </Item>

                    { days.map(d => (
                        <DayAssignation
                            key={ d.id }
                            form={ form }
                            day={ d }
                            assignedData={ activityGuide !== null ? activityGuide.assigned_resources[d.id] : undefined }
                            grade_id={ gradeId }
                            subject_id={ subjectId }
                        />
                    )) }

                    { form.getFieldError('resources') !== undefined && form.getFieldError('resources').length > 0 && (
                        <Col span={ 24 }>
                            <Alert
                                type="error"
                                showIcon
                                message="Dato incorrecto en las asignaciones de recursos"
                                description={ form.getFieldError('resources')[0] }
                            />
                        </Col>
                    ) }

                    { errors.days !== undefined && (
                        <Col span={ 24 }>
                            <Alert
                                type="error"
                                showIcon
                                message="Ocurrió un error al intentar obtener información para el funcionamiento del formulario. Inténtalo más tarde."
                                description={ errors.days }
                            />
                        </Col>
                    ) }
                </Row>
            </Spin>

            <Item
                name="accessibility"
                label={ <h3 className="title-sub-1">{ 'Publicar como' }</h3> }
                rules={ rules.accessibility }
                hasFeedback
            >
                <Select options={ accessibilityTypes }  className="select-resource" />
            </Item>

            <Row justify="end" gutter={ 10 }>
                { onDraft !== undefined && (
                    <Col span={ 8 }>
                        <Button
                            block
                            id="save-as-draft-button"
                            onClick={ async () => {
                                const values: FormValues = await form.validateFields();
                                onDraft({ form, values, labels, rerender });
                            } }
                        >
                            Guardar como borrador
                        </Button>
                    </Col>
                ) }
                <Col span={ onDraft === undefined ? 24 : 16 }>
                    <Button
                        block
                        id="submit-button"
                        type="primary"
                        htmlType="submit"
                        loading={ buttonLoading }
                        disabled={
                            Object.values(errors).map(e => e !== undefined).length > 0
                            || buttonLoading
                        }
                    >
                        { mode === 'CREATE' ? 'Publicar' : 'Guardar cambios' }
                    </Button>
                </Col>
            </Row>
        </Form>
    );
};

export default ActivityGuideForm;
