import { useEffect, useState }      from 'react';
import Service                      from '../../api/abstract/Service';
import { AxiosInstance }            from 'axios';
import { ServiceConstructableType } from '../../types/api/services';
import { useSelector }              from "react-redux";
import { IAppState }                from "../../interfaces/store/IAppState";


function useService<T extends Service>(serviceClass: ServiceConstructableType<T>) {
    const axiosInstance = useSelector<IAppState, AxiosInstance>(({ axios }) => axios.instance);
    const [service, setService] = useState<T | null>(null);

    useEffect(() => {
        setService(new serviceClass(axiosInstance));
    }, [axiosInstance, serviceClass]);

    return service;
}

export default useService;