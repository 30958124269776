import React, { Fragment, useEffect, useState }                                 from 'react';
import { Button, Col, Form, Input, notification, Radio, Row, Spin, Typography } from 'antd';
import { useKeycloak }                                                          from '@react-keycloak/web';
import useService                                                               from '../../../utils/hooks/useService';
import AuthService
                                                                                from '../../../api/services/AuthService';
import { handleAxiosError, isAxiosError }                                       from '../../../utils/services';
import { AxiosError }                                                           from 'axios';
import setFormErrorsByAxiosResponse
                                                                                from '../../../utils/forms/setFormErrorsByAxiosResponse';
import fields
                                                                                from '../../components/RegisterUser/RegisterForm/fields';
import rules
                                                                                from '../../components/RegisterUser/RegisterForm/rules';
import { useSelector }                                                          from 'react-redux';
import { IAppState }                                                            from '../../../interfaces/store/IAppState';
import { IKeycloakUserInfo }                                                    from '../../../interfaces/store/states/IAuthState';

const { Text, Title } = Typography;
const { Item, useForm } = Form;

export interface IFormFields {
    name: string
    last_name: string
    code: string
    email: string
    gender: string
}

const UserSchoolRegister: React.FC = () => {
    const { keycloak } = useKeycloak();
    const [form] = useForm<IFormFields>();
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const service = useService(AuthService);

    const userSSO = useSelector<IAppState, IKeycloakUserInfo | null>(({ auth }) => auth.keycloakUserInfo);

    useEffect(() => {
        if (userSSO !== null) {
            setLoading(true);
            form.setFields([
                {
                    name: 'name',
                    value: userSSO.name
                },
                {
                    name: 'email',
                    value: userSSO.email
                },
            ]);
            setLoading(false);
        }
    }, [form, userSSO]);

    const handleSubmit = async (values: IFormFields) => {
        setLoading(true);

        const { email } = values;
        const data = { ...values, user_name: email, id: userSSO?.sub, from_sso: true };

        if (service !== null) {
            const res = await service.register(data);

            if (isAxiosError(res)) {
                handleAxiosError(res as AxiosError, {
                    422: r => setFormErrorsByAxiosResponse(form, r),
                    default: error => notification.error({
                        message: 'Ha ocurrido un error',
                        description: error.message
                    })
                });
            } else {
                setSuccess(true);
            }
        }

        setLoading(false);
    };

    // layout Form
    const layoutForm = {
        labelCol: { span: 24 },
        wrapperCol: { span: 24 },
    };

    return (
        <Fragment>
            <Row gutter={ [24, 8] } className="row-errors">
                <Col span={ 24 } className="errors-content">
                    { !success && (
                        <Fragment>
                            <Col span={ 24 }>
                                <Title>Registro de usuario Docente Soy</Title>
                                <p><Title level={ 4 }>
                                    <Text
                                        type="secondary">{ 'Te falta poco para poder hacer uso de Docente Soy, por favor complete tu registro.' }
                                    </Text>
                                </Title></p>
                            </Col>
                            <Col span={ 12 } offset={ 6 }>
                                <Spin spinning={ loading }>
                                    <Form<IFormFields>
                                        { ...layoutForm }
                                        form={ form }
                                        onFinish={ handleSubmit }
                                        layout="vertical"
                                        hideRequiredMark
                                    >
                                        { fields.map(({ label, name, rules: r }, i) => (
                                            <Item
                                                key={ i }
                                                name={ name }
                                                label={ label }
                                                rules={ r }
                                                hasFeedback
                                            >
                                                <Input type={ name.includes('email') ? 'email' : 'text' }
                                                       disabled={ name === 'email' } />
                                            </Item>
                                        )) }
                                        <Item
                                            name="gender"
                                            label="Género"
                                            rules={ rules.gender }
                                            hasFeedback
                                        >
                                            <Radio.Group buttonStyle="solid">
                                                <Radio.Button value="f">F</Radio.Button>
                                                <Radio.Button value="m">M</Radio.Button>
                                            </Radio.Group>
                                        </Item>

                                        <Row gutter={ [24, 8] }>
                                            <Col span={ 24 }>
                                                <Row gutter={ [24, 8] }>
                                                    <Col>
                                                        <Button type={ 'default' } shape={ 'round' } block
                                                                onClick={ () => {
                                                                    keycloak.logout({
                                                                        redirectUri: window.location.origin
                                                                    });
                                                                } }
                                                        >
                                                            Cancelar
                                                        </Button>
                                                    </Col>
                                                    <Col>
                                                        <Button shape={ 'round' }
                                                                block
                                                                type="primary"
                                                                htmlType="submit"
                                                                loading={ loading }
                                                        >
                                                            registrarse
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Form>
                                </Spin>
                            </Col>
                        </Fragment>
                    ) }
                </Col>
            </Row>
            { success && (
                <Row>
                    <Col span={ 12 } offset={ 6 } className="errors-content register-completed">
                        <Col span={ 24 }>
                            <Title>Registro de usuario Docente Soy: Completado</Title>
                            <p><Title level={ 4 }>
                                <Text
                                    type="secondary">{ 'Se enviaran a tu correo electrónico las credenciales de ingreso.\n' +
                                '                                Este proceso pude tomar un tiempo pronto enviaremos tus credenciales.' }
                                </Text>
                            </Title></p>
                        </Col>

                        <Button
                            block
                            size="large"
                            type="primary"
                            onClick={ () => {
                                keycloak.logout({
                                    redirectUri: window.location.origin
                                });
                            } }
                        >
                            Regresar
                        </Button>
                    </Col>
                </Row>
            ) }
        </Fragment>
    );
};
export default UserSchoolRegister;
