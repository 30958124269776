import React                               from 'react';
import { BrowserRouter, Redirect, Switch } from 'react-router-dom';

import teacherRoutes   from './routes-definitions/teacher';
import TeacherRoute    from './helpers/auth/TeacherRoute';
import publicRoutes    from './routes-definitions/public';
import PublicRoute     from './helpers/public/PublicRoute';
import lxdRoutes       from './routes-definitions/lxd';
import LxdRoute        from './helpers/auth/LxdRoute';
import adminRoutes     from './routes-definitions/admin';
import AdminRoute      from './helpers/auth/AdminRoute';
import principalRoutes from './routes-definitions/principal';
import PrincipalRoute  from './helpers/auth/PrincipalRoute';
import errorRoutes     from './routes-definitions/error';
import ErrorRoute      from './helpers/error/ErrorRoute';

const Router: React.FC = () => {
    return (
        <BrowserRouter>
            <Switch>
                { adminRoutes.map(({ component, path }, i) => (
                    <AdminRoute
                        exact
                        key={ i }
                        layoutActive={ path.includes('schools') || path === 'plannings/' || path === 'plannings/new' || path === 'plannings/edit/:planning_id' ? 'maintenance'
                            : path.includes('users/approve') ? 'maintenance' : path === 'resource/:resource_id' || path === 'activity_guide/s/:activity_guide_code'
                            || path === 'didactic_map/:didactic_map_id' ? 'resource' : 'other' }
                        path={ `/admin/${ path }` }
                        component={ component }
                    />
                )) }

                { teacherRoutes.map(({ component, path }, i) => (
                    <TeacherRoute
                        exact
                        key={ i }
                        resource={ path === 'resource/:resource_id' || path === 'didactic_map/:didactic_map_id' || path === 'activity_guide/s/:activity_guide_code' }
                        maintenance={ path === 'plannings/' || path === 'plannings/new' || path === 'plannings/edit/:planning_id' }
                        path={ `/teacher/${ path }` }
                        component={ component }
                    />
                )) }

                { lxdRoutes.map(({ component, path }, i) => (
                    <LxdRoute
                        exact
                        key={ i }
                        resource={ path === 'resource/:resource_id' || path === 'didactic_map/:didactic_map_id' || path === 'activity_guide/s/:activity_guide_code' }
                        path={ `/lxd/${ path }` }
                        component={ component }
                    />
                )) }

                { principalRoutes.map(({ component, path }, i) => (
                    <PrincipalRoute
                        exact
                        key={ i }
                        resource={ path === 'resource/:resource_id' || path === 'activity_guide/s/:activity_guide_code' || path === 'didactic_map/:didactic_map_id' }
                        path={ `/director/${ path }` }
                        component={ component }
                    />
                )) }

                { publicRoutes.map(({ component, path }, i) => (
                    <PublicRoute
                        exact
                        key={ i }
                        resource={ path === 'p/resource/:resource_id' || path === 'p/activity_guide/:activity_guide_code' }
                        path={ `/${ path }` }
                        component={ component }
                    />
                )) }

                { errorRoutes.map(({ component, path }, i) => (
                    <ErrorRoute
                        exact
                        key={ i }
                        path={ `${ path }` }
                        component={ component }
                    />
                )) }

                <Redirect to={ '/404' } />
            </Switch>

        </BrowserRouter>
    );
};

export default Router;
