import { ExtraActionCallbacks }                from '../../../../components/EntityRestTable/types';
import IUserSchool                             from '../../../../interfaces/entities/IUserSchool';
import { handleApproveUser, handleRejectUser } from './utils';
import { CheckOutlined, DeleteOutlined }       from '@ant-design/icons';
import React                                   from 'react';
import AuthService                             from '../../../../api/services/AuthService';
import { ModalFunctionsType }                  from '../../../../types/components/modals';

type tableActionsType = (
    service: AuthService | null,
    modal: ModalFunctionsType,
    setApproveLoadingType: React.Dispatch<React.SetStateAction<boolean>>
) => ExtraActionCallbacks<IUserSchool>;

const tableActions: tableActionsType = (service, modal, setApproveLoading) => ({
    approve: {
        title: 'Aprobar usuario',
        callback: (u, getData) => handleApproveUser(service, u, getData, setApproveLoading),
        content: <CheckOutlined />
    },
    decline: {
        title: 'Rechazar petición',
        callback: (u, getData) => handleRejectUser(service, modal, u, getData),
        content: <DeleteOutlined />
    }
});

export default tableActions;