import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { ITeacher }                                             from '../../../../../interfaces/entities/IUser';
import ISchool                                                  from '../../../../../interfaces/entities/ISchool';
import { Button, message, Table }                               from 'antd';
import { CheckOutlined }                                        from '@ant-design/icons';
import useServiceByRef                                          from '../../../../../utils/hooks/useServiceByRef';
import TeacherService                                           from '../../../../../api/services/TeacherService';
import { isAxiosError }                                         from '../../../../../utils/services';
import { AxiosError, AxiosResponse }                            from 'axios';

type Props = {
    teachers?: ITeacher[];
    selectedSchool?: ISchool | undefined;
    searchTeachersBySchool: (id: string) => Promise<void>;
    actionChangeStatus: (id: string, status: number) => void;
    loading: boolean;
    setLoading: Dispatch<SetStateAction<boolean>>;
    create: boolean;
    action: boolean;
};

const DisabledUsersTable: React.FC<Props> = ({
                                                 selectedSchool,
                                                 actionChangeStatus,
                                                 loading,
                                                 setLoading,
                                                 create, action
                                             }) => {
    const [teachersDisabled, setTeachersDisabled] = useState<ITeacher[]>([]);
    const teacherService = useServiceByRef(TeacherService);

    useEffect(() => {
        if (create) {
            setTeachersDisabled([]);
        }
    }, [create]);

    useEffect(() => {
        const getDisabledUsers = async () => {
            if (selectedSchool !== undefined && teacherService.current) {
                setLoading(true);
                const response = await teacherService.current.TeacherDisabledBySchool(selectedSchool.id);

                if (isAxiosError(response)) {
                    const err = response as AxiosError;
                    message.error(`Ocurrio un error al obtener los docentes: ${ err.message }`);
                } else {
                    const { data } = response as AxiosResponse<ITeacher[]>;
                    setTeachersDisabled(data);
                }
                setLoading(false);
            }
        };

        if (selectedSchool !== undefined) {
            getDisabledUsers().finally();
        } else {
            setTeachersDisabled([]);
        }
    }, [selectedSchool, teacherService, setLoading, action]);

    return (
        <Table<ITeacher> className={ 'table-maintenance' } rowKey="id" dataSource={ teachersDisabled }
                         scroll={ { x: '100%' } }
                         loading={ loading }
                         columns={ [
                             {
                                 title: 'Docentes',
                                 render: (_, teachers) => (`${ teachers.name }, ${ teachers.last_name }`),
                             },
                             {
                                 title: '| Colegio',
                                 render: (_, teachers) => (`${ teachers.teachers_schools?.[0].school?.name }`)
                             },
                             {
                                 title: '| Acción',
                                 render: (_, teachers) => (
                                     <Button className={ 'btn-table-action-active' } shape={ 'circle' }
                                             onClick={ () => {
                                                 actionChangeStatus(teachers.id, teachers.status_id);
                                             } }
                                             icon={ <CheckOutlined /> } />
                                 )
                             },
                         ] }
        />
    );
};
export default DisabledUsersTable;