import React      from 'react';
import { Layout } from 'antd';
import '../styles/style';
import HeaderAuth from "../components/header";
import FooterAuth from "../components/footer";

const { Header, Footer, Content } = Layout;

const PublicLayout: React.FC = ({ children }) => {
    return (
        <Layout className="auth-layout container">
            <Header className={ 'auth-header' }>
                <HeaderAuth/>
            </Header>
            <Content className="content background-banner">
                { children }
            </Content>
            <Footer className={ 'esefoot' }> <FooterAuth/></Footer>
        </Layout>
    );
};

export default PublicLayout;
