import React, { Dispatch, SetStateAction, useState }           from 'react';
import { Modal, Form, DatePicker, InputNumber, notification, } from 'antd';
import IUnit
                                                               from '../../../../../interfaces/entities/IUnit';
import moment                                                  from 'moment';
import {
    handleAxiosError,
    isAxiosError
}                                                              from '../../../../../utils/services';
import { AxiosError, AxiosResponse }                           from 'axios';
import useServiceByRef
                                                               from '../../../../../utils/hooks/useServiceByRef';
import PlanningService
                                                               from '../../../../../api/services/PlanningService';
import setFormErrorsByAxiosResponse
                                                               from '../../../../../utils/forms/setFormErrorsByAxiosResponse';
import IAnnualPlanning
                                                               from '../../../../../interfaces/entities/IAnnualPlanning';

const { RangePicker } = DatePicker;

type Props = {
    unit: IUnit;
    planning_id: string;
    showModalEditUnit: boolean;
    onModalEditUnitMdlClose: () => void;
    setUnits: Dispatch<SetStateAction<IUnit[]>>
}

const ModalFormEdit: React.FC<Props> = ({
                                            unit,
                                            planning_id,
                                            showModalEditUnit,
                                            onModalEditUnitMdlClose,
                                            setUnits
                                        }) => {

    const [form] = Form.useForm();
    const planningService = useServiceByRef(PlanningService);

    const dateFormatList = ['DD/MM/YYYY', 'DD/MM/YY'];
    const [loading, setLoading] = useState<boolean>(false);

    const layoutForm = {
        labelCol: { span: 24 },
        wrapperCol: { span: 24 },
    };

    const onFinish = async (values: any) => {
        if (planningService.current) {
            setLoading(true);
            const start_date = moment(values.dates_duration[0]).format('YYYY/MM/DD');
            const end_date = moment(values.dates_duration[1]).format('YYYY/MM/DD');

            const data = { ...values, start_date, end_date, unit_id: unit.id };
            const response = await planningService.current.updateUnitPivot(planning_id, data);
            if (isAxiosError(response)) {
                handleAxiosError(response as AxiosError, {
                    422: response => {
                        setFormErrorsByAxiosResponse(form, response);
                        notification['error']({
                            message: 'Ocurrio un error',
                            description: 'Favor verificar que los datos sean validos',
                        });
                    },
                    500: error => notification.error({
                        message: 'Ha ocurrido un error al actualizar la unidad del plan anual',
                        description: error.message,
                    })
                });
            } else {
                const { data } = response as AxiosResponse<IAnnualPlanning>;
                if (data.units !== undefined) {
                    setUnits(data.units);
                    onModalEditUnitMdlClose();
                    Modal.destroyAll();
                    notification['success']({
                        message: 'Operación finalizada',
                        description:
                            'La unidad de la planificación ha sido modificada exitosamente.',
                        duration: 3
                    });
                }
            }
            setLoading(false);
        }
    };

    return (
        <Modal
            centered
            visible={ showModalEditUnit }
            key={ `edit-unit-${ unit.id }-planning-${ planning_id }` }
            title={ <h3 className="title-sub-1">{ `Editar unidad: ${ unit.name }` }</h3> }
            width={ 400 }
            cancelButtonProps={ { style: { display: 'none' } } }
            okText={ 'Guardar' }
            className={ 'btn-modal-maintenance' }
            onCancel={ () => {
                onModalEditUnitMdlClose();
                Modal.destroyAll();
            } }
            onOk={ () => {
                form
                    .validateFields()
                    .then((values) => {
                        form.resetFields();
                        onFinish(values);
                    });
            } }
            confirmLoading={ loading }
        >
            <Form
                { ...layoutForm }
                form={ form }
                name="edit-unit-planning">
                <Form.Item
                    label={ <h3 className="title-sub-1">{ 'Horas clases' }</h3> }
                    name="hours_classes"
                    rules={ [{ required: true, message: 'La cantidad de horas es requerida' }] }
                    initialValue={ unit.pivot !== undefined && unit.pivot.hours_classes !== 0 ? unit.pivot.hours_classes : 1 }
                    hasFeedback
                >
                    <InputNumber min={ 1 } style={ { width: '100%' } } />
                </Form.Item>
                <Form.Item
                    label={ <h3 className="title-sub-1">{ 'Fechas de duración (Inicio - Fin)' }</h3> }
                    name="dates_duration"
                    rules={ [{ required: true, message: 'Las fechas de duración son requeridas' }] }
                    initialValue={ unit.pivot !== undefined && unit.pivot.start_date !== null && unit.pivot.end_date !== null ? [moment(unit.pivot?.start_date), moment(unit.pivot?.end_date)] : '' }
                    hasFeedback
                >
                    <RangePicker format={ dateFormatList } style={ { width: '100%' } } />
                </Form.Item>
            </Form>
        </Modal>
    );
};
export default ModalFormEdit;
