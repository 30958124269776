import React, { Fragment } from 'react';
import ImgLink             from '../../../../views/images/sociales.svg';
import UrlType             from '../../../../views/images/type-resource/link.svg';
import { Image }           from 'antd';

const OtherResourceType: React.FC = () => {
    return (
        <Fragment>
            {/*TODO: define what type of image it will take*/ }
            <Image
                style={ { borderBottomRightRadius: '16px' } }
                height={ '100%' }
                preview={ false }
                src={ ImgLink }
                alt="" />
            <div className="type_resource">
                <img src={ UrlType } alt="Type_video" />
            </div>
        </Fragment>
    );
};
export default OtherResourceType;
